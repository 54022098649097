@charset "UTF-8";
/*
    Name: Bookland 
    Author: DexignZone
    Portfolio : https://themeforest.net/user/dexignzone/portfolio
   
    Table of Content: 

    1. Abstract
    2. Bootstrap CSS
    3. Base
    4. Layout
    5. Component
    7. Pages
    
*/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400&display=swap');
@import url("../icons/fontawesome/css/all.min.css");
@import url("../icons/flaticon/font/flaticon.css");
@import url("../icons/themify/themify-icons.css");
@import url("../../../node_modules/video-react/dist/video-react.css"); 

:root {
  --gradient: linear-gradient(to right, rgb(255, 64, 62) 0%, rgb(255, 129, 27) 100%);
  --gradient-sec: linear-gradient(to right, rgb(57, 26, 166) 0%, rgb(6, 163, 245) 100%);
  --primary: #202020;
  --secondary: #1a1668;
  --primary-hover: #e58c23;
  --primary-dark: #915611;
  --rgba-primary-1: rgba(234, 164, 81, 0.1);
  --rgba-primary-2: rgba(234, 164, 81, 0.2);
  --rgba-primary-3: rgba(234, 164, 81, 0.3);
  --rgba-primary-4: rgba(234, 164, 81, 0.4);
  --rgba-primary-5: rgba(234, 164, 81, 0.5);
  --rgba-primary-6: rgba(234, 164, 81, 0.6);
  --rgba-primary-7: rgba(234, 164, 81, 0.7);
  --rgba-primary-8: rgba(234, 164, 81, 0.8);
  --rgba-primary-9: rgba(234, 164, 81, 0.9);
  --font-family-base: Open Sans, sans-serif;
  --grey: #f7f7f7;
  --border-radius-base: 6px;
  --font-family-title: Poppins, sans-serif;
  --title: #000000;
  --headings-font-weight: 600;
  --headings-font-weight2: 400;
  --headings-font-weight3: 300;
}

/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #000000;
  --bs-secondary: #1a1668;
  --bs-success: #029e76;
  --bs-info: #00aeff;
  --bs-warning: #fea500;
  --bs-danger: #FF1E6F;
  --bs-light: #f5f5f5;
  --bs-dark: #212529;
  --bs-primary-rgb: 234, 164, 81;
  --bs-secondary-rgb: 26, 22, 104;
  --bs-success-rgb: 2, 158, 118;
  --bs-info-rgb: 0, 174, 255;
  --bs-warning-rgb: 254, 165, 0;
  --bs-danger-rgb: 255, 30, 111;
  --bs-light-rgb: 245, 245, 245;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 119, 119, 119;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Open Sans, sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.6;
  --bs-body-color: #777777;
  --bs-body-bg: #ffffff;
}

/* START CUSTOM DEFINED CSS */
.user-menu {
  /* width: 200px;
  height: 150px; */
  overflow-y: auto;
}

.user-menu a {
  border-left: 3px solid transparent;
  border-top: 1px solid #eee;
  color: #232323;
  display: inline-block;
  padding: 15px 20px;
  width: 100%;
}

.user-menu a:hover {
  border-left-color: var(--primary);
  background-color: #eaa4511a;
}

.user-menu a.active {
  background-color: #eaa4511a;
  border-left-color: var(--primary);
  font-weight: bold;
}

.user-menu a i {
  color: var(--primary);
  margin-right: 14px;
}

.shipping-addresses {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.shipping-addresses > div {
  flex: 1 0 100%;
  padding: 0rem;
}

.shipping-addresses > div.subscription-plan {
  flex: 1 0 32%;
  padding: 0rem;
  margin-bottom: 20px;
}

.shipping-addresses > div > input[type="radio"] {
  display: none;
}
.shipping-addresses > div > input[type="radio"]:not(:disabled) ~ label {
  cursor: pointer;
}
.shipping-addresses > div > label {
  height: 100%;
  display: block;
  background: white;
  border: 2px solid hsla(0, 0%, 0%, 0.8);
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  box-shadow: 0px 3px 10px -2px hsla(150, 5%, 65%, 0.5);
  position: relative;
}
.shipping-addresses > div > input[type="radio"]:checked + label {
  background: hsla(0, 0%, 0%, 0.8);
  color: hsla(215, 0%, 100%, 1);
  box-shadow: 0px 0px 20px hsla(0, 0%, 0%, 0.8);
}
.shipping-addresses > div > input[type="radio"]:checked + label::after {
  color: hsla(215, 5%, 25%, 1);
  font-family: FontAwesome;
  border: 2px solid hsla(0, 0%, 0%, 0.8);
  content: "\f00c";
  font-size: 24px;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  background: white;
  box-shadow: 0px 2px 5px -2px hsla(0, 0%, 0%, 0.25);
}
.shipping-addresses > div > input[type="radio"]#control_05:checked + label {
  background: red;
  border-color: red;
}
.shipping-addresses > div > p {
  font-weight: 900;
}

@media only screen and (max-width: 700px) {
  .shipping-addresses {
    flex-direction: column;
  }
}

th.currency-view {
  text-align: right;
}

td.currency-view {
  text-align: right;
}

.buy-option {
  display: flex;
  justify-content: flex-end;
}

.buy-info {
  display: flex;
  flex-direction: column;
  justify-content: left;
  vertical-align: middle;
}

.buy-info > p, h5 {
  margin: 0 !important;
}

a.link-to-category {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  text-align: center;
}

img.related-books {
  width: 250px !important;
}
/* END CUSTOM DEFINED CSS */

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

.padding-top-20 {
  padding-top: 20px !important;
} 

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

p.offscreen {
  display: none;
  /* display: inline;
  border-color: #000000;
  border-radius: 5px;
  background-color: --bs-info; */
}

p.instructions {
  background-color: #efedff;
  border: 1px solid #ac68b8;
  border-radius: 3px;
  color: #9c27b0;
  padding: 5px;
  margin-top: 10px;
  font-size: 10px;
}

p.errmsg {
  background-color: rgb(247, 195, 195);
  border: 1px solid rgb(247, 126, 126);
  border-radius: 3px;
  color: rgb(168, 18, 18);
  padding: 5px;
  margin-top: 10px;
  font-size: 10px;
}

p.succesmsg {
  background-color: rgb(11, 247, 82);
  border: 1px solid rgb(3, 155, 36);
  border-radius: 3px;
  color: rgb(3, 70, 12);
  padding: 5px;
  margin-top: 10px;
  font-size: 10px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #1a1668;
}

h1,
.h1 {
  font-size: calc(1.3875rem + 1.65vw);
}

@media (min-width: 1200px) {

  h1,
  .h1 {
    font-size: 2.625rem;
  }
}

h2,
.h2 {
  font-size: calc(1.35rem + 1.2vw);
}

@media (min-width: 1200px) {

  h2,
  .h2 {
    font-size: 2.25rem;
  }
}

h3,
.h3 {
  font-size: calc(1.3125rem + 0.75vw);
}

@media (min-width: 1200px) {

  h3,
  .h3 {
    font-size: 1.875rem;
  }
}

h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {

  h4,
  .h4 {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1.125rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  color:black;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #EAA451;
  text-decoration: underline;
}

a:hover {
  color: #bb8341;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr
    /* rtl:ignore */
  ;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}

a>code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.75rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: rgba(151, 151, 159, 0.39);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend+* {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote> :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1200px;
  }
}

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto>* {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1>* {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2>* {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3>* {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4>* {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5>* {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6>* {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-sm-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }

  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-md-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-lg-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-xl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1440) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-xxl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66667%;
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #777777;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #777777;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #777777;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #777777;
  vertical-align: top;
  border-color: #E1E1F0;
}

.table> :not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table>tbody {
  vertical-align: inherit;
}

.table>thead {
  vertical-align: bottom;
}

.table> :not(:first-child) {
  border-top: 2px solid currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm> :not(caption)>*>* {
  padding: 0.25rem 0.25rem;
}

.table-bordered> :not(caption)>* {
  border-width: 1px 0;
}

.table-bordered> :not(caption)>*>* {
  border-width: 0 1px;
}

.table-borderless> :not(caption)>*>* {
  border-bottom-width: 0;
}

.table-borderless> :not(:first-child) {
  border-top-width: 0;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover>tbody>tr:hover>* {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #fbeddc;
  --bs-table-striped-bg: #eee1d1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e2d5c6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e8dbcc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e2d5c6;
}

.table-secondary {
  --bs-table-bg: #d1d0e1;
  --bs-table-striped-bg: #c7c6d6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcbbcb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1c0d0;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcbbcb;
}

.table-success {
  --bs-table-bg: #ccece4;
  --bs-table-striped-bg: #c2e0d9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8d4cd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bddad3;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b8d4cd;
}

.table-info {
  --bs-table-bg: #ccefff;
  --bs-table-striped-bg: #c2e3f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8d7e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdddec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b8d7e6;
}

.table-warning {
  --bs-table-bg: #ffedcc;
  --bs-table-striped-bg: #f2e1c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d5b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecdbbd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6d5b8;
}

.table-danger {
  --bs-table-bg: #ffd2e2;
  --bs-table-striped-bg: #f2c8d7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6bdcb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecc2d1;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6bdcb;
}

.table-light {
  --bs-table-bg: #f5f5f5;
  --bs-table-striped-bg: #e9e9e9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dddddd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e3e3e3;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dddddd;
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #ffffff;
  color: #ffffff;
  border-color: #373b3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1439.98) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: rgba(151, 151, 159, 0.39);
}

.form-control,
.woocommerce #review_form #respond input[type="text"],
.woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond input[type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr .quantity input,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="text"],
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="email"],
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="tel"],
.woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text,
.woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce-EditAccountForm.edit-account input[type="text"],
.woocommerce-EditAccountForm.edit-account input[type="email"],
.woocommerce-EditAccountForm.edit-account input[type="password"],
.login input[type="text"],
.login input[type="password"],
.woocommerce-ResetPassword.lost_reset_password input[type="text"],
.checkout.woocommerce-checkout input[type="text"],
.checkout.woocommerce-checkout input[type="email"],
.checkout.woocommerce-checkout input[type="tel"],
.checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout textarea,
.woocommerce form .form-row input.input-text,
.woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .select2-choice {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #777777;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

  .form-control,
  .woocommerce #review_form #respond input[type="text"],
  .woocommerce #review_form #respond textarea,
  .woocommerce #review_form #respond input[type="email"],
  .woocommerce-cart-form .table tr td.actions .coupon .input-text,
  .woocommerce-cart-form .table tr .quantity input,
  .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
  .woocommerce .woocommerce-checkout.checkout form .form-row input[type="text"],
  .woocommerce .woocommerce-checkout.checkout form .form-row input[type="email"],
  .woocommerce .woocommerce-checkout.checkout form .form-row input[type="tel"],
  .woocommerce .woocommerce-checkout.checkout form .form-row select,
  .woocommerce .woocommerce-checkout.checkout form .form-row input.input-text,
  .woocommerce .woocommerce-checkout.checkout form .form-row input,
  .woocommerce-EditAccountForm.edit-account input[type="text"],
  .woocommerce-EditAccountForm.edit-account input[type="email"],
  .woocommerce-EditAccountForm.edit-account input[type="password"],
  .login input[type="text"],
  .login input[type="password"],
  .woocommerce-ResetPassword.lost_reset_password input[type="text"],
  .checkout.woocommerce-checkout input[type="text"],
  .checkout.woocommerce-checkout input[type="email"],
  .checkout.woocommerce-checkout input[type="tel"],
  .checkout.woocommerce-checkout .select2-choice,
  .checkout.woocommerce-checkout textarea,
  .woocommerce form .form-row input.input-text,
  .woocommerce form .form-row .select2-selection,
  .woocommerce form .form-row .select2-choice {
    transition: none;
  }
}

.form-control[type="file"],
.woocommerce #review_form #respond input[type="file"][type="text"],
.woocommerce #review_form #respond textarea[type="file"],
.woocommerce #review_form #respond input[type="file"][type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .input-text[type="file"],
.woocommerce-cart-form .table tr .quantity input[type="file"],
.woocommerce .woocommerce-checkout.checkout form .form-row textarea[type="file"],
.woocommerce .woocommerce-checkout.checkout form .form-row select[type="file"],
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="file"],
.woocommerce-EditAccountForm.edit-account input[type="file"][type="text"],
.woocommerce-EditAccountForm.edit-account input[type="file"][type="email"],
.woocommerce-EditAccountForm.edit-account input[type="file"][type="password"],
.login input[type="file"][type="text"],
.login input[type="file"][type="password"],
.woocommerce-ResetPassword.lost_reset_password input[type="file"][type="text"],
.checkout.woocommerce-checkout input[type="file"][type="text"],
.checkout.woocommerce-checkout input[type="file"][type="email"],
.checkout.woocommerce-checkout input[type="file"][type="tel"],
.checkout.woocommerce-checkout .select2-choice[type="file"],
.checkout.woocommerce-checkout textarea[type="file"],
.woocommerce form .form-row input.input-text[type="file"],
.woocommerce form .form-row .select2-selection[type="file"],
.woocommerce form .form-row .select2-choice[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]),
.woocommerce #review_form #respond input[type="file"]:not(:disabled):not([readonly])[type="text"],
.woocommerce #review_form #respond textarea[type="file"]:not(:disabled):not([readonly]),
.woocommerce #review_form #respond input[type="file"]:not(:disabled):not([readonly])[type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .input-text[type="file"]:not(:disabled):not([readonly]),
.woocommerce-cart-form .table tr .quantity input[type="file"]:not(:disabled):not([readonly]),
.woocommerce .woocommerce-checkout.checkout form .form-row select[type="file"]:not(:disabled):not([readonly]),
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="file"]:not(:disabled):not([readonly]),
.woocommerce-EditAccountForm.edit-account input[type="file"]:not(:disabled):not([readonly])[type="text"],
.woocommerce-EditAccountForm.edit-account input[type="file"]:not(:disabled):not([readonly])[type="email"],
.woocommerce-EditAccountForm.edit-account input[type="file"]:not(:disabled):not([readonly])[type="password"],
.login input[type="file"]:not(:disabled):not([readonly])[type="text"],
.login input[type="file"]:not(:disabled):not([readonly])[type="password"],
.woocommerce-ResetPassword.lost_reset_password input[type="file"]:not(:disabled):not([readonly])[type="text"],
.checkout.woocommerce-checkout input[type="file"]:not(:disabled):not([readonly])[type="text"],
.checkout.woocommerce-checkout input[type="file"]:not(:disabled):not([readonly])[type="email"],
.checkout.woocommerce-checkout input[type="file"]:not(:disabled):not([readonly])[type="tel"],
.checkout.woocommerce-checkout .select2-choice[type="file"]:not(:disabled):not([readonly]),
.checkout.woocommerce-checkout textarea[type="file"]:not(:disabled):not([readonly]),
.woocommerce form .form-row input.input-text[type="file"]:not(:disabled):not([readonly]),
.woocommerce form .form-row .select2-selection[type="file"]:not(:disabled):not([readonly]),
.woocommerce form .form-row .select2-choice[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus,
.woocommerce #review_form #respond input:focus[type="text"],
.woocommerce #review_form #respond textarea:focus,
.woocommerce #review_form #respond input:focus[type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .input-text:focus,
.woocommerce-cart-form .table tr .quantity input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input:focus,
.woocommerce-EditAccountForm.edit-account input:focus[type="text"],
.woocommerce-EditAccountForm.edit-account input:focus[type="email"],
.woocommerce-EditAccountForm.edit-account input:focus[type="password"],
.login input:focus[type="text"],
.login input:focus[type="password"],
.woocommerce-ResetPassword.lost_reset_password input:focus[type="text"],
.checkout.woocommerce-checkout input:focus[type="text"],
.checkout.woocommerce-checkout input:focus[type="email"],
.checkout.woocommerce-checkout input:focus[type="tel"],
.checkout.woocommerce-checkout .select2-choice:focus,
.checkout.woocommerce-checkout textarea:focus,
.woocommerce form .form-row input.input-text:focus,
.woocommerce form .form-row .select2-selection:focus,
.woocommerce form .form-row .select2-choice:focus {
  color: #777777;
  background-color: #ffffff;
  border-color: #f5d2a8;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(234, 164, 81, 0.25);
}

.form-control::-webkit-date-and-time-value,
.woocommerce #review_form #respond input[type="text"]::-webkit-date-and-time-value,
.woocommerce #review_form #respond textarea::-webkit-date-and-time-value,
.woocommerce #review_form #respond input[type="email"]::-webkit-date-and-time-value,
.woocommerce-cart-form .table tr td.actions .coupon .input-text::-webkit-date-and-time-value,
.woocommerce-cart-form .table tr .quantity input::-webkit-date-and-time-value,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea::-webkit-date-and-time-value,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="text"]::-webkit-date-and-time-value,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="email"]::-webkit-date-and-time-value,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="tel"]::-webkit-date-and-time-value,
.woocommerce .woocommerce-checkout.checkout form .form-row select::-webkit-date-and-time-value,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text::-webkit-date-and-time-value,
.woocommerce .woocommerce-checkout.checkout form .form-row input::-webkit-date-and-time-value,
.woocommerce-EditAccountForm.edit-account input[type="text"]::-webkit-date-and-time-value,
.woocommerce-EditAccountForm.edit-account input[type="email"]::-webkit-date-and-time-value,
.woocommerce-EditAccountForm.edit-account input[type="password"]::-webkit-date-and-time-value,
.login input[type="text"]::-webkit-date-and-time-value,
.login input[type="password"]::-webkit-date-and-time-value,
.woocommerce-ResetPassword.lost_reset_password input[type="text"]::-webkit-date-and-time-value,
.checkout.woocommerce-checkout input[type="text"]::-webkit-date-and-time-value,
.checkout.woocommerce-checkout input[type="email"]::-webkit-date-and-time-value,
.checkout.woocommerce-checkout input[type="tel"]::-webkit-date-and-time-value,
.checkout.woocommerce-checkout .select2-choice::-webkit-date-and-time-value,
.checkout.woocommerce-checkout textarea::-webkit-date-and-time-value,
.woocommerce form .form-row input.input-text::-webkit-date-and-time-value,
.woocommerce form .form-row .select2-selection::-webkit-date-and-time-value,
.woocommerce form .form-row .select2-choice::-webkit-date-and-time-value {
  height: 1.6em;
}

.form-control::placeholder,
.woocommerce #review_form #respond input[type="text"]::placeholder,
.woocommerce #review_form #respond textarea::placeholder,
.woocommerce #review_form #respond input[type="email"]::placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .input-text::placeholder,
.woocommerce-cart-form .table tr .quantity input::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="text"]::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="email"]::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="tel"]::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row select::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input::placeholder,
.woocommerce-EditAccountForm.edit-account input[type="text"]::placeholder,
.woocommerce-EditAccountForm.edit-account input[type="email"]::placeholder,
.woocommerce-EditAccountForm.edit-account input[type="password"]::placeholder,
.login input[type="text"]::placeholder,
.login input[type="password"]::placeholder,
.woocommerce-ResetPassword.lost_reset_password input[type="text"]::placeholder,
.checkout.woocommerce-checkout input[type="text"]::placeholder,
.checkout.woocommerce-checkout input[type="email"]::placeholder,
.checkout.woocommerce-checkout input[type="tel"]::placeholder,
.checkout.woocommerce-checkout .select2-choice::placeholder,
.checkout.woocommerce-checkout textarea::placeholder,
.woocommerce form .form-row input.input-text::placeholder,
.woocommerce form .form-row .select2-selection::placeholder,
.woocommerce form .form-row .select2-choice::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.woocommerce #review_form #respond input:disabled[type="text"],
.woocommerce #review_form #respond textarea:disabled,
.woocommerce #review_form #respond input:disabled[type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .input-text:disabled,
.woocommerce-cart-form .table tr .quantity input:disabled,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea:disabled,
.woocommerce .woocommerce-checkout.checkout form .form-row select:disabled,
.woocommerce .woocommerce-checkout.checkout form .form-row input:disabled,
.woocommerce-EditAccountForm.edit-account input:disabled[type="text"],
.woocommerce-EditAccountForm.edit-account input:disabled[type="email"],
.woocommerce-EditAccountForm.edit-account input:disabled[type="password"],
.login input:disabled[type="text"],
.login input:disabled[type="password"],
.woocommerce-ResetPassword.lost_reset_password input:disabled[type="text"],
.checkout.woocommerce-checkout input:disabled[type="text"],
.checkout.woocommerce-checkout input:disabled[type="email"],
.checkout.woocommerce-checkout input:disabled[type="tel"],
.checkout.woocommerce-checkout .select2-choice:disabled,
.checkout.woocommerce-checkout textarea:disabled,
.woocommerce form .form-row input.input-text:disabled,
.woocommerce form .form-row .select2-selection:disabled,
.woocommerce form .form-row .select2-choice:disabled,
.form-control[readonly],
.woocommerce #review_form #respond input[readonly][type="text"],
.woocommerce #review_form #respond textarea[readonly],
.woocommerce #review_form #respond input[readonly][type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .input-text[readonly],
.woocommerce-cart-form .table tr .quantity input[readonly],
.woocommerce .woocommerce-checkout.checkout form .form-row textarea[readonly],
.woocommerce .woocommerce-checkout.checkout form .form-row select[readonly],
.woocommerce .woocommerce-checkout.checkout form .form-row input[readonly],
.woocommerce-EditAccountForm.edit-account input[readonly][type="text"],
.woocommerce-EditAccountForm.edit-account input[readonly][type="email"],
.woocommerce-EditAccountForm.edit-account input[readonly][type="password"],
.login input[readonly][type="text"],
.login input[readonly][type="password"],
.woocommerce-ResetPassword.lost_reset_password input[readonly][type="text"],
.checkout.woocommerce-checkout input[readonly][type="text"],
.checkout.woocommerce-checkout input[readonly][type="email"],
.checkout.woocommerce-checkout input[readonly][type="tel"],
.checkout.woocommerce-checkout .select2-choice[readonly],
.checkout.woocommerce-checkout textarea[readonly],
.woocommerce form .form-row input.input-text[readonly],
.woocommerce form .form-row .select2-selection[readonly],
.woocommerce form .form-row .select2-choice[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control::file-selector-button,
.woocommerce #review_form #respond input[type="text"]::file-selector-button,
.woocommerce #review_form #respond textarea::file-selector-button,
.woocommerce #review_form #respond input[type="email"]::file-selector-button,
.woocommerce-cart-form .table tr td.actions .coupon .input-text::file-selector-button,
.woocommerce-cart-form .table tr .quantity input::file-selector-button,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea::file-selector-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="text"]::file-selector-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="email"]::file-selector-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="tel"]::file-selector-button,
.woocommerce .woocommerce-checkout.checkout form .form-row select::file-selector-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text::file-selector-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input::file-selector-button,
.woocommerce-EditAccountForm.edit-account input[type="text"]::file-selector-button,
.woocommerce-EditAccountForm.edit-account input[type="email"]::file-selector-button,
.woocommerce-EditAccountForm.edit-account input[type="password"]::file-selector-button,
.login input[type="text"]::file-selector-button,
.login input[type="password"]::file-selector-button,
.woocommerce-ResetPassword.lost_reset_password input[type="text"]::file-selector-button,
.checkout.woocommerce-checkout input[type="text"]::file-selector-button,
.checkout.woocommerce-checkout input[type="email"]::file-selector-button,
.checkout.woocommerce-checkout input[type="tel"]::file-selector-button,
.checkout.woocommerce-checkout .select2-choice::file-selector-button,
.checkout.woocommerce-checkout textarea::file-selector-button,
.woocommerce form .form-row input.input-text::file-selector-button,
.woocommerce form .form-row .select2-selection::file-selector-button,
.woocommerce form .form-row .select2-choice::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #777777;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

  .form-control::file-selector-button,
  .woocommerce #review_form #respond input[type="text"]::file-selector-button,
  .woocommerce #review_form #respond textarea::file-selector-button,
  .woocommerce #review_form #respond input[type="email"]::file-selector-button,
  .woocommerce-cart-form .table tr td.actions .coupon .input-text::file-selector-button,
  .woocommerce-cart-form .table tr .quantity input::file-selector-button,
  .woocommerce .woocommerce-checkout.checkout form .form-row textarea::file-selector-button,
  .woocommerce .woocommerce-checkout.checkout form .form-row input[type="text"]::file-selector-button,
  .woocommerce .woocommerce-checkout.checkout form .form-row input[type="email"]::file-selector-button,
  .woocommerce .woocommerce-checkout.checkout form .form-row input[type="tel"]::file-selector-button,
  .woocommerce .woocommerce-checkout.checkout form .form-row select::file-selector-button,
  .woocommerce .woocommerce-checkout.checkout form .form-row input.input-text::file-selector-button,
  .woocommerce .woocommerce-checkout.checkout form .form-row input::file-selector-button,
  .woocommerce-EditAccountForm.edit-account input[type="text"]::file-selector-button,
  .woocommerce-EditAccountForm.edit-account input[type="email"]::file-selector-button,
  .woocommerce-EditAccountForm.edit-account input[type="password"]::file-selector-button,
  .login input[type="text"]::file-selector-button,
  .login input[type="password"]::file-selector-button,
  .woocommerce-ResetPassword.lost_reset_password input[type="text"]::file-selector-button,
  .checkout.woocommerce-checkout input[type="text"]::file-selector-button,
  .checkout.woocommerce-checkout input[type="email"]::file-selector-button,
  .checkout.woocommerce-checkout input[type="tel"]::file-selector-button,
  .checkout.woocommerce-checkout .select2-choice::file-selector-button,
  .checkout.woocommerce-checkout textarea::file-selector-button,
  .woocommerce form .form-row input.input-text::file-selector-button,
  .woocommerce form .form-row .select2-selection::file-selector-button,
  .woocommerce form .form-row .select2-choice::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce #review_form #respond input[type="text"]:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce #review_form #respond textarea:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce #review_form #respond input[type="email"]:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce-cart-form .table tr td.actions .coupon .input-text:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce-cart-form .table tr .quantity input:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce .woocommerce-checkout.checkout form .form-row select:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce-EditAccountForm.edit-account input[type="text"]:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce-EditAccountForm.edit-account input[type="email"]:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce-EditAccountForm.edit-account input[type="password"]:hover:not(:disabled):not([readonly])::file-selector-button,
.login input[type="text"]:hover:not(:disabled):not([readonly])::file-selector-button,
.login input[type="password"]:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce-ResetPassword.lost_reset_password input[type="text"]:hover:not(:disabled):not([readonly])::file-selector-button,
.checkout.woocommerce-checkout input[type="text"]:hover:not(:disabled):not([readonly])::file-selector-button,
.checkout.woocommerce-checkout input[type="email"]:hover:not(:disabled):not([readonly])::file-selector-button,
.checkout.woocommerce-checkout input[type="tel"]:hover:not(:disabled):not([readonly])::file-selector-button,
.checkout.woocommerce-checkout .select2-choice:hover:not(:disabled):not([readonly])::file-selector-button,
.checkout.woocommerce-checkout textarea:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce form .form-row input.input-text:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce form .form-row .select2-selection:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce form .form-row .select2-choice:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control::-webkit-file-upload-button,
.woocommerce #review_form #respond input[type="text"]::-webkit-file-upload-button,
.woocommerce #review_form #respond textarea::-webkit-file-upload-button,
.woocommerce #review_form #respond input[type="email"]::-webkit-file-upload-button,
.woocommerce-cart-form .table tr td.actions .coupon .input-text::-webkit-file-upload-button,
.woocommerce-cart-form .table tr .quantity input::-webkit-file-upload-button,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea::-webkit-file-upload-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="text"]::-webkit-file-upload-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="email"]::-webkit-file-upload-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="tel"]::-webkit-file-upload-button,
.woocommerce .woocommerce-checkout.checkout form .form-row select::-webkit-file-upload-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text::-webkit-file-upload-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input::-webkit-file-upload-button,
.woocommerce-EditAccountForm.edit-account input[type="text"]::-webkit-file-upload-button,
.woocommerce-EditAccountForm.edit-account input[type="email"]::-webkit-file-upload-button,
.woocommerce-EditAccountForm.edit-account input[type="password"]::-webkit-file-upload-button,
.login input[type="text"]::-webkit-file-upload-button,
.login input[type="password"]::-webkit-file-upload-button,
.woocommerce-ResetPassword.lost_reset_password input[type="text"]::-webkit-file-upload-button,
.checkout.woocommerce-checkout input[type="text"]::-webkit-file-upload-button,
.checkout.woocommerce-checkout input[type="email"]::-webkit-file-upload-button,
.checkout.woocommerce-checkout input[type="tel"]::-webkit-file-upload-button,
.checkout.woocommerce-checkout .select2-choice::-webkit-file-upload-button,
.checkout.woocommerce-checkout textarea::-webkit-file-upload-button,
.woocommerce form .form-row input.input-text::-webkit-file-upload-button,
.woocommerce form .form-row .select2-selection::-webkit-file-upload-button,
.woocommerce form .form-row .select2-choice::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #777777;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

  .form-control::-webkit-file-upload-button,
  .woocommerce #review_form #respond input[type="text"]::-webkit-file-upload-button,
  .woocommerce #review_form #respond textarea::-webkit-file-upload-button,
  .woocommerce #review_form #respond input[type="email"]::-webkit-file-upload-button,
  .woocommerce-cart-form .table tr td.actions .coupon .input-text::-webkit-file-upload-button,
  .woocommerce-cart-form .table tr .quantity input::-webkit-file-upload-button,
  .woocommerce .woocommerce-checkout.checkout form .form-row textarea::-webkit-file-upload-button,
  .woocommerce .woocommerce-checkout.checkout form .form-row input[type="text"]::-webkit-file-upload-button,
  .woocommerce .woocommerce-checkout.checkout form .form-row input[type="email"]::-webkit-file-upload-button,
  .woocommerce .woocommerce-checkout.checkout form .form-row input[type="tel"]::-webkit-file-upload-button,
  .woocommerce .woocommerce-checkout.checkout form .form-row select::-webkit-file-upload-button,
  .woocommerce .woocommerce-checkout.checkout form .form-row input.input-text::-webkit-file-upload-button,
  .woocommerce .woocommerce-checkout.checkout form .form-row input::-webkit-file-upload-button,
  .woocommerce-EditAccountForm.edit-account input[type="text"]::-webkit-file-upload-button,
  .woocommerce-EditAccountForm.edit-account input[type="email"]::-webkit-file-upload-button,
  .woocommerce-EditAccountForm.edit-account input[type="password"]::-webkit-file-upload-button,
  .login input[type="text"]::-webkit-file-upload-button,
  .login input[type="password"]::-webkit-file-upload-button,
  .woocommerce-ResetPassword.lost_reset_password input[type="text"]::-webkit-file-upload-button,
  .checkout.woocommerce-checkout input[type="text"]::-webkit-file-upload-button,
  .checkout.woocommerce-checkout input[type="email"]::-webkit-file-upload-button,
  .checkout.woocommerce-checkout input[type="tel"]::-webkit-file-upload-button,
  .checkout.woocommerce-checkout .select2-choice::-webkit-file-upload-button,
  .checkout.woocommerce-checkout textarea::-webkit-file-upload-button,
  .woocommerce form .form-row input.input-text::-webkit-file-upload-button,
  .woocommerce form .form-row .select2-selection::-webkit-file-upload-button,
  .woocommerce form .form-row .select2-choice::-webkit-file-upload-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.woocommerce #review_form #respond input[type="text"]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.woocommerce #review_form #respond textarea:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.woocommerce #review_form #respond input[type="email"]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.woocommerce-cart-form .table tr td.actions .coupon .input-text:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.woocommerce-cart-form .table tr .quantity input:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.woocommerce .woocommerce-checkout.checkout form .form-row select:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.woocommerce-EditAccountForm.edit-account input[type="text"]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.woocommerce-EditAccountForm.edit-account input[type="email"]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.woocommerce-EditAccountForm.edit-account input[type="password"]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.login input[type="text"]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.login input[type="password"]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.woocommerce-ResetPassword.lost_reset_password input[type="text"]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.checkout.woocommerce-checkout input[type="text"]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.checkout.woocommerce-checkout input[type="email"]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.checkout.woocommerce-checkout input[type="tel"]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.checkout.woocommerce-checkout .select2-choice:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.checkout.woocommerce-checkout textarea:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.woocommerce form .form-row input.input-text:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.woocommerce form .form-row .select2-selection:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.woocommerce form .form-row .select2-choice:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.6;
  color: #777777;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.6em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.75rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.6em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 1rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control,
.woocommerce #review_form #respond textarea,
.woocommerce-cart-form .table tr td.actions .coupon textarea.input-text,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.checkout.woocommerce-checkout textarea.select2-choice,
.checkout.woocommerce-checkout textarea,
.woocommerce form .form-row textarea.select2-selection,
.woocommerce form .form-row textarea.select2-choice {
  min-height: calc(1.6em + 0.75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.6em + 0.5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.6em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.6em;
  border-radius: 0.375rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.6em;
  border-radius: 0.375rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #777777;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #f5d2a8;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(234, 164, 81, 0.25);
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #777777;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.75rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 1rem;
}

.form-check {
  display: block;
  min-height: 1.6rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.3em;
  vertical-align: top;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #f5d2a8;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(234, 164, 81, 0.25);
}

.form-check-input:checked {
  background-color: #EAA451;
  border-color: #EAA451;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #EAA451;
  border-color: #EAA451;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f5d2a8'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled]+.btn,
.btn-check[disabled]+.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-check[disabled]+a,
.woocommerce.widget_shopping_cart .buttons .btn-check[disabled]+a,
.woocommerce #review_form #respond .form-submit .btn-check[disabled]+input,
.woocommerce-cart-form .table tr .btn-check[disabled]+.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-check[disabled]+a.checkout-button,
.woocommerce #respond .btn-check[disabled]+input#submit,
.woocommerce .btn-check[disabled]+a.button,
.woocommerce .btn-check[disabled]+button.button,
.woocommerce .btn-check[disabled]+input.button,
.woocommerce .btn-check[disabled]+#place_order,
.btn-check:disabled+.btn,
.btn-check:disabled+.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-check:disabled+a,
.woocommerce.widget_shopping_cart .buttons .btn-check:disabled+a,
.woocommerce #review_form #respond .form-submit .btn-check:disabled+input,
.woocommerce-cart-form .table tr .btn-check:disabled+.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-check:disabled+a.checkout-button,
.woocommerce #respond .btn-check:disabled+input#submit,
.woocommerce .btn-check:disabled+a.button,
.woocommerce .btn-check:disabled+button.button,
.woocommerce .btn-check:disabled+input.button,
.woocommerce .btn-check:disabled+#place_order {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0rem rgba(234, 164, 81, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0rem rgba(234, 164, 81, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #EAA451;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #f9e4cb;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #EAA451;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #f9e4cb;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating>.form-control,
.woocommerce #review_form #respond .form-floating>input[type="text"],
.woocommerce #review_form #respond .form-floating>textarea,
.woocommerce #review_form #respond .form-floating>input[type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .form-floating>.input-text,
.woocommerce-cart-form .table tr .quantity .form-floating>input,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>select,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>input,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type="text"],
.woocommerce-EditAccountForm.edit-account .form-floating>input[type="email"],
.woocommerce-EditAccountForm.edit-account .form-floating>input[type="password"],
.login .form-floating>input[type="text"],
.login .form-floating>input[type="password"],
.woocommerce-ResetPassword.lost_reset_password .form-floating>input[type="text"],
.checkout.woocommerce-checkout .form-floating>input[type="text"],
.checkout.woocommerce-checkout .form-floating>input[type="email"],
.checkout.woocommerce-checkout .form-floating>input[type="tel"],
.checkout.woocommerce-checkout .form-floating>.select2-choice,
.checkout.woocommerce-checkout .form-floating>textarea,
.woocommerce form .form-row .form-floating>input.input-text,
.woocommerce form .form-row .form-floating>.select2-selection,
.woocommerce form .form-row .form-floating>.select2-choice,
.form-floating>.form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating>label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating>label {
    transition: none;
  }
}

.form-floating>.form-control,
.woocommerce #review_form #respond .form-floating>input[type="text"],
.woocommerce #review_form #respond .form-floating>textarea,
.woocommerce #review_form #respond .form-floating>input[type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .form-floating>.input-text,
.woocommerce-cart-form .table tr .quantity .form-floating>input,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>select,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>input,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type="text"],
.woocommerce-EditAccountForm.edit-account .form-floating>input[type="email"],
.woocommerce-EditAccountForm.edit-account .form-floating>input[type="password"],
.login .form-floating>input[type="text"],
.login .form-floating>input[type="password"],
.woocommerce-ResetPassword.lost_reset_password .form-floating>input[type="text"],
.checkout.woocommerce-checkout .form-floating>input[type="text"],
.checkout.woocommerce-checkout .form-floating>input[type="email"],
.checkout.woocommerce-checkout .form-floating>input[type="tel"],
.checkout.woocommerce-checkout .form-floating>.select2-choice,
.checkout.woocommerce-checkout .form-floating>textarea,
.woocommerce form .form-row .form-floating>input.input-text,
.woocommerce form .form-row .form-floating>.select2-selection,
.woocommerce form .form-row .form-floating>.select2-choice {
  padding: 1rem 0.75rem;
}

.form-floating>.form-control::placeholder,
.woocommerce #review_form #respond .form-floating>input[type="text"]::placeholder,
.woocommerce #review_form #respond .form-floating>textarea::placeholder,
.woocommerce #review_form #respond .form-floating>input[type="email"]::placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .form-floating>.input-text::placeholder,
.woocommerce-cart-form .table tr .quantity .form-floating>input::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>textarea::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>select::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>input::placeholder,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type="text"]::placeholder,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type="email"]::placeholder,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type="password"]::placeholder,
.login .form-floating>input[type="text"]::placeholder,
.login .form-floating>input[type="password"]::placeholder,
.woocommerce-ResetPassword.lost_reset_password .form-floating>input[type="text"]::placeholder,
.checkout.woocommerce-checkout .form-floating>input[type="text"]::placeholder,
.checkout.woocommerce-checkout .form-floating>input[type="email"]::placeholder,
.checkout.woocommerce-checkout .form-floating>input[type="tel"]::placeholder,
.checkout.woocommerce-checkout .form-floating>.select2-choice::placeholder,
.checkout.woocommerce-checkout .form-floating>textarea::placeholder,
.woocommerce form .form-row .form-floating>input.input-text::placeholder,
.woocommerce form .form-row .form-floating>.select2-selection::placeholder,
.woocommerce form .form-row .form-floating>.select2-choice::placeholder {
  color: transparent;
}

.form-floating>.form-control:focus,
.woocommerce #review_form #respond .form-floating>input:focus[type="text"],
.woocommerce #review_form #respond .form-floating>textarea:focus,
.woocommerce #review_form #respond .form-floating>input:focus[type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .form-floating>.input-text:focus,
.woocommerce-cart-form .table tr .quantity .form-floating>input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>textarea:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>select:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>input:focus,
.woocommerce-EditAccountForm.edit-account .form-floating>input:focus[type="text"],
.woocommerce-EditAccountForm.edit-account .form-floating>input:focus[type="email"],
.woocommerce-EditAccountForm.edit-account .form-floating>input:focus[type="password"],
.login .form-floating>input:focus[type="text"],
.login .form-floating>input:focus[type="password"],
.woocommerce-ResetPassword.lost_reset_password .form-floating>input:focus[type="text"],
.checkout.woocommerce-checkout .form-floating>input:focus[type="text"],
.checkout.woocommerce-checkout .form-floating>input:focus[type="email"],
.checkout.woocommerce-checkout .form-floating>input:focus[type="tel"],
.checkout.woocommerce-checkout .form-floating>.select2-choice:focus,
.checkout.woocommerce-checkout .form-floating>textarea:focus,
.woocommerce form .form-row .form-floating>input.input-text:focus,
.woocommerce form .form-row .form-floating>.select2-selection:focus,
.woocommerce form .form-row .form-floating>.select2-choice:focus,
.form-floating>.form-control:not(:placeholder-shown),
.woocommerce #review_form #respond .form-floating>input:not(:placeholder-shown)[type="text"],
.woocommerce #review_form #respond .form-floating>textarea:not(:placeholder-shown),
.woocommerce #review_form #respond .form-floating>input:not(:placeholder-shown)[type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .form-floating>.input-text:not(:placeholder-shown),
.woocommerce-cart-form .table tr .quantity .form-floating>input:not(:placeholder-shown),
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>textarea:not(:placeholder-shown),
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>select:not(:placeholder-shown),
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>input:not(:placeholder-shown),
.woocommerce-EditAccountForm.edit-account .form-floating>input:not(:placeholder-shown)[type="text"],
.woocommerce-EditAccountForm.edit-account .form-floating>input:not(:placeholder-shown)[type="email"],
.woocommerce-EditAccountForm.edit-account .form-floating>input:not(:placeholder-shown)[type="password"],
.login .form-floating>input:not(:placeholder-shown)[type="text"],
.login .form-floating>input:not(:placeholder-shown)[type="password"],
.woocommerce-ResetPassword.lost_reset_password .form-floating>input:not(:placeholder-shown)[type="text"],
.checkout.woocommerce-checkout .form-floating>input:not(:placeholder-shown)[type="text"],
.checkout.woocommerce-checkout .form-floating>input:not(:placeholder-shown)[type="email"],
.checkout.woocommerce-checkout .form-floating>input:not(:placeholder-shown)[type="tel"],
.checkout.woocommerce-checkout .form-floating>.select2-choice:not(:placeholder-shown),
.checkout.woocommerce-checkout .form-floating>textarea:not(:placeholder-shown),
.woocommerce form .form-row .form-floating>input.input-text:not(:placeholder-shown),
.woocommerce form .form-row .form-floating>.select2-selection:not(:placeholder-shown),
.woocommerce form .form-row .form-floating>.select2-choice:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:-webkit-autofill,
.woocommerce #review_form #respond .form-floating>input:-webkit-autofill[type="text"],
.woocommerce #review_form #respond .form-floating>textarea:-webkit-autofill,
.woocommerce #review_form #respond .form-floating>input:-webkit-autofill[type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .form-floating>.input-text:-webkit-autofill,
.woocommerce-cart-form .table tr .quantity .form-floating>input:-webkit-autofill,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>textarea:-webkit-autofill,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>select:-webkit-autofill,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>input:-webkit-autofill,
.woocommerce-EditAccountForm.edit-account .form-floating>input:-webkit-autofill[type="text"],
.woocommerce-EditAccountForm.edit-account .form-floating>input:-webkit-autofill[type="email"],
.woocommerce-EditAccountForm.edit-account .form-floating>input:-webkit-autofill[type="password"],
.login .form-floating>input:-webkit-autofill[type="text"],
.login .form-floating>input:-webkit-autofill[type="password"],
.woocommerce-ResetPassword.lost_reset_password .form-floating>input:-webkit-autofill[type="text"],
.checkout.woocommerce-checkout .form-floating>input:-webkit-autofill[type="text"],
.checkout.woocommerce-checkout .form-floating>input:-webkit-autofill[type="email"],
.checkout.woocommerce-checkout .form-floating>input:-webkit-autofill[type="tel"],
.checkout.woocommerce-checkout .form-floating>.select2-choice:-webkit-autofill,
.checkout.woocommerce-checkout .form-floating>textarea:-webkit-autofill,
.woocommerce form .form-row .form-floating>input.input-text:-webkit-autofill,
.woocommerce form .form-row .form-floating>.select2-selection:-webkit-autofill,
.woocommerce form .form-row .form-floating>.select2-choice:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:focus~label,
.woocommerce #review_form #respond .form-floating>input:focus[type="text"]~label,
.woocommerce #review_form #respond .form-floating>textarea:focus~label,
.woocommerce #review_form #respond .form-floating>input:focus[type="email"]~label,
.woocommerce-cart-form .table tr td.actions .coupon .form-floating>.input-text:focus~label,
.woocommerce-cart-form .table tr .quantity .form-floating>input:focus~label,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>textarea:focus~label,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>select:focus~label,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>input:focus~label,
.woocommerce-EditAccountForm.edit-account .form-floating>input:focus[type="text"]~label,
.woocommerce-EditAccountForm.edit-account .form-floating>input:focus[type="email"]~label,
.woocommerce-EditAccountForm.edit-account .form-floating>input:focus[type="password"]~label,
.login .form-floating>input:focus[type="text"]~label,
.login .form-floating>input:focus[type="password"]~label,
.woocommerce-ResetPassword.lost_reset_password .form-floating>input:focus[type="text"]~label,
.checkout.woocommerce-checkout .form-floating>input:focus[type="text"]~label,
.checkout.woocommerce-checkout .form-floating>input:focus[type="email"]~label,
.checkout.woocommerce-checkout .form-floating>input:focus[type="tel"]~label,
.checkout.woocommerce-checkout .form-floating>.select2-choice:focus~label,
.checkout.woocommerce-checkout .form-floating>textarea:focus~label,
.woocommerce form .form-row .form-floating>input.input-text:focus~label,
.woocommerce form .form-row .form-floating>.select2-selection:focus~label,
.woocommerce form .form-row .form-floating>.select2-choice:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.woocommerce #review_form #respond .form-floating>input:not(:placeholder-shown)[type="text"]~label,
.woocommerce #review_form #respond .form-floating>textarea:not(:placeholder-shown)~label,
.woocommerce #review_form #respond .form-floating>input:not(:placeholder-shown)[type="email"]~label,
.woocommerce-cart-form .table tr td.actions .coupon .form-floating>.input-text:not(:placeholder-shown)~label,
.woocommerce-cart-form .table tr .quantity .form-floating>input:not(:placeholder-shown)~label,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>textarea:not(:placeholder-shown)~label,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>select:not(:placeholder-shown)~label,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>input:not(:placeholder-shown)~label,
.woocommerce-EditAccountForm.edit-account .form-floating>input:not(:placeholder-shown)[type="text"]~label,
.woocommerce-EditAccountForm.edit-account .form-floating>input:not(:placeholder-shown)[type="email"]~label,
.woocommerce-EditAccountForm.edit-account .form-floating>input:not(:placeholder-shown)[type="password"]~label,
.login .form-floating>input:not(:placeholder-shown)[type="text"]~label,
.login .form-floating>input:not(:placeholder-shown)[type="password"]~label,
.woocommerce-ResetPassword.lost_reset_password .form-floating>input:not(:placeholder-shown)[type="text"]~label,
.checkout.woocommerce-checkout .form-floating>input:not(:placeholder-shown)[type="text"]~label,
.checkout.woocommerce-checkout .form-floating>input:not(:placeholder-shown)[type="email"]~label,
.checkout.woocommerce-checkout .form-floating>input:not(:placeholder-shown)[type="tel"]~label,
.checkout.woocommerce-checkout .form-floating>.select2-choice:not(:placeholder-shown)~label,
.checkout.woocommerce-checkout .form-floating>textarea:not(:placeholder-shown)~label,
.woocommerce form .form-row .form-floating>input.input-text:not(:placeholder-shown)~label,
.woocommerce form .form-row .form-floating>.select2-selection:not(:placeholder-shown)~label,
.woocommerce form .form-row .form-floating>.select2-choice:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control:-webkit-autofill~label,
.woocommerce #review_form #respond .form-floating>input:-webkit-autofill[type="text"]~label,
.woocommerce #review_form #respond .form-floating>textarea:-webkit-autofill~label,
.woocommerce #review_form #respond .form-floating>input:-webkit-autofill[type="email"]~label,
.woocommerce-cart-form .table tr td.actions .coupon .form-floating>.input-text:-webkit-autofill~label,
.woocommerce-cart-form .table tr .quantity .form-floating>input:-webkit-autofill~label,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>textarea:-webkit-autofill~label,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>select:-webkit-autofill~label,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>input:-webkit-autofill~label,
.woocommerce-EditAccountForm.edit-account .form-floating>input:-webkit-autofill[type="text"]~label,
.woocommerce-EditAccountForm.edit-account .form-floating>input:-webkit-autofill[type="email"]~label,
.woocommerce-EditAccountForm.edit-account .form-floating>input:-webkit-autofill[type="password"]~label,
.login .form-floating>input:-webkit-autofill[type="text"]~label,
.login .form-floating>input:-webkit-autofill[type="password"]~label,
.woocommerce-ResetPassword.lost_reset_password .form-floating>input:-webkit-autofill[type="text"]~label,
.checkout.woocommerce-checkout .form-floating>input:-webkit-autofill[type="text"]~label,
.checkout.woocommerce-checkout .form-floating>input:-webkit-autofill[type="email"]~label,
.checkout.woocommerce-checkout .form-floating>input:-webkit-autofill[type="tel"]~label,
.checkout.woocommerce-checkout .form-floating>.select2-choice:-webkit-autofill~label,
.checkout.woocommerce-checkout .form-floating>textarea:-webkit-autofill~label,
.woocommerce form .form-row .form-floating>input.input-text:-webkit-autofill~label,
.woocommerce form .form-row .form-floating>.select2-selection:-webkit-autofill~label,
.woocommerce form .form-row .form-floating>.select2-choice:-webkit-autofill~label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control,
.woocommerce #review_form #respond .input-group>input[type="text"],
.woocommerce #review_form #respond .input-group>textarea,
.woocommerce #review_form #respond .input-group>input[type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .input-group>.input-text,
.woocommerce-cart-form .table tr .quantity .input-group>input,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group>textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group>select,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group>input,
.woocommerce-EditAccountForm.edit-account .input-group>input[type="text"],
.woocommerce-EditAccountForm.edit-account .input-group>input[type="email"],
.woocommerce-EditAccountForm.edit-account .input-group>input[type="password"],
.login .input-group>input[type="text"],
.login .input-group>input[type="password"],
.woocommerce-ResetPassword.lost_reset_password .input-group>input[type="text"],
.checkout.woocommerce-checkout .input-group>input[type="text"],
.checkout.woocommerce-checkout .input-group>input[type="email"],
.checkout.woocommerce-checkout .input-group>input[type="tel"],
.checkout.woocommerce-checkout .input-group>.select2-choice,
.checkout.woocommerce-checkout .input-group>textarea,
.woocommerce form .form-row .input-group>input.input-text,
.woocommerce form .form-row .input-group>.select2-selection,
.woocommerce form .form-row .input-group>.select2-choice,
.input-group>.form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group>.form-control:focus,
.woocommerce #review_form #respond .input-group>input:focus[type="text"],
.woocommerce #review_form #respond .input-group>textarea:focus,
.woocommerce #review_form #respond .input-group>input:focus[type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .input-group>.input-text:focus,
.woocommerce-cart-form .table tr .quantity .input-group>input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group>textarea:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group>select:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group>input:focus,
.woocommerce-EditAccountForm.edit-account .input-group>input:focus[type="text"],
.woocommerce-EditAccountForm.edit-account .input-group>input:focus[type="email"],
.woocommerce-EditAccountForm.edit-account .input-group>input:focus[type="password"],
.login .input-group>input:focus[type="text"],
.login .input-group>input:focus[type="password"],
.woocommerce-ResetPassword.lost_reset_password .input-group>input:focus[type="text"],
.checkout.woocommerce-checkout .input-group>input:focus[type="text"],
.checkout.woocommerce-checkout .input-group>input:focus[type="email"],
.checkout.woocommerce-checkout .input-group>input:focus[type="tel"],
.checkout.woocommerce-checkout .input-group>.select2-choice:focus,
.checkout.woocommerce-checkout .input-group>textarea:focus,
.woocommerce form .form-row .input-group>input.input-text:focus,
.woocommerce form .form-row .input-group>.select2-selection:focus,
.woocommerce form .form-row .input-group>.select2-choice:focus,
.input-group>.form-select:focus {
  z-index: 3;
}

.input-group .btn,
.input-group .wp-block-button__link,
.input-group .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .input-group a,
.input-group .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .input-group a,
.input-group .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .input-group input,
.input-group .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .input-group .button,
.input-group .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .input-group a.checkout-button,
.input-group .woocommerce #respond input#submit,
.woocommerce #respond .input-group input#submit,
.input-group .woocommerce a.button,
.woocommerce .input-group a.button,
.input-group .woocommerce button.button,
.woocommerce .input-group button.button,
.input-group .woocommerce input.button,
.woocommerce .input-group input.button,
.input-group .woocommerce #place_order,
.woocommerce .input-group #place_order {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus,
.input-group .wp-block-button__link:focus,
.input-group .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .input-group a:focus,
.input-group .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .input-group a:focus,
.input-group .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .input-group input:focus,
.input-group .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .input-group .button:focus,
.input-group .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .input-group a.checkout-button:focus,
.input-group .woocommerce #respond input#submit:focus,
.woocommerce #respond .input-group input#submit:focus,
.input-group .woocommerce a.button:focus,
.woocommerce .input-group a.button:focus,
.input-group .woocommerce button.button:focus,
.woocommerce .input-group button.button:focus,
.input-group .woocommerce input.button:focus,
.woocommerce .input-group input.button:focus,
.input-group .woocommerce #place_order:focus,
.woocommerce .input-group #place_order:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #777777;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}

.input-group-lg>.form-control,
.woocommerce #review_form #respond .input-group-lg>input[type="text"],
.woocommerce #review_form #respond .input-group-lg>textarea,
.woocommerce #review_form #respond .input-group-lg>input[type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .input-group-lg>.input-text,
.woocommerce-cart-form .table tr .quantity .input-group-lg>input,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group-lg>textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group-lg>select,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group-lg>input,
.woocommerce-EditAccountForm.edit-account .input-group-lg>input[type="text"],
.woocommerce-EditAccountForm.edit-account .input-group-lg>input[type="email"],
.woocommerce-EditAccountForm.edit-account .input-group-lg>input[type="password"],
.login .input-group-lg>input[type="text"],
.login .input-group-lg>input[type="password"],
.woocommerce-ResetPassword.lost_reset_password .input-group-lg>input[type="text"],
.checkout.woocommerce-checkout .input-group-lg>input[type="text"],
.checkout.woocommerce-checkout .input-group-lg>input[type="email"],
.checkout.woocommerce-checkout .input-group-lg>input[type="tel"],
.checkout.woocommerce-checkout .input-group-lg>.select2-choice,
.checkout.woocommerce-checkout .input-group-lg>textarea,
.woocommerce form .form-row .input-group-lg>input.input-text,
.woocommerce form .form-row .input-group-lg>.select2-selection,
.woocommerce form .form-row .input-group-lg>.select2-choice,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text,
.input-group-lg>.btn,
.input-group-lg>.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .input-group-lg>a,
.woocommerce.widget_shopping_cart .buttons .input-group-lg>a,
.woocommerce #review_form #respond .form-submit .input-group-lg>input,
.woocommerce-cart-form .table tr .input-group-lg>.button,
.woocommerce-cart .wc-proceed-to-checkout .input-group-lg>a.checkout-button,
.woocommerce #respond .input-group-lg>input#submit,
.woocommerce .input-group-lg>a.button,
.woocommerce .input-group-lg>button.button,
.woocommerce .input-group-lg>input.button,
.woocommerce .input-group-lg>#place_order {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 1rem;
}

.input-group-sm>.form-control,
.woocommerce #review_form #respond .input-group-sm>input[type="text"],
.woocommerce #review_form #respond .input-group-sm>textarea,
.woocommerce #review_form #respond .input-group-sm>input[type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .input-group-sm>.input-text,
.woocommerce-cart-form .table tr .quantity .input-group-sm>input,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group-sm>textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group-sm>select,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group-sm>input,
.woocommerce-EditAccountForm.edit-account .input-group-sm>input[type="text"],
.woocommerce-EditAccountForm.edit-account .input-group-sm>input[type="email"],
.woocommerce-EditAccountForm.edit-account .input-group-sm>input[type="password"],
.login .input-group-sm>input[type="text"],
.login .input-group-sm>input[type="password"],
.woocommerce-ResetPassword.lost_reset_password .input-group-sm>input[type="text"],
.checkout.woocommerce-checkout .input-group-sm>input[type="text"],
.checkout.woocommerce-checkout .input-group-sm>input[type="email"],
.checkout.woocommerce-checkout .input-group-sm>input[type="tel"],
.checkout.woocommerce-checkout .input-group-sm>.select2-choice,
.checkout.woocommerce-checkout .input-group-sm>textarea,
.woocommerce form .form-row .input-group-sm>input.input-text,
.woocommerce form .form-row .input-group-sm>.select2-selection,
.woocommerce form .form-row .input-group-sm>.select2-choice,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text,
.input-group-sm>.btn,
.input-group-sm>.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .input-group-sm>a,
.woocommerce.widget_shopping_cart .buttons .input-group-sm>a,
.woocommerce #review_form #respond .form-submit .input-group-sm>input,
.woocommerce-cart-form .table tr .input-group-sm>.button,
.woocommerce-cart .wc-proceed-to-checkout .input-group-sm>a.checkout-button,
.woocommerce #respond .input-group-sm>input#submit,
.woocommerce .input-group-sm>a.button,
.woocommerce .input-group-sm>button.button,
.woocommerce .input-group-sm>input.button,
.woocommerce .input-group-sm>#place_order {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.75rem;
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation> :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation>.dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #029e76;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(2, 158, 118, 0.9);
  border-radius: 0.375rem;
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.was-validated .woocommerce #review_form #respond input:valid[type="text"],
.woocommerce #review_form #respond .was-validated input:valid[type="text"],
.was-validated .woocommerce #review_form #respond textarea:valid,
.woocommerce #review_form #respond .was-validated textarea:valid,
.was-validated .woocommerce #review_form #respond input:valid[type="email"],
.woocommerce #review_form #respond .was-validated input:valid[type="email"],
.was-validated .woocommerce-cart-form .table tr td.actions .coupon .input-text:valid,
.woocommerce-cart-form .table tr td.actions .coupon .was-validated .input-text:valid,
.was-validated .woocommerce-cart-form .table tr .quantity input:valid,
.woocommerce-cart-form .table tr .quantity .was-validated input:valid,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row textarea:valid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated textarea:valid,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row select:valid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated select:valid,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row input:valid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated input:valid,
.was-validated .woocommerce-EditAccountForm.edit-account input:valid[type="text"],
.woocommerce-EditAccountForm.edit-account .was-validated input:valid[type="text"],
.was-validated .woocommerce-EditAccountForm.edit-account input:valid[type="email"],
.woocommerce-EditAccountForm.edit-account .was-validated input:valid[type="email"],
.was-validated .woocommerce-EditAccountForm.edit-account input:valid[type="password"],
.woocommerce-EditAccountForm.edit-account .was-validated input:valid[type="password"],
.was-validated .login input:valid[type="text"],
.login .was-validated input:valid[type="text"],
.was-validated .login input:valid[type="password"],
.login .was-validated input:valid[type="password"],
.was-validated .woocommerce-ResetPassword.lost_reset_password input:valid[type="text"],
.woocommerce-ResetPassword.lost_reset_password .was-validated input:valid[type="text"],
.was-validated .checkout.woocommerce-checkout input:valid[type="text"],
.checkout.woocommerce-checkout .was-validated input:valid[type="text"],
.was-validated .checkout.woocommerce-checkout input:valid[type="email"],
.checkout.woocommerce-checkout .was-validated input:valid[type="email"],
.was-validated .checkout.woocommerce-checkout input:valid[type="tel"],
.checkout.woocommerce-checkout .was-validated input:valid[type="tel"],
.was-validated .checkout.woocommerce-checkout .select2-choice:valid,
.checkout.woocommerce-checkout .was-validated .select2-choice:valid,
.was-validated .checkout.woocommerce-checkout textarea:valid,
.checkout.woocommerce-checkout .was-validated textarea:valid,
.was-validated .woocommerce form .form-row input.input-text:valid,
.woocommerce form .form-row .was-validated input.input-text:valid,
.was-validated .woocommerce form .form-row .select2-selection:valid,
.woocommerce form .form-row .was-validated .select2-selection:valid,
.was-validated .woocommerce form .form-row .select2-choice:valid,
.woocommerce form .form-row .was-validated .select2-choice:valid,
.form-control.is-valid,
.woocommerce #review_form #respond input.is-valid[type="text"],
.woocommerce #review_form #respond textarea.is-valid,
.woocommerce #review_form #respond input.is-valid[type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .is-valid.input-text,
.woocommerce-cart-form .table tr .quantity input.is-valid,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.is-valid,
.woocommerce .woocommerce-checkout.checkout form .form-row select.is-valid,
.woocommerce .woocommerce-checkout.checkout form .form-row input.is-valid,
.woocommerce-EditAccountForm.edit-account input.is-valid[type="text"],
.woocommerce-EditAccountForm.edit-account input.is-valid[type="email"],
.woocommerce-EditAccountForm.edit-account input.is-valid[type="password"],
.login input.is-valid[type="text"],
.login input.is-valid[type="password"],
.woocommerce-ResetPassword.lost_reset_password input.is-valid[type="text"],
.checkout.woocommerce-checkout input.is-valid[type="text"],
.checkout.woocommerce-checkout input.is-valid[type="email"],
.checkout.woocommerce-checkout input.is-valid[type="tel"],
.checkout.woocommerce-checkout .is-valid.select2-choice,
.checkout.woocommerce-checkout textarea.is-valid,
.woocommerce form .form-row input.is-valid.input-text,
.woocommerce form .form-row .is-valid.select2-selection,
.woocommerce form .form-row .is-valid.select2-choice {
  border-color: #029e76;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23029e76' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.was-validated .woocommerce #review_form #respond input:valid:focus[type="text"],
.woocommerce #review_form #respond .was-validated input:valid:focus[type="text"],
.was-validated .woocommerce #review_form #respond textarea:valid:focus,
.woocommerce #review_form #respond .was-validated textarea:valid:focus,
.was-validated .woocommerce #review_form #respond input:valid:focus[type="email"],
.woocommerce #review_form #respond .was-validated input:valid:focus[type="email"],
.was-validated .woocommerce-cart-form .table tr td.actions .coupon .input-text:valid:focus,
.woocommerce-cart-form .table tr td.actions .coupon .was-validated .input-text:valid:focus,
.was-validated .woocommerce-cart-form .table tr .quantity input:valid:focus,
.woocommerce-cart-form .table tr .quantity .was-validated input:valid:focus,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row select:valid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated select:valid:focus,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row input:valid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated input:valid:focus,
.was-validated .woocommerce-EditAccountForm.edit-account input:valid:focus[type="text"],
.woocommerce-EditAccountForm.edit-account .was-validated input:valid:focus[type="text"],
.was-validated .woocommerce-EditAccountForm.edit-account input:valid:focus[type="email"],
.woocommerce-EditAccountForm.edit-account .was-validated input:valid:focus[type="email"],
.was-validated .woocommerce-EditAccountForm.edit-account input:valid:focus[type="password"],
.woocommerce-EditAccountForm.edit-account .was-validated input:valid:focus[type="password"],
.was-validated .login input:valid:focus[type="text"],
.login .was-validated input:valid:focus[type="text"],
.was-validated .login input:valid:focus[type="password"],
.login .was-validated input:valid:focus[type="password"],
.was-validated .woocommerce-ResetPassword.lost_reset_password input:valid:focus[type="text"],
.woocommerce-ResetPassword.lost_reset_password .was-validated input:valid:focus[type="text"],
.was-validated .checkout.woocommerce-checkout input:valid:focus[type="text"],
.checkout.woocommerce-checkout .was-validated input:valid:focus[type="text"],
.was-validated .checkout.woocommerce-checkout input:valid:focus[type="email"],
.checkout.woocommerce-checkout .was-validated input:valid:focus[type="email"],
.was-validated .checkout.woocommerce-checkout input:valid:focus[type="tel"],
.checkout.woocommerce-checkout .was-validated input:valid:focus[type="tel"],
.was-validated .checkout.woocommerce-checkout .select2-choice:valid:focus,
.checkout.woocommerce-checkout .was-validated .select2-choice:valid:focus,
.was-validated .checkout.woocommerce-checkout textarea:valid:focus,
.checkout.woocommerce-checkout .was-validated textarea:valid:focus,
.was-validated .woocommerce form .form-row input.input-text:valid:focus,
.woocommerce form .form-row .was-validated input.input-text:valid:focus,
.was-validated .woocommerce form .form-row .select2-selection:valid:focus,
.woocommerce form .form-row .was-validated .select2-selection:valid:focus,
.was-validated .woocommerce form .form-row .select2-choice:valid:focus,
.woocommerce form .form-row .was-validated .select2-choice:valid:focus,
.form-control.is-valid:focus,
.woocommerce #review_form #respond input.is-valid:focus[type="text"],
.woocommerce #review_form #respond textarea.is-valid:focus,
.woocommerce #review_form #respond input.is-valid:focus[type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .is-valid.input-text:focus,
.woocommerce-cart-form .table tr .quantity input.is-valid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.is-valid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.is-valid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.is-valid:focus,
.woocommerce-EditAccountForm.edit-account input.is-valid:focus[type="text"],
.woocommerce-EditAccountForm.edit-account input.is-valid:focus[type="email"],
.woocommerce-EditAccountForm.edit-account input.is-valid:focus[type="password"],
.login input.is-valid:focus[type="text"],
.login input.is-valid:focus[type="password"],
.woocommerce-ResetPassword.lost_reset_password input.is-valid:focus[type="text"],
.checkout.woocommerce-checkout input.is-valid:focus[type="text"],
.checkout.woocommerce-checkout input.is-valid:focus[type="email"],
.checkout.woocommerce-checkout input.is-valid:focus[type="tel"],
.checkout.woocommerce-checkout .is-valid.select2-choice:focus,
.checkout.woocommerce-checkout textarea.is-valid:focus,
.woocommerce form .form-row input.is-valid.input-text:focus,
.woocommerce form .form-row .is-valid.select2-selection:focus,
.woocommerce form .form-row .is-valid.select2-choice:focus {
  border-color: #029e76;
  box-shadow: 0 0 0 0rem rgba(2, 158, 118, 0.25);
}

.was-validated textarea.form-control:valid,
.was-validated .woocommerce #review_form #respond textarea:valid,
.woocommerce #review_form #respond .was-validated textarea:valid,
.was-validated .woocommerce-cart-form .table tr td.actions .coupon textarea.input-text:valid,
.woocommerce-cart-form .table tr td.actions .coupon .was-validated textarea.input-text:valid,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row textarea:valid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated textarea:valid,
.was-validated .checkout.woocommerce-checkout textarea:valid,
.checkout.woocommerce-checkout .was-validated textarea:valid,
.was-validated .woocommerce form .form-row textarea.select2-selection:valid,
.woocommerce form .form-row .was-validated textarea.select2-selection:valid,
.was-validated .woocommerce form .form-row textarea.select2-choice:valid,
.woocommerce form .form-row .was-validated textarea.select2-choice:valid,
textarea.form-control.is-valid,
.woocommerce #review_form #respond textarea.is-valid,
.woocommerce-cart-form .table tr td.actions .coupon textarea.is-valid.input-text,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.is-valid,
.checkout.woocommerce-checkout textarea.is-valid,
.woocommerce form .form-row textarea.is-valid.select2-selection,
.woocommerce form .form-row textarea.is-valid.select2-choice {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: #029e76;
}

.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23029e76' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: #029e76;
  box-shadow: 0 0 0 0rem rgba(2, 158, 118, 0.25);
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: #029e76;
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #029e76;
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0rem rgba(2, 158, 118, 0.25);
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
  color: #029e76;
}

.form-check-inline .form-check-input~.valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:valid,
.was-validated .input-group .woocommerce #review_form #respond input:valid[type="text"],
.woocommerce #review_form #respond .was-validated .input-group input:valid[type="text"],
.was-validated .input-group .woocommerce #review_form #respond textarea:valid,
.woocommerce #review_form #respond .was-validated .input-group textarea:valid,
.was-validated .input-group .woocommerce #review_form #respond input:valid[type="email"],
.woocommerce #review_form #respond .was-validated .input-group input:valid[type="email"],
.was-validated .input-group .woocommerce-cart-form .table tr td.actions .coupon .input-text:valid,
.woocommerce-cart-form .table tr td.actions .coupon .was-validated .input-group .input-text:valid,
.was-validated .input-group .woocommerce-cart-form .table tr .quantity input:valid,
.woocommerce-cart-form .table tr .quantity .was-validated .input-group input:valid,
.was-validated .input-group .woocommerce .woocommerce-checkout.checkout form .form-row select:valid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated .input-group select:valid,
.was-validated .input-group .woocommerce .woocommerce-checkout.checkout form .form-row input:valid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated .input-group input:valid,
.was-validated .input-group .woocommerce-EditAccountForm.edit-account input:valid[type="text"],
.woocommerce-EditAccountForm.edit-account .was-validated .input-group input:valid[type="text"],
.was-validated .input-group .woocommerce-EditAccountForm.edit-account input:valid[type="email"],
.woocommerce-EditAccountForm.edit-account .was-validated .input-group input:valid[type="email"],
.was-validated .input-group .woocommerce-EditAccountForm.edit-account input:valid[type="password"],
.woocommerce-EditAccountForm.edit-account .was-validated .input-group input:valid[type="password"],
.was-validated .input-group .login input:valid[type="text"],
.login .was-validated .input-group input:valid[type="text"],
.was-validated .input-group .login input:valid[type="password"],
.login .was-validated .input-group input:valid[type="password"],
.was-validated .input-group .woocommerce-ResetPassword.lost_reset_password input:valid[type="text"],
.woocommerce-ResetPassword.lost_reset_password .was-validated .input-group input:valid[type="text"],
.was-validated .input-group .checkout.woocommerce-checkout input:valid[type="text"],
.checkout.woocommerce-checkout .was-validated .input-group input:valid[type="text"],
.was-validated .input-group .checkout.woocommerce-checkout input:valid[type="email"],
.checkout.woocommerce-checkout .was-validated .input-group input:valid[type="email"],
.was-validated .input-group .checkout.woocommerce-checkout input:valid[type="tel"],
.checkout.woocommerce-checkout .was-validated .input-group input:valid[type="tel"],
.was-validated .input-group .checkout.woocommerce-checkout .select2-choice:valid,
.checkout.woocommerce-checkout .was-validated .input-group .select2-choice:valid,
.was-validated .input-group .checkout.woocommerce-checkout textarea:valid,
.checkout.woocommerce-checkout .was-validated .input-group textarea:valid,
.was-validated .input-group .woocommerce form .form-row input.input-text:valid,
.woocommerce form .form-row .was-validated .input-group input.input-text:valid,
.was-validated .input-group .woocommerce form .form-row .select2-selection:valid,
.woocommerce form .form-row .was-validated .input-group .select2-selection:valid,
.was-validated .input-group .woocommerce form .form-row .select2-choice:valid,
.woocommerce form .form-row .was-validated .input-group .select2-choice:valid,
.input-group .form-control.is-valid,
.input-group .woocommerce #review_form #respond input.is-valid[type="text"],
.woocommerce #review_form #respond .input-group input.is-valid[type="text"],
.input-group .woocommerce #review_form #respond textarea.is-valid,
.woocommerce #review_form #respond .input-group textarea.is-valid,
.input-group .woocommerce #review_form #respond input.is-valid[type="email"],
.woocommerce #review_form #respond .input-group input.is-valid[type="email"],
.input-group .woocommerce-cart-form .table tr td.actions .coupon .is-valid.input-text,
.woocommerce-cart-form .table tr td.actions .coupon .input-group .is-valid.input-text,
.input-group .woocommerce-cart-form .table tr .quantity input.is-valid,
.woocommerce-cart-form .table tr .quantity .input-group input.is-valid,
.input-group .woocommerce .woocommerce-checkout.checkout form .form-row textarea.is-valid,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group textarea.is-valid,
.input-group .woocommerce .woocommerce-checkout.checkout form .form-row select.is-valid,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group select.is-valid,
.input-group .woocommerce .woocommerce-checkout.checkout form .form-row input.is-valid,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group input.is-valid,
.input-group .woocommerce-EditAccountForm.edit-account input.is-valid[type="text"],
.woocommerce-EditAccountForm.edit-account .input-group input.is-valid[type="text"],
.input-group .woocommerce-EditAccountForm.edit-account input.is-valid[type="email"],
.woocommerce-EditAccountForm.edit-account .input-group input.is-valid[type="email"],
.input-group .woocommerce-EditAccountForm.edit-account input.is-valid[type="password"],
.woocommerce-EditAccountForm.edit-account .input-group input.is-valid[type="password"],
.input-group .login input.is-valid[type="text"],
.login .input-group input.is-valid[type="text"],
.input-group .login input.is-valid[type="password"],
.login .input-group input.is-valid[type="password"],
.input-group .woocommerce-ResetPassword.lost_reset_password input.is-valid[type="text"],
.woocommerce-ResetPassword.lost_reset_password .input-group input.is-valid[type="text"],
.input-group .checkout.woocommerce-checkout input.is-valid[type="text"],
.checkout.woocommerce-checkout .input-group input.is-valid[type="text"],
.input-group .checkout.woocommerce-checkout input.is-valid[type="email"],
.checkout.woocommerce-checkout .input-group input.is-valid[type="email"],
.input-group .checkout.woocommerce-checkout input.is-valid[type="tel"],
.checkout.woocommerce-checkout .input-group input.is-valid[type="tel"],
.input-group .checkout.woocommerce-checkout .is-valid.select2-choice,
.checkout.woocommerce-checkout .input-group .is-valid.select2-choice,
.input-group .checkout.woocommerce-checkout textarea.is-valid,
.checkout.woocommerce-checkout .input-group textarea.is-valid,
.input-group .woocommerce form .form-row input.is-valid.input-text,
.woocommerce form .form-row .input-group input.is-valid.input-text,
.input-group .woocommerce form .form-row .is-valid.select2-selection,
.woocommerce form .form-row .input-group .is-valid.select2-selection,
.input-group .woocommerce form .form-row .is-valid.select2-choice,
.woocommerce form .form-row .input-group .is-valid.select2-choice,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}

.was-validated .input-group .form-control:valid:focus,
.was-validated .input-group .woocommerce #review_form #respond input:valid:focus[type="text"],
.woocommerce #review_form #respond .was-validated .input-group input:valid:focus[type="text"],
.was-validated .input-group .woocommerce #review_form #respond textarea:valid:focus,
.woocommerce #review_form #respond .was-validated .input-group textarea:valid:focus,
.was-validated .input-group .woocommerce #review_form #respond input:valid:focus[type="email"],
.woocommerce #review_form #respond .was-validated .input-group input:valid:focus[type="email"],
.was-validated .input-group .woocommerce-cart-form .table tr td.actions .coupon .input-text:valid:focus,
.woocommerce-cart-form .table tr td.actions .coupon .was-validated .input-group .input-text:valid:focus,
.was-validated .input-group .woocommerce-cart-form .table tr .quantity input:valid:focus,
.woocommerce-cart-form .table tr .quantity .was-validated .input-group input:valid:focus,
.was-validated .input-group .woocommerce .woocommerce-checkout.checkout form .form-row select:valid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated .input-group select:valid:focus,
.was-validated .input-group .woocommerce .woocommerce-checkout.checkout form .form-row input:valid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated .input-group input:valid:focus,
.was-validated .input-group .woocommerce-EditAccountForm.edit-account input:valid:focus[type="text"],
.woocommerce-EditAccountForm.edit-account .was-validated .input-group input:valid:focus[type="text"],
.was-validated .input-group .woocommerce-EditAccountForm.edit-account input:valid:focus[type="email"],
.woocommerce-EditAccountForm.edit-account .was-validated .input-group input:valid:focus[type="email"],
.was-validated .input-group .woocommerce-EditAccountForm.edit-account input:valid:focus[type="password"],
.woocommerce-EditAccountForm.edit-account .was-validated .input-group input:valid:focus[type="password"],
.was-validated .input-group .login input:valid:focus[type="text"],
.login .was-validated .input-group input:valid:focus[type="text"],
.was-validated .input-group .login input:valid:focus[type="password"],
.login .was-validated .input-group input:valid:focus[type="password"],
.was-validated .input-group .woocommerce-ResetPassword.lost_reset_password input:valid:focus[type="text"],
.woocommerce-ResetPassword.lost_reset_password .was-validated .input-group input:valid:focus[type="text"],
.was-validated .input-group .checkout.woocommerce-checkout input:valid:focus[type="text"],
.checkout.woocommerce-checkout .was-validated .input-group input:valid:focus[type="text"],
.was-validated .input-group .checkout.woocommerce-checkout input:valid:focus[type="email"],
.checkout.woocommerce-checkout .was-validated .input-group input:valid:focus[type="email"],
.was-validated .input-group .checkout.woocommerce-checkout input:valid:focus[type="tel"],
.checkout.woocommerce-checkout .was-validated .input-group input:valid:focus[type="tel"],
.was-validated .input-group .checkout.woocommerce-checkout .select2-choice:valid:focus,
.checkout.woocommerce-checkout .was-validated .input-group .select2-choice:valid:focus,
.was-validated .input-group .checkout.woocommerce-checkout textarea:valid:focus,
.checkout.woocommerce-checkout .was-validated .input-group textarea:valid:focus,
.was-validated .input-group .woocommerce form .form-row input.input-text:valid:focus,
.woocommerce form .form-row .was-validated .input-group input.input-text:valid:focus,
.was-validated .input-group .woocommerce form .form-row .select2-selection:valid:focus,
.woocommerce form .form-row .was-validated .input-group .select2-selection:valid:focus,
.was-validated .input-group .woocommerce form .form-row .select2-choice:valid:focus,
.woocommerce form .form-row .was-validated .input-group .select2-choice:valid:focus,
.input-group .form-control.is-valid:focus,
.input-group .woocommerce #review_form #respond input.is-valid:focus[type="text"],
.woocommerce #review_form #respond .input-group input.is-valid:focus[type="text"],
.input-group .woocommerce #review_form #respond textarea.is-valid:focus,
.woocommerce #review_form #respond .input-group textarea.is-valid:focus,
.input-group .woocommerce #review_form #respond input.is-valid:focus[type="email"],
.woocommerce #review_form #respond .input-group input.is-valid:focus[type="email"],
.input-group .woocommerce-cart-form .table tr td.actions .coupon .is-valid.input-text:focus,
.woocommerce-cart-form .table tr td.actions .coupon .input-group .is-valid.input-text:focus,
.input-group .woocommerce-cart-form .table tr .quantity input.is-valid:focus,
.woocommerce-cart-form .table tr .quantity .input-group input.is-valid:focus,
.input-group .woocommerce .woocommerce-checkout.checkout form .form-row select.is-valid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group select.is-valid:focus,
.input-group .woocommerce .woocommerce-checkout.checkout form .form-row input.is-valid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group input.is-valid:focus,
.input-group .woocommerce-EditAccountForm.edit-account input.is-valid:focus[type="text"],
.woocommerce-EditAccountForm.edit-account .input-group input.is-valid:focus[type="text"],
.input-group .woocommerce-EditAccountForm.edit-account input.is-valid:focus[type="email"],
.woocommerce-EditAccountForm.edit-account .input-group input.is-valid:focus[type="email"],
.input-group .woocommerce-EditAccountForm.edit-account input.is-valid:focus[type="password"],
.woocommerce-EditAccountForm.edit-account .input-group input.is-valid:focus[type="password"],
.input-group .login input.is-valid:focus[type="text"],
.login .input-group input.is-valid:focus[type="text"],
.input-group .login input.is-valid:focus[type="password"],
.login .input-group input.is-valid:focus[type="password"],
.input-group .woocommerce-ResetPassword.lost_reset_password input.is-valid:focus[type="text"],
.woocommerce-ResetPassword.lost_reset_password .input-group input.is-valid:focus[type="text"],
.input-group .checkout.woocommerce-checkout input.is-valid:focus[type="text"],
.checkout.woocommerce-checkout .input-group input.is-valid:focus[type="text"],
.input-group .checkout.woocommerce-checkout input.is-valid:focus[type="email"],
.checkout.woocommerce-checkout .input-group input.is-valid:focus[type="email"],
.input-group .checkout.woocommerce-checkout input.is-valid:focus[type="tel"],
.checkout.woocommerce-checkout .input-group input.is-valid:focus[type="tel"],
.input-group .checkout.woocommerce-checkout .is-valid.select2-choice:focus,
.checkout.woocommerce-checkout .input-group .is-valid.select2-choice:focus,
.input-group .checkout.woocommerce-checkout textarea.is-valid:focus,
.checkout.woocommerce-checkout .input-group textarea.is-valid:focus,
.input-group .woocommerce form .form-row input.is-valid.input-text:focus,
.woocommerce form .form-row .input-group input.is-valid.input-text:focus,
.input-group .woocommerce form .form-row .is-valid.select2-selection:focus,
.woocommerce form .form-row .input-group .is-valid.select2-selection:focus,
.input-group .woocommerce form .form-row .is-valid.select2-choice:focus,
.woocommerce form .form-row .input-group .is-valid.select2-choice:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #FF1E6F;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(255, 30, 111, 0.9);
  border-radius: 0.375rem;
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.was-validated .woocommerce #review_form #respond input:invalid[type="text"],
.woocommerce #review_form #respond .was-validated input:invalid[type="text"],
.was-validated .woocommerce #review_form #respond textarea:invalid,
.woocommerce #review_form #respond .was-validated textarea:invalid,
.was-validated .woocommerce #review_form #respond input:invalid[type="email"],
.woocommerce #review_form #respond .was-validated input:invalid[type="email"],
.was-validated .woocommerce-cart-form .table tr td.actions .coupon .input-text:invalid,
.woocommerce-cart-form .table tr td.actions .coupon .was-validated .input-text:invalid,
.was-validated .woocommerce-cart-form .table tr .quantity input:invalid,
.woocommerce-cart-form .table tr .quantity .was-validated input:invalid,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row textarea:invalid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated textarea:invalid,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row select:invalid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated select:invalid,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row input:invalid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated input:invalid,
.was-validated .woocommerce-EditAccountForm.edit-account input:invalid[type="text"],
.woocommerce-EditAccountForm.edit-account .was-validated input:invalid[type="text"],
.was-validated .woocommerce-EditAccountForm.edit-account input:invalid[type="email"],
.woocommerce-EditAccountForm.edit-account .was-validated input:invalid[type="email"],
.was-validated .woocommerce-EditAccountForm.edit-account input:invalid[type="password"],
.woocommerce-EditAccountForm.edit-account .was-validated input:invalid[type="password"],
.was-validated .login input:invalid[type="text"],
.login .was-validated input:invalid[type="text"],
.was-validated .login input:invalid[type="password"],
.login .was-validated input:invalid[type="password"],
.was-validated .woocommerce-ResetPassword.lost_reset_password input:invalid[type="text"],
.woocommerce-ResetPassword.lost_reset_password .was-validated input:invalid[type="text"],
.was-validated .checkout.woocommerce-checkout input:invalid[type="text"],
.checkout.woocommerce-checkout .was-validated input:invalid[type="text"],
.was-validated .checkout.woocommerce-checkout input:invalid[type="email"],
.checkout.woocommerce-checkout .was-validated input:invalid[type="email"],
.was-validated .checkout.woocommerce-checkout input:invalid[type="tel"],
.checkout.woocommerce-checkout .was-validated input:invalid[type="tel"],
.was-validated .checkout.woocommerce-checkout .select2-choice:invalid,
.checkout.woocommerce-checkout .was-validated .select2-choice:invalid,
.was-validated .checkout.woocommerce-checkout textarea:invalid,
.checkout.woocommerce-checkout .was-validated textarea:invalid,
.was-validated .woocommerce form .form-row input.input-text:invalid,
.woocommerce form .form-row .was-validated input.input-text:invalid,
.was-validated .woocommerce form .form-row .select2-selection:invalid,
.woocommerce form .form-row .was-validated .select2-selection:invalid,
.was-validated .woocommerce form .form-row .select2-choice:invalid,
.woocommerce form .form-row .was-validated .select2-choice:invalid,
.form-control.is-invalid,
.woocommerce #review_form #respond input.is-invalid[type="text"],
.woocommerce #review_form #respond textarea.is-invalid,
.woocommerce #review_form #respond input.is-invalid[type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .is-invalid.input-text,
.woocommerce-cart-form .table tr .quantity input.is-invalid,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.is-invalid,
.woocommerce .woocommerce-checkout.checkout form .form-row select.is-invalid,
.woocommerce .woocommerce-checkout.checkout form .form-row input.is-invalid,
.woocommerce-EditAccountForm.edit-account input.is-invalid[type="text"],
.woocommerce-EditAccountForm.edit-account input.is-invalid[type="email"],
.woocommerce-EditAccountForm.edit-account input.is-invalid[type="password"],
.login input.is-invalid[type="text"],
.login input.is-invalid[type="password"],
.woocommerce-ResetPassword.lost_reset_password input.is-invalid[type="text"],
.checkout.woocommerce-checkout input.is-invalid[type="text"],
.checkout.woocommerce-checkout input.is-invalid[type="email"],
.checkout.woocommerce-checkout input.is-invalid[type="tel"],
.checkout.woocommerce-checkout .is-invalid.select2-choice,
.checkout.woocommerce-checkout textarea.is-invalid,
.woocommerce form .form-row input.is-invalid.input-text,
.woocommerce form .form-row .is-invalid.select2-selection,
.woocommerce form .form-row .is-invalid.select2-choice {
  border-color: #FF1E6F;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FF1E6F'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF1E6F' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.was-validated .woocommerce #review_form #respond input:invalid:focus[type="text"],
.woocommerce #review_form #respond .was-validated input:invalid:focus[type="text"],
.was-validated .woocommerce #review_form #respond textarea:invalid:focus,
.woocommerce #review_form #respond .was-validated textarea:invalid:focus,
.was-validated .woocommerce #review_form #respond input:invalid:focus[type="email"],
.woocommerce #review_form #respond .was-validated input:invalid:focus[type="email"],
.was-validated .woocommerce-cart-form .table tr td.actions .coupon .input-text:invalid:focus,
.woocommerce-cart-form .table tr td.actions .coupon .was-validated .input-text:invalid:focus,
.was-validated .woocommerce-cart-form .table tr .quantity input:invalid:focus,
.woocommerce-cart-form .table tr .quantity .was-validated input:invalid:focus,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row select:invalid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated select:invalid:focus,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row input:invalid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated input:invalid:focus,
.was-validated .woocommerce-EditAccountForm.edit-account input:invalid:focus[type="text"],
.woocommerce-EditAccountForm.edit-account .was-validated input:invalid:focus[type="text"],
.was-validated .woocommerce-EditAccountForm.edit-account input:invalid:focus[type="email"],
.woocommerce-EditAccountForm.edit-account .was-validated input:invalid:focus[type="email"],
.was-validated .woocommerce-EditAccountForm.edit-account input:invalid:focus[type="password"],
.woocommerce-EditAccountForm.edit-account .was-validated input:invalid:focus[type="password"],
.was-validated .login input:invalid:focus[type="text"],
.login .was-validated input:invalid:focus[type="text"],
.was-validated .login input:invalid:focus[type="password"],
.login .was-validated input:invalid:focus[type="password"],
.was-validated .woocommerce-ResetPassword.lost_reset_password input:invalid:focus[type="text"],
.woocommerce-ResetPassword.lost_reset_password .was-validated input:invalid:focus[type="text"],
.was-validated .checkout.woocommerce-checkout input:invalid:focus[type="text"],
.checkout.woocommerce-checkout .was-validated input:invalid:focus[type="text"],
.was-validated .checkout.woocommerce-checkout input:invalid:focus[type="email"],
.checkout.woocommerce-checkout .was-validated input:invalid:focus[type="email"],
.was-validated .checkout.woocommerce-checkout input:invalid:focus[type="tel"],
.checkout.woocommerce-checkout .was-validated input:invalid:focus[type="tel"],
.was-validated .checkout.woocommerce-checkout .select2-choice:invalid:focus,
.checkout.woocommerce-checkout .was-validated .select2-choice:invalid:focus,
.was-validated .checkout.woocommerce-checkout textarea:invalid:focus,
.checkout.woocommerce-checkout .was-validated textarea:invalid:focus,
.was-validated .woocommerce form .form-row input.input-text:invalid:focus,
.woocommerce form .form-row .was-validated input.input-text:invalid:focus,
.was-validated .woocommerce form .form-row .select2-selection:invalid:focus,
.woocommerce form .form-row .was-validated .select2-selection:invalid:focus,
.was-validated .woocommerce form .form-row .select2-choice:invalid:focus,
.woocommerce form .form-row .was-validated .select2-choice:invalid:focus,
.form-control.is-invalid:focus,
.woocommerce #review_form #respond input.is-invalid:focus[type="text"],
.woocommerce #review_form #respond textarea.is-invalid:focus,
.woocommerce #review_form #respond input.is-invalid:focus[type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .is-invalid.input-text:focus,
.woocommerce-cart-form .table tr .quantity input.is-invalid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.is-invalid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.is-invalid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.is-invalid:focus,
.woocommerce-EditAccountForm.edit-account input.is-invalid:focus[type="text"],
.woocommerce-EditAccountForm.edit-account input.is-invalid:focus[type="email"],
.woocommerce-EditAccountForm.edit-account input.is-invalid:focus[type="password"],
.login input.is-invalid:focus[type="text"],
.login input.is-invalid:focus[type="password"],
.woocommerce-ResetPassword.lost_reset_password input.is-invalid:focus[type="text"],
.checkout.woocommerce-checkout input.is-invalid:focus[type="text"],
.checkout.woocommerce-checkout input.is-invalid:focus[type="email"],
.checkout.woocommerce-checkout input.is-invalid:focus[type="tel"],
.checkout.woocommerce-checkout .is-invalid.select2-choice:focus,
.checkout.woocommerce-checkout textarea.is-invalid:focus,
.woocommerce form .form-row input.is-invalid.input-text:focus,
.woocommerce form .form-row .is-invalid.select2-selection:focus,
.woocommerce form .form-row .is-invalid.select2-choice:focus {
  border-color: #FF1E6F;
  box-shadow: 0 0 0 0rem rgba(255, 30, 111, 0.25);
}

.was-validated textarea.form-control:invalid,
.was-validated .woocommerce #review_form #respond textarea:invalid,
.woocommerce #review_form #respond .was-validated textarea:invalid,
.was-validated .woocommerce-cart-form .table tr td.actions .coupon textarea.input-text:invalid,
.woocommerce-cart-form .table tr td.actions .coupon .was-validated textarea.input-text:invalid,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row textarea:invalid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated textarea:invalid,
.was-validated .checkout.woocommerce-checkout textarea:invalid,
.checkout.woocommerce-checkout .was-validated textarea:invalid,
.was-validated .woocommerce form .form-row textarea.select2-selection:invalid,
.woocommerce form .form-row .was-validated textarea.select2-selection:invalid,
.was-validated .woocommerce form .form-row textarea.select2-choice:invalid,
.woocommerce form .form-row .was-validated textarea.select2-choice:invalid,
textarea.form-control.is-invalid,
.woocommerce #review_form #respond textarea.is-invalid,
.woocommerce-cart-form .table tr td.actions .coupon textarea.is-invalid.input-text,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.is-invalid,
.checkout.woocommerce-checkout textarea.is-invalid,
.woocommerce form .form-row textarea.is-invalid.select2-selection,
.woocommerce form .form-row textarea.is-invalid.select2-choice {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: #FF1E6F;
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FF1E6F'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF1E6F' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: #FF1E6F;
  box-shadow: 0 0 0 0rem rgba(255, 30, 111, 0.25);
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: #FF1E6F;
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: #FF1E6F;
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0rem rgba(255, 30, 111, 0.25);
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
  color: #FF1E6F;
}

.form-check-inline .form-check-input~.invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:invalid,
.was-validated .input-group .woocommerce #review_form #respond input:invalid[type="text"],
.woocommerce #review_form #respond .was-validated .input-group input:invalid[type="text"],
.was-validated .input-group .woocommerce #review_form #respond textarea:invalid,
.woocommerce #review_form #respond .was-validated .input-group textarea:invalid,
.was-validated .input-group .woocommerce #review_form #respond input:invalid[type="email"],
.woocommerce #review_form #respond .was-validated .input-group input:invalid[type="email"],
.was-validated .input-group .woocommerce-cart-form .table tr td.actions .coupon .input-text:invalid,
.woocommerce-cart-form .table tr td.actions .coupon .was-validated .input-group .input-text:invalid,
.was-validated .input-group .woocommerce-cart-form .table tr .quantity input:invalid,
.woocommerce-cart-form .table tr .quantity .was-validated .input-group input:invalid,
.was-validated .input-group .woocommerce .woocommerce-checkout.checkout form .form-row select:invalid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated .input-group select:invalid,
.was-validated .input-group .woocommerce .woocommerce-checkout.checkout form .form-row input:invalid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated .input-group input:invalid,
.was-validated .input-group .woocommerce-EditAccountForm.edit-account input:invalid[type="text"],
.woocommerce-EditAccountForm.edit-account .was-validated .input-group input:invalid[type="text"],
.was-validated .input-group .woocommerce-EditAccountForm.edit-account input:invalid[type="email"],
.woocommerce-EditAccountForm.edit-account .was-validated .input-group input:invalid[type="email"],
.was-validated .input-group .woocommerce-EditAccountForm.edit-account input:invalid[type="password"],
.woocommerce-EditAccountForm.edit-account .was-validated .input-group input:invalid[type="password"],
.was-validated .input-group .login input:invalid[type="text"],
.login .was-validated .input-group input:invalid[type="text"],
.was-validated .input-group .login input:invalid[type="password"],
.login .was-validated .input-group input:invalid[type="password"],
.was-validated .input-group .woocommerce-ResetPassword.lost_reset_password input:invalid[type="text"],
.woocommerce-ResetPassword.lost_reset_password .was-validated .input-group input:invalid[type="text"],
.was-validated .input-group .checkout.woocommerce-checkout input:invalid[type="text"],
.checkout.woocommerce-checkout .was-validated .input-group input:invalid[type="text"],
.was-validated .input-group .checkout.woocommerce-checkout input:invalid[type="email"],
.checkout.woocommerce-checkout .was-validated .input-group input:invalid[type="email"],
.was-validated .input-group .checkout.woocommerce-checkout input:invalid[type="tel"],
.checkout.woocommerce-checkout .was-validated .input-group input:invalid[type="tel"],
.was-validated .input-group .checkout.woocommerce-checkout .select2-choice:invalid,
.checkout.woocommerce-checkout .was-validated .input-group .select2-choice:invalid,
.was-validated .input-group .checkout.woocommerce-checkout textarea:invalid,
.checkout.woocommerce-checkout .was-validated .input-group textarea:invalid,
.was-validated .input-group .woocommerce form .form-row input.input-text:invalid,
.woocommerce form .form-row .was-validated .input-group input.input-text:invalid,
.was-validated .input-group .woocommerce form .form-row .select2-selection:invalid,
.woocommerce form .form-row .was-validated .input-group .select2-selection:invalid,
.was-validated .input-group .woocommerce form .form-row .select2-choice:invalid,
.woocommerce form .form-row .was-validated .input-group .select2-choice:invalid,
.input-group .form-control.is-invalid,
.input-group .woocommerce #review_form #respond input.is-invalid[type="text"],
.woocommerce #review_form #respond .input-group input.is-invalid[type="text"],
.input-group .woocommerce #review_form #respond textarea.is-invalid,
.woocommerce #review_form #respond .input-group textarea.is-invalid,
.input-group .woocommerce #review_form #respond input.is-invalid[type="email"],
.woocommerce #review_form #respond .input-group input.is-invalid[type="email"],
.input-group .woocommerce-cart-form .table tr td.actions .coupon .is-invalid.input-text,
.woocommerce-cart-form .table tr td.actions .coupon .input-group .is-invalid.input-text,
.input-group .woocommerce-cart-form .table tr .quantity input.is-invalid,
.woocommerce-cart-form .table tr .quantity .input-group input.is-invalid,
.input-group .woocommerce .woocommerce-checkout.checkout form .form-row textarea.is-invalid,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group textarea.is-invalid,
.input-group .woocommerce .woocommerce-checkout.checkout form .form-row select.is-invalid,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group select.is-invalid,
.input-group .woocommerce .woocommerce-checkout.checkout form .form-row input.is-invalid,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group input.is-invalid,
.input-group .woocommerce-EditAccountForm.edit-account input.is-invalid[type="text"],
.woocommerce-EditAccountForm.edit-account .input-group input.is-invalid[type="text"],
.input-group .woocommerce-EditAccountForm.edit-account input.is-invalid[type="email"],
.woocommerce-EditAccountForm.edit-account .input-group input.is-invalid[type="email"],
.input-group .woocommerce-EditAccountForm.edit-account input.is-invalid[type="password"],
.woocommerce-EditAccountForm.edit-account .input-group input.is-invalid[type="password"],
.input-group .login input.is-invalid[type="text"],
.login .input-group input.is-invalid[type="text"],
.input-group .login input.is-invalid[type="password"],
.login .input-group input.is-invalid[type="password"],
.input-group .woocommerce-ResetPassword.lost_reset_password input.is-invalid[type="text"],
.woocommerce-ResetPassword.lost_reset_password .input-group input.is-invalid[type="text"],
.input-group .checkout.woocommerce-checkout input.is-invalid[type="text"],
.checkout.woocommerce-checkout .input-group input.is-invalid[type="text"],
.input-group .checkout.woocommerce-checkout input.is-invalid[type="email"],
.checkout.woocommerce-checkout .input-group input.is-invalid[type="email"],
.input-group .checkout.woocommerce-checkout input.is-invalid[type="tel"],
.checkout.woocommerce-checkout .input-group input.is-invalid[type="tel"],
.input-group .checkout.woocommerce-checkout .is-invalid.select2-choice,
.checkout.woocommerce-checkout .input-group .is-invalid.select2-choice,
.input-group .checkout.woocommerce-checkout textarea.is-invalid,
.checkout.woocommerce-checkout .input-group textarea.is-invalid,
.input-group .woocommerce form .form-row input.is-invalid.input-text,
.woocommerce form .form-row .input-group input.is-invalid.input-text,
.input-group .woocommerce form .form-row .is-invalid.select2-selection,
.woocommerce form .form-row .input-group .is-invalid.select2-selection,
.input-group .woocommerce form .form-row .is-invalid.select2-choice,
.woocommerce form .form-row .input-group .is-invalid.select2-choice,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}

.was-validated .input-group .form-control:invalid:focus,
.was-validated .input-group .woocommerce #review_form #respond input:invalid:focus[type="text"],
.woocommerce #review_form #respond .was-validated .input-group input:invalid:focus[type="text"],
.was-validated .input-group .woocommerce #review_form #respond textarea:invalid:focus,
.woocommerce #review_form #respond .was-validated .input-group textarea:invalid:focus,
.was-validated .input-group .woocommerce #review_form #respond input:invalid:focus[type="email"],
.woocommerce #review_form #respond .was-validated .input-group input:invalid:focus[type="email"],
.was-validated .input-group .woocommerce-cart-form .table tr td.actions .coupon .input-text:invalid:focus,
.woocommerce-cart-form .table tr td.actions .coupon .was-validated .input-group .input-text:invalid:focus,
.was-validated .input-group .woocommerce-cart-form .table tr .quantity input:invalid:focus,
.woocommerce-cart-form .table tr .quantity .was-validated .input-group input:invalid:focus,
.was-validated .input-group .woocommerce .woocommerce-checkout.checkout form .form-row select:invalid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated .input-group select:invalid:focus,
.was-validated .input-group .woocommerce .woocommerce-checkout.checkout form .form-row input:invalid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated .input-group input:invalid:focus,
.was-validated .input-group .woocommerce-EditAccountForm.edit-account input:invalid:focus[type="text"],
.woocommerce-EditAccountForm.edit-account .was-validated .input-group input:invalid:focus[type="text"],
.was-validated .input-group .woocommerce-EditAccountForm.edit-account input:invalid:focus[type="email"],
.woocommerce-EditAccountForm.edit-account .was-validated .input-group input:invalid:focus[type="email"],
.was-validated .input-group .woocommerce-EditAccountForm.edit-account input:invalid:focus[type="password"],
.woocommerce-EditAccountForm.edit-account .was-validated .input-group input:invalid:focus[type="password"],
.was-validated .input-group .login input:invalid:focus[type="text"],
.login .was-validated .input-group input:invalid:focus[type="text"],
.was-validated .input-group .login input:invalid:focus[type="password"],
.login .was-validated .input-group input:invalid:focus[type="password"],
.was-validated .input-group .woocommerce-ResetPassword.lost_reset_password input:invalid:focus[type="text"],
.woocommerce-ResetPassword.lost_reset_password .was-validated .input-group input:invalid:focus[type="text"],
.was-validated .input-group .checkout.woocommerce-checkout input:invalid:focus[type="text"],
.checkout.woocommerce-checkout .was-validated .input-group input:invalid:focus[type="text"],
.was-validated .input-group .checkout.woocommerce-checkout input:invalid:focus[type="email"],
.checkout.woocommerce-checkout .was-validated .input-group input:invalid:focus[type="email"],
.was-validated .input-group .checkout.woocommerce-checkout input:invalid:focus[type="tel"],
.checkout.woocommerce-checkout .was-validated .input-group input:invalid:focus[type="tel"],
.was-validated .input-group .checkout.woocommerce-checkout .select2-choice:invalid:focus,
.checkout.woocommerce-checkout .was-validated .input-group .select2-choice:invalid:focus,
.was-validated .input-group .checkout.woocommerce-checkout textarea:invalid:focus,
.checkout.woocommerce-checkout .was-validated .input-group textarea:invalid:focus,
.was-validated .input-group .woocommerce form .form-row input.input-text:invalid:focus,
.woocommerce form .form-row .was-validated .input-group input.input-text:invalid:focus,
.was-validated .input-group .woocommerce form .form-row .select2-selection:invalid:focus,
.woocommerce form .form-row .was-validated .input-group .select2-selection:invalid:focus,
.was-validated .input-group .woocommerce form .form-row .select2-choice:invalid:focus,
.woocommerce form .form-row .was-validated .input-group .select2-choice:invalid:focus,
.input-group .form-control.is-invalid:focus,
.input-group .woocommerce #review_form #respond input.is-invalid:focus[type="text"],
.woocommerce #review_form #respond .input-group input.is-invalid:focus[type="text"],
.input-group .woocommerce #review_form #respond textarea.is-invalid:focus,
.woocommerce #review_form #respond .input-group textarea.is-invalid:focus,
.input-group .woocommerce #review_form #respond input.is-invalid:focus[type="email"],
.woocommerce #review_form #respond .input-group input.is-invalid:focus[type="email"],
.input-group .woocommerce-cart-form .table tr td.actions .coupon .is-invalid.input-text:focus,
.woocommerce-cart-form .table tr td.actions .coupon .input-group .is-invalid.input-text:focus,
.input-group .woocommerce-cart-form .table tr .quantity input.is-invalid:focus,
.woocommerce-cart-form .table tr .quantity .input-group input.is-invalid:focus,
.input-group .woocommerce .woocommerce-checkout.checkout form .form-row select.is-invalid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group select.is-invalid:focus,
.input-group .woocommerce .woocommerce-checkout.checkout form .form-row input.is-invalid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group input.is-invalid:focus,
.input-group .woocommerce-EditAccountForm.edit-account input.is-invalid:focus[type="text"],
.woocommerce-EditAccountForm.edit-account .input-group input.is-invalid:focus[type="text"],
.input-group .woocommerce-EditAccountForm.edit-account input.is-invalid:focus[type="email"],
.woocommerce-EditAccountForm.edit-account .input-group input.is-invalid:focus[type="email"],
.input-group .woocommerce-EditAccountForm.edit-account input.is-invalid:focus[type="password"],
.woocommerce-EditAccountForm.edit-account .input-group input.is-invalid:focus[type="password"],
.input-group .login input.is-invalid:focus[type="text"],
.login .input-group input.is-invalid:focus[type="text"],
.input-group .login input.is-invalid:focus[type="password"],
.login .input-group input.is-invalid:focus[type="password"],
.input-group .woocommerce-ResetPassword.lost_reset_password input.is-invalid:focus[type="text"],
.woocommerce-ResetPassword.lost_reset_password .input-group input.is-invalid:focus[type="text"],
.input-group .checkout.woocommerce-checkout input.is-invalid:focus[type="text"],
.checkout.woocommerce-checkout .input-group input.is-invalid:focus[type="text"],
.input-group .checkout.woocommerce-checkout input.is-invalid:focus[type="email"],
.checkout.woocommerce-checkout .input-group input.is-invalid:focus[type="email"],
.input-group .checkout.woocommerce-checkout input.is-invalid:focus[type="tel"],
.checkout.woocommerce-checkout .input-group input.is-invalid:focus[type="tel"],
.input-group .checkout.woocommerce-checkout .is-invalid.select2-choice:focus,
.checkout.woocommerce-checkout .input-group .is-invalid.select2-choice:focus,
.input-group .checkout.woocommerce-checkout textarea.is-invalid:focus,
.checkout.woocommerce-checkout .input-group textarea.is-invalid:focus,
.input-group .woocommerce form .form-row input.is-invalid.input-text:focus,
.woocommerce form .form-row .input-group input.is-invalid.input-text:focus,
.input-group .woocommerce form .form-row .is-invalid.select2-selection:focus,
.woocommerce form .form-row .input-group .is-invalid.select2-selection:focus,
.input-group .woocommerce form .form-row .is-invalid.select2-choice:focus,
.woocommerce form .form-row .input-group .is-invalid.select2-choice:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn,
.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons a,
.woocommerce #review_form #respond .form-submit input,
.woocommerce-cart-form .table tr .button,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce #place_order {
  display: inline-block;
  font-weight: 400;
  line-height: 1.6;
  color: #777777;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

  .btn,
  .wp-block-button__link,
  .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce #review_form #respond .form-submit input,
  .woocommerce-cart-form .table tr .button,
  .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce #respond input#submit,
  .woocommerce a.button,
  .woocommerce button.button,
  .woocommerce input.button,
  .woocommerce #place_order {
    transition: none;
  }
}

.btn:hover,
.wp-block-button__link:hover,
.woocommerce .widget_shopping_cart .buttons a:hover,
.woocommerce.widget_shopping_cart .buttons a:hover,
.woocommerce #review_form #respond .form-submit input:hover,
.woocommerce-cart-form .table tr .button:hover,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:hover,
.woocommerce #respond input#submit:hover,
.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce input.button:hover,
.woocommerce #place_order:hover {
  color: #777777;
}

.btn-check:focus+.btn,
.btn-check:focus+.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-check:focus+a,
.woocommerce.widget_shopping_cart .buttons .btn-check:focus+a,
.woocommerce #review_form #respond .form-submit .btn-check:focus+input,
.woocommerce-cart-form .table tr .btn-check:focus+.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-check:focus+a.checkout-button,
.woocommerce #respond .btn-check:focus+input#submit,
.woocommerce .btn-check:focus+a.button,
.woocommerce .btn-check:focus+button.button,
.woocommerce .btn-check:focus+input.button,
.woocommerce .btn-check:focus+#place_order,
.btn:focus,
.wp-block-button__link:focus,
.woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce #review_form #respond .form-submit input:focus,
.woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce #respond input#submit:focus,
.woocommerce a.button:focus,
.woocommerce button.button:focus,
.woocommerce input.button:focus,
.woocommerce #place_order:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(234, 164, 81, 0.25);
}

.btn:disabled,
.wp-block-button__link:disabled,
.woocommerce .widget_shopping_cart .buttons a:disabled,
.woocommerce.widget_shopping_cart .buttons a:disabled,
.woocommerce #review_form #respond .form-submit input:disabled,
.woocommerce-cart-form .table tr .button:disabled,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:disabled,
.woocommerce #respond input#submit:disabled,
.woocommerce a.button:disabled,
.woocommerce button.button:disabled,
.woocommerce input.button:disabled,
.woocommerce #place_order:disabled,
.btn.disabled,
.disabled.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons a.disabled,
.woocommerce.widget_shopping_cart .buttons a.disabled,
.woocommerce #review_form #respond .form-submit input.disabled,
.woocommerce-cart-form .table tr .disabled.button,
.woocommerce-cart .wc-proceed-to-checkout a.disabled.checkout-button,
.woocommerce #respond input.disabled#submit,
.woocommerce a.disabled.button,
.woocommerce button.disabled.button,
.woocommerce input.disabled.button,
.woocommerce .disabled#place_order,
fieldset:disabled .btn,
fieldset:disabled .wp-block-button__link,
fieldset:disabled .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons fieldset:disabled a,
fieldset:disabled .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons fieldset:disabled a,
fieldset:disabled .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit fieldset:disabled input,
fieldset:disabled .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr fieldset:disabled .button,
fieldset:disabled .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout fieldset:disabled a.checkout-button,
fieldset:disabled .woocommerce #respond input#submit,
.woocommerce #respond fieldset:disabled input#submit,
fieldset:disabled .woocommerce a.button,
.woocommerce fieldset:disabled a.button,
fieldset:disabled .woocommerce button.button,
.woocommerce fieldset:disabled button.button,
fieldset:disabled .woocommerce input.button,
.woocommerce fieldset:disabled input.button,
fieldset:disabled .woocommerce #place_order,
.woocommerce fieldset:disabled #place_order {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary,
.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons a,
.woocommerce #review_form #respond .form-submit input,
.woocommerce-cart-form .table tr .button,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce #place_order {
  color: #000;
  background-color: #EAA451;
  border-color: #EAA451;
}

.btn-primary:hover,
.wp-block-button__link:hover,
.woocommerce .widget_shopping_cart .buttons a:hover,
.woocommerce.widget_shopping_cart .buttons a:hover,
.woocommerce #review_form #respond .form-submit input:hover,
.woocommerce-cart-form .table tr .button:hover,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:hover,
.woocommerce #respond input#submit:hover,
.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce input.button:hover,
.woocommerce #place_order:hover {
  color: #000;
  background-color: #edb26b;
  border-color: #ecad62;
}

.btn-check:focus+.btn-primary,
.btn-check:focus+.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-check:focus+a,
.woocommerce.widget_shopping_cart .buttons .btn-check:focus+a,
.woocommerce #review_form #respond .form-submit .btn-check:focus+input,
.woocommerce-cart-form .table tr .btn-check:focus+.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-check:focus+a.checkout-button,
.woocommerce #respond .btn-check:focus+input#submit,
.woocommerce .btn-check:focus+a.button,
.woocommerce .btn-check:focus+button.button,
.woocommerce .btn-check:focus+input.button,
.woocommerce .btn-check:focus+#place_order,
.btn-primary:focus,
.wp-block-button__link:focus,
.woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce #review_form #respond .form-submit input:focus,
.woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce #respond input#submit:focus,
.woocommerce a.button:focus,
.woocommerce button.button:focus,
.woocommerce input.button:focus,
.woocommerce #place_order:focus {
  color: #000;
  background-color: #edb26b;
  border-color: #ecad62;
  box-shadow: 0 0 0 0rem rgba(199, 139, 69, 0.5);
}

.btn-check:checked+.btn-primary,
.btn-check:checked+.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-check:checked+a,
.woocommerce.widget_shopping_cart .buttons .btn-check:checked+a,
.woocommerce #review_form #respond .form-submit .btn-check:checked+input,
.woocommerce-cart-form .table tr .btn-check:checked+.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-check:checked+a.checkout-button,
.woocommerce #respond .btn-check:checked+input#submit,
.woocommerce .btn-check:checked+a.button,
.woocommerce .btn-check:checked+button.button,
.woocommerce .btn-check:checked+input.button,
.woocommerce .btn-check:checked+#place_order,
.btn-check:active+.btn-primary,
.btn-check:active+.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-check:active+a,
.woocommerce.widget_shopping_cart .buttons .btn-check:active+a,
.woocommerce #review_form #respond .form-submit .btn-check:active+input,
.woocommerce-cart-form .table tr .btn-check:active+.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-check:active+a.checkout-button,
.woocommerce #respond .btn-check:active+input#submit,
.woocommerce .btn-check:active+a.button,
.woocommerce .btn-check:active+button.button,
.woocommerce .btn-check:active+input.button,
.woocommerce .btn-check:active+#place_order,
.btn-primary:active,
.wp-block-button__link:active,
.woocommerce .widget_shopping_cart .buttons a:active,
.woocommerce.widget_shopping_cart .buttons a:active,
.woocommerce #review_form #respond .form-submit input:active,
.woocommerce-cart-form .table tr .button:active,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:active,
.woocommerce #respond input#submit:active,
.woocommerce a.button:active,
.woocommerce button.button:active,
.woocommerce input.button:active,
.woocommerce #place_order:active,
.btn-primary.active,
.active.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons a.active,
.woocommerce.widget_shopping_cart .buttons a.active,
.woocommerce #review_form #respond .form-submit input.active,
.woocommerce-cart-form .table tr .active.button,
.woocommerce-cart .wc-proceed-to-checkout a.active.checkout-button,
.woocommerce #respond input.active#submit,
.woocommerce a.active.button,
.woocommerce button.active.button,
.woocommerce input.active.button,
.woocommerce .active#place_order,
.show>.btn-primary.dropdown-toggle,
.show>.dropdown-toggle.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .show>a.dropdown-toggle,
.woocommerce.widget_shopping_cart .buttons .show>a.dropdown-toggle,
.woocommerce #review_form #respond .form-submit .show>input.dropdown-toggle,
.woocommerce-cart-form .table tr .show>.dropdown-toggle.button,
.woocommerce-cart .wc-proceed-to-checkout .show>a.dropdown-toggle.checkout-button,
.woocommerce #respond .show>input.dropdown-toggle#submit,
.woocommerce .show>a.dropdown-toggle.button,
.woocommerce .show>button.dropdown-toggle.button,
.woocommerce .show>input.dropdown-toggle.button,
.woocommerce .show>.dropdown-toggle#place_order {
  color: #000;
  background-color: #eeb674;
  border-color: #ecad62;
}

.btn-check:checked+.btn-primary:focus,
.btn-check:checked+.wp-block-button__link:focus,
.woocommerce .widget_shopping_cart .buttons .btn-check:checked+a:focus,
.woocommerce.widget_shopping_cart .buttons .btn-check:checked+a:focus,
.woocommerce #review_form #respond .form-submit .btn-check:checked+input:focus,
.woocommerce-cart-form .table tr .btn-check:checked+.button:focus,
.woocommerce-cart .wc-proceed-to-checkout .btn-check:checked+a.checkout-button:focus,
.woocommerce #respond .btn-check:checked+input#submit:focus,
.woocommerce .btn-check:checked+a.button:focus,
.woocommerce .btn-check:checked+button.button:focus,
.woocommerce .btn-check:checked+input.button:focus,
.woocommerce .btn-check:checked+#place_order:focus,
.btn-check:active+.btn-primary:focus,
.btn-check:active+.wp-block-button__link:focus,
.woocommerce .widget_shopping_cart .buttons .btn-check:active+a:focus,
.woocommerce.widget_shopping_cart .buttons .btn-check:active+a:focus,
.woocommerce #review_form #respond .form-submit .btn-check:active+input:focus,
.woocommerce-cart-form .table tr .btn-check:active+.button:focus,
.woocommerce-cart .wc-proceed-to-checkout .btn-check:active+a.checkout-button:focus,
.woocommerce #respond .btn-check:active+input#submit:focus,
.woocommerce .btn-check:active+a.button:focus,
.woocommerce .btn-check:active+button.button:focus,
.woocommerce .btn-check:active+input.button:focus,
.woocommerce .btn-check:active+#place_order:focus,
.btn-primary:active:focus,
.wp-block-button__link:active:focus,
.woocommerce .widget_shopping_cart .buttons a:active:focus,
.woocommerce.widget_shopping_cart .buttons a:active:focus,
.woocommerce #review_form #respond .form-submit input:active:focus,
.woocommerce-cart-form .table tr .button:active:focus,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:active:focus,
.woocommerce #respond input#submit:active:focus,
.woocommerce a.button:active:focus,
.woocommerce button.button:active:focus,
.woocommerce input.button:active:focus,
.woocommerce #place_order:active:focus,
.btn-primary.active:focus,
.active.wp-block-button__link:focus,
.woocommerce .widget_shopping_cart .buttons a.active:focus,
.woocommerce.widget_shopping_cart .buttons a.active:focus,
.woocommerce #review_form #respond .form-submit input.active:focus,
.woocommerce-cart-form .table tr .active.button:focus,
.woocommerce-cart .wc-proceed-to-checkout a.active.checkout-button:focus,
.woocommerce #respond input.active#submit:focus,
.woocommerce a.active.button:focus,
.woocommerce button.active.button:focus,
.woocommerce input.active.button:focus,
.woocommerce .active#place_order:focus,
.show>.btn-primary.dropdown-toggle:focus,
.show>.dropdown-toggle.wp-block-button__link:focus,
.woocommerce .widget_shopping_cart .buttons .show>a.dropdown-toggle:focus,
.woocommerce.widget_shopping_cart .buttons .show>a.dropdown-toggle:focus,
.woocommerce #review_form #respond .form-submit .show>input.dropdown-toggle:focus,
.woocommerce-cart-form .table tr .show>.dropdown-toggle.button:focus,
.woocommerce-cart .wc-proceed-to-checkout .show>a.dropdown-toggle.checkout-button:focus,
.woocommerce #respond .show>input.dropdown-toggle#submit:focus,
.woocommerce .show>a.dropdown-toggle.button:focus,
.woocommerce .show>button.dropdown-toggle.button:focus,
.woocommerce .show>input.dropdown-toggle.button:focus,
.woocommerce .show>.dropdown-toggle#place_order:focus {
  box-shadow: 0 0 0 0rem rgba(199, 139, 69, 0.5);
}

.btn-primary:disabled,
.wp-block-button__link:disabled,
.woocommerce .widget_shopping_cart .buttons a:disabled,
.woocommerce.widget_shopping_cart .buttons a:disabled,
.woocommerce #review_form #respond .form-submit input:disabled,
.woocommerce-cart-form .table tr .button:disabled,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:disabled,
.woocommerce #respond input#submit:disabled,
.woocommerce a.button:disabled,
.woocommerce button.button:disabled,
.woocommerce input.button:disabled,
.woocommerce #place_order:disabled,
.btn-primary.disabled,
.disabled.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons a.disabled,
.woocommerce.widget_shopping_cart .buttons a.disabled,
.woocommerce #review_form #respond .form-submit input.disabled,
.woocommerce-cart-form .table tr .disabled.button,
.woocommerce-cart .wc-proceed-to-checkout a.disabled.checkout-button,
.woocommerce #respond input.disabled#submit,
.woocommerce a.disabled.button,
.woocommerce button.disabled.button,
.woocommerce input.disabled.button,
.woocommerce .disabled#place_order {
  color: #000;
  background-color: #EAA451;
  border-color: #EAA451;
}

.btn-secondary {
  color: #ffffff;
  background-color: #1a1668;
  border-color: #1a1668;
}

.btn-secondary:hover {
  color: #ffffff;
  background-color: #161358;
  border-color: #151253;
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
  color: #ffffff;
  background-color: #161358;
  border-color: #151253;
  box-shadow: 0 0 0 0rem rgba(60, 57, 127, 0.5);
}

.btn-check:checked+.btn-secondary,
.btn-check:active+.btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show>.btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #151253;
  border-color: #14114e;
}

.btn-check:checked+.btn-secondary:focus,
.btn-check:active+.btn-secondary:focus,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(60, 57, 127, 0.5);
}

.btn-secondary:disabled,
.btn-secondary.disabled {
  color: #ffffff;
  background-color: #1a1668;
  border-color: #1a1668;
}

.btn-success {
  color: #000;
  background-color: #029e76;
  border-color: #029e76;
}

.btn-success:hover {
  color: #000;
  background-color: #28ad8b;
  border-color: #1ba884;
}

.btn-check:focus+.btn-success,
.btn-success:focus {
  color: #000;
  background-color: #28ad8b;
  border-color: #1ba884;
  box-shadow: 0 0 0 0rem rgba(2, 134, 100, 0.5);
}

.btn-check:checked+.btn-success,
.btn-check:active+.btn-success,
.btn-success:active,
.btn-success.active,
.show>.btn-success.dropdown-toggle {
  color: #000;
  background-color: #35b191;
  border-color: #1ba884;
}

.btn-check:checked+.btn-success:focus,
.btn-check:active+.btn-success:focus,
.btn-success:active:focus,
.btn-success.active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(2, 134, 100, 0.5);
}

.btn-success:disabled,
.btn-success.disabled {
  color: #000;
  background-color: #029e76;
  border-color: #029e76;
}

.btn-info {
  color: #000;
  background-color: #00aeff;
  border-color: #00aeff;
}

.btn-info:hover {
  color: #000;
  background-color: #26baff;
  border-color: #1ab6ff;
}

.btn-check:focus+.btn-info,
.btn-info:focus {
  color: #000;
  background-color: #26baff;
  border-color: #1ab6ff;
  box-shadow: 0 0 0 0rem rgba(0, 148, 217, 0.5);
}

.btn-check:checked+.btn-info,
.btn-check:active+.btn-info,
.btn-info:active,
.btn-info.active,
.show>.btn-info.dropdown-toggle {
  color: #000;
  background-color: #33beff;
  border-color: #1ab6ff;
}

.btn-check:checked+.btn-info:focus,
.btn-check:active+.btn-info:focus,
.btn-info:active:focus,
.btn-info.active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(0, 148, 217, 0.5);
}

.btn-info:disabled,
.btn-info.disabled {
  color: #000;
  background-color: #00aeff;
  border-color: #00aeff;
}

.btn-warning {
  color: #000;
  background-color: #fea500;
  border-color: #fea500;
}

.btn-warning:hover {
  color: #000;
  background-color: #feb326;
  border-color: #feae1a;
}

.btn-check:focus+.btn-warning,
.btn-warning:focus {
  color: #000;
  background-color: #feb326;
  border-color: #feae1a;
  box-shadow: 0 0 0 0rem rgba(216, 140, 0, 0.5);
}

.btn-check:checked+.btn-warning,
.btn-check:active+.btn-warning,
.btn-warning:active,
.btn-warning.active,
.show>.btn-warning.dropdown-toggle {
  color: #000;
  background-color: #feb733;
  border-color: #feae1a;
}

.btn-check:checked+.btn-warning:focus,
.btn-check:active+.btn-warning:focus,
.btn-warning:active:focus,
.btn-warning.active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(216, 140, 0, 0.5);
}

.btn-warning:disabled,
.btn-warning.disabled {
  color: #000;
  background-color: #fea500;
  border-color: #fea500;
}

.btn-danger {
  color: #000;
  background-color: #FF1E6F;
  border-color: #FF1E6F;
}

.btn-danger:hover {
  color: #000;
  background-color: #ff4085;
  border-color: #ff357d;
}

.btn-check:focus+.btn-danger,
.btn-danger:focus {
  color: #000;
  background-color: #ff4085;
  border-color: #ff357d;
  box-shadow: 0 0 0 0rem rgba(217, 26, 94, 0.5);
}

.btn-check:checked+.btn-danger,
.btn-check:active+.btn-danger,
.btn-danger:active,
.btn-danger.active,
.show>.btn-danger.dropdown-toggle {
  color: #000;
  background-color: #ff4b8c;
  border-color: #ff357d;
}

.btn-check:checked+.btn-danger:focus,
.btn-check:active+.btn-danger:focus,
.btn-danger:active:focus,
.btn-danger.active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(217, 26, 94, 0.5);
}

.btn-danger:disabled,
.btn-danger.disabled {
  color: #000;
  background-color: #FF1E6F;
  border-color: #FF1E6F;
}

.btn-light {
  color: #000;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.btn-light:hover {
  color: #000;
  background-color: #f7f7f7;
  border-color: #f6f6f6;
}

.btn-check:focus+.btn-light,
.btn-light:focus {
  color: #000;
  background-color: #f7f7f7;
  border-color: #f6f6f6;
  box-shadow: 0 0 0 0rem rgba(208, 208, 208, 0.5);
}

.btn-check:checked+.btn-light,
.btn-check:active+.btn-light,
.btn-light:active,
.btn-light.active,
.show>.btn-light.dropdown-toggle {
  color: #000;
  background-color: #f7f7f7;
  border-color: #f6f6f6;
}

.btn-check:checked+.btn-light:focus,
.btn-check:active+.btn-light:focus,
.btn-light:active:focus,
.btn-light.active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(208, 208, 208, 0.5);
}

.btn-light:disabled,
.btn-light.disabled {
  color: #000;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.btn-dark {
  color: #ffffff;
  background-color: #212529;
  border-color: #212529;
}

.btn-dark:hover {
  color: #ffffff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}

.btn-check:focus+.btn-dark,
.btn-dark:focus {
  color: #ffffff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: 0 0 0 0rem rgba(66, 70, 73, 0.5);
}

.btn-check:checked+.btn-dark,
.btn-check:active+.btn-dark,
.btn-dark:active,
.btn-dark.active,
.show>.btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #1a1e21;
  border-color: #191c1f;
}

.btn-check:checked+.btn-dark:focus,
.btn-check:active+.btn-dark:focus,
.btn-dark:active:focus,
.btn-dark.active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(66, 70, 73, 0.5);
}

.btn-dark:disabled,
.btn-dark.disabled {
  color: #ffffff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-primary,
.is-style-outline .wp-block-button__link {
  color: #EAA451;
  border-color: #EAA451;
}

.btn-outline-primary:hover,
.is-style-outline .wp-block-button__link:hover {
  color: #000;
  background-color: #EAA451;
  border-color: #EAA451;
}

.btn-check:focus+.btn-outline-primary,
.is-style-outline .btn-check:focus+.wp-block-button__link,
.btn-outline-primary:focus,
.is-style-outline .wp-block-button__link:focus {
  box-shadow: 0 0 0 0rem rgba(234, 164, 81, 0.5);
}

.btn-check:checked+.btn-outline-primary,
.is-style-outline .btn-check:checked+.wp-block-button__link,
.btn-check:active+.btn-outline-primary,
.is-style-outline .btn-check:active+.wp-block-button__link,
.btn-outline-primary:active,
.is-style-outline .wp-block-button__link:active,
.btn-outline-primary.active,
.is-style-outline .active.wp-block-button__link,
.btn-outline-primary.dropdown-toggle.show,
.is-style-outline .dropdown-toggle.show.wp-block-button__link {
  color: #000;
  background-color: #EAA451;
  border-color: #EAA451;
}

.btn-check:checked+.btn-outline-primary:focus,
.is-style-outline .btn-check:checked+.wp-block-button__link:focus,
.btn-check:active+.btn-outline-primary:focus,
.is-style-outline .btn-check:active+.wp-block-button__link:focus,
.btn-outline-primary:active:focus,
.is-style-outline .wp-block-button__link:active:focus,
.btn-outline-primary.active:focus,
.is-style-outline .active.wp-block-button__link:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.is-style-outline .dropdown-toggle.show.wp-block-button__link:focus {
  box-shadow: 0 0 0 0rem rgba(234, 164, 81, 0.5);
}

.btn-outline-primary:disabled,
.is-style-outline .wp-block-button__link:disabled,
.btn-outline-primary.disabled,
.is-style-outline .disabled.wp-block-button__link {
  color: #EAA451;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #1a1668;
  border-color: #1a1668;
}

.btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #1a1668;
  border-color: #1a1668;
}

.btn-check:focus+.btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0rem rgba(26, 22, 104, 0.5);
}

.btn-check:checked+.btn-outline-secondary,
.btn-check:active+.btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
  color: #ffffff;
  background-color: #1a1668;
  border-color: #1a1668;
}

.btn-check:checked+.btn-outline-secondary:focus,
.btn-check:active+.btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(26, 22, 104, 0.5);
}

.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
  color: #1a1668;
  background-color: transparent;
}

.btn-outline-success {
  color: #029e76;
  border-color: #029e76;
}

.btn-outline-success:hover {
  color: #000;
  background-color: #029e76;
  border-color: #029e76;
}

.btn-check:focus+.btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0rem rgba(2, 158, 118, 0.5);
}

.btn-check:checked+.btn-outline-success,
.btn-check:active+.btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
  color: #000;
  background-color: #029e76;
  border-color: #029e76;
}

.btn-check:checked+.btn-outline-success:focus,
.btn-check:active+.btn-outline-success:focus,
.btn-outline-success:active:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(2, 158, 118, 0.5);
}

.btn-outline-success:disabled,
.btn-outline-success.disabled {
  color: #029e76;
  background-color: transparent;
}

.btn-outline-info {
  color: #00aeff;
  border-color: #00aeff;
}

.btn-outline-info:hover {
  color: #000;
  background-color: #00aeff;
  border-color: #00aeff;
}

.btn-check:focus+.btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0rem rgba(0, 174, 255, 0.5);
}

.btn-check:checked+.btn-outline-info,
.btn-check:active+.btn-outline-info,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #00aeff;
  border-color: #00aeff;
}

.btn-check:checked+.btn-outline-info:focus,
.btn-check:active+.btn-outline-info:focus,
.btn-outline-info:active:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(0, 174, 255, 0.5);
}

.btn-outline-info:disabled,
.btn-outline-info.disabled {
  color: #00aeff;
  background-color: transparent;
}

.btn-outline-warning {
  color: #fea500;
  border-color: #fea500;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #fea500;
  border-color: #fea500;
}

.btn-check:focus+.btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0rem rgba(254, 165, 0, 0.5);
}

.btn-check:checked+.btn-outline-warning,
.btn-check:active+.btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #fea500;
  border-color: #fea500;
}

.btn-check:checked+.btn-outline-warning:focus,
.btn-check:active+.btn-outline-warning:focus,
.btn-outline-warning:active:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(254, 165, 0, 0.5);
}

.btn-outline-warning:disabled,
.btn-outline-warning.disabled {
  color: #fea500;
  background-color: transparent;
}

.btn-outline-danger {
  color: #FF1E6F;
  border-color: #FF1E6F;
}

.btn-outline-danger:hover {
  color: #000;
  background-color: #FF1E6F;
  border-color: #FF1E6F;
}

.btn-check:focus+.btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0rem rgba(255, 30, 111, 0.5);
}

.btn-check:checked+.btn-outline-danger,
.btn-check:active+.btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
  color: #000;
  background-color: #FF1E6F;
  border-color: #FF1E6F;
}

.btn-check:checked+.btn-outline-danger:focus,
.btn-check:active+.btn-outline-danger:focus,
.btn-outline-danger:active:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(255, 30, 111, 0.5);
}

.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
  color: #FF1E6F;
  background-color: transparent;
}

.btn-outline-light {
  color: #f5f5f5;
  border-color: #f5f5f5;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.btn-check:focus+.btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0rem rgba(245, 245, 245, 0.5);
}

.btn-check:checked+.btn-outline-light,
.btn-check:active+.btn-outline-light,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.btn-check:checked+.btn-outline-light:focus,
.btn-check:active+.btn-outline-light:focus,
.btn-outline-light:active:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(245, 245, 245, 0.5);
}

.btn-outline-light:disabled,
.btn-outline-light.disabled {
  color: #f5f5f5;
  background-color: transparent;
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}

.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #212529;
  border-color: #212529;
}

.btn-check:focus+.btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0rem rgba(33, 37, 41, 0.5);
}

.btn-check:checked+.btn-outline-dark,
.btn-check:active+.btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
  color: #ffffff;
  background-color: #212529;
  border-color: #212529;
}

.btn-check:checked+.btn-outline-dark:focus,
.btn-check:active+.btn-outline-dark:focus,
.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(33, 37, 41, 0.5);
}

.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
  color: #212529;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #EAA451;
  text-decoration: underline;
}

.btn-link:hover {
  color: #bb8341;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
}

.btn-lg,
.btn-group-lg>.btn,
.btn-group-lg>.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-group-lg>a,
.woocommerce.widget_shopping_cart .buttons .btn-group-lg>a,
.woocommerce #review_form #respond .form-submit .btn-group-lg>input,
.woocommerce-cart-form .table tr .btn-group-lg>.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-lg>a.checkout-button,
.woocommerce #respond .btn-group-lg>input#submit,
.woocommerce .btn-group-lg>a.button,
.woocommerce .btn-group-lg>button.button,
.woocommerce .btn-group-lg>input.button,
.woocommerce .btn-group-lg>#place_order {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 1rem;
}

.btn-sm,
.btn-group-sm>.btn,
.btn-group-sm>.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-group-sm>a,
.woocommerce.widget_shopping_cart .buttons .btn-group-sm>a,
.woocommerce #review_form #respond .form-submit .btn-group-sm>input,
.woocommerce-cart-form .table tr .btn-group-sm>.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-sm>a.checkout-button,
.woocommerce #respond .btn-group-sm>input#submit,
.woocommerce .btn-group-sm>a.button,
.woocommerce .btn-group-sm>button.button,
.woocommerce .btn-group-sm>input.button,
.woocommerce .btn-group-sm>#place_order {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.75rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #777777;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.375rem;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1440) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #EAA451;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-item:hover,
.dropdown-menu-dark .dropdown-item:focus {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  color: #ffffff;
  background-color: #EAA451;
}

.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group>.btn,
.btn-group>.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-group>a,
.woocommerce.widget_shopping_cart .buttons .btn-group>a,
.woocommerce #review_form #respond .form-submit .btn-group>input,
.woocommerce-cart-form .table tr .btn-group>.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-group>a.checkout-button,
.woocommerce #respond .btn-group>input#submit,
.woocommerce .btn-group>a.button,
.woocommerce .btn-group>button.button,
.woocommerce .btn-group>input.button,
.woocommerce .btn-group>#place_order,
.btn-group-vertical>.btn,
.btn-group-vertical>.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input,
.woocommerce-cart-form .table tr .btn-group-vertical>.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button,
.woocommerce #respond .btn-group-vertical>input#submit,
.woocommerce .btn-group-vertical>a.button,
.woocommerce .btn-group-vertical>button.button,
.woocommerce .btn-group-vertical>input.button,
.woocommerce .btn-group-vertical>#place_order {
  position: relative;
  flex: 1 1 auto;
}

.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:checked+.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-group>.btn-check:checked+a,
.woocommerce.widget_shopping_cart .buttons .btn-group>.btn-check:checked+a,
.woocommerce #review_form #respond .form-submit .btn-group>.btn-check:checked+input,
.woocommerce-cart-form .table tr .btn-group>.btn-check:checked+.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-group>.btn-check:checked+a.checkout-button,
.woocommerce #respond .btn-group>.btn-check:checked+input#submit,
.woocommerce .btn-group>.btn-check:checked+a.button,
.woocommerce .btn-group>.btn-check:checked+button.button,
.woocommerce .btn-group>.btn-check:checked+input.button,
.woocommerce .btn-group>.btn-check:checked+#place_order,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn-check:focus+.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-group>.btn-check:focus+a,
.woocommerce.widget_shopping_cart .buttons .btn-group>.btn-check:focus+a,
.woocommerce #review_form #respond .form-submit .btn-group>.btn-check:focus+input,
.woocommerce-cart-form .table tr .btn-group>.btn-check:focus+.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-group>.btn-check:focus+a.checkout-button,
.woocommerce #respond .btn-group>.btn-check:focus+input#submit,
.woocommerce .btn-group>.btn-check:focus+a.button,
.woocommerce .btn-group>.btn-check:focus+button.button,
.woocommerce .btn-group>.btn-check:focus+input.button,
.woocommerce .btn-group>.btn-check:focus+#place_order,
.btn-group>.btn:hover,
.btn-group>.wp-block-button__link:hover,
.woocommerce .widget_shopping_cart .buttons .btn-group>a:hover,
.woocommerce.widget_shopping_cart .buttons .btn-group>a:hover,
.woocommerce #review_form #respond .form-submit .btn-group>input:hover,
.woocommerce-cart-form .table tr .btn-group>.button:hover,
.woocommerce-cart .wc-proceed-to-checkout .btn-group>a.checkout-button:hover,
.woocommerce #respond .btn-group>input#submit:hover,
.woocommerce .btn-group>a.button:hover,
.woocommerce .btn-group>button.button:hover,
.woocommerce .btn-group>input.button:hover,
.woocommerce .btn-group>#place_order:hover,
.btn-group>.btn:focus,
.btn-group>.wp-block-button__link:focus,
.woocommerce .widget_shopping_cart .buttons .btn-group>a:focus,
.woocommerce.widget_shopping_cart .buttons .btn-group>a:focus,
.woocommerce #review_form #respond .form-submit .btn-group>input:focus,
.woocommerce-cart-form .table tr .btn-group>.button:focus,
.woocommerce-cart .wc-proceed-to-checkout .btn-group>a.checkout-button:focus,
.woocommerce #respond .btn-group>input#submit:focus,
.woocommerce .btn-group>a.button:focus,
.woocommerce .btn-group>button.button:focus,
.woocommerce .btn-group>input.button:focus,
.woocommerce .btn-group>#place_order:focus,
.btn-group>.btn:active,
.btn-group>.wp-block-button__link:active,
.woocommerce .widget_shopping_cart .buttons .btn-group>a:active,
.woocommerce.widget_shopping_cart .buttons .btn-group>a:active,
.woocommerce #review_form #respond .form-submit .btn-group>input:active,
.woocommerce-cart-form .table tr .btn-group>.button:active,
.woocommerce-cart .wc-proceed-to-checkout .btn-group>a.checkout-button:active,
.woocommerce #respond .btn-group>input#submit:active,
.woocommerce .btn-group>a.button:active,
.woocommerce .btn-group>button.button:active,
.woocommerce .btn-group>input.button:active,
.woocommerce .btn-group>#place_order:active,
.btn-group>.btn.active,
.btn-group>.active.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-group>a.active,
.woocommerce.widget_shopping_cart .buttons .btn-group>a.active,
.woocommerce #review_form #respond .form-submit .btn-group>input.active,
.woocommerce-cart-form .table tr .btn-group>.active.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-group>a.active.checkout-button,
.woocommerce #respond .btn-group>input.active#submit,
.woocommerce .btn-group>a.active.button,
.woocommerce .btn-group>button.active.button,
.woocommerce .btn-group>input.active.button,
.woocommerce .btn-group>.active#place_order,
.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:checked+.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>.btn-check:checked+a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>.btn-check:checked+a,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>.btn-check:checked+input,
.woocommerce-cart-form .table tr .btn-group-vertical>.btn-check:checked+.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>.btn-check:checked+a.checkout-button,
.woocommerce #respond .btn-group-vertical>.btn-check:checked+input#submit,
.woocommerce .btn-group-vertical>.btn-check:checked+a.button,
.woocommerce .btn-group-vertical>.btn-check:checked+button.button,
.woocommerce .btn-group-vertical>.btn-check:checked+input.button,
.woocommerce .btn-group-vertical>.btn-check:checked+#place_order,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn-check:focus+.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>.btn-check:focus+a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>.btn-check:focus+a,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>.btn-check:focus+input,
.woocommerce-cart-form .table tr .btn-group-vertical>.btn-check:focus+.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>.btn-check:focus+a.checkout-button,
.woocommerce #respond .btn-group-vertical>.btn-check:focus+input#submit,
.woocommerce .btn-group-vertical>.btn-check:focus+a.button,
.woocommerce .btn-group-vertical>.btn-check:focus+button.button,
.woocommerce .btn-group-vertical>.btn-check:focus+input.button,
.woocommerce .btn-group-vertical>.btn-check:focus+#place_order,
.btn-group-vertical>.btn:hover,
.btn-group-vertical>.wp-block-button__link:hover,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a:hover,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a:hover,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input:hover,
.woocommerce-cart-form .table tr .btn-group-vertical>.button:hover,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button:hover,
.woocommerce #respond .btn-group-vertical>input#submit:hover,
.woocommerce .btn-group-vertical>a.button:hover,
.woocommerce .btn-group-vertical>button.button:hover,
.woocommerce .btn-group-vertical>input.button:hover,
.woocommerce .btn-group-vertical>#place_order:hover,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.wp-block-button__link:focus,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a:focus,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a:focus,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input:focus,
.woocommerce-cart-form .table tr .btn-group-vertical>.button:focus,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button:focus,
.woocommerce #respond .btn-group-vertical>input#submit:focus,
.woocommerce .btn-group-vertical>a.button:focus,
.woocommerce .btn-group-vertical>button.button:focus,
.woocommerce .btn-group-vertical>input.button:focus,
.woocommerce .btn-group-vertical>#place_order:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.wp-block-button__link:active,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a:active,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a:active,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input:active,
.woocommerce-cart-form .table tr .btn-group-vertical>.button:active,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button:active,
.woocommerce #respond .btn-group-vertical>input#submit:active,
.woocommerce .btn-group-vertical>a.button:active,
.woocommerce .btn-group-vertical>button.button:active,
.woocommerce .btn-group-vertical>input.button:active,
.woocommerce .btn-group-vertical>#place_order:active,
.btn-group-vertical>.btn.active,
.btn-group-vertical>.active.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a.active,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a.active,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input.active,
.woocommerce-cart-form .table tr .btn-group-vertical>.active.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.active.checkout-button,
.woocommerce #respond .btn-group-vertical>input.active#submit,
.woocommerce .btn-group-vertical>a.active.button,
.woocommerce .btn-group-vertical>button.active.button,
.woocommerce .btn-group-vertical>input.active.button,
.woocommerce .btn-group-vertical>.active#place_order {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group>.btn:not(:first-child),
.btn-group>.wp-block-button__link:not(:first-child),
.woocommerce .widget_shopping_cart .buttons .btn-group>a:not(:first-child),
.woocommerce.widget_shopping_cart .buttons .btn-group>a:not(:first-child),
.woocommerce #review_form #respond .form-submit .btn-group>input:not(:first-child),
.woocommerce-cart-form .table tr .btn-group>.button:not(:first-child),
.woocommerce-cart .wc-proceed-to-checkout .btn-group>a.checkout-button:not(:first-child),
.woocommerce #respond .btn-group>input#submit:not(:first-child),
.woocommerce .btn-group>a.button:not(:first-child),
.woocommerce .btn-group>button.button:not(:first-child),
.woocommerce .btn-group>input.button:not(:first-child),
.woocommerce .btn-group>#place_order:not(:first-child),
.btn-group>.btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.wp-block-button__link:not(:last-child):not(.dropdown-toggle),
.woocommerce .widget_shopping_cart .buttons .btn-group>a:not(:last-child):not(.dropdown-toggle),
.woocommerce.widget_shopping_cart .buttons .btn-group>a:not(:last-child):not(.dropdown-toggle),
.woocommerce #review_form #respond .form-submit .btn-group>input:not(:last-child):not(.dropdown-toggle),
.woocommerce-cart-form .table tr .btn-group>.button:not(:last-child):not(.dropdown-toggle),
.woocommerce-cart .wc-proceed-to-checkout .btn-group>a.checkout-button:not(:last-child):not(.dropdown-toggle),
.woocommerce #respond .btn-group>input#submit:not(:last-child):not(.dropdown-toggle),
.woocommerce .btn-group>a.button:not(:last-child):not(.dropdown-toggle),
.woocommerce .btn-group>button.button:not(:last-child):not(.dropdown-toggle),
.woocommerce .btn-group>input.button:not(:last-child):not(.dropdown-toggle),
.woocommerce .btn-group>#place_order:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn-group:not(:last-child)>.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-group>.btn-group:not(:last-child)>a,
.woocommerce.widget_shopping_cart .buttons .btn-group>.btn-group:not(:last-child)>a,
.woocommerce #review_form #respond .form-submit .btn-group>.btn-group:not(:last-child)>input,
.woocommerce-cart-form .table tr .btn-group>.btn-group:not(:last-child)>.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-group>.btn-group:not(:last-child)>a.checkout-button,
.woocommerce #respond .btn-group>.btn-group:not(:last-child)>input#submit,
.woocommerce .btn-group>.btn-group:not(:last-child)>a.button,
.woocommerce .btn-group>.btn-group:not(:last-child)>button.button,
.woocommerce .btn-group>.btn-group:not(:last-child)>input.button,
.woocommerce .btn-group>.btn-group:not(:last-child)>#place_order {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:nth-child(n + 3),
.btn-group>.wp-block-button__link:nth-child(n + 3),
.woocommerce .widget_shopping_cart .buttons .btn-group>a:nth-child(n + 3),
.woocommerce.widget_shopping_cart .buttons .btn-group>a:nth-child(n + 3),
.woocommerce #review_form #respond .form-submit .btn-group>input:nth-child(n + 3),
.woocommerce-cart-form .table tr .btn-group>.button:nth-child(n + 3),
.woocommerce-cart .wc-proceed-to-checkout .btn-group>a.checkout-button:nth-child(n + 3),
.woocommerce #respond .btn-group>input#submit:nth-child(n + 3),
.woocommerce .btn-group>a.button:nth-child(n + 3),
.woocommerce .btn-group>button.button:nth-child(n + 3),
.woocommerce .btn-group>input.button:nth-child(n + 3),
.woocommerce .btn-group>#place_order:nth-child(n + 3),
.btn-group> :not(.btn-check)+.btn,
.btn-group> :not(.btn-check)+.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-group> :not(.btn-check)+a,
.woocommerce.widget_shopping_cart .buttons .btn-group> :not(.btn-check)+a,
.woocommerce #review_form #respond .form-submit .btn-group> :not(.btn-check)+input,
.woocommerce-cart-form .table tr .btn-group> :not(.btn-check)+.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-group> :not(.btn-check)+a.checkout-button,
.woocommerce #respond .btn-group> :not(.btn-check)+input#submit,
.woocommerce .btn-group> :not(.btn-check)+a.button,
.woocommerce .btn-group> :not(.btn-check)+button.button,
.woocommerce .btn-group> :not(.btn-check)+input.button,
.woocommerce .btn-group> :not(.btn-check)+#place_order,
.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn-group:not(:first-child)>.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-group>.btn-group:not(:first-child)>a,
.woocommerce.widget_shopping_cart .buttons .btn-group>.btn-group:not(:first-child)>a,
.woocommerce #review_form #respond .form-submit .btn-group>.btn-group:not(:first-child)>input,
.woocommerce-cart-form .table tr .btn-group>.btn-group:not(:first-child)>.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-group>.btn-group:not(:first-child)>a.checkout-button,
.woocommerce #respond .btn-group>.btn-group:not(:first-child)>input#submit,
.woocommerce .btn-group>.btn-group:not(:first-child)>a.button,
.woocommerce .btn-group>.btn-group:not(:first-child)>button.button,
.woocommerce .btn-group>.btn-group:not(:first-child)>input.button,
.woocommerce .btn-group>.btn-group:not(:first-child)>#place_order {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-group-sm>.wp-block-button__link+.dropdown-toggle-split,
.woocommerce .widget_shopping_cart .buttons .btn-group-sm>a+.dropdown-toggle-split,
.woocommerce.widget_shopping_cart .buttons .btn-group-sm>a+.dropdown-toggle-split,
.woocommerce #review_form #respond .form-submit .btn-group-sm>input+.dropdown-toggle-split,
.woocommerce-cart-form .table tr .btn-group-sm>.button+.dropdown-toggle-split,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-sm>a.checkout-button+.dropdown-toggle-split,
.woocommerce #respond .btn-group-sm>input#submit+.dropdown-toggle-split,
.woocommerce .btn-group-sm>a.button+.dropdown-toggle-split,
.woocommerce .btn-group-sm>button.button+.dropdown-toggle-split,
.woocommerce .btn-group-sm>input.button+.dropdown-toggle-split,
.woocommerce .btn-group-sm>#place_order+.dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-group-lg>.wp-block-button__link+.dropdown-toggle-split,
.woocommerce .widget_shopping_cart .buttons .btn-group-lg>a+.dropdown-toggle-split,
.woocommerce.widget_shopping_cart .buttons .btn-group-lg>a+.dropdown-toggle-split,
.woocommerce #review_form #respond .form-submit .btn-group-lg>input+.dropdown-toggle-split,
.woocommerce-cart-form .table tr .btn-group-lg>.button+.dropdown-toggle-split,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-lg>a.checkout-button+.dropdown-toggle-split,
.woocommerce #respond .btn-group-lg>input#submit+.dropdown-toggle-split,
.woocommerce .btn-group-lg>a.button+.dropdown-toggle-split,
.woocommerce .btn-group-lg>button.button+.dropdown-toggle-split,
.woocommerce .btn-group-lg>input.button+.dropdown-toggle-split,
.woocommerce .btn-group-lg>#place_order+.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input,
.woocommerce-cart-form .table tr .btn-group-vertical>.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button,
.woocommerce #respond .btn-group-vertical>input#submit,
.woocommerce .btn-group-vertical>a.button,
.woocommerce .btn-group-vertical>button.button,
.woocommerce .btn-group-vertical>input.button,
.woocommerce .btn-group-vertical>#place_order,
.btn-group-vertical>.btn-group {
  width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.wp-block-button__link:not(:first-child),
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a:not(:first-child),
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a:not(:first-child),
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input:not(:first-child),
.woocommerce-cart-form .table tr .btn-group-vertical>.button:not(:first-child),
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button:not(:first-child),
.woocommerce #respond .btn-group-vertical>input#submit:not(:first-child),
.woocommerce .btn-group-vertical>a.button:not(:first-child),
.woocommerce .btn-group-vertical>button.button:not(:first-child),
.woocommerce .btn-group-vertical>input.button:not(:first-child),
.woocommerce .btn-group-vertical>#place_order:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.wp-block-button__link:not(:last-child):not(.dropdown-toggle),
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a:not(:last-child):not(.dropdown-toggle),
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a:not(:last-child):not(.dropdown-toggle),
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input:not(:last-child):not(.dropdown-toggle),
.woocommerce-cart-form .table tr .btn-group-vertical>.button:not(:last-child):not(.dropdown-toggle),
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button:not(:last-child):not(.dropdown-toggle),
.woocommerce #respond .btn-group-vertical>input#submit:not(:last-child):not(.dropdown-toggle),
.woocommerce .btn-group-vertical>a.button:not(:last-child):not(.dropdown-toggle),
.woocommerce .btn-group-vertical>button.button:not(:last-child):not(.dropdown-toggle),
.woocommerce .btn-group-vertical>input.button:not(:last-child):not(.dropdown-toggle),
.woocommerce .btn-group-vertical>#place_order:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn-group:not(:last-child)>.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>.btn-group:not(:last-child)>a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>.btn-group:not(:last-child)>a,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>.btn-group:not(:last-child)>input,
.woocommerce-cart-form .table tr .btn-group-vertical>.btn-group:not(:last-child)>.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>.btn-group:not(:last-child)>a.checkout-button,
.woocommerce #respond .btn-group-vertical>.btn-group:not(:last-child)>input#submit,
.woocommerce .btn-group-vertical>.btn-group:not(:last-child)>a.button,
.woocommerce .btn-group-vertical>.btn-group:not(:last-child)>button.button,
.woocommerce .btn-group-vertical>.btn-group:not(:last-child)>input.button,
.woocommerce .btn-group-vertical>.btn-group:not(:last-child)>#place_order {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn~.btn,
.btn-group-vertical>.wp-block-button__link~.btn,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a~.btn,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a~.btn,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input~.btn,
.woocommerce-cart-form .table tr .btn-group-vertical>.button~.btn,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button~.btn,
.woocommerce #respond .btn-group-vertical>input#submit~.btn,
.woocommerce .btn-group-vertical>a.button~.btn,
.woocommerce .btn-group-vertical>button.button~.btn,
.woocommerce .btn-group-vertical>input.button~.btn,
.woocommerce .btn-group-vertical>#place_order~.btn,
.btn-group-vertical>.btn~.wp-block-button__link,
.btn-group-vertical>.wp-block-button__link~.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a~.wp-block-button__link,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a~.wp-block-button__link,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input~.wp-block-button__link,
.woocommerce-cart-form .table tr .btn-group-vertical>.button~.wp-block-button__link,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button~.wp-block-button__link,
.woocommerce #respond .btn-group-vertical>input#submit~.wp-block-button__link,
.woocommerce .btn-group-vertical>a.button~.wp-block-button__link,
.woocommerce .btn-group-vertical>button.button~.wp-block-button__link,
.woocommerce .btn-group-vertical>input.button~.wp-block-button__link,
.woocommerce .btn-group-vertical>#place_order~.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>.btn~a,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>.wp-block-button__link~a,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a~a,
.woocommerce.widget_shopping_cart .widget_shopping_cart .buttons .btn-group-vertical>a~a,
.woocommerce #review_form #respond .form-submit .widget_shopping_cart .buttons .btn-group-vertical>input~a,
.woocommerce .widget_shopping_cart .buttons #review_form #respond .form-submit .btn-group-vertical>input~a,
.woocommerce-cart-form .table tr .woocommerce .widget_shopping_cart .buttons .btn-group-vertical>.button~a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-cart-form .table tr .btn-group-vertical>.button~a,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a.checkout-button~a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button~a,
.woocommerce #respond .widget_shopping_cart .buttons .btn-group-vertical>input#submit~a,
.woocommerce .widget_shopping_cart .buttons #respond .btn-group-vertical>input#submit~a,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a.button~a,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>button.button~a,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>input.button~a,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>#place_order~a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>.btn~a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>.wp-block-button__link~a,
.woocommerce.widget_shopping_cart .widget_shopping_cart .buttons .btn-group-vertical>a~a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a~a,
.woocommerce.widget_shopping_cart #review_form #respond .form-submit .buttons .btn-group-vertical>input~a,
.woocommerce.widget_shopping_cart .buttons #review_form #respond .form-submit .btn-group-vertical>input~a,
.woocommerce-cart-form .table tr .woocommerce.widget_shopping_cart .buttons .btn-group-vertical>.button~a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-cart-form .table tr .btn-group-vertical>.button~a,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a.checkout-button~a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button~a,
.woocommerce.widget_shopping_cart #respond .buttons .btn-group-vertical>input#submit~a,
.woocommerce.widget_shopping_cart .buttons #respond .btn-group-vertical>input#submit~a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a.button~a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>button.button~a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>input.button~a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>#place_order~a,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>.btn~input,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>.wp-block-button__link~input,
.woocommerce .widget_shopping_cart .buttons #review_form #respond .form-submit .btn-group-vertical>a~input,
.woocommerce #review_form #respond .form-submit .widget_shopping_cart .buttons .btn-group-vertical>a~input,
.woocommerce.widget_shopping_cart .buttons #review_form #respond .form-submit .btn-group-vertical>a~input,
.woocommerce.widget_shopping_cart #review_form #respond .form-submit .buttons .btn-group-vertical>a~input,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input~input,
.woocommerce-cart-form .table tr .woocommerce #review_form #respond .form-submit .btn-group-vertical>.button~input,
.woocommerce #review_form #respond .form-submit .woocommerce-cart-form .table tr .btn-group-vertical>.button~input,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #review_form #respond .form-submit .btn-group-vertical>a.checkout-button~input,
.woocommerce #review_form #respond .form-submit .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button~input,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input#submit~input,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>a.button~input,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>button.button~input,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input.button~input,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>#place_order~input,
.woocommerce-cart-form .table tr .btn-group-vertical>.btn~.button,
.woocommerce-cart-form .table tr .btn-group-vertical>.wp-block-button__link~.button,
.woocommerce .widget_shopping_cart .buttons .woocommerce-cart-form .table tr .btn-group-vertical>a~.button,
.woocommerce-cart-form .table tr .woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a~.button,
.woocommerce.widget_shopping_cart .buttons .woocommerce-cart-form .table tr .btn-group-vertical>a~.button,
.woocommerce-cart-form .table tr .woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a~.button,
.woocommerce #review_form #respond .form-submit .woocommerce-cart-form .table tr .btn-group-vertical>input~.button,
.woocommerce-cart-form .table tr .woocommerce #review_form #respond .form-submit .btn-group-vertical>input~.button,
.woocommerce-cart-form .table tr .btn-group-vertical>.button~.button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-cart-form .table tr .btn-group-vertical>a.checkout-button~.button,
.woocommerce-cart-form .table tr .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button~.button,
.woocommerce #respond .woocommerce-cart-form .table tr .btn-group-vertical>input#submit~.button,
.woocommerce-cart-form .table tr .woocommerce #respond .btn-group-vertical>input#submit~.button,
.woocommerce .woocommerce-cart-form .table tr .btn-group-vertical>a.button~.button,
.woocommerce-cart-form .table tr .woocommerce .btn-group-vertical>a.button~.button,
.woocommerce .woocommerce-cart-form .table tr .btn-group-vertical>button.button~.button,
.woocommerce-cart-form .table tr .woocommerce .btn-group-vertical>button.button~.button,
.woocommerce .woocommerce-cart-form .table tr .btn-group-vertical>input.button~.button,
.woocommerce-cart-form .table tr .woocommerce .btn-group-vertical>input.button~.button,
.woocommerce .woocommerce-cart-form .table tr .btn-group-vertical>#place_order~.button,
.woocommerce-cart-form .table tr .woocommerce .btn-group-vertical>#place_order~.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>.btn~a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>.wp-block-button__link~a.checkout-button,
.woocommerce .widget_shopping_cart .buttons .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a~a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a~a.checkout-button,
.woocommerce.widget_shopping_cart .buttons .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a~a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a~a.checkout-button,
.woocommerce #review_form #respond .form-submit .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>input~a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #review_form #respond .form-submit .btn-group-vertical>input~a.checkout-button,
.woocommerce-cart-form .table tr .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>.button~a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-cart-form .table tr .btn-group-vertical>.button~a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button~a.checkout-button,
.woocommerce #respond .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>input#submit~a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #respond .btn-group-vertical>input#submit~a.checkout-button,
.woocommerce .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.button~a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .btn-group-vertical>a.button~a.checkout-button,
.woocommerce .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>button.button~a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .btn-group-vertical>button.button~a.checkout-button,
.woocommerce .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>input.button~a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .btn-group-vertical>input.button~a.checkout-button,
.woocommerce .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>#place_order~a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .btn-group-vertical>#place_order~a.checkout-button,
.woocommerce #respond .btn-group-vertical>.btn~input#submit,
.woocommerce #respond .btn-group-vertical>.wp-block-button__link~input#submit,
.woocommerce .widget_shopping_cart .buttons #respond .btn-group-vertical>a~input#submit,
.woocommerce #respond .widget_shopping_cart .buttons .btn-group-vertical>a~input#submit,
.woocommerce.widget_shopping_cart .buttons #respond .btn-group-vertical>a~input#submit,
.woocommerce.widget_shopping_cart #respond .buttons .btn-group-vertical>a~input#submit,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input~input#submit,
.woocommerce-cart-form .table tr .woocommerce #respond .btn-group-vertical>.button~input#submit,
.woocommerce #respond .woocommerce-cart-form .table tr .btn-group-vertical>.button~input#submit,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #respond .btn-group-vertical>a.checkout-button~input#submit,
.woocommerce #respond .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button~input#submit,
.woocommerce #respond .btn-group-vertical>input#submit~input#submit,
.woocommerce #respond .btn-group-vertical>a.button~input#submit,
.woocommerce #respond .btn-group-vertical>button.button~input#submit,
.woocommerce #respond .btn-group-vertical>input.button~input#submit,
.woocommerce #respond .btn-group-vertical>#place_order~input#submit,
.woocommerce .btn-group-vertical>.btn~a.button,
.woocommerce .btn-group-vertical>.wp-block-button__link~a.button,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a~a.button,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a~a.button,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input~a.button,
.woocommerce-cart-form .table tr .woocommerce .btn-group-vertical>.button~a.button,
.woocommerce .woocommerce-cart-form .table tr .btn-group-vertical>.button~a.button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .btn-group-vertical>a.checkout-button~a.button,
.woocommerce .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button~a.button,
.woocommerce #respond .btn-group-vertical>input#submit~a.button,
.woocommerce .btn-group-vertical>a.button~a.button,
.woocommerce .btn-group-vertical>button.button~a.button,
.woocommerce .btn-group-vertical>input.button~a.button,
.woocommerce .btn-group-vertical>#place_order~a.button,
.woocommerce .btn-group-vertical>.btn~button.button,
.woocommerce .btn-group-vertical>.wp-block-button__link~button.button,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a~button.button,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a~button.button,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input~button.button,
.woocommerce-cart-form .table tr .woocommerce .btn-group-vertical>.button~button.button,
.woocommerce .woocommerce-cart-form .table tr .btn-group-vertical>.button~button.button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .btn-group-vertical>a.checkout-button~button.button,
.woocommerce .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button~button.button,
.woocommerce #respond .btn-group-vertical>input#submit~button.button,
.woocommerce .btn-group-vertical>a.button~button.button,
.woocommerce .btn-group-vertical>button.button~button.button,
.woocommerce .btn-group-vertical>input.button~button.button,
.woocommerce .btn-group-vertical>#place_order~button.button,
.woocommerce .btn-group-vertical>.btn~input.button,
.woocommerce .btn-group-vertical>.wp-block-button__link~input.button,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a~input.button,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a~input.button,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input~input.button,
.woocommerce-cart-form .table tr .woocommerce .btn-group-vertical>.button~input.button,
.woocommerce .woocommerce-cart-form .table tr .btn-group-vertical>.button~input.button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .btn-group-vertical>a.checkout-button~input.button,
.woocommerce .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button~input.button,
.woocommerce #respond .btn-group-vertical>input#submit~input.button,
.woocommerce .btn-group-vertical>a.button~input.button,
.woocommerce .btn-group-vertical>button.button~input.button,
.woocommerce .btn-group-vertical>input.button~input.button,
.woocommerce .btn-group-vertical>#place_order~input.button,
.woocommerce .btn-group-vertical>.btn~#place_order,
.woocommerce .btn-group-vertical>.wp-block-button__link~#place_order,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a~#place_order,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a~#place_order,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input~#place_order,
.woocommerce-cart-form .table tr .woocommerce .btn-group-vertical>.button~#place_order,
.woocommerce .woocommerce-cart-form .table tr .btn-group-vertical>.button~#place_order,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .btn-group-vertical>a.checkout-button~#place_order,
.woocommerce .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button~#place_order,
.woocommerce #respond .btn-group-vertical>input#submit~#place_order,
.woocommerce .btn-group-vertical>a.button~#place_order,
.woocommerce .btn-group-vertical>button.button~#place_order,
.woocommerce .btn-group-vertical>input.button~#place_order,
.woocommerce .btn-group-vertical>#place_order~#place_order,
.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn-group:not(:first-child)>.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>.btn-group:not(:first-child)>a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>.btn-group:not(:first-child)>a,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>.btn-group:not(:first-child)>input,
.woocommerce-cart-form .table tr .btn-group-vertical>.btn-group:not(:first-child)>.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>.btn-group:not(:first-child)>a.checkout-button,
.woocommerce #respond .btn-group-vertical>.btn-group:not(:first-child)>input#submit,
.woocommerce .btn-group-vertical>.btn-group:not(:first-child)>a.button,
.woocommerce .btn-group-vertical>.btn-group:not(:first-child)>button.button,
.woocommerce .btn-group-vertical>.btn-group:not(:first-child)>input.button,
.woocommerce .btn-group-vertical>.btn-group:not(:first-child)>#place_order {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #EAA451;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover,
.nav-link:focus {
  color: #bb8341;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.375rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #ffffff;
  background-color: #EAA451;
}

.nav-fill>.nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified>.nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-sm,
.navbar>.container-md,
.navbar>.container-lg,
.navbar>.container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-.nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-.dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-.dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-.nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }

  .navbar-expand-sm .offcanvas-top,
  .navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-.dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-.nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }

  .navbar-expand-md .offcanvas-top,
  .navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-.dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-.nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }

  .navbar-expand-lg .offcanvas-top,
  .navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-.dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-.nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }

  .navbar-expand-xl .offcanvas-top,
  .navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1440) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-.dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-.nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }

  .navbar-expand-xxl .offcanvas-top,
  .navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-.dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-.nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}

.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}

.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-.nav-link {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-.nav-link:hover,
.navbar-light .navbar-.nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-.nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-.show>.nav-link,
.navbar-light .navbar-.nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #ffffff;
}

.navbar-dark .navbar-.nav-link {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-.nav-link:hover,
.navbar-dark .navbar-.nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-.nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-.show>.nav-link,
.navbar-dark .navbar-.nav-link.active {
  color: #ffffff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.375rem;
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px);
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.375rem - 1px);
  border-bottom-left-radius: calc(0.375rem - 1px);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link+.card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.375rem - 1px) calc(0.375rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.375rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.375rem - 1px);
  border-bottom-left-radius: calc(0.375rem - 1px);
}

.card-group>.card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-top,
  .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-bottom,
  .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-top,
  .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-bottom,
  .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #777777;
  text-align: left;
  background-color: #ffffff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: #d39449;
  background-color: #fdf6ee;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23d39449'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23777777'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #f5d2a8;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(234, 164, 81, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-item:first-of-type {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.375rem - 1px);
  border-bottom-left-radius: calc(0.375rem - 1px);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/")
    /* rtl: var(--bs-breadcrumb-divider, "/") */
  ;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #EAA451;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #bb8341;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  color: #bb8341;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(234, 164, 81, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #EAA451;
  border-color: #EAA451;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.375rem;
}

.badge:empty {
  display: none;
}

.btn .badge,
.wp-block-button__link .badge,
.woocommerce .widget_shopping_cart .buttons a .badge,
.woocommerce.widget_shopping_cart .buttons a .badge,
.woocommerce #review_form #respond .form-submit input .badge,
.woocommerce-cart-form .table tr .button .badge,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button .badge,
.woocommerce #respond input#submit .badge,
.woocommerce a.button .badge,
.woocommerce button.button .badge,
.woocommerce input.button .badge,
.woocommerce #place_order .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.375rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #8c6231;
  background-color: #fbeddc;
  border-color: #f9e4cb;
}

.alert-primary .alert-link {
  color: #704e27;
}

.alert-secondary {
  color: #100d3e;
  background-color: #d1d0e1;
  border-color: #bab9d2;
}

.alert-secondary .alert-link {
  color: #0d0a32;
}

.alert-success {
  color: #015f47;
  background-color: #ccece4;
  border-color: #b3e2d6;
}

.alert-success .alert-link {
  color: #014c39;
}

.alert-info {
  color: #006899;
  background-color: #ccefff;
  border-color: #b3e7ff;
}

.alert-info .alert-link {
  color: #00537a;
}

.alert-warning {
  color: #664200;
  background-color: #ffedcc;
  border-color: #ffe4b3;
}

.alert-warning .alert-link {
  color: #523500;
}

.alert-danger {
  color: #991243;
  background-color: #ffd2e2;
  border-color: #ffbcd4;
}

.alert-danger .alert-link {
  color: #7a0e36;
}

.alert-light {
  color: #626262;
  background-color: #fdfdfd;
  border-color: #fcfcfc;
}

.alert-light .alert-link {
  color: #4e4e4e;
}

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf;
}

.alert-dark .alert-link {
  color: #101214;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.375rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #EAA451;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.375rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered>li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #777777;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
}

.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #EAA451;
  border-color: #EAA451;
}

.list-group-item+.list-group-item {
  border-top-width: 0;
}

.list-group-item+.list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal>.list-group-item:first-child {
  border-bottom-left-radius: 0.375rem;
  border-top-right-radius: 0;
}

.list-group-horizontal>.list-group-item:last-child {
  border-top-right-radius: 0.375rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal>.list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm>.list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md>.list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg>.list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl>.list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1440) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl>.list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush>.list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #8c6231;
  background-color: #fbeddc;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #8c6231;
  background-color: #e2d5c6;
}

.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #8c6231;
  border-color: #8c6231;
}

.list-group-item-secondary {
  color: #100d3e;
  background-color: #d1d0e1;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #100d3e;
  background-color: #bcbbcb;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #100d3e;
  border-color: #100d3e;
}

.list-group-item-success {
  color: #015f47;
  background-color: #ccece4;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #015f47;
  background-color: #b8d4cd;
}

.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #015f47;
  border-color: #015f47;
}

.list-group-item-info {
  color: #006899;
  background-color: #ccefff;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #006899;
  background-color: #b8d7e6;
}

.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #006899;
  border-color: #006899;
}

.list-group-item-warning {
  color: #664200;
  background-color: #ffedcc;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #664200;
  background-color: #e6d5b8;
}

.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #664200;
  border-color: #664200;
}

.list-group-item-danger {
  color: #991243;
  background-color: #ffd2e2;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #991243;
  background-color: #e6bdcb;
}

.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #991243;
  border-color: #991243;
}

.list-group-item-light {
  color: #626262;
  background-color: #fdfdfd;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #626262;
  background-color: #e4e4e4;
}

.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #626262;
  border-color: #626262;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(234, 164, 81, 0.25);
  opacity: 1;
}

.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.375rem;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container> :not(:last-child) {
  margin-bottom: 15px;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px);
}

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #E1E1F0;
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.6;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #E1E1F0;
  border-bottom-right-radius: calc(1rem - 1px);
  border-bottom-left-radius: calc(1rem - 1px);
}

.modal-footer>* {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1439.98) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000;
  border-radius: 0.375rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0
    /* rtl:ignore */
  ;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}

.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top>.popover-arrow,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-end>.popover-arrow,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom>.popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start>.popover-arrow,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #1a1668;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #777777;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg)
      /* rtl:ignore */
    ;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {

  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #ffffff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}

.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.6;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}

.placeholder.btn::before,
.placeholder.wp-block-button__link::before,
.woocommerce .widget_shopping_cart .buttons a.placeholder::before,
.woocommerce.widget_shopping_cart .buttons a.placeholder::before,
.woocommerce #review_form #respond .form-submit input.placeholder::before,
.woocommerce-cart-form .table tr .placeholder.button::before,
.woocommerce-cart .wc-proceed-to-checkout a.placeholder.checkout-button::before,
.woocommerce #respond input.placeholder#submit::before,
.woocommerce a.placeholder.button::before,
.woocommerce button.placeholder.button::before,
.woocommerce input.placeholder.button::before,
.woocommerce .placeholder#place_order::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #EAA451;
}

.link-primary:hover,
.link-primary:focus {
  color: #eeb674;
}

.link-secondary {
  color: #1a1668;
}

.link-secondary:hover,
.link-secondary:focus {
  color: #151253;
}

.link-success {
  color: #029e76;
}

.link-success:hover,
.link-success:focus {
  color: #35b191;
}

.link-info {
  color: #00aeff;
}

.link-info:hover,
.link-info:focus {
  color: #33beff;
}

.link-warning {
  color: #fea500;
}

.link-warning:hover,
.link-warning:focus {
  color: #feb733;
}

.link-danger {
  color: #FF1E6F;
}

.link-danger:hover,
.link-danger:focus {
  color: #ff4b8c;
}

.link-light {
  color: #f5f5f5;
}

.link-light:hover,
.link-light:focus {
  color: #f7f7f7;
}

.link-dark {
  color: #212529;
}

.link-dark:hover,
.link-dark:focus {
  color: #1a1e21;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio>* {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1440) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #E1E1F0 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #E1E1F0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #E1E1F0 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #E1E1F0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #E1E1F0 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #EAA451 !important;
}

.border-secondary {
  border-color: #1a1668 !important;
}

.border-success {
  border-color: #029e76 !important;
}

.border-info {
  border-color: #00aeff !important;
}

.border-warning {
  border-color: #fea500 !important;
}

.border-danger {
  border-color: #FF1E6F !important;
}

.border-light {
  border-color: #f5f5f5 !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.3875rem + 1.65vw) !important;
}

.fs-2 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-3 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1.125rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.6 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: rgba(151, 151, 159, 0.39) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.375rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.75rem !important;
}

.rounded-2 {
  border-radius: 0.375rem !important;
}

.rounded-3 {
  border-radius: 1rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.rounded-end {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.375rem !important;
  border-top-left-radius: 0.375rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1440) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.625rem !important;
  }

  .fs-2 {
    font-size: 2.25rem !important;
  }

  .fs-3 {
    font-size: 1.875rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

* {
  outline: none;
  padding: 0;
}

*::after {
  margin: 0;
  padding: 0;
}

*::before {
  margin: 0;
  padding: 0;
}

body {
  line-height: 1.6;
  color: #777777;
  font-size: 1rem;
  font-family: var(--font-family-base);
}

body.fixed {
  overflow: hidden;
  position: fixed;
}

@media only screen and (max-width: 575px) {
  body {
    font-size: 14px;
  }
}

h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
h5 a,
.h5 a,
h6 a,
.h6 a {
  color: var(--title);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--font-family-title);
  font-weight: 600;
  color: var(--title);
  line-height: 1.4;
}

h1,
.h1,
.h1 {
  font-size: 2.625rem;
}

@media only screen and (max-width: 1680px) {

  h1,
  .h1,
  .h1 {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 1280px) {

  h1,
  .h1,
  .h1 {
    font-size: 2.813rem;
  }
}

@media only screen and (max-width: 767px) {

  h1,
  .h1,
  .h1 {
    font-size: 2.625rem;
  }
}

@media only screen and (max-width: 575px) {

  h1,
  .h1,
  .h1 {
    font-size: 2.25rem;
  }
}

h2,
.h2,
.h2 {
  font-size: 2.25rem;
}

@media only screen and (max-width: 1680px) {

  h2,
  .h2,
  .h2 {
    font-size: 2.625rem;
  }
}

@media only screen and (max-width: 1280px) {

  h2,
  .h2,
  .h2 {
    font-size: 2.375rem;
  }
}

@media only screen and (max-width: 767px) {

  h2,
  .h2,
  .h2 {
    font-size: 2rem;
  }
}

h3,
.h3,
.h3 {
  font-size: 1.875rem;
}

@media only screen and (max-width: 1680px) {

  h3,
  .h3,
  .h3 {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 1280px) {

  h3,
  .h3,
  .h3 {
    font-size: 1.875rem;
  }
}

@media only screen and (max-width: 767px) {

  h3,
  .h3,
  .h3 {
    font-size: 1.625rem;
  }
}

h4,
.h4,
.h4 {
  font-size: 1.5rem;
}

@media only screen and (max-width: 767px) {

  h4,
  .h4,
  .h4 {
    font-size: 1.25rem;
  }
}

h5,
.h5,
.h5 {
  font-size: 1.25rem;
}

@media only screen and (max-width: 767px) {

  h5,
  .h5,
  .h5 {
    font-size: 1.125rem;
  }
}

h6,
.h6,
.h6 {
  font-size: 1.125rem;
}

@media only screen and (max-width: 767px) {

  h6,
  .h6,
  .h6 {
    font-size: 1rem;
  }
}

ul {
  padding: 0;
  margin: 0;
}

img {
  border-style: none;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  outline: none;
  color: var(--primary);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

a:hover,
a:focus,
a.active {
  text-decoration: none;
  outline: none;
  color: var(--primary-hover);
}

.text-primary {
  color: var(--primary) !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

.bg-blue {
  background-color: #060340 !important;
}

b,
strong,
.strong {
  font-weight: 700;
}

.h-0 {
  height: 0;
}

#main-wrapper {
  opacity: 0;
  transition: all 0.25s ease-in;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-top: 60px;
}

#main-wrapper.show {
  opacity: 1;
}

.content-body {
  margin-left: 65px;
  margin-top: 18px;
  margin-right: 6px;
  margin-bottom: 45px;
}

/* .container {
	max-width: 1600px;
	@include custommq ($max:1600px){
		max-width: 1400px;
	}
} */

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (max-width: 1280px) {

  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 575px) {

  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.bg-grey {
  background-color: var(--grey);
}

.text-black {
  color: #071c35;
}

::selection {
  color: #ffffff;
  background: var(--primary);
}

.modal-backdrop {
  z-index: 99999;
}

.modal {
  z-index: 100000;
}

/* Flaticon */
[class*="flaticon-"] {
  font-family: "Flaticon";
}

@media only screen and (max-width: 991px) {
  .dz-order-1 {
    order: 1;
    margin-top: 20px;
  }
}

.gradient {
  background-image: var(--gradient);
}

.gradient-sec {
  background-image: var(--gradient-sec);
}

#pointer-dot {
  left: -4px;
  top: -4px;
  width: 12px;
  height: 12px;
  position: fixed;
  border-radius: 10px;
  z-index: 999998;
  pointer-events: none;
  transition: border-color 0.5s;
  background: #333;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
}

#pointer-ring {
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  display: block;
  border: 1px solid var(--primary) !important;
  position: fixed;
  border-radius: 100px;
  z-index: 999999;
  pointer-events: none;
  transition: width 0.3s, height 0.3s;
}

#pointer-ring.active {
  width: 50px;
  height: 50px;
  opacity: 0.5;
}

#pointer-ring.active+#pointer-dot {
  opacity: 0;
}

.content-inner {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media only screen and (max-width: 1480px) {
  .content-inner {
    padding-top: 30px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .content-inner {
    padding-top: 30px;
    padding-bottom: 20px;
  }
}

.content-inner-1 {
  padding-top: 30px;
  padding-bottom: 100px;
}

@media only screen and (max-width: 1480px) {
  .content-inner-1 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .content-inner-1 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.content-inner-2 {
  padding-top: 100px;
  padding-bottom: 0;
}

@media only screen and (max-width: 1480px) {
  .content-inner-2 {
    padding-top: 70px;
  }
}

.content-inner-3 {
  padding-top: 60px;
  padding-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .content-inner-3 {
    padding-top: 50px;
    padding-bottom: 20px;
  }
}

.mfp-bg {
  z-index: 99999;
}

.mfp-wrap {
  z-index: 100000;
}

.rounded {
  border-radius: var(--border-radius-base) !important;
}

.half-shape-top-w {
  position: relative;
  z-index: 2;
}

.half-shape-top-w:after {
  content: "";
  height: 50%;
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: -1;
}

.half-shape-bottom-w {
  position: relative;
  z-index: 0;
}

.half-shape-bottom-w:after {
  content: "";
  height: 50%;
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: -1;
}

.height-md {
  height: 370px;
}

.height-lg {
  height: 600px;
}

@media only screen and (max-width: 575px) {
  .height-lg {
    height: 400px;
  }
}

.border-primary {
  border-color: var(--primary) !important;
}

.border-bottom {
  border-bottom: 1px solid #eee !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.preloader-wrapper-1,
.preloader-wrapper-2 {
  align-items: center;
  position: fixed;
  z-index: 999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
}

.preloader-wrapper-1 .preloader-inner,
.preloader-wrapper-2 .preloader-inner {
  position: relative;
}

.preloader-wrapper-1 .preloader-inner {
  height: 100px;
  width: 150px;
  padding: 5px;
  border-radius: 0;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3), 0 0 0 5px var(--primary) inset;
  perspective: 37.5rem;
  transform-style: preserve-3d;
  transform: translate3d(0, 0, 0);
  animation: pre_ani_cover_1 7s ease-in-out infinite;
}

.preloader-wrapper-1 .preloader-inner .preloader-shade,
.preloader-wrapper-1 .preloader-inner .preloader-wrap {
  width: calc(50% - 0.25rem);
  position: absolute;
  left: 0.25rem;
  animation: pre_ani_cover_1 7s ease-in-out infinite;
}

.preloader-wrapper-1 .preloader-inner .preloader-shade {
  background-image: linear-gradient(-45deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.3) 50%);
  height: 3.75rem;
  filter: blur(0.25rem);
  transform: scaleY(0);
  transform-origin: 100% 0%;
  top: calc(100% - 0.25rem);
  animation-name: pre_ani_shadow_1;
}

.preloader-wrapper-1 .preloader-inner .preloader-wrap {
  background-color: #fff;
  background-image: linear-gradient(90deg, rgba(227, 228, 232, 0) 87.5%, #e3e4e8);
  height: calc(100% - 0.5rem);
  transform-origin: 100% 50%;
  animation-name: pre_ani_wrap_1;
}

.preloader-wrapper-1 .preloader-inner .wrap2,
.preloader-wrapper-1 .preloader-inner .wrap3,
.preloader-wrapper-1 .preloader-inner .wrap4 {
  background-image: repeating-linear-gradient(#17181c 0 0.125rem, rgba(23, 24, 28, 0) 0.125rem 0.5rem), linear-gradient(90deg, rgba(227, 228, 232, 0) 87.5%, #e3e4e8);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 2.5rem 4.125rem, 100% 100%;
}

.preloader-wrapper-1 .preloader-inner .wrap2 {
  animation-name: pre_ani_wrap_2;
}

.preloader-wrapper-1 .preloader-inner .wrap3 {
  animation-name: pre_ani_wrap_3;
}

.preloader-wrapper-1 .preloader-inner .wrap4 {
  animation-name: pre_ani_wrap_4;
}

.preloader-wrapper-1 .preloader-inner .wrap5 {
  animation-name: pre_ani_wrap_5;
}

@keyframes pre_ani_cover_1 {

  from,
  5%,
  45%,
  55%,
  95%,
  to {
    background-color: var(--rgba-primary-6);
    animation-timing-function: ease-out;
  }

  10%,
  40%,
  60%,
  90% {
    background-color: var(--rgba-primary-6);
    animation-timing-function: ease-in;
  }
}

@keyframes pre_ani_shadow_1 {

  from,
  10.01%,
  20.01%,
  30.01%,
  40.01% {
    transform: translate3d(0, 0, 1px) scaleY(0) rotateY(0);
    animation-timing-function: ease-in;
  }

  5%,
  15%,
  25%,
  35%,
  45%,
  55%,
  65%,
  75%,
  85%,
  95% {
    transform: translate3d(0, 0, 1px) scaleY(0.2) rotateY(90deg);
    animation-timing-function: ease-out;
  }

  10%,
  20%,
  30%,
  40%,
  50%,
  to {
    transform: translate3d(0, 0, 1px) scaleY(0) rotateY(180deg);
    animation-timing-function: ease-out;
  }

  50.01%,
  60.01%,
  70.01%,
  80.01%,
  90.01% {
    transform: translate3d(0, 0, 1px) scaleY(0) rotateY(180deg);
    animation-timing-function: ease-in;
  }

  60%,
  70%,
  80%,
  90%,
  to {
    transform: translate3d(0, 0, 1px) scaleY(0) rotateY(0);
    animation-timing-function: ease-out;
  }
}

@keyframes pre_ani_wrap_1 {

  from,
  to {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(0.4deg);
    animation-timing-function: ease-in-out;
  }

  10%,
  15% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(180deg);
    animation-timing-function: ease-out;
  }

  20%,
  80% {
    background-color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, 0, 1px) rotateY(180deg);
    animation-timing-function: ease-in;
  }

  85%,
  90% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(180deg);
    animation-timing-function: ease-in-out;
  }
}

@keyframes pre_ani_wrap_2 {

  from,
  to {
    background-color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, 0, 1px) rotateY(0.3deg);
    animation-timing-function: ease-in;
  }

  5%,
  10% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(0.3deg);
    animation-timing-function: ease-in-out;
  }

  20%,
  25% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(179.9deg);
    animation-timing-function: ease-out;
  }

  30%,
  70% {
    background-color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, 0, 1px) rotateY(179.9deg);
    animation-timing-function: ease-in;
  }

  75%,
  80% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(179.9deg);
    animation-timing-function: ease-in-out;
  }

  90%,
  95% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(0.3deg);
    animation-timing-function: ease-out;
  }
}

@keyframes pre_ani_wrap_3 {

  from,
  10%,
  90%,
  to {
    background-color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, 0, 1px) rotateY(0.2deg);
    animation-timing-function: ease-in;
  }

  15%,
  20% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(0.2deg);
    animation-timing-function: ease-in-out;
  }

  30%,
  35% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(179.8deg);
    animation-timing-function: ease-out;
  }

  40%,
  60% {
    background-color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, 0, 1px) rotateY(179.8deg);
    animation-timing-function: ease-in;
  }

  65%,
  70% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(179.8deg);
    animation-timing-function: ease-in-out;
  }

  80%,
  85% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(0.2deg);
    animation-timing-function: ease-out;
  }
}

@keyframes pre_ani_wrap_4 {

  from,
  20%,
  80%,
  to {
    background-color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, 0, 1px) rotateY(0.1deg);
    animation-timing-function: ease-in;
  }

  25%,
  30% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(0.1deg);
    animation-timing-function: ease-in-out;
  }

  40%,
  45% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(179.7deg);
    animation-timing-function: ease-out;
  }

  50% {
    background-color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, 0, 1px) rotateY(179.7deg);
    animation-timing-function: ease-in;
  }

  55%,
  60% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(179.7deg);
    animation-timing-function: ease-in-out;
  }

  70%,
  75% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(0.1deg);
    animation-timing-function: ease-out;
  }
}

@keyframes pre_ani_wrap_5 {

  from,
  30%,
  70%,
  to {
    background-color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, 0, 1px) rotateY(0);
    animation-timing-function: ease-in;
  }

  35%,
  40% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(0deg);
    animation-timing-function: ease-in-out;
  }

  50% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(179.6deg);
    animation-timing-function: ease-in-out;
  }

  60%,
  65% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(0);
    animation-timing-function: ease-out;
  }
}

.preloader-wrapper-2 .preloader-inner {
  width: 110px;
  height: 90px;
  margin: 50px auto 0;
}

.preloader-wrapper-2 .preloader-inner span {
  background: var(--primary);
  width: 5px;
  height: 80px;
  border-radius: 10px;
  transform: translateY(-50%) rotate(0);
  position: absolute;
  top: 50%;
  animation: pre_ani_2 0.7s infinite alternate-reverse;
}

.preloader-wrapper-2 .preloader-inner span:nth-child(1) {
  margin-left: 0px;
}

.preloader-wrapper-2 .preloader-inner span:nth-child(2) {
  margin-left: 14px;
  animation-delay: 0.1s;
}

.preloader-wrapper-2 .preloader-inner span:nth-child(3) {
  margin-left: 28px;
  animation-delay: 0.2s;
}

.preloader-wrapper-2 .preloader-inner span:nth-child(4) {
  margin-left: 42px;
  animation-delay: 0.3s;
}

.preloader-wrapper-2 .preloader-inner span:nth-child(5) {
  margin-left: 56px;
  animation-delay: 0.4s;
}

.preloader-wrapper-2 .preloader-inner span:nth-child(6) {
  margin-left: 70px;
  animation-delay: 0.5s;
}

.preloader-wrapper-2 .preloader-inner span:nth-child(7) {
  margin-left: 84px;
  animation-delay: 0.6s;
}

.preloader-wrapper-2 .preloader-inner span:nth-child(8) {
  margin-left: 98px;
  animation-delay: 0.7s;
}

@keyframes pre_ani_2 {
  0% {
    height: 5px;
    transform: translateY(-50%) rotate(40deg);
  }

  100% {
    height: 90px;
    transform: translateY(-50%) rotate(0deg);
  }
}

.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.font-26 {
  font-size: 26px;
}

.font-30 {
  font-size: 30px;
}

.font-35 {
  font-size: 35px;
}

.font-40 {
  font-size: 40px;
}

.font-45 {
  font-size: 45px;
}

.font-50 {
  font-size: 50px;
}

.font-55 {
  font-size: 55px;
}

.font-60 {
  font-size: 60px;
}

.font-70 {
  font-size: 70px;
}

.font-75 {
  font-size: 75px;
}

.font-80 {
  font-size: 80px;
}

.font-90 {
  font-size: 90px;
}

.font-100 {
  font-size: 100px;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

.p-a0 {
  padding: 0;
}

.p-a5 {
  padding: 5px;
}

.p-a10 {
  padding: 10px;
}

.p-a15 {
  padding: 15px;
}

.p-a20 {
  padding: 20px;
}

.p-a25 {
  padding: 25px;
}

.p-a30 {
  padding: 30px;
}

.p-a40 {
  padding: 40px;
}

.p-a50 {
  padding: 50px;
}

.p-a60 {
  padding: 60px;
}

.p-a70 {
  padding: 70px;
}

.p-a80 {
  padding: 80px;
}

.p-a90 {
  padding: 90px;
}

.p-a100 {
  padding: 100px;
}

.p-t0 {
  padding-top: 0;
}

.p-t5 {
  padding-top: 5px;
}

.p-t10 {
  padding-top: 10px;
}

.p-t15 {
  padding-top: 15px;
}

.p-t20 {
  padding-top: 20px;
}

.p-t30 {
  padding-top: 30px;
}

.p-t40 {
  padding-top: 40px;
}

.p-t50 {
  padding-top: 50px;
}

.p-t60 {
  padding-top: 60px;
}

.p-t70 {
  padding-top: 70px;
}

.p-t80 {
  padding-top: 80px;
}

.p-t90 {
  padding-top: 90px;
}

.p-t100 {
  padding-top: 100px;
}

.p-b0 {
  padding-bottom: 0;
}

.p-b5 {
  padding-bottom: 5px;
}

.p-b10 {
  padding-bottom: 10px;
}

.p-b15 {
  padding-bottom: 15px;
}

.p-b20 {
  padding-bottom: 20px;
}

.p-b30 {
  padding-bottom: 30px;
}

.p-b40 {
  padding-bottom: 40px;
}

.p-b50 {
  padding-bottom: 50px;
}

.p-b60 {
  padding-bottom: 60px;
}

.p-b70 {
  padding-bottom: 70px;
}

.p-b80 {
  padding-bottom: 80px;
}

.p-b90 {
  padding-bottom: 90px;
}

.p-b100 {
  padding-bottom: 100px;
}

.p-l0 {
  padding-left: 0;
}

.p-l5 {
  padding-left: 5px;
}

.p-l10 {
  padding-left: 10px;
}

.p-l15 {
  padding-left: 15px;
}

.p-l20 {
  padding-left: 20px;
}

.p-l30 {
  padding-left: 30px;
}

.p-l40 {
  padding-left: 40px;
}

.p-l50 {
  padding-left: 50px;
}

.p-l60 {
  padding-left: 60px;
}

.p-l70 {
  padding-left: 70px;
}

.p-l80 {
  padding-left: 80px;
}

.p-l90 {
  padding-left: 90px;
}

.p-l100 {
  padding-left: 100px;
}

.p-r0 {
  padding-right: 0;
}

.p-r5 {
  padding-right: 5px;
}

.p-r10 {
  padding-right: 10px;
}

.p-r15 {
  padding-right: 15px;
}

.p-r20 {
  padding-right: 20px;
}

.p-r30 {
  padding-right: 30px;
}

.p-r40 {
  padding-right: 40px;
}

.p-r50 {
  padding-right: 50px;
}

.p-r60 {
  padding-right: 60px;
}

.p-r70 {
  padding-right: 70px;
}

.p-r80 {
  padding-right: 80px;
}

.p-r90 {
  padding-right: 90px;
}

.p-r100 {
  padding-right: 100px;
}

.p-lr0 {
  padding-left: 0;
  padding-right: 0;
}

.p-lr5 {
  padding-left: 5px;
  padding-right: 5px;
}

.p-lr10 {
  padding-left: 10px;
  padding-right: 10px;
}

.p-lr15 {
  padding-left: 15px;
  padding-right: 15px;
}

.p-lr20 {
  padding-left: 20px;
  padding-right: 20px;
}

.p-lr30 {
  padding-left: 30px;
  padding-right: 30px;
}

.p-lr40 {
  padding-left: 40px;
  padding-right: 40px;
}

.p-lr50 {
  padding-left: 50px;
  padding-right: 50px;
}

.p-lr60 {
  padding-left: 60px;
  padding-right: 60px;
}

.p-lr70 {
  padding-left: 70px;
  padding-right: 70px;
}

.p-lr80 {
  padding-left: 80px;
  padding-right: 80px;
}

.p-lr90 {
  padding-left: 90px;
  padding-right: 90px;
}

.p-lr100 {
  padding-left: 100px;
  padding-right: 100px;
}

.p-tb0 {
  padding-bottom: 0;
  padding-top: 0;
}

.p-tb5 {
  padding-bottom: 5px;
  padding-top: 5px;
}

.p-tb10 {
  padding-bottom: 10px;
  padding-top: 10px;
}

.p-tb15 {
  padding-bottom: 15px;
  padding-top: 15px;
}

.p-tb20 {
  padding-bottom: 20px;
  padding-top: 20px;
}

.p-tb30 {
  padding-bottom: 30px;
  padding-top: 30px;
}

.p-tb40 {
  padding-bottom: 40px;
  padding-top: 40px;
}

.p-tb50 {
  padding-bottom: 50px;
  padding-top: 50px;
}

.p-tb60 {
  padding-bottom: 60px;
  padding-top: 60px;
}

.p-tb70 {
  padding-bottom: 70px;
  padding-top: 70px;
}

.p-tb80 {
  padding-bottom: 80px;
  padding-top: 80px;
}

.p-tb90 {
  padding-bottom: 90px;
  padding-top: 90px;
}

.p-tb100 {
  padding-bottom: 100px;
  padding-top: 100px;
}

.m-auto {
  margin: auto;
}

.m-a0 {
  margin: 0;
}

.m-a5 {
  margin: 5px;
}

.m-a10 {
  margin: 10px;
}

.m-a15 {
  margin: 15px;
}

.m-a20 {
  margin: 20px;
}

.m-a30 {
  margin: 30px;
}

.m-a40 {
  margin: 40px;
}

.m-a50 {
  margin: 50px;
}

.m-a60 {
  margin: 60px;
}

.m-a70 {
  margin: 70px;
}

.m-a80 {
  margin: 80px;
}

.m-a90 {
  margin: 90px;
}

.m-a100 {
  margin: 100px;
}

.m-t0 {
  margin-top: 0;
}

.m-t5 {
  margin-top: 5px;
}

.m-t10 {
  margin-top: 10px;
}

.m-t15 {
  margin-top: 15px;
}

.m-t18 {
  margin-top: 18px;
}

.m-t20 {
  margin-top: 20px;
}

.m-t30 {
  margin-top: 30px;
}

.m-t40 {
  margin-top: 40px;
}

.m-t45 {
  margin-top: 45px;
}

.m-t50 {
  margin-top: 50px;
}

.m-t60 {
  margin-top: 60px;
}

.m-t70 {
  margin-top: 70px;
}

.m-t80 {
  margin-top: 80px;
}

.m-t90 {
  margin-top: 90px;
}

.m-t100 {
  margin-top: 100px;
}

.m-b0 {
  margin-bottom: 0;
}

.m-b5 {
  margin-bottom: 5px;
}

.m-b10 {
  margin-bottom: 10px;
}

.m-b15 {
  margin-bottom: 15px;
}

.m-b20 {
  margin-bottom: 20px;
}

.m-b25 {
  margin-bottom: 25px;
}

.m-b30 {
  margin-bottom: 30px;
}

.m-b40 {
  margin-bottom: 40px;
}

.m-b50 {
  margin-bottom: 50px;
}

.m-b60 {
  margin-bottom: 60px;
}

.m-b70 {
  margin-bottom: 70px;
}

.m-b80 {
  margin-bottom: 80px;
}

.m-b90 {
  margin-bottom: 90px;
}

.m-b100 {
  margin-bottom: 100px;
}

.m-l0 {
  margin-left: 0;
}

.m-l5 {
  margin-left: 5px;
}

.m-l10 {
  margin-left: 10px;
}

.m-l15 {
  margin-left: 15px;
}

.m-l20 {
  margin-left: 20px;
}

.m-l30 {
  margin-left: 30px;
}

.m-l40 {
  margin-left: 40px;
}

.m-l50 {
  margin-left: 50px;
}

.m-l60 {
  margin-left: 60px;
}

.m-l70 {
  margin-left: 70px;
}

.m-l80 {
  margin-left: 80px;
}

.m-l90 {
  margin-left: 90px;
}

.m-l100 {
  margin-left: 100px;
}

.m-r0 {
  margin-right: 0;
}

.m-r5 {
  margin-right: 5px;
}

.m-r10 {
  margin-right: 10px;
}

.m-r15 {
  margin-right: 15px;
}

.m-r20 {
  margin-right: 20px;
}

.m-r30 {
  margin-right: 30px;
}

.m-r40 {
  margin-right: 40px;
}

.m-r50 {
  margin-right: 50px;
}

.m-r60 {
  margin-right: 60px;
}

.m-r70 {
  margin-right: 70px;
}

.m-r80 {
  margin-right: 80px;
}

.m-r90 {
  margin-right: 90px;
}

.m-r100 {
  margin-right: 100px;
}

.m-lr0 {
  margin-left: 0;
  margin-right: 0;
}

.m-lr5 {
  margin-left: 5px;
  margin-right: 5px;
}

.m-lr10 {
  margin-left: 10px;
  margin-right: 10px;
}

.m-lr15 {
  margin-left: 15px;
  margin-right: 15px;
}

.m-lr20 {
  margin-left: 20px;
  margin-right: 20px;
}

.m-lr30 {
  margin-left: 30px;
  margin-right: 30px;
}

.m-lr40 {
  margin-left: 40px;
  margin-right: 40px;
}

.m-lr50 {
  margin-left: 50px;
  margin-right: 50px;
}

.m-lr60 {
  margin-left: 60px;
  margin-right: 60px;
}

.m-lr70 {
  margin-left: 70px;
  margin-right: 70px;
}

.m-lr80 {
  margin-left: 80px;
  margin-right: 80px;
}

.m-lr90 {
  margin-left: 90px;
  margin-right: 90px;
}

.m-lr100 {
  margin-left: 100px;
  margin-right: 100px;
}

.m-tb0 {
  margin-bottom: 0;
  margin-top: 0;
}

.m-tb5 {
  margin-bottom: 5px;
  margin-top: 5px;
}

.m-tb10 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.m-tb15 {
  margin-bottom: 15px;
  margin-top: 15px;
}

.m-tb20 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.m-tb30 {
  margin-bottom: 30px;
  margin-top: 30px;
}

.m-tb40 {
  margin-bottom: 40px;
  margin-top: 40px;
}

.m-tb50 {
  margin-bottom: 50px;
  margin-top: 50px;
}

.m-tb60 {
  margin-bottom: 60px;
  margin-top: 60px;
}

.m-tb70 {
  margin-bottom: 70px;
  margin-top: 70px;
}

.m-tb80 {
  margin-bottom: 80px;
  margin-top: 80px;
}

.m-tb90 {
  margin-bottom: 90px;
  margin-top: 90px;
}

.m-tb100 {
  margin-bottom: 100px;
  margin-top: 100px;
}

@media only screen and (max-width: 1200px) {
  .m-lg-t0 {
    margin-top: 0;
  }

  .m-lg-t5 {
    margin-top: 5px;
  }

  .m-lg-t10 {
    margin-top: 10px;
  }

  .m-lg-t15 {
    margin-top: 15px;
  }

  .m-lg-t20 {
    margin-top: 20px;
  }

  .m-lg-t30 {
    margin-top: 30px;
  }

  .m-lg-t40 {
    margin-top: 40px;
  }

  .m-lg-t50 {
    margin-top: 50px;
  }

  .m-lg-t60 {
    margin-top: 60px;
  }

  .m-lg-t70 {
    margin-top: 70px;
  }

  .m-lg-t80 {
    margin-top: 80px;
  }

  .m-lg-t90 {
    margin-top: 90px;
  }

  .m-lg-t100 {
    margin-top: 100px;
  }

  .m-lg-b0 {
    margin-bottom: 0;
  }

  .m-lg-b5 {
    margin-bottom: 5px;
  }

  .m-lg-b10 {
    margin-bottom: 10px;
  }

  .m-lg-b15 {
    margin-bottom: 15px;
  }

  .m-lg-b20 {
    margin-bottom: 20px;
  }

  .m-lg-b30 {
    margin-bottom: 30px;
  }

  .m-lg-b40 {
    margin-bottom: 40px;
  }

  .m-lg-b50 {
    margin-bottom: 50px;
  }

  .m-lg-b60 {
    margin-bottom: 60px;
  }

  .m-lg-b70 {
    margin-bottom: 70px;
  }

  .m-lg-b80 {
    margin-bottom: 80px;
  }

  .m-lg-b90 {
    margin-bottom: 90px;
  }

  .m-lg-b100 {
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .m-md-t0 {
    margin-top: 0;
  }

  .m-md-t5 {
    margin-top: 5px;
  }

  .m-md-t10 {
    margin-top: 10px;
  }

  .m-md-t15 {
    margin-top: 15px;
  }

  .m-md-t20 {
    margin-top: 20px;
  }

  .m-md-t30 {
    margin-top: 30px;
  }

  .m-md-t40 {
    margin-top: 40px;
  }

  .m-md-t50 {
    margin-top: 50px;
  }

  .m-md-t60 {
    margin-top: 60px;
  }

  .m-md-t70 {
    margin-top: 70px;
  }

  .m-md-t80 {
    margin-top: 80px;
  }

  .m-md-t90 {
    margin-top: 90px;
  }

  .m-md-t100 {
    margin-top: 100px;
  }

  .m-md-b0 {
    margin-bottom: 0;
  }

  .m-md-b5 {
    margin-bottom: 5px;
  }

  .m-md-b10 {
    margin-bottom: 10px;
  }

  .m-md-b15 {
    margin-bottom: 15px;
  }

  .m-md-b20 {
    margin-bottom: 20px;
  }

  .m-md-b30 {
    margin-bottom: 30px;
  }

  .m-md-b40 {
    margin-bottom: 40px;
  }

  .m-md-b50 {
    margin-bottom: 50px;
  }

  .m-md-b60 {
    margin-bottom: 60px;
  }

  .m-md-b70 {
    margin-bottom: 70px;
  }

  .m-md-b80 {
    margin-bottom: 80px;
  }

  .m-md-b90 {
    margin-bottom: 90px;
  }

  .m-md-b100 {
    margin-bottom: 100px;
  }

  .m-md-r0 {
    margin-right: 0;
  }

  .p-md-r0 {
    padding-right: 0;
  }

  .p-md-t40 {
    padding-top: 40px;
  }

  .p-md-b40 {
    padding-bottom: 40px;
  }

  .p-md-l0 {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .m-sm-t0 {
    margin-top: 0;
  }

  .m-sm-t5 {
    margin-top: 5px;
  }

  .m-sm-t10 {
    margin-top: 10px;
  }

  .m-sm-t15 {
    margin-top: 15px;
  }

  .m-sm-t20 {
    margin-top: 20px;
  }

  .m-sm-t30 {
    margin-top: 30px;
  }

  .m-sm-t40 {
    margin-top: 40px;
  }

  .m-sm-t50 {
    margin-top: 50px;
  }

  .m-sm-t60 {
    margin-top: 60px;
  }

  .m-sm-t70 {
    margin-top: 70px;
  }

  .m-sm-t80 {
    margin-top: 80px;
  }

  .m-sm-t90 {
    margin-top: 90px;
  }

  .m-sm-t100 {
    margin-top: 100px;
  }

  .m-sm-b0 {
    margin-bottom: 0;
  }

  .m-sm-b5 {
    margin-bottom: 5px;
  }

  .m-sm-b10 {
    margin-bottom: 10px;
  }

  .m-sm-b15 {
    margin-bottom: 15px;
  }

  .m-sm-b20 {
    margin-bottom: 20px;
  }

  .m-sm-b30 {
    margin-bottom: 30px;
  }

  .m-sm-b40 {
    margin-bottom: 40px;
  }

  .m-sm-b50 {
    margin-bottom: 50px;
  }

  .m-sm-b60 {
    margin-bottom: 60px;
  }

  .m-sm-b70 {
    margin-bottom: 70px;
  }

  .m-sm-b80 {
    margin-bottom: 80px;
  }

  .m-sm-b90 {
    margin-bottom: 90px;
  }

  .m-sm-b100 {
    margin-bottom: 100px;
  }

  .m-sm-r10 {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 576px) {
  .m-xs-t0 {
    margin-top: 0;
  }

  .m-xs-t5 {
    margin-top: 5px;
  }

  .m-xs-t10 {
    margin-top: 10px;
  }

  .m-xs-t15 {
    margin-top: 15px;
  }

  .m-xs-t20 {
    margin-top: 20px;
  }

  .m-xs-t30 {
    margin-top: 30px;
  }

  .m-xs-t40 {
    margin-top: 40px;
  }

  .m-xs-t50 {
    margin-top: 50px;
  }

  .m-xs-t60 {
    margin-top: 60px;
  }

  .m-xs-t70 {
    margin-top: 70px;
  }

  .m-xs-t80 {
    margin-top: 80px;
  }

  .m-xs-t90 {
    margin-top: 90px;
  }

  .m-xs-t100 {
    margin-top: 100px;
  }

  .m-xs-b0 {
    margin-bottom: 0;
  }

  .m-xs-b5 {
    margin-bottom: 5px;
  }

  .m-xs-b10 {
    margin-bottom: 10px;
  }

  .m-xs-b15 {
    margin-bottom: 15px;
  }

  .m-xs-b20 {
    margin-bottom: 20px;
  }

  .m-xs-b30 {
    margin-bottom: 30px;
  }

  .m-xs-b40 {
    margin-bottom: 40px;
  }

  .m-xs-b50 {
    margin-bottom: 50px;
  }

  .m-xs-b60 {
    margin-bottom: 60px;
  }

  .m-xs-b70 {
    margin-bottom: 70px;
  }

  .m-xs-b80 {
    margin-bottom: 80px;
  }

  .m-xs-b90 {
    margin-bottom: 90px;
  }

  .m-xs-b100 {
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 1200px) {
  .p-lg-t0 {
    padding-top: 0;
  }

  .p-lg-t5 {
    padding-top: 5px;
  }

  .p-lg-t10 {
    padding-top: 10px;
  }

  .p-lg-t15 {
    padding-top: 15px;
  }

  .p-lg-t20 {
    padding-top: 20px;
  }

  .p-lg-t30 {
    padding-top: 30px;
  }

  .p-lg-t40 {
    padding-top: 40px;
  }

  .p-lg-t50 {
    padding-top: 50px;
  }

  .p-lg-t60 {
    padding-top: 60px;
  }

  .p-lg-t70 {
    padding-top: 70px;
  }

  .p-lg-t80 {
    padding-top: 80px;
  }

  .p-lg-t90 {
    padding-top: 90px;
  }

  .p-lg-t100 {
    padding-top: 100px;
  }

  .p-lg-b0 {
    padding-bottom: 0;
  }

  .p-lg-b5 {
    padding-bottom: 5px;
  }

  .p-lg-b10 {
    padding-bottom: 10px;
  }

  .p-lg-b15 {
    padding-bottom: 15px;
  }

  .p-lg-b20 {
    padding-bottom: 20px;
  }

  .p-lg-b30 {
    padding-bottom: 30px;
  }

  .p-lg-b40 {
    padding-bottom: 40px;
  }

  .p-lg-b50 {
    padding-bottom: 50px;
  }

  .p-lg-b60 {
    padding-bottom: 60px;
  }

  .p-lg-b70 {
    padding-bottom: 70px;
  }

  .p-lg-b80 {
    padding-bottom: 80px;
  }

  .p-lg-b90 {
    padding-bottom: 90px;
  }

  .p-lg-b100 {
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .p-md-t0 {
    padding-top: 0;
  }

  .p-md-t5 {
    padding-top: 5px;
  }

  .p-md-t10 {
    padding-top: 10px;
  }

  .p-md-t15 {
    padding-top: 15px;
  }

  .p-md-t20 {
    padding-top: 20px;
  }

  .p-md-t30 {
    padding-top: 30px;
  }

  .p-md-t40 {
    padding-top: 40px;
  }

  .p-md-t50 {
    padding-top: 50px;
  }

  .p-md-t60 {
    padding-top: 60px;
  }

  .p-md-t70 {
    padding-top: 70px;
  }

  .p-md-t80 {
    padding-top: 80px;
  }

  .p-md-t90 {
    padding-top: 90px;
  }

  .p-md-t100 {
    padding-top: 100px;
  }

  .p-md-b0 {
    padding-bottom: 0;
  }

  .p-md-b5 {
    padding-bottom: 5px;
  }

  .p-md-b10 {
    padding-bottom: 10px;
  }

  .p-md-b15 {
    padding-bottom: 15px;
  }

  .p-md-b20 {
    padding-bottom: 20px;
  }

  .p-md-b30 {
    padding-bottom: 30px;
  }

  .p-md-b40 {
    padding-bottom: 40px;
  }

  .p-md-b50 {
    padding-bottom: 50px;
  }

  .p-md-b60 {
    padding-bottom: 60px;
  }

  .p-md-b70 {
    padding-bottom: 70px;
  }

  .p-md-b80 {
    padding-bottom: 80px;
  }

  .p-md-b90 {
    padding-bottom: 90px;
  }

  .p-md-b100 {
    padding-bottom: 100px;
  }

  .p-md-r0 {
    margin-right: 0;
  }

  .p-md-r0 {
    padding-right: 0;
  }

  .p-md-t40 {
    padding-top: 40px;
  }

  .p-md-b40 {
    padding-bottom: 40px;
  }

  .p-md-l0 {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .p-sm-t0 {
    padding-top: 0;
  }

  .p-sm-t5 {
    padding-top: 5px;
  }

  .p-sm-t10 {
    padding-top: 10px;
  }

  .p-sm-t15 {
    padding-top: 15px;
  }

  .p-sm-t20 {
    padding-top: 20px;
  }

  .p-sm-t30 {
    padding-top: 30px;
  }

  .p-sm-t40 {
    padding-top: 40px;
  }

  .p-sm-t50 {
    padding-top: 50px;
  }

  .p-sm-t60 {
    padding-top: 60px;
  }

  .p-sm-t70 {
    padding-top: 70px;
  }

  .p-sm-t80 {
    padding-top: 80px;
  }

  .p-sm-t90 {
    padding-top: 90px;
  }

  .p-sm-t100 {
    padding-top: 100px;
  }

  .p-sm-b0 {
    padding-bottom: 0;
  }

  .p-sm-b5 {
    padding-bottom: 5px;
  }

  .p-sm-b10 {
    padding-bottom: 10px;
  }

  .p-sm-b15 {
    padding-bottom: 15px;
  }

  .p-sm-b20 {
    padding-bottom: 20px;
  }

  .p-sm-b30 {
    padding-bottom: 30px;
  }

  .p-sm-b40 {
    padding-bottom: 40px;
  }

  .p-sm-b50 {
    padding-bottom: 50px;
  }

  .p-sm-b60 {
    padding-bottom: 60px;
  }

  .p-sm-b70 {
    padding-bottom: 70px;
  }

  .p-sm-b80 {
    padding-bottom: 80px;
  }

  .p-sm-b90 {
    padding-bottom: 90px;
  }

  .p-sm-b100 {
    padding-bottom: 100px;
  }

  .p-sm-r10 {
    padding-right: 10px;
  }
}

@media only screen and (max-width: 576px) {
  .p-xs-t0 {
    padding-top: 0;
  }

  .p-xs-t5 {
    padding-top: 5px;
  }

  .p-xs-t10 {
    padding-top: 10px;
  }

  .p-xs-t15 {
    padding-top: 15px;
  }

  .p-xs-t20 {
    padding-top: 20px;
  }

  .p-xs-t30 {
    padding-top: 30px;
  }

  .p-xs-t40 {
    padding-top: 40px;
  }

  .p-xs-t50 {
    padding-top: 50px;
  }

  .p-xs-t60 {
    padding-top: 60px;
  }

  .p-xs-t70 {
    padding-top: 70px;
  }

  .p-xs-t80 {
    padding-top: 80px;
  }

  .p-xs-t90 {
    padding-top: 90px;
  }

  .p-xs-t100 {
    padding-top: 100px;
  }

  .p-xs-b0 {
    padding-bottom: 0;
  }

  .p-xs-b5 {
    padding-bottom: 5px;
  }

  .p-xs-b10 {
    padding-bottom: 10px;
  }

  .p-xs-b15 {
    padding-bottom: 15px;
  }

  .p-xs-b20 {
    padding-bottom: 20px;
  }

  .p-xs-b30 {
    padding-bottom: 30px;
  }

  .p-xs-b40 {
    padding-bottom: 40px;
  }

  .p-xs-b50 {
    padding-bottom: 50px;
  }

  .p-xs-b60 {
    padding-bottom: 60px;
  }

  .p-xs-b70 {
    padding-bottom: 70px;
  }

  .p-xs-b80 {
    padding-bottom: 80px;
  }

  .p-xs-b90 {
    padding-bottom: 90px;
  }

  .p-xs-b100 {
    padding-bottom: 100px;
  }
}

.max-w50 {
  max-width: 50px;
}

.max-w60 {
  max-width: 60px;
}

.max-w80 {
  max-width: 80px;
}

.max-w100 {
  max-width: 100px;
}

.max-w200 {
  max-width: 200px;
}

.max-w300 {
  max-width: 300px;
}

.max-w400 {
  max-width: 400px;
}

.max-w500 {
  max-width: 500px;
}

.max-w600 {
  max-width: 600px;
}

.max-w700 {
  max-width: 700px;
}

.max-w800 {
  max-width: 800px;
}

.max-w900 {
  max-width: 900px;
}

.max-w1000 {
  max-width: 1000px;
}

.bg-img-fix {
  background-attachment: unset;
  background-size: cover;
}

.full-img {
  width: 100%;
}

.sticky-top {
  top: 120px;
}

.page-title {
  padding: 65px 0px 45px;
  position: relative;
  background: #f5f5f5;
  background-size: cover;
  background-position: center;
}

.page-title p {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #7272A8;
  text-transform: capitalize;
}

@media only screen and (max-width: 575px) {
  .page-title p {
    font-size: 16px;
  }
}

.page-title h3,
.page-title .h3 {
  font-size: 40px;
  line-height: 1.2;
  font-weight: 600;
  text-transform: capitalize;
}

@media only screen and (max-width: 575px) {

  .page-title h3,
  .page-title .h3 {
    font-size: 28px;
  }
}

.img-cover {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

section {
  position: relative;
  z-index: 1;
}

.section-head {
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .section-head {
    margin-bottom: 20px;
  }
}

.section-head h1,
.section-head .h1,
.section-head h2,
.section-head .h2,
.section-head h3,
.section-head .h3,
.section-head h4,
.section-head .h4,
.section-head h5,
.section-head .h5,
.section-head h6,
.section-head .h6 {
  font-family: var(--font-family-title);
}

.section-head h2,
.section-head .h2 {
  font-size: 2.625rem;
  font-weight: 600;
}

@media only screen and (max-width: 1480px) {

  .section-head h2,
  .section-head .h2 {
    font-size: 2.25rem;
  }
}

@media only screen and (max-width: 767px) {

  .section-head h2,
  .section-head .h2 {
    font-size: 1.625rem;
  }
}

.section-head h3,
.section-head .h3 {
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.2;
}

@media only screen and (max-width: 1200px) {

  .section-head h3,
  .section-head .h3 {
    font-size: 2.15rem;
  }
}

@media only screen and (max-width: 991px) {

  .section-head h3,
  .section-head .h3 {
    font-size: 1.75rem;
  }
}

.section-head h4,
.section-head .h4 {
  font-size: 2rem;
}

@media only screen and (max-width: 575px) {

  .section-head h4,
  .section-head .h4 {
    font-size: 1.5rem;
  }
}

.section-head p {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .section-head p {
    font-size: 14px;
  }
}

.section-head.text-center p {
  max-width: 753px;
  margin-left: auto;
  margin-right: auto;
}

.section-head.m-b30 {
  margin-bottom: 30px;
}

.section-head .sub-title {
  text-transform: uppercase;
}

.row.spno,
.spno {
  margin-left: 0;
  margin-right: 0;
}

.row.spno [class*="col"],
.row.spno [class*="col"],
.spno [class*="col"],
.spno [class*="col"] {
  padding-left: 0;
  padding-right: 0;
}

.row.sp4,
.sp4 {
  margin-left: -4px;
  margin-right: -4px;
}

.row.sp4 [class*="col"],
.row.sp4 [class*="col"],
.sp4 [class*="col"],
.sp4 [class*="col"] {
  padding-left: 4px;
  padding-right: 4px;
}

.row.sp15,
.sp15 {
  margin-left: -7px;
  margin-right: -7px;
}

.row.sp15 [class*="col"],
.row.sp15 [class*="col"],
.sp15 [class*="col"],
.sp15 [class*="col"] {
  padding-left: 7px;
  padding-right: 7px;
}

.row.sp10,
.sp10 {
  margin-left: -10px;
  margin-right: -10px;
}

.row.sp10 [class*="col"],
.row.sp10 [class*="col"],
.sp10 [class*="col"],
.sp10 [class*="col"] {
  padding-left: 10px;
  padding-right: 10px;
}

.rounded-xl {
  border-radius: 50% !important;
  overflow: hidden;
}

.rounded-lg {
  border-radius: 20px !important;
  overflow: hidden;
}

.rounded-md {
  border-radius: 6px !important;
  overflow: hidden;
}

.rounded-sm {
  border-radius: 4px !important;
  overflow: hidden;
}

.text-maroon {
  color: #9e0168;
}

.text-orange {
  color: #ff8853;
}

.text-yellow {
  color: #ffa808;
}

.text-skyblue {
  color: #53C258;
}

.text-red {
  color: #f22462;
}

.text-green {
  color: #53C258;
}

.text-blue {
  color: #131045;
}

.bg-maroon {
  background-color: #9e0168;
}

.bg-orange {
  background-color: #ff8853;
}

.bg-yellow {
  background-color: #ffa808;
}

.bg-skyblue {
  background-color: #00aeff;
}

.bg-red {
  background-color: #f22462;
}

.bg-green {
  background-color: #53C258;
}

.bg-blue {
  background-color: #131045;
}

.scale8 {
  transform: scale(1.85);
  -moz-transform: scale(1.85);
  -webkit-transform: scale(1.85);
  -ms-transform: scale(1.85);
  -o-transform: scale(1.85);
}

.scale7 {
  transform: scale(1.7);
  -moz-transform: scale(1.7);
  -webkit-transform: scale(1.7);
  -ms-transform: scale(1.7);
  -o-transform: scale(1.7);
}

.scale5 {
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
}

.scale3 {
  transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
}

.scale2 {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.scale08 {
  transform: scale(0.8);
  -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
}

.scale05 {
  transform: scale(0.5);
  -moz-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
}

.tl {
  transform-origin: left;
  -moz-transform-origin: left;
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  -o-transform-origin: left;
}

.shadow-none {
  box-shadow: unset;
}

/*==== Shortcode Css ====*/
.sort-title {
  margin-bottom: 40px;
}

.sort-title h4:after,
.sort-title .h4:after {
  left: -160px;
}

.sort-title.text-white h4:after,
.sort-title.text-white .h4:after,
.sort-title.text-white h4:before,
.sort-title.text-white .h4:before {
  background: #fff;
}

.sort-title h4:after,
.sort-title .h4:after,
.sort-title h4:before,
.sort-title .h4:before {
  background: #000 none repeat scroll 0 0;
  content: "";
  height: 1px;
  position: absolute;
  right: -160px;
  top: 50%;
  width: 150px;
  opacity: 0.15;
}

.sort-title h4,
.sort-title .h4 {
  display: inline-block;
  margin: 0;
  position: relative;
}

@media only screen and (max-width: 767px) {

  .sort-title h4:after,
  .sort-title .h4:after,
  .sort-title h4:before,
  .sort-title .h4:before {
    content: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.main-slider {
  overflow: hidden;
  position: relative;
}

.main-slider .swiper-slide {
  height: 100% !important;
  background-repeat: no-repeat;
  background-position: center bottom;
  overflow: hidden;
}

.main-slider .main-swiper-thumb {
  position: absolute;
  right: 0;
  bottom: 60px;
  z-index: 99;
  width: 45vw;
  height: auto;
  overflow: hidden;
  padding: 20px 20px 20px 0;
}

.main-slider .main-swiper-thumb .swiper-wrapper {
  height: auto;
}

.main-slider .main-swiper-thumb .swiper-slide {
  opacity: 0.6;
  width: 380px;
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
}

.main-slider .main-swiper-thumb .swiper-slide.swiper-slide-active {
  opacity: 1;
}

.main-slider .main-swiper-thumb .swiper-slide.swiper-slide-active .books-card {
  box-shadow: 0px 7px 24px 0px rgba(17, 12, 46, 0.15);
}

.main-slider .main-swiper-thumb .books-card {
  background-color: #fff;
  border-radius: var(--border-radius-base);
  display: flex;
  align-items: center;
  padding: 15px;
}

.main-slider .main-swiper-thumb .books-card .dz-media {
  width: 85px;
  min-width: 85px;
  margin-right: 20px;
}

.main-slider .main-swiper-thumb .books-card .dz-content .dz-meta ul li {
  color: var(--secondary);
  font-size: 14px;
  font-weight: 400;
  font-family: var(--font-family-title);
}

.main-slider .main-swiper-thumb .books-card .dz-content .book-footer {
  display: flex;
  justify-content: space-between;
}

.main-slider .main-swiper-thumb .books-card .dz-content .book-footer .price .price-num {
  font-family: var(--font-family-title);
  font-size: 20px;
  color: var(--title);
  font-weight: var(--headings-font-weight);
}

@media only screen and (max-width: 991px) {
  .main-slider .main-swiper-thumb {
    display: none;
  }
}

.main-slider.style-1 .swiper-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 60px 0;
}

.main-slider.style-1 .swiper-pagination-wrapper {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 999;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 600px) {
  .main-slider.style-1 .swiper-pagination-wrapper {
    top: 100%;
    left: 10%;
  }
}

.main-slider.style-1 .banner-content {
  padding-top: 100px;
}

.main-slider.style-1 .banner-content .content-info {
  padding-bottom: 30px;
  z-index: 2;
  position: relative;
}

.main-slider.style-1 .banner-content .sub-title {
  color: #F0BBE3;
  line-height: 1.6;
  letter-spacing: 20px;
  font-weight: var(--headings-font-weight3);
}

.main-slider.style-1 .banner-content .title {
  color: #ffffff;
  font-size: 3.125rem;
}

.main-slider.style-1 .banner-content .dz-tags {
  margin-bottom: 35px;
  line-height: 1.5;
  display: inline-flex;
}

.main-slider.style-1 .banner-content .dz-tags li a {
  font-size: 1.125rem;
  margin-right: 20px;
  color: #E5CCB8;
  font-weight: var(--headings-font-weight2);
}

.main-slider.style-1 .banner-content .text {
  padding-left: 32px;
  color: rgba(255, 255, 255, 0.8);
  border-left: 1px solid var(--primary);
  font-weight: var(--headings-font-weight2);
}

.main-slider.style-1 .banner-content .price {
  margin: 25px 0px 25px;
  display: inline-flex;
  align-items: center;
}

.main-slider.style-1 .banner-content .price .price-num {
  margin-bottom: 0px;
  font-size: 30px;
  font-weight: 600;
  color: var(--primary);
}

.main-slider.style-1 .banner-content .price del {
  font-family: var(--font-family-title);
  font-style: normal;
  font-weight: var(--headings-font-weight2);
  color: #E5CCB8;
  margin-bottom: 0;
  font-size: 18px;
  padding: 0 10px;
}

.main-slider.style-1 .banner-content .partner p {
  color: #9290B0;
  font-weight: var(--headings-font-weight2);
}

.main-slider.style-1 .banner-content .partner .brand-logo .mid-logo {
  margin: 0 60px;
}

.main-slider.style-1 .banner-media {
  position: relative;
  z-index: 1;
  width: 720px;
  float: left;
  margin-top: -50px;
}

.main-slider.style-1 .banner-media:after {
  content: "";
  position: absolute;
  width: 750px;
  height: 750px;
  border-radius: 50%;
  bottom: -91px;
  left: 50%;
  background-color: #120d5c;
  z-index: -1;
  opacity: 0.8;
  transform: translateX(-50%);
}

.main-slider.style-1 .banner-media img {
  width: 100%;
}

.main-slider.style-1 .pattern {
  position: absolute;
  top: 32%;
  left: 38%;
}

.main-slider.style-1.slider-white .banner-content .sub-title {
  color: var(--primary);
}

.main-slider.style-1.slider-white .banner-content .title {
  color: var(--secondary);
}

.main-slider.style-1.slider-white .banner-content .dz-tags li a {
  color: var(--secondary);
}

.main-slider.style-1.slider-white .banner-content .text {
  border-left: 1px solid #3B2F4A;
  color: var(--secondary);
}

@media only screen and (max-width: 600px) {
  .main-slider.style-1.slider-white .banner-content .text {
    border: 0;
  }
}

.main-slider.style-1.slider-white .banner-content .price del {
  color: #5F5C9D;
}

.main-slider.style-1.slider-white .banner-content .price .badge {
  background-color: #FF1E6F;
}

@media only screen and (max-width: 575px) {
  .main-slider.style-1.slider-white .banner-content .price {
    display: none;
  }
}

.main-slider.style-1.slider-white .banner-media:after {
  background-color: var(--primary);
}

@media only screen and (max-width: 1280px) {
  .main-slider.style-1 .banner-content {
    padding-top: 50px;
  }

  .main-slider.style-1 .banner-content .content-btn {
    margin-bottom: 0;
  }

  .main-slider.style-1 .banner-content .title {
    font-size: 2.5rem;
  }

  .main-slider.style-1 .banner-media {
    width: 650px;
    margin-top: 0;
  }

  .main-slider.style-1 .banner-media:after {
    transform: translateX(-50%) scale(0.9);
  }
}

@media only screen and (max-width: 991px) {
  .main-slider.style-1 .banner-media {
    width: 420px;
  }

  .main-slider.style-1 .banner-media:after {
    display: none;
  }

  .main-slider.style-1 .pattern {
    display: none;
  }

  .main-slider.style-1 .swiper-content {
    padding: 0px;
  }

  .main-slider.style-1 .banner-content .title {
    font-size: 1.875rem;
  }

  .main-slider.style-1 .banner-content .sub-title {
    letter-spacing: 5px;
  }

  .main-slider.style-1 .banner-content .dz-tags {
    margin-bottom: 20px;
  }

  .main-slider.style-1 .banner-content .dz-tags li a {
    font-size: 14px;
  }

  .main-slider.style-1 .banner-content .text {
    padding-left: 13px;
    font-size: 15px;
  }

  .main-slider.style-1 .banner-content .price {
    margin: 15px 0px;
  }
}

@media only screen and (max-width: 1024px) {
  .main-slider.style-1 .banner-content .sub-title {
    letter-spacing: 10px;
  }

  .main-slider.style-1 .banner-content .partner {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider.style-1 .banner-content .text {
    padding-left: 0;
    padding-right: 0;
    border: 0;
  }

  .main-slider.style-1 .banner-content .price {
    display: none;
  }

  .main-slider.style-1 .banner-content .content-btn {
    margin-top: 30px;
  }

  .main-slider.style-1 .banner-media img {
    max-width: 340px;
    left: 219px;
  }
}

@media only screen and (max-width: 600px) {
  .main-slider.style-1 .banner-media {
    left: 18%;
  }
}

@media only screen and (max-width: 575px) {
  .main-slider.style-1 .banner-media {
    left: 0%;
  }

  .main-slider.style-1 .banner-content {
    padding-top: 30px;
  }

  .main-slider.style-1 .banner-content .sub-title {
    font-size: 14px;
  }

  .main-slider.style-1 .banner-content .title {
    font-size: 1.5rem;
    margin-bottom: 10px !important;
  }

  .main-slider.style-1 .banner-content .dz-tags {
    display: none;
  }
}

.main-slider.style-2 {
  background-color: #FCFCFC;
}

.main-slider.style-2 .banner-content .content-info {
  padding-bottom: 25px;
}

.main-slider.style-2 .banner-content .sub-title {
  line-height: 1.6;
  letter-spacing: 10px;
  font-weight: var(--headings-font-weight3);
}

.main-slider.style-2 .banner-content .title {
  color: var(--secondary);
  font-size: 3.75rem;
  margin-bottom: -5px;
}

.main-slider.style-2 .banner-content .sub-head {
  line-height: 2;
  font-size: 2.5rem;
  font-weight: var(--headings-font-weight);
  font-family: var(--font-family-title);
  color: var(--title);
  margin-bottom: 28px;
}

.main-slider.style-2 .banner-content .para {
  font-weight: var(--headings-font-weight2);
  line-height: 1.5;
  font-size: 14px;
  width: 512px;
  margin-bottom: 65px;
}

.main-slider.style-2 .banner-content .partner {
  margin-bottom: 60px;
}

.main-slider.style-2 .banner-content .partner p {
  color: #9290B0;
  font-weight: var(--headings-font-weight2);
  margin-bottom: 28px;
}

.main-slider.style-2 .banner-content .partner .brand-logo .mid-logo {
  margin: 0 60px;
}

.main-slider.style-2 .banner-content .content-btn {
  margin-bottom: 85px;
}

.main-slider.style-2 .banner-content .content-btn img {
  position: absolute;
  left: -27px;
  bottom: 45%;
}

.main-slider.style-2 .banner-media {
  position: relative;
  z-index: 1;
  bottom: 0;
}

.main-slider.style-2 .banner-media:after {
  content: "";
  position: absolute;
  width: 750px;
  height: 750px;
  border-radius: 50%;
  bottom: -130px;
  left: 44%;
  background-color: #F0BBE3;
  z-index: -1;
  opacity: 0.5;
  transform: translateX(-50%);
}

.main-slider.style-2 .banner-media img {
  max-width: 810px;
  position: absolute;
  bottom: 0;
  left: -86px;
}

@media only screen and (max-width: 1480px) {
  .main-slider.style-2 .banner-media:after {
    width: 650px;
    height: 650px;
    left: 47%;
  }

  .main-slider.style-2 .banner-media img {
    max-width: 770px;
    left: -100px;
  }
}

@media only screen and (max-width: 1280px) {
  .main-slider.style-2 .banner-media:after {
    width: 600px;
    height: 600px;
    left: 47%;
  }

  .main-slider.style-2 .banner-media img {
    max-width: 600px;
    left: -22px;
  }

  .main-slider.style-2 .banner-content .partner {
    display: none;
  }

  .main-slider.style-2 .banner-content .content-btn img {
    left: -6px;
    bottom: 30%;
  }
}

@media only screen and (max-width: 1024px) {
  .main-slider.style-2 .banner-media:after {
    width: 550px;
    height: 550px;
  }

  .main-slider.style-2 .banner-media img {
    left: -91px;
  }

  .main-slider.style-2 .banner-content .title {
    font-size: 2.7rem;
  }

  .main-slider.style-2 .banner-content .sub-head {
    font-size: 2rem;
  }

  .main-slider.style-2 .banner-content .para {
    width: 400px;
  }
}

@media only screen and (max-width: 991px) {
  .main-slider.style-2 .banner-media:after {
    display: none;
  }

  .main-slider.style-2 .banner-media img {
    max-width: 420px;
    left: 329px;
  }

  .main-slider.style-2 .banner-content .content-info {
    padding-bottom: 142px;
  }

  .main-slider.style-2 .banner-content .para {
    width: 512px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider.style-2 .banner-content .content-info {
    padding-bottom: 200px;
  }

  .main-slider.style-2 .banner-content .sub-head {
    font-size: 1.5rem;
    margin-bottom: 0;
  }

  .main-slider.style-2 .banner-content .para {
    margin-bottom: 25px;
  }

  .main-slider.style-2 .banner-media img {
    left: 75px;
  }
}

@media only screen and (max-width: 575px) {
  .main-slider.style-2 .banner-content .content-info {
    padding-bottom: 250px;
  }

  .main-slider.style-2 .banner-content .title {
    font-size: 2rem;
  }

  .main-slider.style-2 .banner-content .para {
    width: auto;
  }

  .main-slider.style-2 .banner-media img {
    max-width: 370px;
    left: -15px;
  }
}

.main-swiper .swiper-pagination-five {
  position: static;
  top: 50%;
  left: 0;
  z-index: 9;
  width: auto !important;
  transform: translateY(-50%);
  writing-mode: vertical-rl;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-swiper .swiper-pagination-five .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  left: 0;
  margin: 10px !important;
  border-radius: 50px;
  position: relative;
  opacity: 0.1;
  background-color: var(--primary);
}

.main-swiper .swiper-pagination-five .swiper-pagination-bullet.swiper-pagination-bullet-active {
  color: var(--primary);
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .main-swiper .swiper-pagination-five {
    left: auto;
    right: 0;
  }
}

@media screen and (max-width: 600px) {
  .main-swiper .swiper-pagination-five {
    writing-mode: horizontal-tb;
  }
}

.alert.alert-sm {
  padding: 5px 15px;
  font-size: 12px;
}

.alert.alert-lg {
  padding: 25px 15px;
  font-size: 16px;
}

.alert.alert-xl {
  padding: 35px 15px;
  font-size: 18px;
}

.alert[class*=alert-] i {
  margin-right: 8px;
}

.alert[class*=alert-] ul {
  padding-left: 25px;
  margin-top: 10px;
}

.card {
  border: 0px solid #E1E1F0;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04);
  background: #ffffff;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E1E1F0;
  background: #ffffff;
  padding: 20px 30px;
}

.card-title {
  font-size: 24px;
  margin-bottom: 0px;
}

.card .card-body {
  padding: 20px 30px;
  background: #ffffff;
  border-radius: 15px;
}

.form-label {
  font-size: 14px;
  font-weight: 500;
  color: #777777;
  margin-bottom: 10px;
  background: transparent;
  border-color: #E1E1F0;
}

.form-control,
.woocommerce #review_form #respond input[type="text"],
.woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond input[type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr .quantity input,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="text"],
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="email"],
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="tel"],
.woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text,
.woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce-EditAccountForm.edit-account input[type="text"],
.woocommerce-EditAccountForm.edit-account input[type="email"],
.woocommerce-EditAccountForm.edit-account input[type="password"],
.login input[type="text"],
.login input[type="password"],
.woocommerce-ResetPassword.lost_reset_password input[type="text"],
.checkout.woocommerce-checkout input[type="text"],
.checkout.woocommerce-checkout input[type="email"],
.checkout.woocommerce-checkout input[type="tel"],
.checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout textarea,
.woocommerce form .form-row input.input-text,
.woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .select2-choice {
  height: 48px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 400;
  color: #1a1668;
  transition: all 0.3s ease-in-out;
  background: #f5f5f5;
  border-radius: var(--border-radius-base);
}

.form-control .select,
.woocommerce #review_form #respond input[type="text"] .select,
.woocommerce #review_form #respond textarea .select,
.woocommerce #review_form #respond input[type="email"] .select,
.woocommerce-cart-form .table tr td.actions .coupon .input-text .select,
.woocommerce-cart-form .table tr .quantity input .select,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea .select,
.woocommerce .woocommerce-checkout.checkout form .form-row select .select,
.woocommerce .woocommerce-checkout.checkout form .form-row input .select,
.woocommerce-EditAccountForm.edit-account input[type="text"] .select,
.woocommerce-EditAccountForm.edit-account input[type="email"] .select,
.woocommerce-EditAccountForm.edit-account input[type="password"] .select,
.login input[type="text"] .select,
.login input[type="password"] .select,
.woocommerce-ResetPassword.lost_reset_password input[type="text"] .select,
.checkout.woocommerce-checkout input[type="text"] .select,
.checkout.woocommerce-checkout input[type="email"] .select,
.checkout.woocommerce-checkout input[type="tel"] .select,
.checkout.woocommerce-checkout .select2-choice .select,
.checkout.woocommerce-checkout textarea .select,
.woocommerce form .form-row input.input-text .select,
.woocommerce form .form-row .select2-selection .select,
.woocommerce form .form-row .select2-choice .select {
  padding: 12px 0;
}

.form-control span,
.woocommerce #review_form #respond input[type="text"] span,
.woocommerce #review_form #respond textarea span,
.woocommerce #review_form #respond input[type="email"] span,
.woocommerce-cart-form .table tr td.actions .coupon .input-text span,
.woocommerce-cart-form .table tr .quantity input span,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea span,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="text"] span,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="email"] span,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="tel"] span,
.woocommerce .woocommerce-checkout.checkout form .form-row select span,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text span,
.woocommerce .woocommerce-checkout.checkout form .form-row input span,
.woocommerce-EditAccountForm.edit-account input[type="text"] span,
.woocommerce-EditAccountForm.edit-account input[type="email"] span,
.woocommerce-EditAccountForm.edit-account input[type="password"] span,
.login input[type="text"] span,
.login input[type="password"] span,
.woocommerce-ResetPassword.lost_reset_password input[type="text"] span,
.checkout.woocommerce-checkout input[type="text"] span,
.checkout.woocommerce-checkout input[type="email"] span,
.checkout.woocommerce-checkout input[type="tel"] span,
.checkout.woocommerce-checkout .select2-choice span,
.checkout.woocommerce-checkout textarea span,
.woocommerce form .form-row input.input-text span,
.woocommerce form .form-row .select2-selection span,
.woocommerce form .form-row .select2-choice span {
  margin-top: 0;
}

.form-control::-webkit-input-placeholder,
.woocommerce #review_form #respond input[type="text"]::-webkit-input-placeholder,
.woocommerce #review_form #respond textarea::-webkit-input-placeholder,
.woocommerce #review_form #respond input[type="email"]::-webkit-input-placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .input-text::-webkit-input-placeholder,
.woocommerce-cart-form .table tr .quantity input::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="text"]::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="email"]::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="tel"]::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row select::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input::-webkit-input-placeholder,
.woocommerce-EditAccountForm.edit-account input[type="text"]::-webkit-input-placeholder,
.woocommerce-EditAccountForm.edit-account input[type="email"]::-webkit-input-placeholder,
.woocommerce-EditAccountForm.edit-account input[type="password"]::-webkit-input-placeholder,
.login input[type="text"]::-webkit-input-placeholder,
.login input[type="password"]::-webkit-input-placeholder,
.woocommerce-ResetPassword.lost_reset_password input[type="text"]::-webkit-input-placeholder,
.checkout.woocommerce-checkout input[type="text"]::-webkit-input-placeholder,
.checkout.woocommerce-checkout input[type="email"]::-webkit-input-placeholder,
.checkout.woocommerce-checkout input[type="tel"]::-webkit-input-placeholder,
.checkout.woocommerce-checkout .select2-choice::-webkit-input-placeholder,
.checkout.woocommerce-checkout textarea::-webkit-input-placeholder,
.woocommerce form .form-row input.input-text::-webkit-input-placeholder,
.woocommerce form .form-row .select2-selection::-webkit-input-placeholder,
.woocommerce form .form-row .select2-choice::-webkit-input-placeholder {
  color: #4D4A85;
}

.form-control:-ms-input-placeholder,
.woocommerce #review_form #respond input:-ms-input-placeholder[type="text"],
.woocommerce #review_form #respond textarea:-ms-input-placeholder,
.woocommerce #review_form #respond input:-ms-input-placeholder[type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .input-text:-ms-input-placeholder,
.woocommerce-cart-form .table tr .quantity input:-ms-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea:-ms-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row select:-ms-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input:-ms-input-placeholder,
.woocommerce-EditAccountForm.edit-account input:-ms-input-placeholder[type="text"],
.woocommerce-EditAccountForm.edit-account input:-ms-input-placeholder[type="email"],
.woocommerce-EditAccountForm.edit-account input:-ms-input-placeholder[type="password"],
.login input:-ms-input-placeholder[type="text"],
.login input:-ms-input-placeholder[type="password"],
.woocommerce-ResetPassword.lost_reset_password input:-ms-input-placeholder[type="text"],
.checkout.woocommerce-checkout input:-ms-input-placeholder[type="text"],
.checkout.woocommerce-checkout input:-ms-input-placeholder[type="email"],
.checkout.woocommerce-checkout input:-ms-input-placeholder[type="tel"],
.checkout.woocommerce-checkout .select2-choice:-ms-input-placeholder,
.checkout.woocommerce-checkout textarea:-ms-input-placeholder,
.woocommerce form .form-row input.input-text:-ms-input-placeholder,
.woocommerce form .form-row .select2-selection:-ms-input-placeholder,
.woocommerce form .form-row .select2-choice:-ms-input-placeholder {
  color: #4D4A85;
}

.form-control::placeholder,
.woocommerce #review_form #respond input[type="text"]::placeholder,
.woocommerce #review_form #respond textarea::placeholder,
.woocommerce #review_form #respond input[type="email"]::placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .input-text::placeholder,
.woocommerce-cart-form .table tr .quantity input::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="text"]::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="email"]::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="tel"]::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row select::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input::placeholder,
.woocommerce-EditAccountForm.edit-account input[type="text"]::placeholder,
.woocommerce-EditAccountForm.edit-account input[type="email"]::placeholder,
.woocommerce-EditAccountForm.edit-account input[type="password"]::placeholder,
.login input[type="text"]::placeholder,
.login input[type="password"]::placeholder,
.woocommerce-ResetPassword.lost_reset_password input[type="text"]::placeholder,
.checkout.woocommerce-checkout input[type="text"]::placeholder,
.checkout.woocommerce-checkout input[type="email"]::placeholder,
.checkout.woocommerce-checkout input[type="tel"]::placeholder,
.checkout.woocommerce-checkout .select2-choice::placeholder,
.checkout.woocommerce-checkout textarea::placeholder,
.woocommerce form .form-row input.input-text::placeholder,
.woocommerce form .form-row .select2-selection::placeholder,
.woocommerce form .form-row .select2-choice::placeholder {
  color: #4D4A85;
}

.form-control:focus,
.woocommerce #review_form #respond input:focus[type="text"],
.woocommerce #review_form #respond textarea:focus,
.woocommerce #review_form #respond input:focus[type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .input-text:focus,
.woocommerce-cart-form .table tr .quantity input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input:focus,
.woocommerce-EditAccountForm.edit-account input:focus[type="text"],
.woocommerce-EditAccountForm.edit-account input:focus[type="email"],
.woocommerce-EditAccountForm.edit-account input:focus[type="password"],
.login input:focus[type="text"],
.login input:focus[type="password"],
.woocommerce-ResetPassword.lost_reset_password input:focus[type="text"],
.checkout.woocommerce-checkout input:focus[type="text"],
.checkout.woocommerce-checkout input:focus[type="email"],
.checkout.woocommerce-checkout input:focus[type="tel"],
.checkout.woocommerce-checkout .select2-choice:focus,
.checkout.woocommerce-checkout textarea:focus,
.woocommerce form .form-row input.input-text:focus,
.woocommerce form .form-row .select2-selection:focus,
.woocommerce form .form-row .select2-choice:focus,
.form-control:active,
.woocommerce #review_form #respond input:active[type="text"],
.woocommerce #review_form #respond textarea:active,
.woocommerce #review_form #respond input:active[type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .input-text:active,
.woocommerce-cart-form .table tr .quantity input:active,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea:active,
.woocommerce .woocommerce-checkout.checkout form .form-row select:active,
.woocommerce .woocommerce-checkout.checkout form .form-row input:active,
.woocommerce-EditAccountForm.edit-account input:active[type="text"],
.woocommerce-EditAccountForm.edit-account input:active[type="email"],
.woocommerce-EditAccountForm.edit-account input:active[type="password"],
.login input:active[type="text"],
.login input:active[type="password"],
.woocommerce-ResetPassword.lost_reset_password input:active[type="text"],
.checkout.woocommerce-checkout input:active[type="text"],
.checkout.woocommerce-checkout input:active[type="email"],
.checkout.woocommerce-checkout input:active[type="tel"],
.checkout.woocommerce-checkout .select2-choice:active,
.checkout.woocommerce-checkout textarea:active,
.woocommerce form .form-row input.input-text:active,
.woocommerce form .form-row .select2-selection:active,
.woocommerce form .form-row .select2-choice:active,
.form-control.active,
.woocommerce #review_form #respond input.active[type="text"],
.woocommerce #review_form #respond textarea.active,
.woocommerce #review_form #respond input.active[type="email"],
.woocommerce-cart-form .table tr td.actions .coupon .active.input-text,
.woocommerce-cart-form .table tr .quantity input.active,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.active,
.woocommerce .woocommerce-checkout.checkout form .form-row select.active,
.woocommerce .woocommerce-checkout.checkout form .form-row input.active,
.woocommerce-EditAccountForm.edit-account input.active[type="text"],
.woocommerce-EditAccountForm.edit-account input.active[type="email"],
.woocommerce-EditAccountForm.edit-account input.active[type="password"],
.login input.active[type="text"],
.login input.active[type="password"],
.woocommerce-ResetPassword.lost_reset_password input.active[type="text"],
.checkout.woocommerce-checkout input.active[type="text"],
.checkout.woocommerce-checkout input.active[type="email"],
.checkout.woocommerce-checkout input.active[type="tel"],
.checkout.woocommerce-checkout .active.select2-choice,
.checkout.woocommerce-checkout textarea.active,
.woocommerce form .form-row input.active.input-text,
.woocommerce form .form-row .active.select2-selection,
.woocommerce form .form-row .active.select2-choice {
  border-color: var(--primary);
  background: #f5f5f5;
}

textarea.form-control,
.woocommerce #review_form #respond textarea,
.woocommerce-cart-form .table tr td.actions .coupon textarea.input-text,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.checkout.woocommerce-checkout textarea.select2-choice,
.checkout.woocommerce-checkout textarea,
.woocommerce form .form-row textarea.select2-selection,
.woocommerce form .form-row textarea.select2-choice {
  min-height: auto;
  height: unset;
}

textarea {
  height: 120px;
  resize: none;
}

input:-internal-autofill-selected {
  background: white !important;
  background-image: none !important;
  color: -internal-light-dark-color(black, white) !important;
}

.input-group-text {
  padding: 5px 15px;
  background: #ffffff;
  margin-bottom: 0px !important;
  color: #777777;
  border-color: transparent;
}

.toggle {
  cursor: pointer;
  display: block;
}

.toggle-switch {
  display: inline-block;
  background: #f7f7f7;
  border-radius: 16px;
  width: 45px;
  height: 20px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}

.toggle-switch:before,
.toggle-switch:after {
  content: "";
}

.toggle-switch:before {
  display: block;
  background: linear-gradient(to bottom, #ffffff 0%, #eee 100%);
  border-radius: 50%;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 1px;
  left: 3px;
  transition: left 0.25s;
}

.toggle:hover .toggle-switch:before {
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
}

.toggle-checkbox:checked+.toggle-switch {
  background: var(--primary);
}

.toggle-checkbox:checked+.toggle-switch:before {
  left: 26px;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-label {
  margin-left: 15px;
  position: relative;
  top: 2px;
  font-size: 16px;
  font-weight: 400;
}

.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  border: 1px solid #E1E1F0;
  border-radius: 5px;
  color: rgba(151, 151, 159, 0.39);
}

.file-upload-wrapper:after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  padding: 4px 15px;
  display: block;
  width: calc(100% - 40px);
  pointer-events: none;
  z-index: 20;
  height: calc(1.5em + 1rem + 2px);
  line-height: 30px;
  border-radius: 5px 10px 10px 5px;
  font-weight: 400;
  overflow: hidden;
}

.file-upload-wrapper:before {
  content: 'Upload';
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  height: calc(1.5em + 1rem + 2px);
  background: var(--primary);
  color: #ffffff;
  font-weight: 400;
  z-index: 25;
  font-size: 14px;
  line-height: 40px;
  padding: 0 15px;
  text-transform: capitalize;
  pointer-events: none;
  border-radius: 0 5px 5px 0;
}

.file-upload-wrapper:hover:before {
  background: #e58c23;
}

.file-upload-wrapper input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: calc(1.5em + 1rem + 2px);
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

#ui-datepicker-div {
  display: none;
  background: #fafafa;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  margin-top: 0.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.ui-datepicker-calendar thead th {
  padding: 0.25rem 0;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
  color: #777777;
}

.ui-datepicker-calendar tbody td {
  width: 2.5rem;
  text-align: center;
  padding: 0;
}

.ui-datepicker-calendar tbody td a {
  display: block;
  border-radius: 0.25rem;
  line-height: 2rem;
  transition: 0.3s all;
  color: #777777;
  font-size: 0.875rem;
  text-decoration: none;
}

.ui-datepicker-calendar tbody td a:hover {
  background-color: var(--primary);
  color: white;
}

.ui-datepicker-calendar tbody td a.ui-state-active {
  background-color: var(--primary);
  color: white;
}

.ui-datepicker-header a.ui-corner-all {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  transition: 0.3s all;
}

.ui-datepicker-header a.ui-corner-all:hover {
  background-color: #faebda;
  color: #ffffff;
}

.ui-datepicker-header a.ui-datepicker-prev {
  left: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 0.5rem;
  background-position: 50%;
  transform: rotate(180deg);
}

.ui-datepicker-header a.ui-datepicker-next {
  right: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 50%;
}

.ui-datepicker-header a>span {
  display: none;
}

.ui-datepicker-title {
  text-align: center;
  line-height: 2rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding-bottom: 0.25rem;
}

.ui-datepicker-week-col {
  color: #777777;
  font-weight: 400;
  font-size: 0.75rem;
}

label.error {
  color: #FF1E6F;
  position: absolute;
  bottom: 0;
  margin-bottom: -22px;
  font-size: 12px;
  font-weight: 400;
}

.nice-select {
  display: flex;
  align-items: center;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.nice-select ul {
  width: 100% !important;
}

.nice-select .list {
  background: #212529;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #212529;
  color: var(--primary);
}

.form-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.label-title {
  font-weight: 600;
  color: var(--secondary);
  margin-bottom: 5px;
  font-size: 14px;
}

.form-select {
  height: 48px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 400;
  color: #1a1668;
  transition: all 0.3s ease-in-out;
  background-color: #f5f5f5;
}

.form-select .dropdown-toggle {
  border: 0 !important;
  padding: 0;
  font-weight: normal;
  text-transform: unset;
  outline: none;
}

.form-select .dropdown-toggle:focus {
  outline: 0 !important;
  outline-offset: 0;
}

.form-select .dropdown-toggle:after {
  content: none;
}

.form-select.show {
  box-shadow: none !important;
  outline: 0px !important;
}

.form-select.show .dropdown-toggle {
  box-shadow: none !important;
  outline: 0px !important;
}

.form-select.show.btn-group .dropdown-menu.inner {
  display: block;
}

.form-select .dropdown-menu {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  margin-top: -1px;
  padding: 10px 0;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  font-size: 14px;
}

.form-select .dropdown-menu ul li {
  padding: 0;
}

.form-select .dropdown-menu ul li a {
  display: block;
  padding: 8px 20px;
  clear: both;
  font-weight: 400;
  color: #333;
  white-space: nowrap;
}

.form-select .dropdown-menu ul li a img {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  margin-right: 5px;
}

.form-select .dropdown-menu ul li a:hover {
  background-color: #f2f2f4;
}

.form-select .dropdown-menu ul li.selected a {
  background-color: var(--primary);
  color: #ffffff;
}

.form-select:focus,
.form-select:active,
.form-select.active {
  border-color: var(--primary);
}

.form-select.btn-group {
  padding: 10px 20px;
  border-width: 1px;
}

.form-select.btn-group.show {
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1) !important;
  border-color: var(--primary);
}

.form-select.btn-group .form-control .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce #review_form #respond input[type="text"] .dropdown-toggle.btn-default,
.woocommerce #review_form #respond .form-select.btn-group input[type="text"] .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce #review_form #respond textarea .dropdown-toggle.btn-default,
.woocommerce #review_form #respond .form-select.btn-group textarea .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce #review_form #respond input[type="email"] .dropdown-toggle.btn-default,
.woocommerce #review_form #respond .form-select.btn-group input[type="email"] .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce-cart-form .table tr td.actions .coupon .input-text .dropdown-toggle.btn-default,
.woocommerce-cart-form .table tr td.actions .coupon .form-select.btn-group .input-text .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce-cart-form .table tr .quantity input .dropdown-toggle.btn-default,
.woocommerce-cart-form .table tr .quantity .form-select.btn-group input .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce .woocommerce-checkout.checkout form .form-row select .dropdown-toggle.btn-default,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-select.btn-group select .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce .woocommerce-checkout.checkout form .form-row input .dropdown-toggle.btn-default,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-select.btn-group input .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce-EditAccountForm.edit-account input[type="text"] .dropdown-toggle.btn-default,
.woocommerce-EditAccountForm.edit-account .form-select.btn-group input[type="text"] .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce-EditAccountForm.edit-account input[type="email"] .dropdown-toggle.btn-default,
.woocommerce-EditAccountForm.edit-account .form-select.btn-group input[type="email"] .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce-EditAccountForm.edit-account input[type="password"] .dropdown-toggle.btn-default,
.woocommerce-EditAccountForm.edit-account .form-select.btn-group input[type="password"] .dropdown-toggle.btn-default,
.form-select.btn-group .login input[type="text"] .dropdown-toggle.btn-default,
.login .form-select.btn-group input[type="text"] .dropdown-toggle.btn-default,
.form-select.btn-group .login input[type="password"] .dropdown-toggle.btn-default,
.login .form-select.btn-group input[type="password"] .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce-ResetPassword.lost_reset_password input[type="text"] .dropdown-toggle.btn-default,
.woocommerce-ResetPassword.lost_reset_password .form-select.btn-group input[type="text"] .dropdown-toggle.btn-default,
.form-select.btn-group .checkout.woocommerce-checkout input[type="text"] .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout .form-select.btn-group input[type="text"] .dropdown-toggle.btn-default,
.form-select.btn-group .checkout.woocommerce-checkout input[type="email"] .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout .form-select.btn-group input[type="email"] .dropdown-toggle.btn-default,
.form-select.btn-group .checkout.woocommerce-checkout input[type="tel"] .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout .form-select.btn-group input[type="tel"] .dropdown-toggle.btn-default,
.form-select.btn-group .checkout.woocommerce-checkout .select2-choice .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout .form-select.btn-group .select2-choice .dropdown-toggle.btn-default,
.form-select.btn-group .checkout.woocommerce-checkout textarea .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout .form-select.btn-group textarea .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce form .form-row input.input-text .dropdown-toggle.btn-default,
.woocommerce form .form-row .form-select.btn-group input.input-text .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce form .form-row .select2-selection .dropdown-toggle.btn-default,
.woocommerce form .form-row .form-select.btn-group .select2-selection .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce form .form-row .select2-choice .dropdown-toggle.btn-default,
.woocommerce form .form-row .form-select.btn-group .select2-choice .dropdown-toggle.btn-default {
  padding: 0;
}

.form-select.btn-group .dropdown-toggle .caret {
  font-size: 10px;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.form-select.btn-group .dropdown-toggle .caret:before {
  content: "\f078";
}

.custom-file-label {
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  height: 45px;
  border-radius: 30px !important;
  padding: 10px 20px;
  font-size: 15px;
  border-color: transparent;
}

.custom-file-label:after {
  content: none !important;
}

.dz-form.radius-no .form-control,
.dz-form.radius-no .woocommerce #review_form #respond input[type="text"],
.woocommerce #review_form #respond .dz-form.radius-no input[type="text"],
.dz-form.radius-no .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .dz-form.radius-no textarea,
.dz-form.radius-no .woocommerce #review_form #respond input[type="email"],
.woocommerce #review_form #respond .dz-form.radius-no input[type="email"],
.dz-form.radius-no .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .dz-form.radius-no .input-text,
.dz-form.radius-no .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .dz-form.radius-no input,
.dz-form.radius-no .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-form.radius-no textarea,
.dz-form.radius-no .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-form.radius-no select,
.dz-form.radius-no .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-form.radius-no input,
.dz-form.radius-no .woocommerce-EditAccountForm.edit-account input[type="text"],
.woocommerce-EditAccountForm.edit-account .dz-form.radius-no input[type="text"],
.dz-form.radius-no .woocommerce-EditAccountForm.edit-account input[type="email"],
.woocommerce-EditAccountForm.edit-account .dz-form.radius-no input[type="email"],
.dz-form.radius-no .woocommerce-EditAccountForm.edit-account input[type="password"],
.woocommerce-EditAccountForm.edit-account .dz-form.radius-no input[type="password"],
.dz-form.radius-no .login input[type="text"],
.login .dz-form.radius-no input[type="text"],
.dz-form.radius-no .login input[type="password"],
.login .dz-form.radius-no input[type="password"],
.dz-form.radius-no .woocommerce-ResetPassword.lost_reset_password input[type="text"],
.woocommerce-ResetPassword.lost_reset_password .dz-form.radius-no input[type="text"],
.dz-form.radius-no .checkout.woocommerce-checkout input[type="text"],
.checkout.woocommerce-checkout .dz-form.radius-no input[type="text"],
.dz-form.radius-no .checkout.woocommerce-checkout input[type="email"],
.checkout.woocommerce-checkout .dz-form.radius-no input[type="email"],
.dz-form.radius-no .checkout.woocommerce-checkout input[type="tel"],
.checkout.woocommerce-checkout .dz-form.radius-no input[type="tel"],
.dz-form.radius-no .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .dz-form.radius-no .select2-choice,
.dz-form.radius-no .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .dz-form.radius-no textarea,
.dz-form.radius-no .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .dz-form.radius-no input.input-text,
.dz-form.radius-no .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .dz-form.radius-no .select2-selection,
.dz-form.radius-no .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .dz-form.radius-no .select2-choice {
  border-radius: 0;
}

.dz-form.style-1 textarea {
  height: 185px;
  padding: 15px ​20px;
}

.form-check-input {
  width: 1.125em;
  height: 1.125em;
  border: 2px solid rgba(0, 0, 0, 0.25);
}

.input-group {
  margin-bottom: 20px;
}

.wpcf7-form .row {
  --bs-gutter-x: 20px;
}

.checkout-form {
  margin-bottom: 40px;
}

.checkout-form .form-label {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary);
}

.checkout-form .form-control,
.checkout-form .woocommerce #review_form #respond input[type="text"],
.woocommerce #review_form #respond .checkout-form input[type="text"],
.checkout-form .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .checkout-form textarea,
.checkout-form .woocommerce #review_form #respond input[type="email"],
.woocommerce #review_form #respond .checkout-form input[type="email"],
.checkout-form .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .checkout-form .input-text,
.checkout-form .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .checkout-form input,
.checkout-form .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .checkout-form textarea,
.checkout-form .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .checkout-form select,
.checkout-form .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .checkout-form input,
.checkout-form .woocommerce-EditAccountForm.edit-account input[type="text"],
.woocommerce-EditAccountForm.edit-account .checkout-form input[type="text"],
.checkout-form .woocommerce-EditAccountForm.edit-account input[type="email"],
.woocommerce-EditAccountForm.edit-account .checkout-form input[type="email"],
.checkout-form .woocommerce-EditAccountForm.edit-account input[type="password"],
.woocommerce-EditAccountForm.edit-account .checkout-form input[type="password"],
.checkout-form .login input[type="text"],
.login .checkout-form input[type="text"],
.checkout-form .login input[type="password"],
.login .checkout-form input[type="password"],
.checkout-form .woocommerce-ResetPassword.lost_reset_password input[type="text"],
.woocommerce-ResetPassword.lost_reset_password .checkout-form input[type="text"],
.checkout-form .checkout.woocommerce-checkout input[type="text"],
.checkout.woocommerce-checkout .checkout-form input[type="text"],
.checkout-form .checkout.woocommerce-checkout input[type="email"],
.checkout.woocommerce-checkout .checkout-form input[type="email"],
.checkout-form .checkout.woocommerce-checkout input[type="tel"],
.checkout.woocommerce-checkout .checkout-form input[type="tel"],
.checkout-form .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .checkout-form .select2-choice,
.checkout-form .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .checkout-form textarea,
.checkout-form .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .checkout-form input.input-text,
.checkout-form .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .checkout-form .select2-selection,
.checkout-form .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .checkout-form .select2-choice {
  background: none;
  border: 1px solid transparent;
  border-bottom: 2px solid #E1E1E1;
  border-radius: 0;
  padding: 10px 0;
}

.checkout-form .form-control::placeholder,
.checkout-form .woocommerce #review_form #respond input[type="text"]::placeholder,
.woocommerce #review_form #respond .checkout-form input[type="text"]::placeholder,
.checkout-form .woocommerce #review_form #respond textarea::placeholder,
.woocommerce #review_form #respond .checkout-form textarea::placeholder,
.checkout-form .woocommerce #review_form #respond input[type="email"]::placeholder,
.woocommerce #review_form #respond .checkout-form input[type="email"]::placeholder,
.checkout-form .woocommerce-cart-form .table tr td.actions .coupon .input-text::placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .checkout-form .input-text::placeholder,
.checkout-form .woocommerce-cart-form .table tr .quantity input::placeholder,
.woocommerce-cart-form .table tr .quantity .checkout-form input::placeholder,
.checkout-form .woocommerce .woocommerce-checkout.checkout form .form-row textarea::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .checkout-form textarea::placeholder,
.checkout-form .woocommerce .woocommerce-checkout.checkout form .form-row select::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .checkout-form select::placeholder,
.checkout-form .woocommerce .woocommerce-checkout.checkout form .form-row input::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .checkout-form input::placeholder,
.checkout-form .woocommerce-EditAccountForm.edit-account input[type="text"]::placeholder,
.woocommerce-EditAccountForm.edit-account .checkout-form input[type="text"]::placeholder,
.checkout-form .woocommerce-EditAccountForm.edit-account input[type="email"]::placeholder,
.woocommerce-EditAccountForm.edit-account .checkout-form input[type="email"]::placeholder,
.checkout-form .woocommerce-EditAccountForm.edit-account input[type="password"]::placeholder,
.woocommerce-EditAccountForm.edit-account .checkout-form input[type="password"]::placeholder,
.checkout-form .login input[type="text"]::placeholder,
.login .checkout-form input[type="text"]::placeholder,
.checkout-form .login input[type="password"]::placeholder,
.login .checkout-form input[type="password"]::placeholder,
.checkout-form .woocommerce-ResetPassword.lost_reset_password input[type="text"]::placeholder,
.woocommerce-ResetPassword.lost_reset_password .checkout-form input[type="text"]::placeholder,
.checkout-form .checkout.woocommerce-checkout input[type="text"]::placeholder,
.checkout.woocommerce-checkout .checkout-form input[type="text"]::placeholder,
.checkout-form .checkout.woocommerce-checkout input[type="email"]::placeholder,
.checkout.woocommerce-checkout .checkout-form input[type="email"]::placeholder,
.checkout-form .checkout.woocommerce-checkout input[type="tel"]::placeholder,
.checkout.woocommerce-checkout .checkout-form input[type="tel"]::placeholder,
.checkout-form .checkout.woocommerce-checkout .select2-choice::placeholder,
.checkout.woocommerce-checkout .checkout-form .select2-choice::placeholder,
.checkout-form .checkout.woocommerce-checkout textarea::placeholder,
.checkout.woocommerce-checkout .checkout-form textarea::placeholder,
.checkout-form .woocommerce form .form-row input.input-text::placeholder,
.woocommerce form .form-row .checkout-form input.input-text::placeholder,
.checkout-form .woocommerce form .form-row .select2-selection::placeholder,
.woocommerce form .form-row .checkout-form .select2-selection::placeholder,
.checkout-form .woocommerce form .form-row .select2-choice::placeholder,
.woocommerce form .form-row .checkout-form .select2-choice::placeholder {
  font-weight: 600;
  font-size: 16px;
  color: var(--seconday);
}

.pay-bottom {
  padding-bottom: 55px;
  border-bottom: 2px dashed #AAAAAA;
}

.pay-bottom .money-type {
  position: relative;
  text-align: center;
}

.pay-bottom .money-type .form-check {
  border: 1px solid #F0F0F0;
  border-radius: var(--border-radius-base);
  padding-left: 0;
}

.pay-bottom .money-type .form-check .form-check-input {
  position: absolute;
  right: 15px;
  top: 15px;
}

.pay-bottom .money-type .form-check .form-check-input:checked+.form-check-label {
  background-color: #FFF0DF;
  border: 1px solid var(--primary);
  border-radius: var(--border-radius-base);
}

.pay-bottom .money-type .form-check .form-check-input:checked+.form-check-label i {
  color: var(--primary);
}

.pay-bottom .money-type .form-check .form-check-label {
  width: 100%;
  margin: 0 !important;
  padding: 10px 10px;
}

.pay-bottom .money-type .form-check .payment-check i {
  font-size: 68px;
}

.input-group-text.checkout-text {
  background: none;
  border-color: transparent;
  position: relative;
  right: 60px;
}

.input-group-text.checkout-text i {
  font-size: 20px;
  font-weight: 600;
}

.form-control.default-select .btn,
.woocommerce #review_form #respond input.default-select[type="text"] .btn,
.woocommerce #review_form #respond textarea.default-select .btn,
.woocommerce #review_form #respond input.default-select[type="email"] .btn,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .btn,
.woocommerce-cart-form .table tr .quantity input.default-select .btn,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .btn,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] .btn,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] .btn,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] .btn,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .btn,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text .btn,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .btn,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .btn,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .btn,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .btn,
.login input.default-select[type="text"] .btn,
.login input.default-select[type="password"] .btn,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .btn,
.checkout.woocommerce-checkout input.default-select[type="text"] .btn,
.checkout.woocommerce-checkout input.default-select[type="email"] .btn,
.checkout.woocommerce-checkout input.default-select[type="tel"] .btn,
.checkout.woocommerce-checkout .default-select.select2-choice .btn,
.checkout.woocommerce-checkout textarea.default-select .btn,
.woocommerce form .form-row input.default-select.input-text .btn,
.woocommerce form .form-row .default-select.select2-selection .btn,
.woocommerce form .form-row .default-select.select2-choice .btn,
.form-control.default-select .wp-block-button__link,
.woocommerce #review_form #respond input.default-select[type="text"] .wp-block-button__link,
.woocommerce #review_form #respond textarea.default-select .wp-block-button__link,
.woocommerce #review_form #respond input.default-select[type="email"] .wp-block-button__link,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .wp-block-button__link,
.woocommerce-cart-form .table tr .quantity input.default-select .wp-block-button__link,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .wp-block-button__link,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] .wp-block-button__link,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] .wp-block-button__link,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] .wp-block-button__link,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .wp-block-button__link,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text .wp-block-button__link,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .wp-block-button__link,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .wp-block-button__link,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .wp-block-button__link,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .wp-block-button__link,
.login input.default-select[type="text"] .wp-block-button__link,
.login input.default-select[type="password"] .wp-block-button__link,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .wp-block-button__link,
.checkout.woocommerce-checkout input.default-select[type="text"] .wp-block-button__link,
.checkout.woocommerce-checkout input.default-select[type="email"] .wp-block-button__link,
.checkout.woocommerce-checkout input.default-select[type="tel"] .wp-block-button__link,
.checkout.woocommerce-checkout .default-select.select2-choice .wp-block-button__link,
.checkout.woocommerce-checkout textarea.default-select .wp-block-button__link,
.woocommerce form .form-row input.default-select.input-text .wp-block-button__link,
.woocommerce form .form-row .default-select.select2-selection .wp-block-button__link,
.woocommerce form .form-row .default-select.select2-choice .wp-block-button__link,
.form-control.default-select .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .form-control.default-select a,
.woocommerce #review_form #respond input.default-select[type="text"] .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons #review_form #respond input.default-select[type="text"] a,
.woocommerce #review_form #respond textarea.default-select .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons #review_form #respond textarea.default-select a,
.woocommerce #review_form #respond input.default-select[type="email"] .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons #review_form #respond input.default-select[type="email"] a,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-cart-form .table tr .quantity input.default-select a,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row textarea.default-select a,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] a,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] a,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] a,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row select.default-select a,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select.input-text a,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select a,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type="text"] a,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type="email"] a,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type="password"] a,
.login input.default-select[type="text"] .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .login input.default-select[type="text"] a,
.login input.default-select[type="password"] .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .login input.default-select[type="password"] a,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] a,
.checkout.woocommerce-checkout input.default-select[type="text"] .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type="text"] a,
.checkout.woocommerce-checkout input.default-select[type="email"] .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type="email"] a,
.checkout.woocommerce-checkout input.default-select[type="tel"] .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type="tel"] a,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout .default-select.select2-choice a,
.checkout.woocommerce-checkout textarea.default-select .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout textarea.default-select a,
.woocommerce form .form-row input.default-select.input-text .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons form .form-row input.default-select.input-text a,
.woocommerce form .form-row .default-select.select2-selection .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons form .form-row .default-select.select2-selection a,
.woocommerce form .form-row .default-select.select2-choice .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons form .form-row .default-select.select2-choice a,
.form-control.default-select .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .form-control.default-select a,
.woocommerce.widget_shopping_cart #review_form #respond input.default-select[type="text"] .buttons a,
.woocommerce.widget_shopping_cart .buttons #review_form #respond input.default-select[type="text"] a,
.woocommerce.widget_shopping_cart #review_form #respond textarea.default-select .buttons a,
.woocommerce.widget_shopping_cart .buttons #review_form #respond textarea.default-select a,
.woocommerce.widget_shopping_cart #review_form #respond input.default-select[type="email"] .buttons a,
.woocommerce.widget_shopping_cart .buttons #review_form #respond input.default-select[type="email"] a,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-cart-form .table tr .quantity input.default-select a,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row textarea.default-select .buttons a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row textarea.default-select a,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] .buttons a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] a,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] .buttons a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] a,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] .buttons a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] a,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row select.default-select .buttons a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row select.default-select a,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row input.default-select.input-text .buttons a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select.input-text a,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row input.default-select .buttons a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select a,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type="text"] a,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type="email"] a,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type="password"] a,
.login input.default-select[type="text"] .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .login input.default-select[type="text"] a,
.login input.default-select[type="password"] .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .login input.default-select[type="password"] a,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] a,
.checkout.woocommerce-checkout input.default-select[type="text"] .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type="text"] a,
.checkout.woocommerce-checkout input.default-select[type="email"] .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type="email"] a,
.checkout.woocommerce-checkout input.default-select[type="tel"] .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type="tel"] a,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout .default-select.select2-choice a,
.checkout.woocommerce-checkout textarea.default-select .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout textarea.default-select a,
.woocommerce.widget_shopping_cart form .form-row input.default-select.input-text .buttons a,
.woocommerce.widget_shopping_cart .buttons form .form-row input.default-select.input-text a,
.woocommerce.widget_shopping_cart form .form-row .default-select.select2-selection .buttons a,
.woocommerce.widget_shopping_cart .buttons form .form-row .default-select.select2-selection a,
.woocommerce.widget_shopping_cart form .form-row .default-select.select2-choice .buttons a,
.woocommerce.widget_shopping_cart .buttons form .form-row .default-select.select2-choice a,
.form-control.default-select .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .form-control.default-select input,
.woocommerce #review_form #respond input.default-select[type="text"] .form-submit input,
.woocommerce #review_form #respond .form-submit input.default-select[type="text"] input,
.woocommerce #review_form #respond textarea.default-select .form-submit input,
.woocommerce #review_form #respond .form-submit textarea.default-select input,
.woocommerce #review_form #respond input.default-select[type="email"] .form-submit input,
.woocommerce #review_form #respond .form-submit input.default-select[type="email"] input,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text input,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .woocommerce-cart-form .table tr .quantity input.default-select input,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row textarea.default-select input,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] input,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] input,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] input,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row select.default-select input,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row input.default-select.input-text input,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row input.default-select input,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .woocommerce-EditAccountForm.edit-account input.default-select[type="text"] input,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .woocommerce-EditAccountForm.edit-account input.default-select[type="email"] input,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .woocommerce-EditAccountForm.edit-account input.default-select[type="password"] input,
.login input.default-select[type="text"] .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .login input.default-select[type="text"] input,
.login input.default-select[type="password"] .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .login input.default-select[type="password"] input,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] input,
.checkout.woocommerce-checkout input.default-select[type="text"] .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout input.default-select[type="text"] input,
.checkout.woocommerce-checkout input.default-select[type="email"] .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout input.default-select[type="email"] input,
.checkout.woocommerce-checkout input.default-select[type="tel"] .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout input.default-select[type="tel"] input,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout .default-select.select2-choice input,
.checkout.woocommerce-checkout textarea.default-select .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout textarea.default-select input,
.woocommerce form .form-row input.default-select.input-text #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit form .form-row input.default-select.input-text input,
.woocommerce form .form-row .default-select.select2-selection #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit form .form-row .default-select.select2-selection input,
.woocommerce form .form-row .default-select.select2-choice #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit form .form-row .default-select.select2-choice input,
.form-control.default-select .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .form-control.default-select .button,
.woocommerce #review_form #respond input.default-select[type="text"] .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce #review_form #respond input.default-select[type="text"] .button,
.woocommerce #review_form #respond textarea.default-select .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce #review_form #respond textarea.default-select .button,
.woocommerce #review_form #respond input.default-select[type="email"] .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce #review_form #respond input.default-select[type="email"] .button,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .button,
.woocommerce-cart-form .table tr .quantity input.default-select .button,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] .button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] .button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] .button,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text .button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .button,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .button,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .button,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .button,
.login input.default-select[type="text"] .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .login input.default-select[type="text"] .button,
.login input.default-select[type="password"] .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .login input.default-select[type="password"] .button,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .button,
.checkout.woocommerce-checkout input.default-select[type="text"] .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout input.default-select[type="text"] .button,
.checkout.woocommerce-checkout input.default-select[type="email"] .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout input.default-select[type="email"] .button,
.checkout.woocommerce-checkout input.default-select[type="tel"] .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout input.default-select[type="tel"] .button,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout .default-select.select2-choice .button,
.checkout.woocommerce-checkout textarea.default-select .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout textarea.default-select .button,
.woocommerce form .form-row input.default-select.input-text .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce form .form-row input.default-select.input-text .button,
.woocommerce form .form-row .default-select.select2-selection .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce form .form-row .default-select.select2-selection .button,
.woocommerce form .form-row .default-select.select2-choice .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce form .form-row .default-select.select2-choice .button,
.form-control.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .form-control.default-select a.checkout-button,
.woocommerce #review_form #respond input.default-select[type="text"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #review_form #respond input.default-select[type="text"] a.checkout-button,
.woocommerce #review_form #respond textarea.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #review_form #respond textarea.default-select a.checkout-button,
.woocommerce #review_form #respond input.default-select[type="email"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #review_form #respond input.default-select[type="email"] a.checkout-button,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a.checkout-button,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-cart-form .table tr .quantity input.default-select a.checkout-button,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select a.checkout-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] a.checkout-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] a.checkout-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] a.checkout-button,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row select.default-select a.checkout-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text a.checkout-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select a.checkout-button,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-EditAccountForm.edit-account input.default-select[type="text"] a.checkout-button,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-EditAccountForm.edit-account input.default-select[type="email"] a.checkout-button,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-EditAccountForm.edit-account input.default-select[type="password"] a.checkout-button,
.login input.default-select[type="text"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .login input.default-select[type="text"] a.checkout-button,
.login input.default-select[type="password"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .login input.default-select[type="password"] a.checkout-button,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] a.checkout-button,
.checkout.woocommerce-checkout input.default-select[type="text"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout input.default-select[type="text"] a.checkout-button,
.checkout.woocommerce-checkout input.default-select[type="email"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout input.default-select[type="email"] a.checkout-button,
.checkout.woocommerce-checkout input.default-select[type="tel"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout input.default-select[type="tel"] a.checkout-button,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout .default-select.select2-choice a.checkout-button,
.checkout.woocommerce-checkout textarea.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout textarea.default-select a.checkout-button,
.woocommerce form .form-row input.default-select.input-text .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce form .form-row input.default-select.input-text a.checkout-button,
.woocommerce form .form-row .default-select.select2-selection .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce form .form-row .default-select.select2-selection a.checkout-button,
.woocommerce form .form-row .default-select.select2-choice .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce form .form-row .default-select.select2-choice a.checkout-button,
.form-control.default-select .woocommerce #respond input#submit,
.woocommerce #respond .form-control.default-select input#submit,
.woocommerce #review_form #respond input.default-select[type="text"] input#submit,
.woocommerce #review_form #respond textarea.default-select input#submit,
.woocommerce #review_form #respond input.default-select[type="email"] input#submit,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce #respond input#submit,
.woocommerce #respond .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text input#submit,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce #respond input#submit,
.woocommerce #respond .woocommerce-cart-form .table tr .quantity input.default-select input#submit,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select #respond input#submit,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row textarea.default-select input#submit,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] #respond input#submit,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] input#submit,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] #respond input#submit,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] input#submit,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] #respond input#submit,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] input#submit,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select #respond input#submit,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row select.default-select input#submit,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text #respond input#submit,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row input.default-select.input-text input#submit,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select #respond input#submit,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row input.default-select input#submit,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .woocommerce #respond input#submit,
.woocommerce #respond .woocommerce-EditAccountForm.edit-account input.default-select[type="text"] input#submit,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .woocommerce #respond input#submit,
.woocommerce #respond .woocommerce-EditAccountForm.edit-account input.default-select[type="email"] input#submit,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .woocommerce #respond input#submit,
.woocommerce #respond .woocommerce-EditAccountForm.edit-account input.default-select[type="password"] input#submit,
.login input.default-select[type="text"] .woocommerce #respond input#submit,
.woocommerce #respond .login input.default-select[type="text"] input#submit,
.login input.default-select[type="password"] .woocommerce #respond input#submit,
.woocommerce #respond .login input.default-select[type="password"] input#submit,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .woocommerce #respond input#submit,
.woocommerce #respond .woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] input#submit,
.checkout.woocommerce-checkout input.default-select[type="text"] .woocommerce #respond input#submit,
.woocommerce #respond .checkout.woocommerce-checkout input.default-select[type="text"] input#submit,
.checkout.woocommerce-checkout input.default-select[type="email"] .woocommerce #respond input#submit,
.woocommerce #respond .checkout.woocommerce-checkout input.default-select[type="email"] input#submit,
.checkout.woocommerce-checkout input.default-select[type="tel"] .woocommerce #respond input#submit,
.woocommerce #respond .checkout.woocommerce-checkout input.default-select[type="tel"] input#submit,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce #respond input#submit,
.woocommerce #respond .checkout.woocommerce-checkout .default-select.select2-choice input#submit,
.checkout.woocommerce-checkout textarea.default-select .woocommerce #respond input#submit,
.woocommerce #respond .checkout.woocommerce-checkout textarea.default-select input#submit,
.woocommerce form .form-row input.default-select.input-text #respond input#submit,
.woocommerce #respond form .form-row input.default-select.input-text input#submit,
.woocommerce form .form-row .default-select.select2-selection #respond input#submit,
.woocommerce #respond form .form-row .default-select.select2-selection input#submit,
.woocommerce form .form-row .default-select.select2-choice #respond input#submit,
.woocommerce #respond form .form-row .default-select.select2-choice input#submit,
.form-control.default-select .woocommerce a.button,
.woocommerce .form-control.default-select a.button,
.woocommerce #review_form #respond input.default-select[type="text"] a.button,
.woocommerce #review_form #respond textarea.default-select a.button,
.woocommerce #review_form #respond input.default-select[type="email"] a.button,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce a.button,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a.button,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce a.button,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select a.button,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select a.button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] a.button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] a.button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] a.button,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select a.button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text a.button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select a.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .woocommerce a.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="text"] a.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .woocommerce a.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="email"] a.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .woocommerce a.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="password"] a.button,
.login input.default-select[type="text"] .woocommerce a.button,
.woocommerce .login input.default-select[type="text"] a.button,
.login input.default-select[type="password"] .woocommerce a.button,
.woocommerce .login input.default-select[type="password"] a.button,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .woocommerce a.button,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] a.button,
.checkout.woocommerce-checkout input.default-select[type="text"] .woocommerce a.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="text"] a.button,
.checkout.woocommerce-checkout input.default-select[type="email"] .woocommerce a.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="email"] a.button,
.checkout.woocommerce-checkout input.default-select[type="tel"] .woocommerce a.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="tel"] a.button,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce a.button,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice a.button,
.checkout.woocommerce-checkout textarea.default-select .woocommerce a.button,
.woocommerce .checkout.woocommerce-checkout textarea.default-select a.button,
.woocommerce form .form-row input.default-select.input-text a.button,
.woocommerce form .form-row .default-select.select2-selection a.button,
.woocommerce form .form-row .default-select.select2-choice a.button,
.form-control.default-select .woocommerce button.button,
.woocommerce .form-control.default-select button.button,
.woocommerce #review_form #respond input.default-select[type="text"] button.button,
.woocommerce #review_form #respond textarea.default-select button.button,
.woocommerce #review_form #respond input.default-select[type="email"] button.button,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce button.button,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text button.button,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce button.button,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select button.button,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select button.button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] button.button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] button.button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] button.button,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select button.button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text button.button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select button.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .woocommerce button.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="text"] button.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .woocommerce button.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="email"] button.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .woocommerce button.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="password"] button.button,
.login input.default-select[type="text"] .woocommerce button.button,
.woocommerce .login input.default-select[type="text"] button.button,
.login input.default-select[type="password"] .woocommerce button.button,
.woocommerce .login input.default-select[type="password"] button.button,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .woocommerce button.button,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] button.button,
.checkout.woocommerce-checkout input.default-select[type="text"] .woocommerce button.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="text"] button.button,
.checkout.woocommerce-checkout input.default-select[type="email"] .woocommerce button.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="email"] button.button,
.checkout.woocommerce-checkout input.default-select[type="tel"] .woocommerce button.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="tel"] button.button,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce button.button,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice button.button,
.checkout.woocommerce-checkout textarea.default-select .woocommerce button.button,
.woocommerce .checkout.woocommerce-checkout textarea.default-select button.button,
.woocommerce form .form-row input.default-select.input-text button.button,
.woocommerce form .form-row .default-select.select2-selection button.button,
.woocommerce form .form-row .default-select.select2-choice button.button,
.form-control.default-select .woocommerce input.button,
.woocommerce .form-control.default-select input.button,
.woocommerce #review_form #respond input.default-select[type="text"] input.button,
.woocommerce #review_form #respond textarea.default-select input.button,
.woocommerce #review_form #respond input.default-select[type="email"] input.button,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce input.button,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text input.button,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce input.button,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select input.button,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select input.button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] input.button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] input.button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] input.button,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select input.button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text input.button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select input.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .woocommerce input.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="text"] input.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .woocommerce input.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="email"] input.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .woocommerce input.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="password"] input.button,
.login input.default-select[type="text"] .woocommerce input.button,
.woocommerce .login input.default-select[type="text"] input.button,
.login input.default-select[type="password"] .woocommerce input.button,
.woocommerce .login input.default-select[type="password"] input.button,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .woocommerce input.button,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] input.button,
.checkout.woocommerce-checkout input.default-select[type="text"] .woocommerce input.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="text"] input.button,
.checkout.woocommerce-checkout input.default-select[type="email"] .woocommerce input.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="email"] input.button,
.checkout.woocommerce-checkout input.default-select[type="tel"] .woocommerce input.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="tel"] input.button,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce input.button,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice input.button,
.checkout.woocommerce-checkout textarea.default-select .woocommerce input.button,
.woocommerce .checkout.woocommerce-checkout textarea.default-select input.button,
.woocommerce form .form-row input.default-select.input-text input.button,
.woocommerce form .form-row .default-select.select2-selection input.button,
.woocommerce form .form-row .default-select.select2-choice input.button,
.form-control.default-select .woocommerce #place_order,
.woocommerce .form-control.default-select #place_order,
.woocommerce #review_form #respond input.default-select[type="text"] #place_order,
.woocommerce #review_form #respond textarea.default-select #place_order,
.woocommerce #review_form #respond input.default-select[type="email"] #place_order,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce #place_order,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text #place_order,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce #place_order,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select #place_order,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select #place_order,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] #place_order,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] #place_order,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] #place_order,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select #place_order,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text #place_order,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select #place_order,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .woocommerce #place_order,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="text"] #place_order,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .woocommerce #place_order,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="email"] #place_order,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .woocommerce #place_order,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="password"] #place_order,
.login input.default-select[type="text"] .woocommerce #place_order,
.woocommerce .login input.default-select[type="text"] #place_order,
.login input.default-select[type="password"] .woocommerce #place_order,
.woocommerce .login input.default-select[type="password"] #place_order,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .woocommerce #place_order,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] #place_order,
.checkout.woocommerce-checkout input.default-select[type="text"] .woocommerce #place_order,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="text"] #place_order,
.checkout.woocommerce-checkout input.default-select[type="email"] .woocommerce #place_order,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="email"] #place_order,
.checkout.woocommerce-checkout input.default-select[type="tel"] .woocommerce #place_order,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="tel"] #place_order,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce #place_order,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice #place_order,
.checkout.woocommerce-checkout textarea.default-select .woocommerce #place_order,
.woocommerce .checkout.woocommerce-checkout textarea.default-select #place_order,
.woocommerce form .form-row input.default-select.input-text #place_order,
.woocommerce form .form-row .default-select.select2-selection #place_order,
.woocommerce form .form-row .default-select.select2-choice #place_order {
  background: transparent;
  border-width: 0 0 2px;
  border-radius: 0;
  padding: 15px 10px !important;
  border-color: #E1E1E1;
  padding: 10px 20px;
}

.form-control.default-select .btn:focus,
.woocommerce #review_form #respond input.default-select[type="text"] .btn:focus,
.woocommerce #review_form #respond textarea.default-select .btn:focus,
.woocommerce #review_form #respond input.default-select[type="email"] .btn:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .btn:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .btn:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .btn:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] .btn:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] .btn:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] .btn:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .btn:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text .btn:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .btn:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .btn:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .btn:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .btn:focus,
.login input.default-select[type="text"] .btn:focus,
.login input.default-select[type="password"] .btn:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .btn:focus,
.checkout.woocommerce-checkout input.default-select[type="text"] .btn:focus,
.checkout.woocommerce-checkout input.default-select[type="email"] .btn:focus,
.checkout.woocommerce-checkout input.default-select[type="tel"] .btn:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .btn:focus,
.checkout.woocommerce-checkout textarea.default-select .btn:focus,
.woocommerce form .form-row input.default-select.input-text .btn:focus,
.woocommerce form .form-row .default-select.select2-selection .btn:focus,
.woocommerce form .form-row .default-select.select2-choice .btn:focus,
.form-control.default-select .wp-block-button__link:focus,
.woocommerce #review_form #respond input.default-select[type="text"] .wp-block-button__link:focus,
.woocommerce #review_form #respond textarea.default-select .wp-block-button__link:focus,
.woocommerce #review_form #respond input.default-select[type="email"] .wp-block-button__link:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .wp-block-button__link:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .wp-block-button__link:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .wp-block-button__link:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] .wp-block-button__link:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] .wp-block-button__link:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] .wp-block-button__link:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .wp-block-button__link:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text .wp-block-button__link:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .wp-block-button__link:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .wp-block-button__link:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .wp-block-button__link:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .wp-block-button__link:focus,
.login input.default-select[type="text"] .wp-block-button__link:focus,
.login input.default-select[type="password"] .wp-block-button__link:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .wp-block-button__link:focus,
.checkout.woocommerce-checkout input.default-select[type="text"] .wp-block-button__link:focus,
.checkout.woocommerce-checkout input.default-select[type="email"] .wp-block-button__link:focus,
.checkout.woocommerce-checkout input.default-select[type="tel"] .wp-block-button__link:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .wp-block-button__link:focus,
.checkout.woocommerce-checkout textarea.default-select .wp-block-button__link:focus,
.woocommerce form .form-row input.default-select.input-text .wp-block-button__link:focus,
.woocommerce form .form-row .default-select.select2-selection .wp-block-button__link:focus,
.woocommerce form .form-row .default-select.select2-choice .wp-block-button__link:focus,
.form-control.default-select .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .form-control.default-select a:focus,
.woocommerce #review_form #respond input.default-select[type="text"] .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons #review_form #respond input.default-select[type="text"] a:focus,
.woocommerce #review_form #respond textarea.default-select .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons #review_form #respond textarea.default-select a:focus,
.woocommerce #review_form #respond input.default-select[type="email"] .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons #review_form #respond input.default-select[type="email"] a:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-cart-form .table tr .quantity input.default-select a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row textarea.default-select a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row select.default-select a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select.input-text a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select a:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type="text"] a:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type="email"] a:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type="password"] a:focus,
.login input.default-select[type="text"] .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .login input.default-select[type="text"] a:focus,
.login input.default-select[type="password"] .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .login input.default-select[type="password"] a:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] a:focus,
.checkout.woocommerce-checkout input.default-select[type="text"] .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type="text"] a:focus,
.checkout.woocommerce-checkout input.default-select[type="email"] .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type="email"] a:focus,
.checkout.woocommerce-checkout input.default-select[type="tel"] .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type="tel"] a:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout .default-select.select2-choice a:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout textarea.default-select a:focus,
.woocommerce form .form-row input.default-select.input-text .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons form .form-row input.default-select.input-text a:focus,
.woocommerce form .form-row .default-select.select2-selection .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons form .form-row .default-select.select2-selection a:focus,
.woocommerce form .form-row .default-select.select2-choice .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons form .form-row .default-select.select2-choice a:focus,
.form-control.default-select .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .form-control.default-select a:focus,
.woocommerce.widget_shopping_cart #review_form #respond input.default-select[type="text"] .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons #review_form #respond input.default-select[type="text"] a:focus,
.woocommerce.widget_shopping_cart #review_form #respond textarea.default-select .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons #review_form #respond textarea.default-select a:focus,
.woocommerce.widget_shopping_cart #review_form #respond input.default-select[type="email"] .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons #review_form #respond input.default-select[type="email"] a:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-cart-form .table tr .quantity input.default-select a:focus,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row textarea.default-select .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row textarea.default-select a:focus,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] a:focus,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] a:focus,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] a:focus,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row select.default-select .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row select.default-select a:focus,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row input.default-select.input-text .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select.input-text a:focus,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row input.default-select .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select a:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type="text"] a:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type="email"] a:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type="password"] a:focus,
.login input.default-select[type="text"] .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .login input.default-select[type="text"] a:focus,
.login input.default-select[type="password"] .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .login input.default-select[type="password"] a:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] a:focus,
.checkout.woocommerce-checkout input.default-select[type="text"] .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type="text"] a:focus,
.checkout.woocommerce-checkout input.default-select[type="email"] .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type="email"] a:focus,
.checkout.woocommerce-checkout input.default-select[type="tel"] .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type="tel"] a:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout .default-select.select2-choice a:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout textarea.default-select a:focus,
.woocommerce.widget_shopping_cart form .form-row input.default-select.input-text .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons form .form-row input.default-select.input-text a:focus,
.woocommerce.widget_shopping_cart form .form-row .default-select.select2-selection .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons form .form-row .default-select.select2-selection a:focus,
.woocommerce.widget_shopping_cart form .form-row .default-select.select2-choice .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons form .form-row .default-select.select2-choice a:focus,
.form-control.default-select .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .form-control.default-select input:focus,
.woocommerce #review_form #respond input.default-select[type="text"] .form-submit input:focus,
.woocommerce #review_form #respond .form-submit input.default-select[type="text"] input:focus,
.woocommerce #review_form #respond textarea.default-select .form-submit input:focus,
.woocommerce #review_form #respond .form-submit textarea.default-select input:focus,
.woocommerce #review_form #respond input.default-select[type="email"] .form-submit input:focus,
.woocommerce #review_form #respond .form-submit input.default-select[type="email"] input:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text input:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-cart-form .table tr .quantity input.default-select input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row textarea.default-select input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row select.default-select input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row input.default-select.input-text input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row input.default-select input:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-EditAccountForm.edit-account input.default-select[type="text"] input:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-EditAccountForm.edit-account input.default-select[type="email"] input:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-EditAccountForm.edit-account input.default-select[type="password"] input:focus,
.login input.default-select[type="text"] .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .login input.default-select[type="text"] input:focus,
.login input.default-select[type="password"] .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .login input.default-select[type="password"] input:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] input:focus,
.checkout.woocommerce-checkout input.default-select[type="text"] .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout input.default-select[type="text"] input:focus,
.checkout.woocommerce-checkout input.default-select[type="email"] .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout input.default-select[type="email"] input:focus,
.checkout.woocommerce-checkout input.default-select[type="tel"] .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout input.default-select[type="tel"] input:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout .default-select.select2-choice input:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout textarea.default-select input:focus,
.woocommerce form .form-row input.default-select.input-text #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit form .form-row input.default-select.input-text input:focus,
.woocommerce form .form-row .default-select.select2-selection #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit form .form-row .default-select.select2-selection input:focus,
.woocommerce form .form-row .default-select.select2-choice #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit form .form-row .default-select.select2-choice input:focus,
.form-control.default-select .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .form-control.default-select .button:focus,
.woocommerce #review_form #respond input.default-select[type="text"] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .woocommerce #review_form #respond input.default-select[type="text"] .button:focus,
.woocommerce #review_form #respond textarea.default-select .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .woocommerce #review_form #respond textarea.default-select .button:focus,
.woocommerce #review_form #respond input.default-select[type="email"] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .woocommerce #review_form #respond input.default-select[type="email"] .button:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .button:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] .button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] .button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] .button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text .button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .button:focus,
.login input.default-select[type="text"] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .login input.default-select[type="text"] .button:focus,
.login input.default-select[type="password"] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .login input.default-select[type="password"] .button:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .button:focus,
.checkout.woocommerce-checkout input.default-select[type="text"] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout input.default-select[type="text"] .button:focus,
.checkout.woocommerce-checkout input.default-select[type="email"] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout input.default-select[type="email"] .button:focus,
.checkout.woocommerce-checkout input.default-select[type="tel"] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout input.default-select[type="tel"] .button:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout .default-select.select2-choice .button:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout textarea.default-select .button:focus,
.woocommerce form .form-row input.default-select.input-text .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .woocommerce form .form-row input.default-select.input-text .button:focus,
.woocommerce form .form-row .default-select.select2-selection .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .woocommerce form .form-row .default-select.select2-selection .button:focus,
.woocommerce form .form-row .default-select.select2-choice .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .woocommerce form .form-row .default-select.select2-choice .button:focus,
.form-control.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .form-control.default-select a.checkout-button:focus,
.woocommerce #review_form #respond input.default-select[type="text"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #review_form #respond input.default-select[type="text"] a.checkout-button:focus,
.woocommerce #review_form #respond textarea.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #review_form #respond textarea.default-select a.checkout-button:focus,
.woocommerce #review_form #respond input.default-select[type="email"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #review_form #respond input.default-select[type="email"] a.checkout-button:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a.checkout-button:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-cart-form .table tr .quantity input.default-select a.checkout-button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select a.checkout-button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] a.checkout-button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] a.checkout-button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] a.checkout-button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row select.default-select a.checkout-button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text a.checkout-button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select a.checkout-button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-EditAccountForm.edit-account input.default-select[type="text"] a.checkout-button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-EditAccountForm.edit-account input.default-select[type="email"] a.checkout-button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-EditAccountForm.edit-account input.default-select[type="password"] a.checkout-button:focus,
.login input.default-select[type="text"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .login input.default-select[type="text"] a.checkout-button:focus,
.login input.default-select[type="password"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .login input.default-select[type="password"] a.checkout-button:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] a.checkout-button:focus,
.checkout.woocommerce-checkout input.default-select[type="text"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout input.default-select[type="text"] a.checkout-button:focus,
.checkout.woocommerce-checkout input.default-select[type="email"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout input.default-select[type="email"] a.checkout-button:focus,
.checkout.woocommerce-checkout input.default-select[type="tel"] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout input.default-select[type="tel"] a.checkout-button:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout .default-select.select2-choice a.checkout-button:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout textarea.default-select a.checkout-button:focus,
.woocommerce form .form-row input.default-select.input-text .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce form .form-row input.default-select.input-text a.checkout-button:focus,
.woocommerce form .form-row .default-select.select2-selection .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce form .form-row .default-select.select2-selection a.checkout-button:focus,
.woocommerce form .form-row .default-select.select2-choice .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce form .form-row .default-select.select2-choice a.checkout-button:focus,
.form-control.default-select .woocommerce #respond input#submit:focus,
.woocommerce #respond .form-control.default-select input#submit:focus,
.woocommerce #review_form #respond input.default-select[type="text"] input#submit:focus,
.woocommerce #review_form #respond textarea.default-select input#submit:focus,
.woocommerce #review_form #respond input.default-select[type="email"] input#submit:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce #respond input#submit:focus,
.woocommerce #respond .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text input#submit:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce #respond input#submit:focus,
.woocommerce #respond .woocommerce-cart-form .table tr .quantity input.default-select input#submit:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select #respond input#submit:focus,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row textarea.default-select input#submit:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] #respond input#submit:focus,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] input#submit:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] #respond input#submit:focus,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] input#submit:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] #respond input#submit:focus,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] input#submit:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select #respond input#submit:focus,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row select.default-select input#submit:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text #respond input#submit:focus,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row input.default-select.input-text input#submit:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select #respond input#submit:focus,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row input.default-select input#submit:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .woocommerce #respond input#submit:focus,
.woocommerce #respond .woocommerce-EditAccountForm.edit-account input.default-select[type="text"] input#submit:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .woocommerce #respond input#submit:focus,
.woocommerce #respond .woocommerce-EditAccountForm.edit-account input.default-select[type="email"] input#submit:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .woocommerce #respond input#submit:focus,
.woocommerce #respond .woocommerce-EditAccountForm.edit-account input.default-select[type="password"] input#submit:focus,
.login input.default-select[type="text"] .woocommerce #respond input#submit:focus,
.woocommerce #respond .login input.default-select[type="text"] input#submit:focus,
.login input.default-select[type="password"] .woocommerce #respond input#submit:focus,
.woocommerce #respond .login input.default-select[type="password"] input#submit:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .woocommerce #respond input#submit:focus,
.woocommerce #respond .woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] input#submit:focus,
.checkout.woocommerce-checkout input.default-select[type="text"] .woocommerce #respond input#submit:focus,
.woocommerce #respond .checkout.woocommerce-checkout input.default-select[type="text"] input#submit:focus,
.checkout.woocommerce-checkout input.default-select[type="email"] .woocommerce #respond input#submit:focus,
.woocommerce #respond .checkout.woocommerce-checkout input.default-select[type="email"] input#submit:focus,
.checkout.woocommerce-checkout input.default-select[type="tel"] .woocommerce #respond input#submit:focus,
.woocommerce #respond .checkout.woocommerce-checkout input.default-select[type="tel"] input#submit:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce #respond input#submit:focus,
.woocommerce #respond .checkout.woocommerce-checkout .default-select.select2-choice input#submit:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce #respond input#submit:focus,
.woocommerce #respond .checkout.woocommerce-checkout textarea.default-select input#submit:focus,
.woocommerce form .form-row input.default-select.input-text #respond input#submit:focus,
.woocommerce #respond form .form-row input.default-select.input-text input#submit:focus,
.woocommerce form .form-row .default-select.select2-selection #respond input#submit:focus,
.woocommerce #respond form .form-row .default-select.select2-selection input#submit:focus,
.woocommerce form .form-row .default-select.select2-choice #respond input#submit:focus,
.woocommerce #respond form .form-row .default-select.select2-choice input#submit:focus,
.form-control.default-select .woocommerce a.button:focus,
.woocommerce .form-control.default-select a.button:focus,
.woocommerce #review_form #respond input.default-select[type="text"] a.button:focus,
.woocommerce #review_form #respond textarea.default-select a.button:focus,
.woocommerce #review_form #respond input.default-select[type="email"] a.button:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce a.button:focus,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a.button:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce a.button:focus,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select a.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select a.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] a.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] a.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] a.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select a.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text a.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select a.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .woocommerce a.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="text"] a.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .woocommerce a.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="email"] a.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .woocommerce a.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="password"] a.button:focus,
.login input.default-select[type="text"] .woocommerce a.button:focus,
.woocommerce .login input.default-select[type="text"] a.button:focus,
.login input.default-select[type="password"] .woocommerce a.button:focus,
.woocommerce .login input.default-select[type="password"] a.button:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .woocommerce a.button:focus,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] a.button:focus,
.checkout.woocommerce-checkout input.default-select[type="text"] .woocommerce a.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="text"] a.button:focus,
.checkout.woocommerce-checkout input.default-select[type="email"] .woocommerce a.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="email"] a.button:focus,
.checkout.woocommerce-checkout input.default-select[type="tel"] .woocommerce a.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="tel"] a.button:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce a.button:focus,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice a.button:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce a.button:focus,
.woocommerce .checkout.woocommerce-checkout textarea.default-select a.button:focus,
.woocommerce form .form-row input.default-select.input-text a.button:focus,
.woocommerce form .form-row .default-select.select2-selection a.button:focus,
.woocommerce form .form-row .default-select.select2-choice a.button:focus,
.form-control.default-select .woocommerce button.button:focus,
.woocommerce .form-control.default-select button.button:focus,
.woocommerce #review_form #respond input.default-select[type="text"] button.button:focus,
.woocommerce #review_form #respond textarea.default-select button.button:focus,
.woocommerce #review_form #respond input.default-select[type="email"] button.button:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce button.button:focus,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text button.button:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce button.button:focus,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select button.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select button.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] button.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] button.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] button.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select button.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text button.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select button.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .woocommerce button.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="text"] button.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .woocommerce button.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="email"] button.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .woocommerce button.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="password"] button.button:focus,
.login input.default-select[type="text"] .woocommerce button.button:focus,
.woocommerce .login input.default-select[type="text"] button.button:focus,
.login input.default-select[type="password"] .woocommerce button.button:focus,
.woocommerce .login input.default-select[type="password"] button.button:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .woocommerce button.button:focus,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] button.button:focus,
.checkout.woocommerce-checkout input.default-select[type="text"] .woocommerce button.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="text"] button.button:focus,
.checkout.woocommerce-checkout input.default-select[type="email"] .woocommerce button.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="email"] button.button:focus,
.checkout.woocommerce-checkout input.default-select[type="tel"] .woocommerce button.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="tel"] button.button:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce button.button:focus,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice button.button:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce button.button:focus,
.woocommerce .checkout.woocommerce-checkout textarea.default-select button.button:focus,
.woocommerce form .form-row input.default-select.input-text button.button:focus,
.woocommerce form .form-row .default-select.select2-selection button.button:focus,
.woocommerce form .form-row .default-select.select2-choice button.button:focus,
.form-control.default-select .woocommerce input.button:focus,
.woocommerce .form-control.default-select input.button:focus,
.woocommerce #review_form #respond input.default-select[type="text"] input.button:focus,
.woocommerce #review_form #respond textarea.default-select input.button:focus,
.woocommerce #review_form #respond input.default-select[type="email"] input.button:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce input.button:focus,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text input.button:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce input.button:focus,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select input.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select input.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] input.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] input.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] input.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select input.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text input.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select input.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .woocommerce input.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="text"] input.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .woocommerce input.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="email"] input.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .woocommerce input.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="password"] input.button:focus,
.login input.default-select[type="text"] .woocommerce input.button:focus,
.woocommerce .login input.default-select[type="text"] input.button:focus,
.login input.default-select[type="password"] .woocommerce input.button:focus,
.woocommerce .login input.default-select[type="password"] input.button:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .woocommerce input.button:focus,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] input.button:focus,
.checkout.woocommerce-checkout input.default-select[type="text"] .woocommerce input.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="text"] input.button:focus,
.checkout.woocommerce-checkout input.default-select[type="email"] .woocommerce input.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="email"] input.button:focus,
.checkout.woocommerce-checkout input.default-select[type="tel"] .woocommerce input.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="tel"] input.button:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce input.button:focus,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice input.button:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce input.button:focus,
.woocommerce .checkout.woocommerce-checkout textarea.default-select input.button:focus,
.woocommerce form .form-row input.default-select.input-text input.button:focus,
.woocommerce form .form-row .default-select.select2-selection input.button:focus,
.woocommerce form .form-row .default-select.select2-choice input.button:focus,
.form-control.default-select .woocommerce #place_order:focus,
.woocommerce .form-control.default-select #place_order:focus,
.woocommerce #review_form #respond input.default-select[type="text"] #place_order:focus,
.woocommerce #review_form #respond textarea.default-select #place_order:focus,
.woocommerce #review_form #respond input.default-select[type="email"] #place_order:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce #place_order:focus,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text #place_order:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce #place_order:focus,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select #place_order:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select #place_order:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="text"] #place_order:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="email"] #place_order:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select[type="tel"] #place_order:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select #place_order:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select.input-text #place_order:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select #place_order:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .woocommerce #place_order:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="text"] #place_order:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .woocommerce #place_order:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="email"] #place_order:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .woocommerce #place_order:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type="password"] #place_order:focus,
.login input.default-select[type="text"] .woocommerce #place_order:focus,
.woocommerce .login input.default-select[type="text"] #place_order:focus,
.login input.default-select[type="password"] .woocommerce #place_order:focus,
.woocommerce .login input.default-select[type="password"] #place_order:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .woocommerce #place_order:focus,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] #place_order:focus,
.checkout.woocommerce-checkout input.default-select[type="text"] .woocommerce #place_order:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="text"] #place_order:focus,
.checkout.woocommerce-checkout input.default-select[type="email"] .woocommerce #place_order:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="email"] #place_order:focus,
.checkout.woocommerce-checkout input.default-select[type="tel"] .woocommerce #place_order:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type="tel"] #place_order:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce #place_order:focus,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice #place_order:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce #place_order:focus,
.woocommerce .checkout.woocommerce-checkout textarea.default-select #place_order:focus,
.woocommerce form .form-row input.default-select.input-text #place_order:focus,
.woocommerce form .form-row .default-select.select2-selection #place_order:focus,
.woocommerce form .form-row .default-select.select2-choice #place_order:focus {
  outline: 0 !important;
}

.form-control.default-select .filter-option-inner-inner,
.woocommerce #review_form #respond input.default-select[type="text"] .filter-option-inner-inner,
.woocommerce #review_form #respond textarea.default-select .filter-option-inner-inner,
.woocommerce #review_form #respond input.default-select[type="email"] .filter-option-inner-inner,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .filter-option-inner-inner,
.woocommerce-cart-form .table tr .quantity input.default-select .filter-option-inner-inner,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .filter-option-inner-inner,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .filter-option-inner-inner,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .filter-option-inner-inner,
.woocommerce-EditAccountForm.edit-account input.default-select[type="text"] .filter-option-inner-inner,
.woocommerce-EditAccountForm.edit-account input.default-select[type="email"] .filter-option-inner-inner,
.woocommerce-EditAccountForm.edit-account input.default-select[type="password"] .filter-option-inner-inner,
.login input.default-select[type="text"] .filter-option-inner-inner,
.login input.default-select[type="password"] .filter-option-inner-inner,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type="text"] .filter-option-inner-inner,
.checkout.woocommerce-checkout input.default-select[type="text"] .filter-option-inner-inner,
.checkout.woocommerce-checkout input.default-select[type="email"] .filter-option-inner-inner,
.checkout.woocommerce-checkout input.default-select[type="tel"] .filter-option-inner-inner,
.checkout.woocommerce-checkout .default-select.select2-choice .filter-option-inner-inner,
.checkout.woocommerce-checkout textarea.default-select .filter-option-inner-inner,
.woocommerce form .form-row input.default-select.input-text .filter-option-inner-inner,
.woocommerce form .form-row .default-select.select2-selection .filter-option-inner-inner,
.woocommerce form .form-row .default-select.select2-choice .filter-option-inner-inner {
  font-weight: 600;
  font-size: 16px;
}

.shop-form .form-group {
  margin-bottom: 25px;
}

.shop-form .form-group .bootstrap-select {
  width: 100% !important;
}

.shop-form .form-group .bootstrap-select .btn,
.shop-form .form-group .bootstrap-select .wp-block-button__link,
.shop-form .form-group .bootstrap-select .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .shop-form .form-group .bootstrap-select a,
.shop-form .form-group .bootstrap-select .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .shop-form .form-group .bootstrap-select a,
.shop-form .form-group .bootstrap-select .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .shop-form .form-group .bootstrap-select input,
.shop-form .form-group .bootstrap-select .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .shop-form .form-group .bootstrap-select .button,
.shop-form .form-group .bootstrap-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .shop-form .form-group .bootstrap-select a.checkout-button,
.shop-form .form-group .bootstrap-select .woocommerce #respond input#submit,
.woocommerce #respond .shop-form .form-group .bootstrap-select input#submit,
.shop-form .form-group .bootstrap-select .woocommerce a.button,
.woocommerce .shop-form .form-group .bootstrap-select a.button,
.shop-form .form-group .bootstrap-select .woocommerce button.button,
.woocommerce .shop-form .form-group .bootstrap-select button.button,
.shop-form .form-group .bootstrap-select .woocommerce input.button,
.woocommerce .shop-form .form-group .bootstrap-select input.button,
.shop-form .form-group .bootstrap-select .woocommerce #place_order,
.woocommerce .shop-form .form-group .bootstrap-select #place_order {
  font-weight: 300;
  color: #000;
}

.shop-form .form-group .bootstrap-select .btn.btn-light,
.shop-form .form-group .bootstrap-select .btn-light.wp-block-button__link,
.shop-form .form-group .bootstrap-select .woocommerce .widget_shopping_cart .buttons a.btn-light,
.woocommerce .widget_shopping_cart .buttons .shop-form .form-group .bootstrap-select a.btn-light,
.shop-form .form-group .bootstrap-select .woocommerce.widget_shopping_cart .buttons a.btn-light,
.woocommerce.widget_shopping_cart .buttons .shop-form .form-group .bootstrap-select a.btn-light,
.shop-form .form-group .bootstrap-select .woocommerce #review_form #respond .form-submit input.btn-light,
.woocommerce #review_form #respond .form-submit .shop-form .form-group .bootstrap-select input.btn-light,
.shop-form .form-group .bootstrap-select .woocommerce-cart-form .table tr .btn-light.button,
.woocommerce-cart-form .table tr .shop-form .form-group .bootstrap-select .btn-light.button,
.shop-form .form-group .bootstrap-select .woocommerce-cart .wc-proceed-to-checkout a.btn-light.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .shop-form .form-group .bootstrap-select a.btn-light.checkout-button,
.shop-form .form-group .bootstrap-select .woocommerce #respond input.btn-light#submit,
.woocommerce #respond .shop-form .form-group .bootstrap-select input.btn-light#submit,
.shop-form .form-group .bootstrap-select .woocommerce a.btn-light.button,
.woocommerce .shop-form .form-group .bootstrap-select a.btn-light.button,
.shop-form .form-group .bootstrap-select .woocommerce button.btn-light.button,
.woocommerce .shop-form .form-group .bootstrap-select button.btn-light.button,
.shop-form .form-group .bootstrap-select .woocommerce input.btn-light.button,
.woocommerce .shop-form .form-group .bootstrap-select input.btn-light.button,
.shop-form .form-group .bootstrap-select .woocommerce .btn-light#place_order,
.woocommerce .shop-form .form-group .bootstrap-select .btn-light#place_order {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.shop-form .form-group .bootstrap-select .btn.btn-light:hover,
.shop-form .form-group .bootstrap-select .btn-light.wp-block-button__link:hover,
.shop-form .form-group .bootstrap-select .woocommerce .widget_shopping_cart .buttons a.btn-light:hover,
.woocommerce .widget_shopping_cart .buttons .shop-form .form-group .bootstrap-select a.btn-light:hover,
.shop-form .form-group .bootstrap-select .woocommerce.widget_shopping_cart .buttons a.btn-light:hover,
.woocommerce.widget_shopping_cart .buttons .shop-form .form-group .bootstrap-select a.btn-light:hover,
.shop-form .form-group .bootstrap-select .woocommerce #review_form #respond .form-submit input.btn-light:hover,
.woocommerce #review_form #respond .form-submit .shop-form .form-group .bootstrap-select input.btn-light:hover,
.shop-form .form-group .bootstrap-select .woocommerce-cart-form .table tr .btn-light.button:hover,
.woocommerce-cart-form .table tr .shop-form .form-group .bootstrap-select .btn-light.button:hover,
.shop-form .form-group .bootstrap-select .woocommerce-cart .wc-proceed-to-checkout a.btn-light.checkout-button:hover,
.woocommerce-cart .wc-proceed-to-checkout .shop-form .form-group .bootstrap-select a.btn-light.checkout-button:hover,
.shop-form .form-group .bootstrap-select .woocommerce #respond input.btn-light#submit:hover,
.woocommerce #respond .shop-form .form-group .bootstrap-select input.btn-light#submit:hover,
.shop-form .form-group .bootstrap-select .woocommerce a.btn-light.button:hover,
.woocommerce .shop-form .form-group .bootstrap-select a.btn-light.button:hover,
.shop-form .form-group .bootstrap-select .woocommerce button.btn-light.button:hover,
.woocommerce .shop-form .form-group .bootstrap-select button.btn-light.button:hover,
.shop-form .form-group .bootstrap-select .woocommerce input.btn-light.button:hover,
.woocommerce .shop-form .form-group .bootstrap-select input.btn-light.button:hover,
.shop-form .form-group .bootstrap-select .woocommerce .btn-light#place_order:hover,
.woocommerce .shop-form .form-group .bootstrap-select .btn-light#place_order:hover {
  background-color: whitesmoke;
  border-color: 1px solid rgba(0, 0, 0, 0.125);
}

.shop-form .form-group .bootstrap-select .btn.btn-light:focus,
.shop-form .form-group .bootstrap-select .btn-light.wp-block-button__link:focus,
.shop-form .form-group .bootstrap-select .woocommerce .widget_shopping_cart .buttons a.btn-light:focus,
.woocommerce .widget_shopping_cart .buttons .shop-form .form-group .bootstrap-select a.btn-light:focus,
.shop-form .form-group .bootstrap-select .woocommerce.widget_shopping_cart .buttons a.btn-light:focus,
.woocommerce.widget_shopping_cart .buttons .shop-form .form-group .bootstrap-select a.btn-light:focus,
.shop-form .form-group .bootstrap-select .woocommerce #review_form #respond .form-submit input.btn-light:focus,
.woocommerce #review_form #respond .form-submit .shop-form .form-group .bootstrap-select input.btn-light:focus,
.shop-form .form-group .bootstrap-select .woocommerce-cart-form .table tr .btn-light.button:focus,
.woocommerce-cart-form .table tr .shop-form .form-group .bootstrap-select .btn-light.button:focus,
.shop-form .form-group .bootstrap-select .woocommerce-cart .wc-proceed-to-checkout a.btn-light.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .shop-form .form-group .bootstrap-select a.btn-light.checkout-button:focus,
.shop-form .form-group .bootstrap-select .woocommerce #respond input.btn-light#submit:focus,
.woocommerce #respond .shop-form .form-group .bootstrap-select input.btn-light#submit:focus,
.shop-form .form-group .bootstrap-select .woocommerce a.btn-light.button:focus,
.woocommerce .shop-form .form-group .bootstrap-select a.btn-light.button:focus,
.shop-form .form-group .bootstrap-select .woocommerce button.btn-light.button:focus,
.woocommerce .shop-form .form-group .bootstrap-select button.btn-light.button:focus,
.shop-form .form-group .bootstrap-select .woocommerce input.btn-light.button:focus,
.woocommerce .shop-form .form-group .bootstrap-select input.btn-light.button:focus,
.shop-form .form-group .bootstrap-select .woocommerce .btn-light#place_order:focus,
.woocommerce .shop-form .form-group .bootstrap-select .btn-light#place_order:focus {
  outline: 0 !important;
}

@media only screen and (max-width: 991px) {
  .shop-form {
    margin-top: 25px;
  }
}

.login-area {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 30px;
  height: 100%;
  border-radius: 6px;
}

.dz-ajax-overlay {
  position: relative;
}

.dz-ajax-overlay:after {
  content: "Loading...";
  height: 100%;
  width: 100%;
  background-color: #000;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  border-radius: 5px;
  opacity: 0.8;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 14px;
  font-style: italic;
}

button:hover,
button:active,
button:focus {
  outline: 0;
}

.btn,
.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons a,
.woocommerce #review_form #respond .form-submit input,
.woocommerce-cart-form .table tr .button,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce #place_order {
  font-size: 14px;
  padding: 12.5px 20px;
  line-height: 1.5;
  display: inline-flex;
  border-radius: var(--border-radius-base);
  font-weight: 500;
  position: relative;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  align-items: center;
  overflow: hidden;
  z-index: 1;
  text-align: center;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  justify-content: center;
}

.btn.btn-lg,
.btn-group-lg>.btn,
.btn-group-lg>.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-group-lg>a,
.woocommerce.widget_shopping_cart .buttons .btn-group-lg>a,
.woocommerce #review_form #respond .form-submit .btn-group-lg>input,
.woocommerce-cart-form .table tr .btn-group-lg>.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-lg>a.checkout-button,
.woocommerce #respond .btn-group-lg>input#submit,
.woocommerce .btn-group-lg>a.button,
.woocommerce .btn-group-lg>button.button,
.woocommerce .btn-group-lg>input.button,
.woocommerce .btn-group-lg>#place_order,
.btn-lg.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons a.btn-lg,
.woocommerce.widget_shopping_cart .buttons a.btn-lg,
.woocommerce #review_form #respond .form-submit input.btn-lg,
.woocommerce-cart-form .table tr .btn-lg.button,
.woocommerce-cart .wc-proceed-to-checkout a.btn-lg.checkout-button,
.woocommerce #respond input.btn-lg#submit,
.woocommerce a.btn-lg.button,
.woocommerce button.btn-lg.button,
.woocommerce input.btn-lg.button,
.woocommerce .btn-lg#place_order {
  padding: 24px 40px;
  font-size: 16px;
}

.btn.btn-sm,
.btn-group-sm>.btn,
.btn-group-sm>.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-group-sm>a,
.woocommerce.widget_shopping_cart .buttons .btn-group-sm>a,
.woocommerce #review_form #respond .form-submit .btn-group-sm>input,
.woocommerce-cart-form .table tr .btn-group-sm>.button,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-sm>a.checkout-button,
.woocommerce #respond .btn-group-sm>input#submit,
.woocommerce .btn-group-sm>a.button,
.woocommerce .btn-group-sm>button.button,
.woocommerce .btn-group-sm>input.button,
.woocommerce .btn-group-sm>#place_order,
.btn-sm.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons a.btn-sm,
.woocommerce.widget_shopping_cart .buttons a.btn-sm,
.woocommerce #review_form #respond .form-submit input.btn-sm,
.woocommerce-cart-form .table tr .btn-sm.button,
.woocommerce-cart .wc-proceed-to-checkout a.btn-sm.checkout-button,
.woocommerce #respond input.btn-sm#submit,
.woocommerce a.btn-sm.button,
.woocommerce button.btn-sm.button,
.woocommerce input.btn-sm.button,
.woocommerce .btn-sm#place_order {
  font-size: 13px;
  padding: 8px 15px;
}

.btn.btn-xs,
.btn-xs.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons a.btn-xs,
.woocommerce.widget_shopping_cart .buttons a.btn-xs,
.woocommerce #review_form #respond .form-submit input.btn-xs,
.woocommerce-cart-form .table tr .btn-xs.button,
.woocommerce-cart .wc-proceed-to-checkout a.btn-xs.checkout-button,
.woocommerce #respond input.btn-xs#submit,
.woocommerce a.btn-xs.button,
.woocommerce button.btn-xs.button,
.woocommerce input.btn-xs.button,
.woocommerce .btn-xs#place_order {
  font-size: 14px;
  padding: 11px 25px;
}

.btn.btn-primary,
.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons a,
.woocommerce #review_form #respond .form-submit input,
.woocommerce-cart-form .table tr .button,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce #place_order {
  color: #ffffff;
}

.btn.btn-primary:hover,
.wp-block-button__link:hover,
.woocommerce .widget_shopping_cart .buttons a:hover,
.woocommerce.widget_shopping_cart .buttons a:hover,
.woocommerce #review_form #respond .form-submit input:hover,
.woocommerce-cart-form .table tr .button:hover,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:hover,
.woocommerce #respond input#submit:hover,
.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce input.button:hover,
.woocommerce #place_order:hover {
  color: #ffffff;
}

.btn-success,
.btn-danger,
.btn-warning {
  color: #fff;
}

.btn-success:hover,
.btn-danger:hover,
.btn-warning:hover {
  color: #fff;
}

.btn-success.effect:after,
.btn-info.effect:after,
.btn-danger.effect:after,
.btn-warning.effect:after {
  background: var(--secondary);
}

.btn-rounded {
  border-radius: 30px !important;
}

.btn-link {
  text-transform: uppercase;
  font-family: var(--font-family-base);
  position: relative;
  z-index: 1;
  display: inline-block;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  padding: 5px 0 5px 0;
}

.shadow-primary {
  box-shadow: 0px 19px 29px rgba(220, 181, 145, 0.34);
}

.btn-info {
  color: #fff;
}

.btn-info:hover {
  color: #fff;
}

.btn-white {
  background-color: #fff;
  color: var(--primary);
}

.btn-white:hover {
  color: var(--primary);
}

.btn-secondary {
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-secondary:after {
  background-color: var(--primary);
}

.btn-primary,
.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons a,
.woocommerce #review_form #respond .form-submit input,
.woocommerce-cart-form .table tr .button,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce #place_order {
  border-color: var(--primary);
  background-color: var(--primary);
}

.btn-primary.disabled,
.disabled.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons a.disabled,
.woocommerce.widget_shopping_cart .buttons a.disabled,
.woocommerce #review_form #respond .form-submit input.disabled,
.woocommerce-cart-form .table tr .disabled.button,
.woocommerce-cart .wc-proceed-to-checkout a.disabled.checkout-button,
.woocommerce #respond input.disabled#submit,
.woocommerce a.disabled.button,
.woocommerce button.disabled.button,
.woocommerce input.disabled.button,
.woocommerce .disabled#place_order,
.btn-primary:disabled,
.wp-block-button__link:disabled,
.woocommerce .widget_shopping_cart .buttons a:disabled,
.woocommerce.widget_shopping_cart .buttons a:disabled,
.woocommerce #review_form #respond .form-submit input:disabled,
.woocommerce-cart-form .table tr .button:disabled,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:disabled,
.woocommerce #respond input#submit:disabled,
.woocommerce a.button:disabled,
.woocommerce button.button:disabled,
.woocommerce input.button:disabled,
.woocommerce #place_order:disabled,
.btn-primary:not(:disabled):not(.disabled).active,
.wp-block-button__link:not(:disabled):not(.disabled).active,
.woocommerce .widget_shopping_cart .buttons a:not(:disabled):not(.disabled).active,
.woocommerce.widget_shopping_cart .buttons a:not(:disabled):not(.disabled).active,
.woocommerce #review_form #respond .form-submit input:not(:disabled):not(.disabled).active,
.woocommerce-cart-form .table tr .button:not(:disabled):not(.disabled).active,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:not(:disabled):not(.disabled).active,
.woocommerce #respond input#submit:not(:disabled):not(.disabled).active,
.woocommerce a.button:not(:disabled):not(.disabled).active,
.woocommerce button.button:not(:disabled):not(.disabled).active,
.woocommerce input.button:not(:disabled):not(.disabled).active,
.woocommerce #place_order:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.wp-block-button__link:not(:disabled):not(.disabled):active,
.woocommerce .widget_shopping_cart .buttons a:not(:disabled):not(.disabled):active,
.woocommerce.widget_shopping_cart .buttons a:not(:disabled):not(.disabled):active,
.woocommerce #review_form #respond .form-submit input:not(:disabled):not(.disabled):active,
.woocommerce-cart-form .table tr .button:not(:disabled):not(.disabled):active,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:not(:disabled):not(.disabled):active,
.woocommerce #respond input#submit:not(:disabled):not(.disabled):active,
.woocommerce a.button:not(:disabled):not(.disabled):active,
.woocommerce button.button:not(:disabled):not(.disabled):active,
.woocommerce input.button:not(:disabled):not(.disabled):active,
.woocommerce #place_order:not(:disabled):not(.disabled):active,
.btn-primary:active,
.wp-block-button__link:active,
.woocommerce .widget_shopping_cart .buttons a:active,
.woocommerce.widget_shopping_cart .buttons a:active,
.woocommerce #review_form #respond .form-submit input:active,
.woocommerce-cart-form .table tr .button:active,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:active,
.woocommerce #respond input#submit:active,
.woocommerce a.button:active,
.woocommerce button.button:active,
.woocommerce input.button:active,
.woocommerce #place_order:active,
.btn-primary:focus,
.wp-block-button__link:focus,
.woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce #review_form #respond .form-submit input:focus,
.woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce #respond input#submit:focus,
.woocommerce a.button:focus,
.woocommerce button.button:focus,
.woocommerce input.button:focus,
.woocommerce #place_order:focus,
.btn-primary:hover,
.wp-block-button__link:hover,
.woocommerce .widget_shopping_cart .buttons a:hover,
.woocommerce.widget_shopping_cart .buttons a:hover,
.woocommerce #review_form #respond .form-submit input:hover,
.woocommerce-cart-form .table tr .button:hover,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:hover,
.woocommerce #respond input#submit:hover,
.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce input.button:hover,
.woocommerce #place_order:hover {
  border-color: var(--primary-hover);
  background-color: var(--primary-hover);
}

.btn-primary:after,
.wp-block-button__link:after,
.woocommerce .widget_shopping_cart .buttons a:after,
.woocommerce.widget_shopping_cart .buttons a:after,
.woocommerce #review_form #respond .form-submit input:after,
.woocommerce-cart-form .table tr .button:after,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:after,
.woocommerce #respond input#submit:after,
.woocommerce a.button:after,
.woocommerce button.button:after,
.woocommerce input.button:after,
.woocommerce #place_order:after {
  background-color: var(--secondary);
}

.btn-link {
  color: var(--primary);
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 500;
}

.btn-link:hover {
  color: var(--primary-hover);
}

.btn-outline-primary,
.is-style-outline .wp-block-button__link {
  color: var(--primary);
  border-color: var(--primary);
}

.btn-outline-primary.disabled,
.is-style-outline .disabled.wp-block-button__link,
.btn-outline-primary:disabled,
.is-style-outline .wp-block-button__link:disabled,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.is-style-outline .wp-block-button__link:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.is-style-outline .wp-block-button__link:not(:disabled):not(.disabled):active,
.btn-outline-primary:active,
.is-style-outline .wp-block-button__link:active,
.btn-outline-primary:focus,
.is-style-outline .wp-block-button__link:focus,
.btn-outline-primary:hover,
.is-style-outline .wp-block-button__link:hover {
  border-color: var(--primary-hover);
  background-color: var(--primary-hover);
  color: #ffffff;
}

.btn-link-lg {
  font-weight: 700;
  font-size: 18px;
}

.btn-link-lg:hover {
  text-decoration: unset;
}

.btn-light:hover {
  background-color: #ffb4ed;
  color: #131045 !important;
  border-color: #ffb4ed;
}

.btn-light.text-primary:hover {
  color: #ffffff !important;
}

.wp-block-button {
  margin-bottom: 0.3125rem;
}

.is-style-squared .wp-block-button__link {
  border-radius: 0;
}

.wp-block-button__link {
  border-radius: 60px;
}

.wp-block-button__link:after {
  content: none;
}

.bookmark-btn .form-check-label {
  height: 48px;
  width: 48px;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  line-height: 48px;
  border-radius: var(--border-radius-base);
  border: 1px solid #F0F0F0;
  background: #fff;
}

.bookmark-btn .form-check-input {
  display: none;
}

.bookmark-btn .form-check-input:checked+.form-check-label {
  color: #ffffff;
  background-color: var(--bs-danger);
  border: 1px solid var(--bs-danger);
}

.bookmark-btn.style-1 .form-check-label {
  margin-left: 10px;
}

.bookmark-btn.style-2 .form-check-label {
  position: absolute;
  top: 30px;
  right: 30px;
}

@media only screen and (max-width: 991px) {
  .bookmark-btn.style-2 .form-check-label {
    top: 25px;
    right: 25px;
  }
}

@media only screen and (max-width: 575px) {
  .bookmark-btn.style-2 .form-check-label {
    top: 30px;
    right: 30px;
  }
}

.panel-btn {
  display: none;
  padding: 5px 18px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 1024px) {
  .panel-btn {
    display: block;
  }
}

.panel-close-btn {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .panel-close-btn {
    display: block;
  }
}

.btnhover {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.btnhover:before,
.btnhover:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
  opacity: 1;
  -webkit-transform: translate(-105%, 0);
  transform: translate(-105%, 0);
  border-right-width: 2px;
  border-right-style: solid;
  border-right-color: white;
  background-color: rgba(255, 255, 255, 0.5);
}

.btnhover:after {
  -webkit-transition-delay: 0.75s;
  /* Safari */
  transition-delay: 0.75s;
}

.btnhover:hover:before,
.btnhover:hover:after {
  opacity: 0;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.btnhover2:hover i {
  -webkit-animation: upToDown 0.5s forwards;
  -moz-animation: upToDown 0.5s forwards;
  animation: upToDown 0.5s forwards;
}

.btnhover3:hover i {
  -webkit-animation: upToRight 0.5s forwards;
  -moz-animation: upToRight 0.5s forwards;
  animation: upToRight 0.5s forwards;
}

.badge {
  padding: 6px;
  font-weight: var(--headings-font-weight);
  background-color: var(--primary);
  color: white;
  border-radius: var(--border-radius-base);
  align-self: center;
  font-family: var(--font-family-title);
  font-size: 11px;
  min-width: 22px;
  height: 22px;
  text-align: center;
}

.badge:hover {
  color: #fff;
}

.badge.badge-primary {
  background-color: var(--primary);
}

.badge.badge-secondary {
  background-color: #1a1668;
}

.badge.badge-info {
  background-color: #00aeff;
}

.badge.badge-success {
  background-color: #029e76;
}

.badge.badge-warning {
  background-color: #fea500;
}

.badge.badge-danger {
  background-color: #FF1E6F;
}

.badge.badge-light {
  background-color: #f5f5f5;
}

.table-responsive-sm {
  min-width: 48rem;
}

.table {
  margin-bottom: 0px;
  font-weight: 400;
}

.table tr td,
.table tr th {
  padding: 10px 20px;
}

@media only screen and (max-width: 575px) {

  .table tr td,
  .table tr th {
    padding: 5px;
  }
}

.table.book-overview tr th {
  color: #1a1668;
  font-size: 16px;
  font-weight: 500;
  width: 200px;
}

.table-striped thead tr th {
  padding-bottom: 15px !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #ffffff;
}

.table-striped tfoot {
  border-top: 2px solid #E1E1F0;
}

.table-striped tfoot tr th {
  padding-top: 10px;
}

.table> :not(:first-child) {
  border-top: 1px solid #dee2e6;
}

.check-tbl {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  vertical-align: middle;
}

.check-tbl thead th {
  font-size: 16px;
  font-weight: 600;
  padding: 15px 15px;
  color: #fff;
  background: var(--secondary);
}

.check-tbl tbody td {
  padding: 15px;
  font-weight: 500;
  color: var(--secondary);
}

.check-tbl tbody td img {
  width: 100px;
  border-radius: 6px;
}

.check-tbl .product-item-close {
  text-align: right;
}

.check-tbl .product-item-close a {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background: var(--bs-danger);
  display: inline-block;
  color: #fff;
  text-align: center;
  line-height: 40px;
}

.check-tbl .product-item-totle {
  min-width: 180px;
}

.product-item-img {
  width: 100px;
  height: 100px;
}

.dz-separator {
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 8px;
  position: relative;
}

.dz-separator.m-b0 {
  margin-bottom: 0;
}

.dz-separator.style-liner {
  width: 20px;
}

.dz-separator.style-icon {
  width: 30px;
  height: auto;
  text-align: center;
  font-size: 20px;
}

.dz-separator.style-skew {
  width: 15px;
  height: 10px;
  margin-left: 1px;
  margin-right: 1px;
  -moz-transform: skewX(-10deg);
  -webkit-transform: skewX(-10deg);
  -o-transform: skewX(-10deg);
  -ms-transform: skewX(-10deg);
  transform: skewX(-10deg);
}

.dz-separator.style-skew[class*="style-"]:after,
.dz-separator.style-skew[class*="style-"]:before {
  width: 80px;
  height: 4px;
  left: 20px;
  -moz-transform: translateY(-50%) skewX(-10deg);
  -webkit-transform: translateY(-50%) skewX(-10deg);
  -o-transform: translateY(-50%) skewX(-10deg);
  -ms-transform: translateY(-50%) skewX(-10deg);
  transform: translateY(-50%) skewX(-10deg);
}

.dz-separator.style-skew[class*="style-"]:before {
  right: 20px;
  left: auto;
}

.dz-separator.bnr-title {
  height: 1px;
  width: 155px;
  opacity: 0.5;
}

.dz-separator.bnr-title:before,
.dz-separator.bnr-title:after {
  position: absolute;
  content: "";
  background-color: inherit;
}

.dz-separator.bnr-title:before {
  height: inherit;
  right: -80px;
  width: 25px;
}

.dz-separator.bnr-title:after {
  height: inherit;
  right: -90px;
  top: 0;
  width: 6px;
}

.dz-separator.bnr-title i {
  background-color: inherit;
  display: block;
  height: inherit;
  position: absolute;
  right: -50px;
  width: 45px;
}

.dz-separator-outer {
  overflow: hidden;
}

.dz-separator.style-1 {
  height: 3px;
  width: 60px;
  background: var(--primary);
}

.dz-separator.style-2 {
  height: 10px;
  display: block;
  width: 82px;
  position: relative;
  margin: 0;
}

.dz-separator.style-2:before,
.dz-separator.style-2:after {
  content: "";
  height: 3px;
  width: 89px;
  position: absolute;
  background: var(--primary);
  left: 0;
}

.dz-separator.style-2:after {
  width: 80%;
  bottom: 0;
}

.dz-separator.style-2:before {
  width: 100%;
  top: 0;
}

.site-filters {
  margin-bottom: 30px;
}

.site-filters ul {
  margin: 0;
  list-style: none;
}

.site-filters li {
  display: inline-block;
  padding: 0;
  margin-bottom: 3px;
}

.site-filters li.btn,
.site-filters li.wp-block-button__link,
.site-filters .woocommerce-cart-form .table tr li.button,
.woocommerce-cart-form .table tr .site-filters li.button,
.site-filters .woocommerce li#place_order,
.woocommerce .site-filters li#place_order {
  box-shadow: none;
  -webkit-box-shadow: none;
  outline: none !important;
}

.site-filters li input {
  display: none;
}

.site-filters li a {
  margin: 0 5px 5px 0;
  display: block;
}

.site-filters li.active [class*="btn"] {
  color: #fff;
  background-color: #EFBB20;
}

.site-filters.center {
  text-align: center;
}

.site-filters.center ul {
  display: inline-block;
  margin: auto;
}

.site-filters.center [class*="btn"] {
  display: inline-block;
  margin: 0 5px 10px;
  text-transform: uppercase;
}

@media only screen and (max-width: 575px) {
  .site-filters.center [class*="btn"] {
    margin: 0 1px 5px;
    padding: 5px 10px;
    font-size: 12px;
  }
}

.dz-img-overlay1,
.dz-img-overlay2,
.dz-img-overlay3,
.dz-img-overlay4,
.dz-img-overlay5,
.dz-img-overlay6,
.dz-img-overlay7,
.dz-img-overlay8,
.dz-img-overlay9,
.dz-img-overlay11,
.dz-img-overlay12 {
  position: relative;
}

.dz-img-overlay1:before,
.dz-img-overlay1:after,
.dz-img-overlay2:before,
.dz-img-overlay2:after,
.dz-img-overlay3:before,
.dz-img-overlay3:after,
.dz-img-overlay4:before,
.dz-img-overlay4:after,
.dz-img-overlay5:before,
.dz-img-overlay5:after,
.dz-img-overlay6:before,
.dz-img-overlay6:after,
.dz-img-overlay7:before,
.dz-img-overlay7:after,
.dz-img-overlay8:before,
.dz-img-overlay8:after,
.dz-img-overlay9:before,
.dz-img-overlay9:after,
.dz-img-overlay11:before,
.dz-img-overlay11:after,
.dz-img-overlay12:before,
.dz-img-overlay12:after {
  content: "";
  background: #282d32;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0.4;
  z-index: 1;
  -webkit-transition: all 0.8s linear;
  -moz-transition: all 0.8s linear;
  -ms-transition: all 0.8s linear;
  -o-transition: all 0.8s linear;
  transition: all 0.8s linear;
}

.dz-img-overlay1.overlay-primary,
.dz-img-overlay2.overlay-primary,
.dz-img-overlay3.overlay-primary,
.dz-img-overlay4.overlay-primary,
.dz-img-overlay5.overlay-primary,
.dz-img-overlay6.overlay-primary,
.dz-img-overlay7.overlay-primary,
.dz-img-overlay8.overlay-primary,
.dz-img-overlay9.overlay-primary,
.dz-img-overlay11.overlay-primary,
.dz-img-overlay12.overlay-primary {
  background: var(--primary);
}

.dz-box:hover .dz-img-overlay1:before {
  opacity: 0.7;
}

.dz-box:hover .dz-img-overlay2:before {
  opacity: 0.9;
}

.dz-box:hover .dz-img-overlay3:before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dz-box:hover .dz-img-overlay4:before {
  width: 100%;
  left: 0;
}

.dz-box:hover .dz-img-overlay5:before {
  height: 100%;
  top: 0;
}

.dz-box:hover .dz-img-overlay6:before {
  height: 100%;
}

.dz-box:hover .dz-img-overlay7:before,
.dz-box:hover .dz-img-overlay7:after {
  width: 50%;
}

.dz-box:hover .dz-img-overlay8:before,
.dz-box:hover .dz-img-overlay8:after {
  width: 100%;
  opacity: 0.3;
}

.dz-box:hover .dz-img-overlay9:before,
.dz-box:hover .dz-img-overlay9:after {
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

.dz-img-overlay1:before {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dz-img-overlay1:hover:before {
  opacity: 0.5;
}

.dz-img-overlay2:before {
  width: 100%;
  height: 100%;
  opacity: 0;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
}

.dz-img-overlay2:before:hover:before {
  opacity: 0.9;
}

.dz-img-overlay3:before {
  left: 50%;
  top: 50%;
}

.dz-img-overlay3:hover:before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dz-img-overlay4:before {
  left: 50%;
  top: 0;
  height: 100%;
}

.dz-img-overlay4:hover:before {
  width: 100%;
  left: 0;
}

.dz-img-overlay5:before {
  left: 0;
  top: 50%;
  width: 100%;
}

.dz-img-overlay5:hover:before {
  height: 100%;
  top: 0;
}

.dz-img-overlay6:before {
  left: 0;
  top: auto;
  bottom: 0;
  width: 100%;
}

.dz-img-overlay6:hover:before {
  height: 100%;
}

.dz-img-overlay7:before,
.dz-img-overlay7:after {
  height: 100%;
}

.dz-img-overlay7:after {
  left: auto;
  right: 0;
}

.dz-img-overlay7:hover:before,
.dz-img-overlay7:hover:after {
  width: 50%;
}

.dz-img-overlay8:before,
.dz-img-overlay8:after {
  height: 100%;
}

.dz-img-overlay8:after {
  left: auto;
  right: 0;
}

.dz-img-overlay8:hover:before,
.dz-img-overlay8:hover:after {
  width: 100%;
  opacity: 0.3;
}

.dz-img-overlay9:before:before,
.dz-img-overlay9:before:after,
.dz-img-overlay9:after:before,
.dz-img-overlay9:after:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}

.dz-img-overlay9:before:hover:before,
.dz-img-overlay9:before:hover:after,
.dz-img-overlay9:after:hover:before,
.dz-img-overlay9:after:hover:after {
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

/*  */
.overlay-secondary-light,
.overlay-secondary-middle,
.overlay-secondary-dark,
.overlay-black-light,
.overlay-black-middle,
.overlay-black-dark,
.overlay-gradient-light,
.overlay-gradient-middle,
.overlay-gradient-dark,
.overlay-white-light,
.overlay-white-middle,
.overlay-white-dark,
.overlay-primary-light,
.overlay-primary-middle,
.overlay-primary-dark,
.overlay-primary {
  position: relative;
}

.overlay-secondary-light:before,
.overlay-secondary-middle:before,
.overlay-secondary-dark:before,
.overlay-black-light:before,
.overlay-black-middle:before,
.overlay-black-dark:before,
.overlay-gradient-light:before,
.overlay-gradient-middle:before,
.overlay-gradient-dark:before,
.overlay-white-light:before,
.overlay-white-middle:before,
.overlay-white-dark:before,
.overlay-primary-light:before,
.overlay-primary-middle:before,
.overlay-primary-dark:before,
.overlay-primary:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.overlay-secondary-light .container,
.overlay-secondary-light .container-fluid,
.overlay-secondary-light .container-sm,
.overlay-secondary-light .container-md,
.overlay-secondary-light .container-lg,
.overlay-secondary-light .container-xl,
.overlay-secondary-middle .container,
.overlay-secondary-middle .container-fluid,
.overlay-secondary-middle .container-sm,
.overlay-secondary-middle .container-md,
.overlay-secondary-middle .container-lg,
.overlay-secondary-middle .container-xl,
.overlay-secondary-dark .container,
.overlay-secondary-dark .container-fluid,
.overlay-secondary-dark .container-sm,
.overlay-secondary-dark .container-md,
.overlay-secondary-dark .container-lg,
.overlay-secondary-dark .container-xl,
.overlay-black-light .container,
.overlay-black-light .container-fluid,
.overlay-black-light .container-sm,
.overlay-black-light .container-md,
.overlay-black-light .container-lg,
.overlay-black-light .container-xl,
.overlay-black-middle .container,
.overlay-black-middle .container-fluid,
.overlay-black-middle .container-sm,
.overlay-black-middle .container-md,
.overlay-black-middle .container-lg,
.overlay-black-middle .container-xl,
.overlay-black-dark .container,
.overlay-black-dark .container-fluid,
.overlay-black-dark .container-sm,
.overlay-black-dark .container-md,
.overlay-black-dark .container-lg,
.overlay-black-dark .container-xl,
.overlay-gradient-light .container,
.overlay-gradient-light .container-fluid,
.overlay-gradient-light .container-sm,
.overlay-gradient-light .container-md,
.overlay-gradient-light .container-lg,
.overlay-gradient-light .container-xl,
.overlay-gradient-middle .container,
.overlay-gradient-middle .container-fluid,
.overlay-gradient-middle .container-sm,
.overlay-gradient-middle .container-md,
.overlay-gradient-middle .container-lg,
.overlay-gradient-middle .container-xl,
.overlay-gradient-dark .container,
.overlay-gradient-dark .container-fluid,
.overlay-gradient-dark .container-sm,
.overlay-gradient-dark .container-md,
.overlay-gradient-dark .container-lg,
.overlay-gradient-dark .container-xl,
.overlay-white-light .container,
.overlay-white-light .container-fluid,
.overlay-white-light .container-sm,
.overlay-white-light .container-md,
.overlay-white-light .container-lg,
.overlay-white-light .container-xl,
.overlay-white-middle .container,
.overlay-white-middle .container-fluid,
.overlay-white-middle .container-sm,
.overlay-white-middle .container-md,
.overlay-white-middle .container-lg,
.overlay-white-middle .container-xl,
.overlay-white-dark .container,
.overlay-white-dark .container-fluid,
.overlay-white-dark .container-sm,
.overlay-white-dark .container-md,
.overlay-white-dark .container-lg,
.overlay-white-dark .container-xl,
.overlay-primary-light .container,
.overlay-primary-light .container-fluid,
.overlay-primary-light .container-sm,
.overlay-primary-light .container-md,
.overlay-primary-light .container-lg,
.overlay-primary-light .container-xl,
.overlay-primary-middle .container,
.overlay-primary-middle .container-fluid,
.overlay-primary-middle .container-sm,
.overlay-primary-middle .container-md,
.overlay-primary-middle .container-lg,
.overlay-primary-middle .container-xl,
.overlay-primary-dark .container,
.overlay-primary-dark .container-fluid,
.overlay-primary-dark .container-sm,
.overlay-primary-dark .container-md,
.overlay-primary-dark .container-lg,
.overlay-primary-dark .container-xl,
.overlay-primary .container,
.overlay-primary .container-fluid,
.overlay-primary .container-sm,
.overlay-primary .container-md,
.overlay-primary .container-lg,
.overlay-primary .container-xl {
  position: relative;
  z-index: 1;
}

.overlay-black-light:before,
.overlay-black-middle:before,
.overlay-black-dark:before {
  background: #13212a;
}

.overlay-secondary-light:before,
.overlay-secondary-middle:before,
.overlay-secondary-dark:before {
  background: #1a1668;
}

.overlay-gradient-light:before,
.overlay-gradient-middle:before,
.overlay-gradient-dark:before {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
}

.overlay-white-light:before,
.overlay-white-middle:before,
.overlay-white-dark:before {
  background: #FFF;
}

.overlay-primary-light:before,
.overlay-primary-middle:before,
.overlay-primary-dark:before,
.overlay-primary:before {
  background: var(--primary);
}

.overlay-primary-light:before,
.overlay-white-light:before,
.overlay-gradient-light:before,
.overlay-secondary-light:before,
.overlay-black-light:before {
  opacity: 0.3;
}

.overlay-primary-middle:before,
.overlay-white-middle:before,
.overlay-gradient-middle:before,
.overlay-black-middle:before,
.overlay-secondary-middle:before {
  opacity: 0.7;
}

.overlay-primary-dark:before,
.overlay-white-dark:before,
.overlay-gradient-dark:before,
.overlay-black-dark:before,
.overlay-secondary-dark:before {
  opacity: 0.9;
}

.overlay-bx {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
}

.overlay-bx .align-b {
  height: auto;
  left: 0;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: auto;
  bottom: 0;
  width: 100%;
  transform: translate(0);
  -o-transform: translate(0);
  -moz-transform: translate(0);
  -webkit-transform: translate(0);
}

.overlay-bx .align-m {
  height: auto;
  left: 50%;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 10px;
}

.overlay-icon {
  height: auto;
  left: 50%;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 10px;
}

.overlay-icon a {
  display: inline-block;
  padding: 0;
  margin: 0 2px;
}

.overlay-icon a i {
  background-color: #FFF;
}

.overlay-bx:hover a>i,
.dz-media:hover .overlay-bx a>i,
.dz-box-bx:hover .overlay-bx a>i {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.overlay-bx:hover,
.dz-media:hover .overlay-bx,
.dz-box:hover .overlay-bx,
.ow-img:hover .overlay-bx,
.ow-portfolio-img:hover .overlay-bx {
  opacity: 1;
  visibility: visible;
}

.gallery-box-1 .overlay-bx .overlay-icon span i,
.gallery-box-1 .overlay-bx .overlay-icon a i {
  background-color: #000;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
  cursor: pointer;
}

.gallery-box-1 .overlay-bx .icon-bx-xs {
  width: 60px;
  height: 60px;
  line-height: 60px;
}

.img-overlay-gradients {
  position: relative;
}

.img-overlay-gradients:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.img-overlay-gradients.gradients-secondary:before {
  background: -moz-linear-gradient(bottom, transparent 0%, #1a1668 100%);
  background: -webkit-linear-gradient(bottom, transparent 0%, #1a1668 100%);
  background: linear-gradient(to bottom, transparent 0%, #1a1668 100%);
}

.img-overlay-gradients.gradients-primary:before {
  background: -moz-linear-gradient(bottom, transparent 0%, var(--primary) 100%);
  background: -webkit-linear-gradient(bottom, transparent 0%, var(--primary) 100%);
  background: linear-gradient(to bottom, transparent 0%, var(--primary) 100%);
}

.overlay-shine .dz-media {
  position: relative;
  overflow: hidden;
}

.overlay-shine .dz-media:before {
  position: absolute;
  top: 0;
  left: -85%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.overlay-shine:hover .dz-media:before {
  -webkit-animation: shine .75s;
  animation: shine .75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

/* info box show */
.dz-info-has {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 30px 15px 40px;
  opacity: 0;
  margin-bottom: -100px;
  z-index: 2;
}

.dz-info-has.p-a15 {
  padding: 15px;
}

.dz-info-has.p-a20 {
  padding: 20px;
}

.dz-info-has h1,
.dz-info-has .h1,
.dz-info-has h2,
.dz-info-has .h2,
.dz-info-has h3,
.dz-info-has .h3,
.dz-info-has h4,
.dz-info-has .h4,
.dz-info-has h5,
.dz-info-has .h5,
.dz-info-has a,
.dz-info-has p,
.dz-info-has h6,
.dz-info-has .h6 {
  color: #fff;
}

.dz-info-has .dz-info-has-text {
  margin-bottom: 20px;
  line-height: 24px;
}

.dz-info-has.bg-white h1,
.dz-info-has.bg-white .h1,
.dz-info-has.bg-white h2,
.dz-info-has.bg-white .h2,
.dz-info-has.bg-white h3,
.dz-info-has.bg-white .h3,
.dz-info-has.bg-white h4,
.dz-info-has.bg-white .h4,
.dz-info-has.bg-white h5,
.dz-info-has.bg-white .h5,
.dz-info-has.bg-white h6,
.dz-info-has.bg-white .h6 {
  color: #3d474a;
}

.dz-info-has.bg-white h1 a,
.dz-info-has.bg-white .h1 a,
.dz-info-has.bg-white h2 a,
.dz-info-has.bg-white .h2 a,
.dz-info-has.bg-white h3 a,
.dz-info-has.bg-white .h3 a,
.dz-info-has.bg-white h4 a,
.dz-info-has.bg-white .h4 a,
.dz-info-has.bg-white h5 a,
.dz-info-has.bg-white .h5 a,
.dz-info-has.bg-white h6 a,
.dz-info-has.bg-white .h6 a {
  color: #3d474a;
}

.dz-info-has.bg-white p {
  color: #3d474a;
}

.dz-info-has.bg-primary {
  background-color: rgba(27, 188, 232, 0.9);
}

.dz-info-has.bg-green {
  background-color: rgba(106, 179, 62, 0.9);
}

.dz-info-has.bg-black {
  background-color: rgba(0, 0, 0, 0.6);
}

.dz-info-has.bg-white {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.dz-media {
  overflow: hidden;
  position: relative;
}

.dz-media:hover .dz-info-has {
  opacity: 1;
  width: 100%;
  margin-bottom: 0;
}

.dz-box:hover .dz-info-has {
  opacity: 1;
  width: 100%;
  margin-bottom: 0;
}

.overlay-bx.no-hover,
.dz-info-has.no-hover {
  opacity: 1;
  width: 100%;
  margin-bottom: 0;
  visibility: visible;
}

.owl-none .owl-nav {
  display: none;
}

.owl-nav button.owl-prev,
.owl-nav button.owl-next {
  cursor: pointer;
  transition: all 0.5s;
  display: inline-block;
  font-size: 24px !important;
  font-weight: 500 !important;
}

.owl-theme .owl-nav,
.owl-theme .owl-dots {
  margin-top: 20px;
  text-align: center;
}

.owl-theme .owl-.disabled {
  opacity: .5;
  cursor: default;
}

.owl-theme .owl-dots {
  text-align: center;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot:hover span,
.owl-theme .owl-dots .owl-dot.active span {
  background: #869791;
}

.owl-theme.owl-dots-1 .owl-dots .owl-dot span {
  background-color: #dadada;
  width: 15px;
  height: 15px;
  margin: 5px;
}

.owl-theme.owl-dots-1 .owl-dots .owl-dot:hover span,
.owl-theme.owl-dots-1 .owl-dots .owl-dot.active span {
  background-color: var(--primary);
}

.owl-theme.owl-dots-2 .owl-dots {
  margin-top: 40px;
  position: unset;
  right: auto;
  text-align: center;
  top: auto;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: auto;
}

.owl-theme.owl-dots-2 .owl-dots .owl-dot {
  display: inline-block;
  margin: 4px;
}

.owl-theme.owl-dots-2 .owl-dots .owl-dot span {
  height: 10px;
  width: 10px;
  margin: 0;
  border-radius: 0;
}

.owl-theme.owl-dots-2 .owl-dots .owl-dot:hover span,
.owl-theme.owl-dots-2 .owl-dots .owl-dot.active span {
  background-color: var(--primary);
}

.owl-theme.owl-dots-3 .owl-dots {
  margin-top: 40px;
  position: unset;
  display: block;
  right: auto;
  text-align: center;
  top: auto;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: auto;
}

.owl-theme.owl-dots-3 .owl-dots .owl-dot {
  display: inline-block;
  margin: 4px;
}

.owl-theme.owl-dots-3 .owl-dots .owl-dot span {
  height: 8px;
  width: 8px;
  margin: 0;
  background-color: #898c90;
  border-radius: 8px;
}

.owl-theme.owl-dots-3 .owl-dots .owl-dot:hover span,
.owl-theme.owl-dots-3 .owl-dots .owl-dot.active span {
  background-color: var(--primary);
}

.owl-btn-top-c .owl-nav {
  text-align: center;
}

.owl-btn-top-l .owl-nav {
  text-align: left;
}

.owl-btn-top-l .owl-.owl-prev {
  margin-left: 0;
}

.owl-btn-top-r .owl-nav {
  position: absolute;
  top: -125px;
  right: 0;
  margin: 0;
}

.owl-btn-top-r .owl-.owl-next {
  margin-right: 0;
}

.owl-btn-top-lr .owl-nav {
  text-align: left;
}

.owl-btn-top-lr .owl-.owl-next {
  margin-right: 0;
  float: right;
}

.owl-btn-full .owl-prev,
.owl-btn-full .owl-next {
  padding: 40px 8px;
}

.owl-btn-center .owl-nav {
  justify-content: center;
}

.owl-btn-center-lr .owl-prev,
.owl-btn-center-lr .owl-next {
  position: absolute;
  left: 0;
  top: 50%;
  margin: 0;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  cursor: pointer;
}

.owl-btn-center-lr .owl-prev {
  left: -110px;
}

.owl-btn-center-lr .owl-next {
  left: auto;
  right: -110px;
}

@media only screen and (max-width: 1480px) {
  .owl-btn-center-lr .owl-prev {
    left: -40px;
  }

  .owl-btn-center-lr .owl-next {
    right: -40px;
  }
}

@media only screen and (max-width: 1280px) {
  .owl-btn-center-lr .owl-prev {
    left: -20px;
  }

  .owl-btn-center-lr .owl-next {
    right: -20px;
  }
}

.owl-carousel .owl-item img {
  transform-style: inherit;
}

.owl-btn-1 .owl-nav button.owl-prev,
.owl-btn-1 .owl-nav button.owl-next {
  border-radius: 3px;
  line-height: 40px;
  margin: 0 20px !important;
  padding: 0;
  text-align: center;
  color: var(--primary) !important;
  font-size: 35px;
  height: auto;
  width: auto;
  background-color: transparent;
  position: relative;
}

.owl-btn-1 .owl-nav button.owl-prev:before,
.owl-btn-1 .owl-nav button.owl-next:before {
  content: "";
  position: absolute;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 3px;
  position: absolute;
  font-weight: 400;
  top: 8px;
  color: #212529;
}

.owl-btn-1 .owl-nav button.owl-prev:hover,
.owl-btn-1 .owl-nav button.owl-next:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #000;
  opacity: 1;
}

@media only screen and (max-width: 991px) {

  .owl-btn-1 .owl-nav button.owl-prev,
  .owl-btn-1 .owl-nav button.owl-next {
    margin: 0 10px !important;
  }
}

.owl-btn-1 .owl-nav button.owl-prev {
  padding: 0 30px 0 0px !important;
}

.owl-btn-1 .owl-nav button.owl-prev:before {
  content: "PREV";
  right: 0;
}

.owl-btn-1 .owl-nav button.owl-next {
  padding: 0 0 0 30px !important;
}

.owl-btn-1 .owl-nav button.owl-next:before {
  content: "NEXT";
  left: 0;
}

.owl-btn-1.btn-white .owl-.owl-prev,
.owl-btn-1.btn-white .owl-.owl-next {
  color: #fff;
}

.owl-btn-1.btn-white .owl-.owl-prev:hover,
.owl-btn-1.btn-white .owl-.owl-next:hover {
  color: #fff;
}

.owl-btn-2.owl-btn-rounded .owl-.owl-prev,
.owl-btn-2.owl-btn-rounded .owl-.owl-next {
  border-radius: 8px;
}

.owl-btn-2 .owl-.owl-prev,
.owl-btn-2 .owl-.owl-next {
  height: 60px;
  line-height: 60px;
  margin: 0 10px !important;
  padding: 0;
  text-align: center;
  width: 60px;
  box-shadow: 0 4px 15px var(--rgba-primary-2);
}

@media only screen and (max-width: 575px) {

  .owl-btn-2 .owl-.owl-prev,
  .owl-btn-2 .owl-.owl-next {
    height: 40px;
    line-height: 40px;
    width: 40px;
    font-size: 18px !important;
  }
}

.owl-btn-3 .owl-.owl-prev,
.owl-btn-3 .owl-.owl-next {
  border-radius: 50px;
  height: 45px;
  line-height: 45px;
  margin: 0 10px !important;
  padding: 0;
  text-align: center;
  width: 45px;
}

.owl-btn-primary .owl-nav button.owl-prev,
.owl-btn-primary .owl-nav button.owl-next {
  background-color: var(--primary);
  color: #ffffff;
  transition: all 0.5s;
}

.owl-btn-primary .owl-nav button.owl-prev:hover,
.owl-btn-primary .owl-nav button.owl-next:hover {
  background-color: #ffffff;
  color: var(--primary);
}

.owl-btn-white .owl-prev,
.owl-btn-white .owl-next {
  background-color: #ffffff;
  color: var(--primary);
  transition: all 0.5s;
}

.owl-btn-white .owl-prev:hover,
.owl-btn-white .owl-next:hover {
  background-color: var(--primary);
  color: #ffffff;
}

.owl-dots-none .owl-dots {
  display: none;
}

.owl-dots-white-full .owl-dots .owl-dot span,
.owl-dots-black-full .owl-dots .owl-dot span,
.owl-dots-primary-full .owl-dots .owl-dot span {
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  margin: 4px;
}

.owl-dots-white-full .owl-dots .owl-dot:active span,
.owl-dots-white-full .owl-dots .owl-dot.active span,
.owl-dots-black-full .owl-dots .owl-dot:active span,
.owl-dots-black-full .owl-dots .owl-dot.active span,
.owl-dots-primary-full .owl-dots .owl-dot:active span,
.owl-dots-primary-full .owl-dots .owl-dot.active span {
  opacity: 1;
  background-color: var(--primary);
}

.owl-dots-white-big .owl-dots .owl-dot span,
.owl-dots-black-big .owl-dots .owl-dot span,
.owl-dots-primary-big .owl-dots .owl-dot span {
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  margin: 4px;
}

.owl-dots-white-big .owl-dots .owl-dot:active span,
.owl-dots-black-big .owl-dots .owl-dot:active span,
.owl-dots-primary-big .owl-dots .owl-dot:active span {
  width: 14px;
  height: 14px;
  opacity: 1;
  margin: 0px 4px 2px 4px;
}

.owl-dots-primary-full .owl-dots .owl-dot span,
.owl-dots-primary-big .owl-dots .owl-dot span {
  background-color: var(--primary);
}

.owl-dots-black-full .owl-dots .owl-dot span,
.owl-dots-black-big .owl-dots .owl-dot span {
  background-color: #333;
}

.owl-dots-white-full .owl-dots .owl-dot span,
.owl-dots-white-big .owl-dots .owl-dot span {
  background-color: #f5f5f5;
}

/* owl num count */
.owl-num-count .owl-dots {
  text-align: right;
  counter-reset: li;
  justify-content: center;
  display: flex;
}

.owl-num-count .owl-dots .owl-dot {
  padding: 5px 2px !important;
  cursor: pointer;
  font-size: 18px;
  position: relative;
}

.owl-num-count .owl-dots .owl-dot span {
  width: 20px;
  height: 5px;
  border-radius: 10px;
  transition: all 0.5s;
  background-color: transparent;
}

.owl-num-count .owl-dots .owl-dot:before {
  content: "0"counter(li, decimal);
  counter-increment: li;
  font-weight: 600;
  color: #777777;
  font-size: 18px;
}

.owl-num-count .owl-dots .owl-dot.active:before {
  color: var(--primary);
}

.owl-num-count .owl-dots .owl-dot.active span {
  background-color: var(--primary);
}

.dz-img-effect {
  position: relative;
  overflow: hidden;
  display: block;
  vertical-align: middle;
}

.dz-img-effect img {
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.dz-img-effect.opacity img:hover {
  opacity: 0.8;
}

.dz-img-effect.zoom-slow img {
  transition: all 10s;
  -moz-transition: all 10s;
  -webkit-transition: all 10s;
  -o-transition: all 10s;
}

.dz-img-effect.zoom-slow:hover img {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.dz-img-effect.zoom:hover img {
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
}

.dz-img-effect.shrink:hover img {
  transform: scale(0.8);
  -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
}

.dz-img-effect.side-pan:hover img {
  margin-left: -11%;
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.dz-img-effect.vertical-pan:hover img {
  margin-top: -10%;
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.dz-img-effect.fade-in {
  background: #000;
}

.dz-img-effect.fade-in img {
  opacity: 0.65;
}

.dz-img-effect.fade-in:hover img {
  opacity: 1;
}

.dz-img-effect.fade-out {
  background: #000;
}

.dz-img-effect.fade-out:hover img {
  opacity: 0.7;
}

.dz-img-effect.rotate:hover img {
  -moz-transform: scale(1.5) rotate(-20deg);
  -webkit-transform: scale(1.5) rotate(-20deg);
  -o-transform: scale(1.5) rotate(-20deg);
  -ms-transform: scale(1.5) rotate(-20deg);
  transform: scale(1.5) rotate(-20deg);
}

.dz-img-effect.image-sepia img {
  -webkit-filter: sepia(100%);
  filter: sepia(100%);
}

.dz-img-effect.blurr img {
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.dz-img-effect.blurr:hover img {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
}

.dz-img-effect.blurr-invert img {
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
}

.dz-img-effect.blurr-invert:hover img {
  filter: blur(0px);
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
}

.dz-img-effect.off-color img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  -ms-filter: grayscale(0);
}

.dz-img-effect.off-color:hover img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
}

.dz-img-effect.on-color img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
}

.dz-img-effect.on-color:hover img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  -ms-filter: grayscale(0);
}

.dz-box:hover .dz-img-effect.rotate img {
  -moz-transform: scale(1.5) rotate(-20deg);
  -webkit-transform: scale(1.5) rotate(-20deg);
  -o-transform: scale(1.5) rotate(-20deg);
  -ms-transform: scale(1.5) rotate(-20deg);
  transform: scale(1.5) rotate(-20deg);
}

.dz-accordion .accordion-item {
  border-radius: var(--border-radius-base);
  overflow: hidden;
  background: #fff;
  margin-bottom: 20px;
}

.dz-accordion .accordion-body {
  padding: 25px 30px;
  border-radius: 0;
}

.dz-accordion .accordion-collapse {
  border: 0;
}

.dz-accordion .accordion-header .accordion-button {
  font-size: 18px;
  font-weight: 800;
  border: 0;
  border-radius: 8px;
  padding: 22px 65px 22px 30px;
  transition: all 0.5s;
  color: #ffffff;
  box-shadow: 5px 0 15px var(--rgba-primary-4);
  background-color: var(--primary);
}

.dz-accordion .accordion-header .accordion-button:after {
  content: none;
}

.dz-accordion .accordion-header .accordion-button .toggle-close {
  font-family: themify;
  height: 55px;
  width: 55px;
  display: block;
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: var(--border-radius-base);
  line-height: 57px;
  text-align: center;
  font-size: 20px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  z-index: 1;
  overflow: hidden;
}

.dz-accordion .accordion-header .accordion-button .toggle-close:after {
  content: "\f068";
  position: absolute;
  font-family: 'Font Awesome 5 Free';
  z-index: -1;
  color: #fff;
  background-color: transparent;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 1;
}

.dz-accordion .accordion-header .accordion-button.collapsed {
  background: #f5f5f5;
  color: #13212a;
}

.dz-accordion .accordion-header .accordion-button.collapsed:after {
  opacity: 0;
}

.dz-accordion .accordion-header .accordion-button.collapsed .toggle-close:after {
  content: "\f067";
  opacity: 1;
  color: var(--primary);
}

@media only screen and (max-width: 575px) {
  .dz-accordion .accordion-header .accordion-button {
    padding: 15px 65px 15px 15px;
    font-size: 16px;
  }
}

.dz-accordion.accordion-sm .accordion-body {
  padding: 25px;
}

.dz-accordion.accordion-sm .accordion-header .accordion-button {
  padding: 18px 55px 18px 25px;
}

.dz-accordion.accordion-sm .accordion-header .accordion-button .toggle-close {
  height: 50px;
  width: 50px;
  line-height: 50px;
}

.dz-accordion.accordion-sm .accordion-header .accordion-button .toggle-close:before {
  font-size: 25px;
  line-height: 50px;
}

@media only screen and (max-width: 575px) {
  .dz-accordion.accordion-sm .accordion-header .accordion-button {
    padding: 15px 65px 15px 15px;
  }
}

.dz-accordion.style-1 .accordion-item {
  margin-bottom: 0;
}

.dz-accordion.style-1 .accordion-header .accordion-button {
  background: transparent;
  padding: 20px 10px 20px 45px;
  box-shadow: none;
  color: var(--title);
  border-bottom: 1px solid #cccccc;
  border-radius: 0;
}

.dz-accordion.style-1 .accordion-header .accordion-button .toggle-close {
  left: 0;
  height: 30px;
  width: 30px;
}

.dz-accordion.style-1 .accordion-header .accordion-button .toggle-close:after {
  color: var(--primary);
  height: 30px;
  width: 30px;
  line-height: 25px;
  font-size: 17px;
  border-radius: 30px;
  border: 2px solid var(--primary);
}

.dz-accordion.style-1 .accordion-header .accordion-button.collapsed {
  border-bottom: 0;
}

.dz-accordion.style-1 .accordion-body {
  padding: 20px 0;
}

.dz-accordion.gradient-bg .accordion-item {
  overflow: visible;
}

.dz-accordion.gradient-bg .accordion-header .accordion-button {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#c42780+9,1a1dbc+100 */
  background: #c42780;
  /* Old browsers */
  background: -moz-linear-gradient(left, #c42780 9%, #1a1dbc 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #c42780 9%, #1a1dbc 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #c42780 9%, #1a1dbc 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c42780', endColorstr='#1a1dbc', GradientType=1);
  /* IE6-9 */
  box-shadow: none;
}

.dz-accordion.gradient-bg .accordion-header .accordion-button.collapsed {
  background: #fff;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 6px 12px -2px, rgba(0, 0, 0, 0.15) 0px 3px 7px -3px;
}

.dz-accordion.rounded .accordion-header .accordion-button {
  border-radius: 40px;
  box-shadow: none;
}

.dz-accordion.rounded .accordion-header .accordion-button.collapsed {
  box-shadow: none;
  background: var(--primary);
  color: #fff;
}

.dz-accordion.rounded .accordion-header .accordion-button.collapsed .toggle-close::after {
  color: #fff;
}

.acod-content {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 40px;
  margin-top: -30px;
  transition: all 0.5s;
}

.acod-content .widget {
  padding: 25px 25px;
}

.acod-content .widget.widget_services {
  display: flex;
  flex-wrap: wrap;
}

.acod-content .widget.widget_services .form-check {
  margin-bottom: 10px;
  width: 25%;
}

.acod-content .widget.widget_services .form-check .form-check-input {
  border: 2px solid #AAAAAA;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: 0;
}

.acod-content .widget.widget_services .form-check .form-check-input:checked {
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.acod-content .widget.widget_services .form-check .form-check-label {
  font-family: var(--font-family-title);
  margin-left: 10px;
  font-size: 15px;
}

@media only screen and (max-width: 1680px) {
  .acod-content .widget.widget_services .form-check .form-check-input {
    margin-top: 0.3rem;
  }

  .acod-content .widget.widget_services .form-check .form-check-label {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1280px) {
  .acod-content .widget.widget_services .form-check {
    width: 33%;
  }
}

@media only screen and (max-width: 767px) {
  .acod-content .widget.widget_services .form-check {
    width: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .acod-content .widget.widget_services .form-check {
    width: 100%;
  }
}

.acod-content .widget.widget_services.style-2 .form-check {
  width: 33.33%;
}

@media only screen and (max-width: 767px) {
  .acod-content .widget.widget_services.style-2 .form-check {
    width: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .acod-content .widget.widget_services.style-2 .form-check {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .acod-content {
    max-height: 250px;
    overflow: scroll;
  }

  .acod-content .widget {
    margin-bottom: 0;
  }
}

.accordion.accordion-filter .accordion-item {
  margin-bottom: 12px;
  border-radius: 6px;
  box-shadow: none;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.accordion.accordion-filter .accordion-item .accordion-button {
  background: #ffffff;
  padding: 12px 20px;
  color: var(--secondary);
  font-size: 1rem;
  font-family: var(--font-family-title);
  box-shadow: none;
  font-weight: 500;
  height: 60px;
}

.accordion.accordion-filter .accordion-item .accordion-body {
  padding: 20px 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  transition: all 0.5s;
}

.accordion.accordion-filter .accordion-item .accordion-body.show {
  padding: 20px;
}

.accordion.accordion-filter .accordion-item .accordion-body ul {
  padding-left: 0px;
}

.accordion.accordion-filter .accordion-item .accordion-body ul li {
  padding: 4px 0px;
  font-size: 16px;
}

.accordion.accordion-filter .accordion-item .accordion-body ul li a {
  color: var(--secondary);
}

.accordion.accordion-filter .accordion-item .accordion-body ul li a:hover {
  color: var(--primary);
}

.accordion.accordion-filter .accordion-item .accordion-body .slider-range {
  padding: 15px 8px 75px;
}

.pagination.style-1 {
  justify-content: end;
}

.pagination.style-1 li .page-numbers,
.pagination.style-1 li .page-link {
  height: 50px;
  min-width: 50px;
  line-height: 50px;
  color: var(--secondary);
  text-align: center;
  border: 0;
  padding: 0;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 16px;
  font-weight: var(--headings-font-weight);
  border-radius: var(--border-radius-base);
  padding: 0 10px;
  margin: 0 2px;
  display: block;
  background-color: #ebebeb;
}

.pagination.style-1 li .page-numbers.current,
.pagination.style-1 li .page-numbers.active,
.pagination.style-1 li .page-numbers:hover,
.pagination.style-1 li .page-link.current,
.pagination.style-1 li .page-link.active,
.pagination.style-1 li .page-link:hover {
  color: #ffffff;
  background-color: var(--secondary);
  border-color: var(--secondary);
}

@media only screen and (max-width: 575px) {
  .pagination.style-1 li {
    margin: 0 3px;
  }

  .pagination.style-1 li .page-numbers,
  .pagination.style-1 li .page-link {
    height: 35px;
    min-width: 35px;
    line-height: 35px;
    border-radius: 6px;
  }
}

.pagination.style-1 li .prev,
.pagination.style-1 li .next {
  background-color: #ebebeb;
  border-color: transparent;
  font-size: 16px;
  min-width: 113px;
  border-radius: 6px;
}

.pagination.style-1 li .prev:hover,
.pagination.style-1 li .next:hover {
  background-color: var(--secondary);
  box-shadow: none;
  color: #ffffff;
}

@media only screen and (max-width: 575px) {

  .pagination.style-1 li .prev,
  .pagination.style-1 li .next {
    min-width: 90px;
    font-size: 14px;
  }
}

.pagination.style-1.text-center {
  justify-content: center;
}

.pagination.style-1.pagination-lg li .page-numbers,
.pagination.style-1.pagination-lg li .page-link {
  height: 80px;
  min-width: 80px;
  line-height: 80px;
  border-radius: 80px;
}

.pagination.style-1.pagination-md li .page-numbers,
.pagination.style-1.pagination-md li .page-link {
  height: 60px;
  min-width: 60px;
  line-height: 60px;
  border-radius: 40px;
}

.pagination.style-1.pagination-sm li .page-numbers,
.pagination.style-1.pagination-sm li .page-link {
  height: 40px;
  min-width: 40px;
  line-height: 40px;
  border-radius: 40px;
}

.pagination.style-1.rounded-0 .page-item .page-link {
  border-radius: 0;
}

.pagination.style-1 .page-numbers {
  border: 1px solid #F0F0F0;
  display: inline-flex;
  margin: 0 20px;
  background-color: #ffffff;
  border-radius: var(--border-radius-base);
}

.pagination.style-1 .page-numbers .page-item .page-link {
  background-color: #ffffff;
  color: var(--secondary);
}

.pagination.style-1 .page-numbers .page-item .page-link.active,
.pagination.style-1 .page-numbers .page-item .page-link:hover {
  color: #ffffff;
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.pagination.style-1 .page-numbers .page-item .page-link.active .page-num,
.pagination.style-1 .page-numbers .page-item .page-link:hover .page-num {
  background-color: transparent;
}

@media only screen and (max-width: 575px) {
  .pagination.style-1 .page-numbers {
    margin: 0 5px;
  }
}

@media only screen and (max-width: 767px) {
  .pagination.style-1 {
    justify-content: center;
  }
}

.dz-content-bx {
  position: relative;
  overflow: hidden;
}

.dz-content-bx .content-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dz-content-bx .dz-content-inner {
  position: relative;
}

.list-circle li,
.list-angle-double li,
.list-square li,
.list-square-check li,
.list-check-circle li,
.list-check li,
.list-plus-circle li,
.list-arrow-left-circle li,
.list-arrow-right-circle li {
  position: relative;
  padding: 5px 5px 5px 30px;
  font-family: var(--font-family-title);
  font-size: 16px;
}

.list-circle li:before,
.list-angle-double li:before,
.list-square li:before,
.list-square-check li:before,
.list-check-circle li:before,
.list-check li:before,
.list-plus-circle li:before,
.list-arrow-left-circle li:before,
.list-arrow-right-circle li:before {
  position: absolute;
  left: 0;
  top: 8px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.list-circle.primary li:before,
.list-angle-double.primary li:before,
.list-square.primary li:before,
.list-square-check.primary li:before,
.list-check-circle.primary li:before,
.list-check.primary li:before,
.list-plus-circle.primary li:before,
.list-arrow-left-circle.primary li:before,
.list-arrow-right-circle.primary li:before {
  color: var(--primary);
}

.list-circle.white li:before,
.list-angle-double.white li:before,
.list-square.white li:before,
.list-square-check.white li:before,
.list-check-circle.white li:before,
.list-check.white li:before,
.list-plus-circle.white li:before,
.list-arrow-left-circle.white li:before,
.list-arrow-right-circle.white li:before {
  color: #fff;
}

.list-circle.gray li:before,
.list-angle-double.gray li:before,
.list-square.gray li:before,
.list-square-check.gray li:before,
.list-check-circle.gray li:before,
.list-check.gray li:before,
.list-plus-circle.gray li:before,
.list-arrow-left-circle.gray li:before,
.list-arrow-right-circle.gray li:before {
  color: #cbcbcb;
}

ul.list-circle li {
  padding: 5px 5px 5px 22px;
}

ul.list-circle li:before {
  content: "\f111";
  font-size: 6px;
  top: 15px;
}

ul.list-angle-double li:before {
  content: "\f101";
  font-size: 18px;
}

ul.list-square li:before {
  content: "\f45c";
  top: 50%;
  transform: translateY(-50%);
}

ul.list-check li:before {
  content: "\f00c";
  font-size: 16px;
}

ul.list-check-circle li:before {
  content: "\f058";
  font-size: 18px;
  font-weight: 500;
  top: 5px;
}

ul.list-plus-circle li:before {
  content: "\f055";
  font-size: 18px;
  top: 5px;
}

ul.list-arrow-left-circle li:before,
ul.list-arrow-right-circle li:before {
  font-size: 10px;
  top: 10px;
  height: 20px;
  width: 20px;
  background-color: #888888;
  border-radius: 50px;
  text-align: center;
  line-height: 21px;
}

ul.list-arrow-right-circle li:before {
  content: "\f054";
  padding-left: 1px;
}

ul.list-arrow-left-circle li:before {
  content: "\f053";
  padding-right: 1px;
}

ul.grid-2 {
  display: flex;
  flex-wrap: wrap;
}

ul.grid-2 li {
  width: 50%;
}

@media only screen and (max-width: 575px) {
  ul.grid-2 li {
    width: 100%;
  }
}

.list-square-check li {
  padding: 10px 15px 10px 60px;
  min-height: 45px;
  font-weight: 600;
  color: var(--title);
  margin-bottom: 15px;
}

.list-square-check li:before {
  content: "\f00c";
  top: 50%;
  height: 45px;
  width: 45px;
  line-height: 45px;
  background-color: var(--rgba-primary-1);
  color: #000;
  text-align: center;
  padding: 0 0 0 0;
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
  transform: translateY(-50%);
}

.list-square-check li:hover:before {
  background-color: var(--primary);
  color: #fff;
}

.list-number-circle li {
  padding: 0px 15px 0px 70px;
  position: relative;
  margin-bottom: 30px;
}

.list-number-circle li:after {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50px;
  background: var(--primary);
  color: #fff;
  font-family: var(--font-family-title);
  font-size: 24px;
  font-weight: 600;
}

.list-number-circle li p {
  margin-bottom: 0;
}

.list-square-box li {
  padding: 0px 0 0px 25px;
  position: relative;
  margin-bottom: 30px;
}

.list-square-box li:after {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  height: 12px;
  width: 12px;
  background: var(--primary);
}

.list-square-box li p {
  margin-bottom: 0;
  font-size: 17px;
}

.list-check-box li {
  padding: 20px 10px 20px 70px;
  position: relative;
  margin-bottom: 10px;
  color: #fff;
  background: var(--secondary);
  font-size: 24px;
  font-family: var(--font-family-title);
  border-radius: var(--border-radius-base);
  text-transform: uppercase;
  margin-right: 40px;
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
  font-weight: 500;
}

.list-check-box li:after {
  content: "\f058";
  font-size: 25px;
  font-weight: 500;
  position: absolute;
  left: 30px;
  color: #fff;
  font-family: "Font Awesome 5 Free";
  top: 50%;
  transform: translateY(-50%);
}

.list-check-box li:hover {
  background: var(--primary);
  margin-right: 0;
}

@media only screen and (max-width: 1200px) {
  .list-check-box li {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .list-check-box li {
    font-size: 18px;
    padding: 20px 10px 20px 55px;
  }

  .list-check-box li:after {
    left: 20px;
  }
}

/* Light Gallery */
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-sub-html,
.lg-toolbar {
  background-color: rgba(30, 30, 30, 0.6);
}

.lg-outer .lg-toogle-thumb,
.lg-outer .lg-thumb-outer,
.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
  background-color: transparent;
}

.lg-backdrop {
  background-color: rgba(30, 30, 30, 0.9);
}

.lg-outer .lg-toogle-thumb,
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-toolbar .lg-icon,
#lg-counter {
  color: #ffffff;
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: var(--primary);
}

.lightimg {
  cursor: pointer;
}

.progress-bx {
  overflow: hidden;
}

.progress-bx .progress-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.progress-bx .progress-info .title {
  margin-bottom: 0;
}

.progress-bx .progress-info .progress-value {
  margin-bottom: 0;
}

.progress-bx .progress {
  overflow: unset;
}

.progress-bx .progress .progress-bar {
  position: relative;
  overflow: unset;
}

.scroltop {
  background: var(--primary);
  border-color: var(--primary);
  border-radius: 50px;
  border-style: solid;
  border-width: 0;
  bottom: 15px;
  color: #fff !important;
  cursor: pointer;
  display: none;
  height: 50px;
  line-height: 50px;
  margin: 0;
  position: fixed;
  right: 15px;
  text-align: center;
  width: 50px;
  z-index: 999;
  box-shadow: -4px 4px 24px -10px;
  transition: all .8s;
  -moz-transition: all .8s;
  -webkit-transition: all .8s;
  -ms-transition: all .8s;
  -o-transition: all .8s;
}

.scroltop:after {
  border: 1px dashed var(--primary);
  transform: scale(1.2);
  transition: all .5s;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  content: '';
  top: 0;
  left: 0;
  padding: 0;
  z-index: -1;
  -webkit-transition: -webkit-transform .2s, opacity .3s;
  -moz-transition: -moz-transform .2s, opacity .3s;
  transition: transform .2s, opacity .3s;
}

.scroltop:hover:after {
  -webkit-animation: spinAround 9s linear infinite;
  -moz-animation: spinAround 9s linear infinite;
  animation: spinAround 9s linear infinite;
}

@media only screen and (max-width: 575px) {
  .scroltop {
    height: 40px;
    width: 40px;
    line-height: 40px;
  }
}

@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg) scale(1.2);
  }

  to {
    -webkit-transform: rotate(360deg) scale(1.2);
  }
}

@-moz-keyframes spinAround {
  from {
    -moz-transform: rotate(0deg) scale(1.2);
  }

  to {
    -moz-transform: rotate(360deg) scale(1.2);
  }
}

@keyframes spinAround {
  from {
    transform: rotate(0deg) scale(1.2);
  }

  to {
    transform: rotate(360deg) scale(1.2);
  }
}

.btn-next,
.btn-prev {
  box-shadow: 1px 1.732px 60px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  width: 60px;
  height: 60px;
  color: var(--primary);
  background: #fff;
  font-size: 15px;
  letter-spacing: 2px;
  z-index: 1;
  text-align: center;
  line-height: 60px;
  border-radius: var(--border-radius-base);
  margin: 0 10px;
  display: inline-block;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

@media only screen and (max-width: 767px) {

  .btn-next,
  .btn-prev {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
}

.btn-next:hover,
.btn-prev:hover {
  background: var(--primary);
  color: #fff;
  box-shadow: 0px 12px 20px 0px var(--rgba-primary-3);
}

.btn-next:after,
.btn-prev:after {
  content: none;
}

.btn-next i,
.btn-prev i {
  font-size: 28px;
  line-height: inherit;
}

@media only screen and (max-width: 767px) {

  .btn-next i,
  .btn-prev i {
    font-size: 24px;
  }
}

.swiper-btn-center-lr {
  position: relative;
}

.swiper-btn-center-lr .btn-next,
.swiper-btn-center-lr .btn-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.swiper-btn-center-lr .btn-prev {
  left: -100px;
}

.swiper-btn-center-lr .btn-next {
  right: -100px;
}

.swiper-btn-center-lr:hover .btn-prev {
  left: 0px;
}

.swiper-btn-center-lr:hover .btn-next {
  right: 0px;
}

@media only screen and (max-width: 1400px) {
  .swiper-btn-center-lr .btn-prev {
    left: 0px;
  }

  .swiper-btn-center-lr .btn-next {
    right: 0px;
  }
}

.widget {
  margin-bottom: 45px;
}

.widget:last-child {
  margin-bottom: 0;
}

.widget .widget-title {
  position: relative;
  padding-left: 20px;
  margin-bottom: 25px;
  font-weight: 600;
  text-transform: uppercase;
}

@media only screen and (max-width: 991px) {
  .widget .widget-title {
    font-size: 20px;
  }
}

.widget .widget-title:before {
  content: "";
  height: 100%;
  width: 5px;
  border-radius: 10px;
  background-color: var(--primary);
  position: absolute;
  left: 0;
  top: 0;
}

.widget.style-1 .widget-title {
  padding: 0 0 12px;
  margin-bottom: 30px;
}

.widget.style-1 .widget-title:after,
.widget.style-1 .widget-title:before {
  content: "";
  height: 2px;
  border-radius: 10px;
  background-image: var(--gradient);
  background-size: 200%;
  position: absolute;
  bottom: 0;
}

.widget.style-1 .widget-title:before {
  width: 25px;
  left: 0;
  top: auto;
}

.widget.style-1 .widget-title:after {
  width: 55px;
  left: 32px;
}

.search-bx .form-control,
.search-bx .woocommerce #review_form #respond input[type="text"],
.woocommerce #review_form #respond .search-bx input[type="text"],
.search-bx .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .search-bx textarea,
.search-bx .woocommerce #review_form #respond input[type="email"],
.woocommerce #review_form #respond .search-bx input[type="email"],
.search-bx .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .search-bx .input-text,
.search-bx .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .search-bx input,
.search-bx .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .search-bx textarea,
.search-bx .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .search-bx select,
.search-bx .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .search-bx input,
.search-bx .woocommerce-EditAccountForm.edit-account input[type="text"],
.woocommerce-EditAccountForm.edit-account .search-bx input[type="text"],
.search-bx .woocommerce-EditAccountForm.edit-account input[type="email"],
.woocommerce-EditAccountForm.edit-account .search-bx input[type="email"],
.search-bx .woocommerce-EditAccountForm.edit-account input[type="password"],
.woocommerce-EditAccountForm.edit-account .search-bx input[type="password"],
.search-bx .login input[type="text"],
.login .search-bx input[type="text"],
.search-bx .login input[type="password"],
.login .search-bx input[type="password"],
.search-bx .woocommerce-ResetPassword.lost_reset_password input[type="text"],
.woocommerce-ResetPassword.lost_reset_password .search-bx input[type="text"],
.search-bx .checkout.woocommerce-checkout input[type="text"],
.checkout.woocommerce-checkout .search-bx input[type="text"],
.search-bx .checkout.woocommerce-checkout input[type="email"],
.checkout.woocommerce-checkout .search-bx input[type="email"],
.search-bx .checkout.woocommerce-checkout input[type="tel"],
.checkout.woocommerce-checkout .search-bx input[type="tel"],
.search-bx .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .search-bx .select2-choice,
.search-bx .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .search-bx textarea,
.search-bx .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .search-bx input.input-text,
.search-bx .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .search-bx .select2-selection,
.search-bx .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .search-bx .select2-choice {
  height: 45px;
  border: 0;
}

.search-bx .input-group-prepend {
  border-right: 0;
  top: 50%;
}

.search-bx .input-group-prepend .input-group-text i {
  color: #828282;
}

.search-bx button {
  position: absolute;
  right: 0;
  height: 45px;
  top: 0;
  font-size: 30px;
  padding: 0 10px 0 10px;
  z-index: 99;
  border-radius: 0 6px 6px 0;
  width: 45px;
}

.search-bx.style-1 {
  padding: 30px;
  background-color: #1a1668;
  border-radius: 0.375rem;
}

.search-bx.style-1 .input-group {
  margin-bottom: 0;
}

.search-bx.style-1 button {
  border-radius: 0 0.375rem 0.375rem 0;
  padding: 0px 10px;
}

.download-file {
  background-image: var(--gradient);
  color: #ffffff;
  padding: 25px 25px 20px 20px;
  border-radius: 4px;
}

.download-file .title {
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.download-file ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.download-file ul li a {
  background-color: #ffffff;
  color: #000;
  display: block;
  margin-bottom: 10px;
  border-radius: 4px;
  width: 100%;
  padding: 15px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.download-file ul li a i {
  width: 18px;
  height: 18px;
  position: relative;
}

.download-file ul li a i:after,
.download-file ul li a i:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
}

.download-file ul li a i:before {
  -webkit-animation: download1 2s ease infinite;
  animation: download1 2s ease infinite;
}

.download-file ul li a .text {
  display: inline;
  position: relative;
}

.download-file ul li a:hover .text {
  color: var(--primary);
}

@-webkit-keyframes download1 {

  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes download1 {

  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

.widget_contact {
  position: relative;
  padding: 50px 30px;
  border-radius: 6px;
  background-size: cover;
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.widget_contact:after {
  background: var(--gradient-sec);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.85;
  z-index: -1;
  background-size: 200%;
}

.widget_contact img {
  margin-bottom: 20px;
}

.widget_contact h4,
.widget_contact .h4 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 30px;
}

.widget_contact .phone-number {
  position: relative;
  font-size: 24px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 11px;
}

.widget_contact .phone-number a {
  color: #fff;
}

.widget_contact .email {
  position: relative;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 30px;
}

.widget_contact .email a {
  color: #fff;
}

.service-carouse .item {
  overflow: hidden;
  border-radius: 6px;
}

.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list,
.widget_categories ul,
.widget_archive ul,
.widget_meta ul,
.widget_pages ul,
.widget_recent_comments ul,
.widget_nav_menu ul,
.widget_recent_entries ul,
.widget_services ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.wp-block-latest-posts li,
.wp-block-categories-list li,
.wp-block-archives-list li,
.widget_categories ul li,
.widget_archive ul li,
.widget_meta ul li,
.widget_pages ul li,
.widget_recent_comments ul li,
.widget_nav_menu ul li,
.widget_recent_entries ul li,
.widget_services ul li {
  padding-bottom: 1.125rem;
  margin-bottom: 0.8125rem;
  position: relative;
  padding: 0.5rem 0rem 0.5rem 1.25rem;
  margin-bottom: 0;
  line-height: 1.25rem;
}

.wp-block-latest-posts li a,
.wp-block-categories-list li a,
.wp-block-archives-list li a,
.widget_categories ul li a,
.widget_archive ul li a,
.widget_meta ul li a,
.widget_pages ul li a,
.widget_recent_comments ul li a,
.widget_nav_menu ul li a,
.widget_recent_entries ul li a,
.widget_services ul li a {
  color: inherit;
  text-transform: capitalize;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  position: relative;
  display: inline-block;
}

.wp-block-latest-posts li a:before,
.wp-block-categories-list li a:before,
.wp-block-archives-list li a:before,
.widget_categories ul li a:before,
.widget_archive ul li a:before,
.widget_meta ul li a:before,
.widget_pages ul li a:before,
.widget_recent_comments ul li a:before,
.widget_nav_menu ul li a:before,
.widget_recent_entries ul li a:before,
.widget_services ul li a:before {
  content: "\f105";
  font-family: "Fontawesome";
  font-weight: 700;
  position: absolute;
  left: 0;
  top: 8px;
  display: block;
  left: -1.25rem;
  top: 0;
  color: var(--primary);
}

.wp-block-latest-posts li a:hover,
.wp-block-categories-list li a:hover,
.wp-block-archives-list li a:hover,
.widget_categories ul li a:hover,
.widget_archive ul li a:hover,
.widget_meta ul li a:hover,
.widget_pages ul li a:hover,
.widget_recent_comments ul li a:hover,
.widget_nav_menu ul li a:hover,
.widget_recent_entries ul li a:hover,
.widget_services ul li a:hover {
  color: var(--primary);
}

.wp-block-latest-posts li li,
.wp-block-categories-list li li,
.wp-block-archives-list li li,
.widget_categories ul li li,
.widget_archive ul li li,
.widget_meta ul li li,
.widget_pages ul li li,
.widget_recent_comments ul li li,
.widget_nav_menu ul li li,
.widget_recent_entries ul li li,
.widget_services ul li li {
  border-bottom: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.375rem;
}

.wp-block-latest-posts li li:last-child,
.wp-block-categories-list li li:last-child,
.wp-block-archives-list li li:last-child,
.widget_categories ul li li:last-child,
.widget_archive ul li li:last-child,
.widget_meta ul li li:last-child,
.widget_pages ul li li:last-child,
.widget_recent_comments ul li li:last-child,
.widget_nav_menu ul li li:last-child,
.widget_recent_entries ul li li:last-child,
.widget_services ul li li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.wp-block-latest-posts li li:before,
.wp-block-categories-list li li:before,
.wp-block-archives-list li li:before,
.widget_categories ul li li:before,
.widget_archive ul li li:before,
.widget_meta ul li li:before,
.widget_pages ul li li:before,
.widget_recent_comments ul li li:before,
.widget_nav_menu ul li li:before,
.widget_recent_entries ul li li:before,
.widget_services ul li li:before {
  top: 0.5rem;
  left: 0;
}

.wp-block-latest-posts ul,
.wp-block-categories-list ul,
.wp-block-archives-list ul,
.widget_categories ul ul,
.widget_archive ul ul,
.widget_meta ul ul,
.widget_pages ul ul,
.widget_recent_comments ul ul,
.widget_nav_menu ul ul,
.widget_recent_entries ul ul,
.widget_services ul ul {
  padding-left: 1.125rem;
  margin-top: 0.5rem;
  margin-left: -0.9375rem;
}

.widget_archive li ul,
.wp-block-categories-list li ul,
.wp-block-archives-list li ul,
.wp-block-latest-posts li ul,
.widget_categories li ul {
  margin-bottom: -0.625rem;
}

.widget_archive ul li,
.wp-block-categories-list li,
.wp-block-archives-list li,
.wp-block-latest-posts li,
.widget_categories ul li {
  text-align: right;
  display: table;
  width: 100%;
  padding: 0.625rem 0rem 0.625rem 1.25rem !important;
  line-height: 1.3 !important;
}

.wp-block-categories-list li:before,
.wp-block-archives-list li:before,
.wp-block-latest-posts li:before {
  content: none !important;
}

.widget_archive li a,
.wp-block-latest-posts li a,
.wp-block-categories-list li a,
.wp-block-archives-list li a,
.widget_categories li a {
  float: left;
  text-transform: capitalize;
  text-align: left;
}

.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list {
  margin-bottom: 2.5rem;
  padding: 0 !important;
}

.widget_recent_comments ul {
  margin-top: -0.625rem;
}

.widget_recent_comments ul li {
  padding-left: 1.875rem;
  line-height: 1.625rem;
}

.widget_recent_comments ul li a {
  font-weight: 500;
}

.widget_recent_comments ul li a:before {
  content: none;
}

.widget_recent_comments ul li:before {
  content: "\f0e6";
  font-family: FontAwesome;
  background: transparent;
  width: auto;
  height: auto;
  position: absolute;
  left: 0;
  top: 0.5rem;
  font-size: 1.125rem;
}

.widget_meta ul li a abbr[title] {
  color: #333;
  border-bottom: none;
}

.widget_calendar th,
.widget_calendar td {
  text-align: center;
  border: 0.0625rem solid var(--rgba-primary-1);
  padding: 0.4em;
}

.widget_calendar th,
.widget_calendar tfoot td {
  border-color: rgba(0, 0, 0, 0.05);
}

.widget_calendar th:last-child,
.widget_calendar tfoot td:last-child {
  border: 0;
}

.widget_calendar tr {
  border-bottom: 0.0625rem solid var(--rgba-primary-1);
}

.widget_calendar tr:nth-child(2n+2) {
  background-color: var(--rgba-primary-05);
}

.widget_calendar table {
  border-collapse: collapse;
  margin: 0;
  width: 100%;
}

.widget_calendar .calendar_wrap th,
.widget_calendar tfoot td a {
  color: #fff;
  background: #1f2471;
}

.widget_calendar .calendar_wrap td a {
  font-weight: 600;
  text-transform: uppercase;
  color: inherit;
}

.widget_calendar caption {
  text-align: center;
  font-weight: 600;
  caption-side: top;
  background: #1f2471;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  text-transform: uppercase;
  font-size: 0.9375rem;
}

footer .widget_calendar th,
footer .widget_calendar td {
  border-color: rgba(255, 255, 255, 0.1);
}

footer .widget_calendar tr {
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
}

footer .widget_calendar tr:nth-child(2n+2) {
  background-color: rgba(255, 255, 255, 0.05);
}

.wp-calendar-nav {
  background: var(--title);
  color: #fff;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.wp-calendar-nav span {
  padding: 0.3125rem 1.25rem;
  width: 44%;
  border-right: 0.0625rem solid rgba(255, 255, 255, 0.2);
}

.wp-calendar-nav span a {
  color: #fff;
}

.widget_archive select {
  width: 100%;
  padding: 0.3125rem;
  border: 0.0625rem solid #CCC;
}

.widget_text select {
  width: 100%;
  border: 0.0625rem solid #CCC;
}

.widget_text select option {
  width: 100%;
}

.widget_text p {
  font-size: 0.9375rem;
  line-height: 1.75rem;
}

.rsswidget img {
  display: inherit;
}

.widget-title .rsswidget {
  display: inline-block;
}

ul a.rsswidget,
ul cite {
  font-weight: 600;
  color: var(--title);
}

ul a.rsswidget {
  font-size: 1.125rem;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

ul a.rsswidget:hover {
  color: var(--primary);
}

.rssSummary {
  margin: 1.25rem 0;
  line-height: 1.7;
  font-size: 15px;
}

.rss-date {
  display: block;
  margin-top: 0.3125rem;
  font-weight: 400;
}

.widget_rss ul li {
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.1);
  padding-top: 0;
}

.widget_rss ul li:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.widget_gallery ul {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.3rem;
  margin-right: -0.3rem;
}

.widget_gallery.gallery-grid-2 li {
  width: 50%;
}

.widget_gallery.gallery-grid-4 li {
  width: 25%;
}

.widget_gallery li {
  display: inline-block;
  width: 33.33%;
  float: left;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  padding: 0.125rem;
}

.widget_gallery li img {
  display: inline-block;
  width: 100%;
}

.widget_gallery li:nth-child(3n-3) {
  margin-right: 0;
}

.widget_gallery li:hover {
  opacity: 0.7;
}

.widget_gallery li a {
  display: inline-block;
}

.widget_tag_cloud .tagcloud {
  clear: both;
  display: table;
}

.widget_tag_cloud .tagcloud a {
  position: relative;
  border: 1px solid;
  border-radius: 0.375rem;
  padding: 6px 15px;
  display: inline-block;
  margin: 0 6px 10px 0;
  font-size: 15px;
  color: inherit;
  line-height: 1.4;
}

.widget_tag_cloud .tagcloud a:hover {
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
  color: #fff;
}

.recent-posts-entry .widget-post-bx .widget-post {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 0.375rem;
  margin-bottom: 10px;
  transition: all 0.5s;
}

.recent-posts-entry .widget-post-bx .widget-post .dz-media {
  width: 100px;
  min-width: 100px;
  border-radius: 0.375rem;
}

.recent-posts-entry .widget-post-bx .widget-post .dz-info {
  padding-left: 15px;
}

.recent-posts-entry .widget-post-bx .widget-post .dz-meta li {
  font-size: 14px;
  color: var(--primary);
}

.recent-posts-entry .widget-post-bx .widget-post .title {
  font-weight: 600;
}

.recent-posts-entry .widget-post-bx .widget-post:hover {
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.1);
}

.service_menu_nav {
  background: #fff;
  padding: 30px 30px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

@media only screen and (max-width: 1280px) {
  .service_menu_nav {
    padding: 15px;
  }
}

.service_menu_nav ul li {
  padding: 0;
}

.service_menu_nav ul li a {
  background-color: #fff;
  display: block;
  border-radius: 6px;
  padding: 15px 25px;
  margin-bottom: 10px;
  color: #000;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  background-size: 200%;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

@media only screen and (max-width: 1280px) {
  .service_menu_nav ul li a {
    padding: 15px;
  }
}

.service_menu_nav ul li a:before {
  content: "\f061";
  font-weight: 900;
  font-family: "Font Awesome 6 Free";
  position: absolute;
  right: 50px;
  left: auto;
  top: 50%;
  font-size: 18px;
  opacity: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1280px) {
  .service_menu_nav ul li a:before {
    font-size: 22px;
  }
}

.service_menu_nav ul li a:after {
  background: var(--secondary);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  content: "";
  background-size: 200%;
  opacity: 0;
  z-index: -1;
}

.service_menu_nav ul li:hover a,
.service_menu_nav ul li.current-menu-item a,
.service_menu_nav ul li.active a {
  padding-left: 20px;
  border-color: transparent;
  transform: translateX(0);
  -moz-transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  color: #ffffff;
}

.service_menu_nav ul li:hover a:before,
.service_menu_nav ul li.current-menu-item a:before,
.service_menu_nav ul li.active a:before {
  opacity: 1;
  right: 10px;
  color: #ffffff;
}

@media only screen and (max-width: 1280px) {

  .service_menu_nav ul li:hover a:before,
  .service_menu_nav ul li.current-menu-item a:before,
  .service_menu_nav ul li.active a:before {
    font-size: 22px;
    right: 5px;
  }
}

.service_menu_nav ul li:hover a:after,
.service_menu_nav ul li.current-menu-item a:after,
.service_menu_nav ul li.active a:after {
  opacity: 1;
}

.author-box {
  padding: 30px;
  background: var(--rgba-primary-1);
  border-radius: 6px;
}

.author-box .author-profile-info {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .author-box .author-profile-info {
    display: block;
    text-align: center;
  }
}

.author-box .author-profile-info .author-profile-pic {
  width: 100px;
  height: 100px;
  border-radius: 0.25rem;
  overflow: hidden;
  min-width: 100px;
}

@media only screen and (max-width: 575px) {
  .author-box .author-profile-info .author-profile-pic {
    width: 80px;
    height: 80px;
    min-width: 80px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}

.author-box .author-profile-info .author-profile-pic img {
  width: 100%;
}

.author-box .author-profile-info .author-profile-content {
  padding-left: 20px;
}

@media only screen and (max-width: 575px) {
  .author-box .author-profile-info .author-profile-content {
    padding-left: 0;
  }
}

.author-box .author-profile-info .author-profile-content h6,
.author-box .author-profile-info .author-profile-content .h6 {
  font-size: 24px;
}

.author-box .author-profile-info .author-profile-content p {
  margin-bottom: 15px;
}

.author-box .author-profile-info .author-profile-content ul {
  padding: 0;
  margin: 0;
  margin-right: 5px;
  display: table;
  float: left;
}

@media only screen and (max-width: 575px) {
  .author-box .author-profile-info .author-profile-content ul {
    display: inline-block;
    float: none;
    margin-right: 0;
  }
}

.author-box .author-profile-info .author-profile-content ul li {
  padding: 0;
  margin: 0;
  margin-right: 10px;
  float: left;
}

@media only screen and (max-width: 575px) {
  .author-box .author-profile-info .author-profile-content ul li {
    margin-right: 5px;
    margin-left: 5px;
  }
}

.author-box .author-profile-info .author-profile-content ul li a {
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 40px;
  font-size: 14px;
  background: var(--primary);
  color: #fff;
  vertical-align: middle;
  display: block;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.author-box .author-profile-info .author-profile-content ul li a i {
  vertical-align: middle;
}

.inquiry-modal .modal-dialog {
  max-width: 700px;
  display: flex;
  min-height: auto;
  justify-content: stretch;
  align-items: stretch;
}

.inquiry-modal .form-control,
.inquiry-modal .woocommerce #review_form #respond input[type="text"],
.woocommerce #review_form #respond .inquiry-modal input[type="text"],
.inquiry-modal .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .inquiry-modal textarea,
.inquiry-modal .woocommerce #review_form #respond input[type="email"],
.woocommerce #review_form #respond .inquiry-modal input[type="email"],
.inquiry-modal .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .inquiry-modal .input-text,
.inquiry-modal .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .inquiry-modal input,
.inquiry-modal .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .inquiry-modal textarea,
.inquiry-modal .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .inquiry-modal select,
.inquiry-modal .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .inquiry-modal input,
.inquiry-modal .woocommerce-EditAccountForm.edit-account input[type="text"],
.woocommerce-EditAccountForm.edit-account .inquiry-modal input[type="text"],
.inquiry-modal .woocommerce-EditAccountForm.edit-account input[type="email"],
.woocommerce-EditAccountForm.edit-account .inquiry-modal input[type="email"],
.inquiry-modal .woocommerce-EditAccountForm.edit-account input[type="password"],
.woocommerce-EditAccountForm.edit-account .inquiry-modal input[type="password"],
.inquiry-modal .login input[type="text"],
.login .inquiry-modal input[type="text"],
.inquiry-modal .login input[type="password"],
.login .inquiry-modal input[type="password"],
.inquiry-modal .woocommerce-ResetPassword.lost_reset_password input[type="text"],
.woocommerce-ResetPassword.lost_reset_password .inquiry-modal input[type="text"],
.inquiry-modal .checkout.woocommerce-checkout input[type="text"],
.checkout.woocommerce-checkout .inquiry-modal input[type="text"],
.inquiry-modal .checkout.woocommerce-checkout input[type="email"],
.checkout.woocommerce-checkout .inquiry-modal input[type="email"],
.inquiry-modal .checkout.woocommerce-checkout input[type="tel"],
.checkout.woocommerce-checkout .inquiry-modal input[type="tel"],
.inquiry-modal .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .inquiry-modal .select2-choice,
.inquiry-modal .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .inquiry-modal textarea,
.inquiry-modal .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .inquiry-modal input.input-text,
.inquiry-modal .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .inquiry-modal .select2-selection,
.inquiry-modal .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .inquiry-modal .select2-choice {
  font-size: 15px;
  font-weight: 300;
  color: #000;
}

.inquiry-modal .inquiry-adv {
  flex: 0 0 50%;
  max-width: 50%;
}

@media only screen and (max-width: 767px) {
  .inquiry-modal .inquiry-adv {
    display: none;
  }
}

.inquiry-modal .inquiry-adv img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.inquiry-modal .modal-content {
  border: 0;
  border-radius: 0;
  background: #fff;
  padding: 30px;
  flex: 0 0 50%;
  max-width: 50%;
}

@media only screen and (max-width: 767px) {
  .inquiry-modal .modal-content {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 20px;
  }
}

.inquiry-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  display: block;
  text-align: center;
}

.inquiry-modal .modal-content .modal-header .modal-title {
  font-size: 24px;
  margin-bottom: 15px;
  line-height: 1.3;
  font-family: var(--font-family-title);
  text-align: center;
  width: 100%;
}

.inquiry-modal .modal-content .modal-header i {
  color: var(--primary);
  font-size: 54px;
  line-height: 1;
  display: inline-block;
}

.inquiry-modal .modal-content .modal-body {
  padding: 0;
}

.inquiry-modal .btn-close {
  margin: 0;
  position: absolute;
  right: -40px;
  top: -40px;
  color: #fff;
  font-weight: 100;
  text-shadow: none;
  opacity: 1;
  font-size: 40px;
  padding: 0;
  height: 40px;
  line-height: 40px;
  width: 40px;
  border: 0;
  background: transparent;
}

@media only screen and (max-width: 767px) {
  .inquiry-modal .btn-close {
    right: 0;
  }
}

.dzmove1 {
  animation: dzMove1 1s linear infinite;
}

.dzheart {
  animation: dzHeart 1s linear infinite;
}

@keyframes dzMove1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  25% {
    -webkit-transform: translate(10px, 10px);
    transform: translate(10px, 10px);
  }

  50% {
    -webkit-transform: translate(5px, 5px);
    transform: translate(5px, 5px);
  }

  75% {
    -webkit-transform: translate(10px, -5px);
    transform: translate(10px, -5px);
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes dzHeart {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(0.9);
  }
}

.nav-tabs.nav-tabs1 {
  border: 0;
  justify-content: space-between;
}

.nav-tabs.nav-tabs1 .nav-link {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  color: #777777;
  border-radius: 0;
  line-height: 18px;
  padding: 20px 30px;
}

.nav-tabs.nav-tabs1 .nav-link.active {
  color: var(--title);
  border: 1px solid #E1E1F0;
}

@media only screen and (max-width: 1280px) {
  .nav-tabs.nav-tabs1 .nav-link {
    font-size: 16px;
    line-height: 16px;
    padding: 18px 25px;
  }
}

@media only screen and (max-width: 575px) {
  .nav-tabs.nav-tabs1 .nav-link {
    font-size: 14px;
    line-height: 14px;
    padding: 15px;
  }
}

.tab-content {
  margin-top: 0px;
}

.dz-social-icon li {
  display: inline-block;
  margin-right: 5px;
}

.dz-social-icon li a {
  height: 40px;
  width: 40px;
  min-width: 40px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  border-radius: 40px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.dz-social-icon.style-1 {
  display: flex;
  margin-top: 30px;
}

.dz-social-icon.style-1 li {
  margin-right: 19px;
  color: #000;
}

.dz-social-icon.style-1 li a {
  color: var(--primary);
  background-color: var(--rgba-primary-1);
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  display: block;
}

.dz-social-icon.style-1 li a:hover {
  background-color: var(--primary);
  color: #fff;
}

@media only screen and (max-width: 1191px) {
  .dz-social-icon.style-1 {
    justify-content: center;
  }
}

.dz-social-icon.style-2 li a.twitter {
  background-color: #1da1f2;
}

.dz-social-icon.style-2 li a.facebook {
  background-color: #3b5998;
}

.dz-social-icon.style-2 li a.instagram {
  background-color: #c32aa3;
}

.dz-social-icon.style-2 li a.youtube {
  background-color: #FF0000;
}

.dz-social-icon.style-2 li a:hover {
  color: #fff;
  background-color: var(--primary);
}

.dz-social-icon.style-3 li {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: var(--border-radius-base);
}

.dz-social-icon.style-3 li:nth-child(1) {
  background-color: #3b5998;
}

.dz-social-icon.style-3 li:nth-child(2) {
  background-color: #1da1f2;
}

.dz-social-icon.style-3 li:nth-child(3) {
  background-color: #25d366;
}

.dz-social-icon.style-3 li:nth-child(4) {
  background-color: #db4439;
}

.dz-social-icon.style-3 li a {
  color: #ffffff;
  font-size: 16px;
  line-height: 1;
}

@media only screen and (max-width: 575px) {
  .dz-social-icon.style-3 {
    display: none;
  }
}

@-webkit-keyframes upToDown {
  49% {
    -webkit-transform: translateY(100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }

  51% {
    opacity: 1;
  }
}

@-moz-keyframes upToDown {
  49% {
    -moz-transform: translateY(100%);
  }

  50% {
    opacity: 0;
    -moz-transform: translateY(-100%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes upToDown {
  49% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  51% {
    opacity: 1;
  }
}

@-webkit-keyframes upToRight {
  49% {
    -webkit-transform: translateX(100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }

  51% {
    opacity: 1;
  }
}

@-moz-keyframes upToRight {
  49% {
    -moz-transform: translateX(100%);
  }

  50% {
    opacity: 0;
    -moz-transform: translateX(-100%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes upToRight {
  49% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  51% {
    opacity: 1;
  }
}

@-webkit-keyframes leftToRight {
  49% {
    -webkit-transform: translateX(100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }

  51% {
    opacity: 1;
  }
}

@-moz-keyframes leftToRight {
  49% {
    -moz-transform: translateX(100%);
  }

  50% {
    opacity: 0;
    -moz-transform: translateX(-100%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes leftToRight {
  49% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes movedelement {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  25% {
    -webkit-transform: translate(10px, 10px);
    transform: translate(10px, 10px);
  }

  50% {
    -webkit-transform: translate(5px, 5px);
    transform: translate(5px, 5px);
  }

  75% {
    -webkit-transform: translate(10px, -5px);
    transform: translate(10px, -5px);
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes movedelement2 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  25% {
    -webkit-transform: translate(-10px, -10px);
    transform: translate(-10px, -10px);
  }

  50% {
    -webkit-transform: translate(-5px, -5px);
    transform: translate(-5px, -5px);
  }

  75% {
    -webkit-transform: translate(-10px, 5px);
    transform: translate(-10px, 5px);
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes movedelement3 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  25% {
    -webkit-transform: translate(15px, 15px);
    transform: translate(15px, 15px);
  }

  50% {
    -webkit-transform: translate(8px, 8px);
    transform: translate(8px, 8px);
  }

  75% {
    -webkit-transform: translate(15px, -8px);
    transform: translate(15px, -8px);
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes movedelement4 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  25% {
    -webkit-transform: translate(-15px, -15px);
    transform: translate(-15px, -15px);
  }

  50% {
    -webkit-transform: translate(-8px, -8px);
    transform: translate(-8px, -8px);
  }

  75% {
    -webkit-transform: translate(-15px, 8px);
    transform: translate(-15px, 8px);
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes movedelement5 {
  0% {
    -webkit-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
  }

  25% {
    -webkit-transform: translate(10px, calc(-50% + 10px));
    transform: translate(10px, calc(-50% + 10px));
  }

  50% {
    -webkit-transform: translate(5px, calc(-50% + 5px));
    transform: translate(5px, calc(-50% + 5px));
  }

  75% {
    -webkit-transform: translate(10px, calc(-50% + -5px));
    transform: translate(10px, calc(-50% + -5px));
  }

  to {
    -webkit-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: translate(-50%, -50%) rotate(-45deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

@-moz-keyframes spin2 {
  100% {
    -moz-transform: translate(-50%, -50%) rotate(45deg);
  }
}

@-webkit-keyframes spin2 {
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
  }
}

@keyframes spin2 {
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.dz-divider {
  height: 1px;
  position: relative;
  margin: 30px 0;
}

.dz-divider.divider-2px {
  height: 2px;
}

.dz-divider.divider-3px {
  height: 2px;
}

.dz-divider.divider-4px {
  height: 2px;
}

.dz-divider i {
  position: absolute;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  display: block;
  border-radius: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.dz-divider.icon-left {
  margin-left: 40px;
}

.dz-divider.icon-left i {
  left: -40px;
}

.dz-divider.icon-right {
  margin-right: 40px;
}

.dz-divider.icon-right i {
  right: -40px;
}

.dz-divider.icon-center i {
  left: 50%;
  margin-left: -5px;
}

.bg-gray-dark {
  background-color: #d3d3d3;
}

.range-slider.style-1 {
  padding: 10px 15px 40px;
}

.range-slider.style-1 .noUi-horizontal {
  height: 8px;
}

.range-slider.style-1 .noUi-horizontal .noUi-handle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 0;
  background-color: var(--secondary);
  box-shadow: none;
  top: -9px;
}

.range-slider.style-1 .noUi-horizontal .noUi-handle:before,
.range-slider.style-1 .noUi-horizontal .noUi-handle:after {
  content: none;
}

.range-slider.style-1 .noUi-horizontal .noUi-handle .noUi-tooltip {
  border: 0px;
  background: var(--primary);
  color: white;
  border-radius: 4px;
  font-size: 13px;
  bottom: -35px;
  font-weight: 600;
  padding: 2px 5px;
}

.range-slider.style-1 .noUi-horizontal .noUi-handle .noUi-tooltip:after {
  content: "";
  position: absolute;
  background-color: inherit;
  width: 10px;
  height: 10px;
  top: 4px;
  margin-left: -4px;
  left: 50%;
  transform: rotate(45deg) translate(-50%, -50%);
}

.range-slider.style-1 .noUi-target {
  background: #F0EEFF;
  border-radius: 4px;
  border: 0;
  box-shadow: none;
}

.range-slider.style-1 .noUi-target .noUi-connect {
  background: var(--secondary);
}

.dropdown-menu {
  background-color: #f5f5f5;
}

.dropdown-menu .dropdown-item {
  padding: 5px 20px;
  font-size: 14px;
}

.dropdown-menu .dropdown-footer {
  padding: 10px 20px 10px;
}

.dropdown-menu .dropdown-header {
  padding: 10px 20px 10px;
}

.main-faq-content {
  position: relative;
}

.main-faq-content .faq-content-box {
  position: relative;
}

.main-faq-content .faq-content-box .faq-accordion {
  margin-top: 22px;
}

.main-faq-content .faq-content-box .faq-accordion .card {
  background: whitesmoke;
  box-shadow: none;
  border: none;
  border-radius: 6px !important;
  overflow: hidden;
  margin-bottom: 20px;
  display: block;
}

.main-faq-content .faq-content-box .faq-accordion .card:last-child {
  margin-bottom: 0;
}

.main-faq-content .faq-content-box .faq-accordion .card .card-body {
  background: none;
  padding: 20px 20px;
}

.main-faq-content .faq-content-box .faq-accordion .card .card-header {
  padding: 0;
  background: transparent;
  border: none;
  display: block;
  border-radius: 4px;
  margin-bottom: 0 !important;
}

.main-faq-content .faq-content-box .faq-accordion .card .card-header .accordion-button {
  height: 55px;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0px;
  padding: 5px 60px 5px 20px;
  background: var(--primary);
  color: white;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease 0s;
}

.main-faq-content .faq-content-box .faq-accordion .card .card-header .accordion-button:after {
  content: none;
}

.main-faq-content .faq-content-box .faq-accordion .card .card-header .accordion-button .icon {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 45px;
  height: 45px;
  background: white;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.main-faq-content .faq-content-box .faq-accordion .card .card-header .accordion-button .icon i {
  position: relative;
  color: var(--primary);
  transform: rotate(270deg);
  transition: 0.3s;
  z-index: 1;
}

.main-faq-content .faq-content-box .faq-accordion .card .card-header .accordion-button.collapsed .icon i {
  transform: rotate(180deg);
}

.content-box.style-1 {
  padding: 30px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: var(--border-radius-base);
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.content-box.style-1 .dz-info {
  margin-bottom: 30px;
}

.content-box.style-1 .dz-info .title {
  line-height: 1.2;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 20px;
  margin-bottom: 20px;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.content-box.style-1 .dz-bottom {
  position: relative;
  z-index: 1;
}

.content-box.style-1 .dz-bottom:after {
  content: "";
  position: absolute;
  right: 0;
  background: #e9e9e9;
  height: 1px;
  width: 60%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.content-box.style-1:hover {
  background-color: var(--primary);
  color: #fff;
}

.content-box.style-1:hover .dz-info .title {
  color: #fff;
}

.content-box.style-1:hover .dz-bottom a {
  color: #fff;
}

.icon-bx-xl {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 150px;
  height: 150px;
  line-height: 150px;
}

.icon-bx-xl.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.icon-bx-xl i {
  font-size: 80px;
  vertical-align: middle;
}

.icon-bx-xl img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 80px;
}

.icon-bx-lg {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 120px;
  height: 120px;
  line-height: 120px;
}

.icon-bx-lg.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.icon-bx-lg i {
  font-size: 50px;
  vertical-align: middle;
}

.icon-bx-lg img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 55px;
}

.icon-bx-md {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.icon-bx-md.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.icon-bx-md i {
  font-size: 35px;
  vertical-align: middle;
}

.icon-bx-md img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 40px;
}

.icon-bx-sm {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 60px;
  height: 60px;
  min-width: 60px;
  line-height: 60px;
}

.icon-bx-sm.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.icon-bx-sm i {
  font-size: 26px;
  vertical-align: middle;
}

.icon-bx-sm img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 26px;
}

.icon-bx-xs {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 60px;
  height: 60px;
  line-height: 60px;
}

.icon-bx-xs.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.icon-bx-xs i {
  font-size: 30px;
  vertical-align: middle;
}

.icon-bx-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 20px;
}

.icon-xl {
  display: inline-block;
  text-align: center;
  width: 100px;
}

.icon-xl i {
  vertical-align: middle;
  font-size: 80px;
}

.icon-xl img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 80px;
}

.icon-lg {
  display: inline-block;
  text-align: center;
  line-height: 1;
}

.icon-lg i {
  vertical-align: middle;
  font-size: 60px;
}

.icon-lg img {
  vertical-align: middle;
  max-width: 100%;
  min-width: 65px;
  width: 65px;
}

.icon-md {
  display: inline-block;
  text-align: center;
}

.icon-md i {
  vertical-align: middle;
  font-size: 45px;
}

.icon-md img {
  vertical-align: middle;
  max-width: 100%;
  min-width: 45px;
  width: 45px;
}

.icon-sm {
  display: inline-block;
  text-align: center;
  width: 40px;
}

.icon-sm i {
  vertical-align: middle;
  font-size: 30px;
}

.icon-sm img {
  vertical-align: middle;
  max-width: 100%;
  min-width: 30px;
  width: 30px;
}

.icon-xs {
  display: inline-block;
  text-align: center;
  width: 30px;
}

.icon-xs i {
  vertical-align: middle;
  font-size: 20px;
}

.icon-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 20px;
}

.icon-bx-wraper {
  position: relative;
}

.icon-bx-wraper .dz-tilte {
  margin-top: 0;
}

.icon-bx-wraper .after-titile-line {
  margin-bottom: 10px;
}

.icon-bx-wraper p:last-child {
  margin: 0;
}

.icon-bx-wraper.center {
  text-align: center;
}

.icon-bx-wraper.left .icon-bx-xl,
.icon-bx-wraper.left .icon-bx-lg,
.icon-bx-wraper.left .icon-bx-md,
.icon-bx-wraper.left .icon-bx-sm,
.icon-bx-wraper.left .icon-bx-xs {
  float: left;
  margin-right: 20px;
}

.icon-bx-wraper.left .icon-xl,
.icon-bx-wraper.left .icon-lg,
.icon-bx-wraper.left .icon-md,
.icon-bx-wraper.left .icon-sm,
.icon-bx-wraper.left .icon-xs {
  float: left;
  margin-right: 10px;
}

.icon-bx-wraper.right {
  text-align: right;
}

.icon-bx-wraper.right .icon-bx-xl,
.icon-bx-wraper.right .icon-bx-lg,
.icon-bx-wraper.right .icon-bx-md,
.icon-bx-wraper.right .icon-bx-sm,
.icon-bx-wraper.right .icon-bx-xs {
  float: right;
  margin-left: 20px;
}

.icon-bx-wraper.right .icon-xl,
.icon-bx-wraper.right .icon-lg,
.icon-bx-wraper.right .icon-md,
.icon-bx-wraper.right .icon-sm,
.icon-bx-wraper.right .icon-xs {
  float: right;
  margin-left: 10px;
}

.icon-bx-wraper.bx-style-1,
.icon-bx-wraper.bx-style-2 {
  border-width: 1px;
  border-style: solid;
  border-color: #ebedf2;
}

.icon-bx-wraper.bx-style-2.center [class*="icon-bx-"] {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-bx-wraper.bx-style-2.center .icon-bx-xl {
  margin-left: -75px;
}

.icon-bx-wraper.bx-style-2.center .icon-bx-lg {
  margin-left: -60px;
}

.icon-bx-wraper.bx-style-2.center .icon-bx-md {
  margin-left: -50px;
}

.icon-bx-wraper.bx-style-2.center .icon-bx-sm {
  margin-left: -40px;
}

.icon-bx-wraper.bx-style-2.center .icon-bx-xs {
  margin-left: -20px;
}

.icon-bx-wraper.bx-style-2.left [class*="icon-bx-"] {
  position: absolute;
  top: auto;
  left: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.icon-bx-wraper.bx-style-2.right [class*="icon-bx-"] {
  position: absolute;
  top: auto;
  right: 0;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

.icon-content {
  overflow: hidden;
}

[class*="icon-bx-"][class*="bg-"] a,
[class*="icon-bx-"][class*="bg-"] span {
  color: #ffffff;
}

[class*="icon-bx-"].bg-white a {
  color: inherit;
}

[class*="icon-bx-"][class*="border-"] {
  display: table;
  line-height: normal;
  margin-left: auto;
  margin-right: auto;
}

[class*="icon-bx-"][class*="border-"] .icon-cell {
  display: table-cell;
  vertical-align: middle;
}

.dz-box,
.dz-info,
.dz-tilte,
.dz-tilte-inner {
  position: relative;
}

.dz-tilte-inner {
  display: inline-block;
}

.dz-box[class*="border-"],
.dz-info[class*="border-"] {
  border-color: #eee;
}

.dz-info.border-1,
.dz-info.border-2,
.dz-info.border-3,
.dz-info.border-4,
.dz-info.border-5 {
  border-top: none;
}

.border-1,
.border-2,
.border-3,
.border-4,
.border-5 {
  border-style: solid;
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-5 {
  border-width: 5px;
}

.left-border,
.right-border {
  position: relative;
}

.left-border:before,
.right-border:before {
  content: "";
  position: absolute;
  top: 5px;
  width: 1px;
  height: 90%;
  background: #CCC;
}

.right-border:before {
  right: 0;
}

.dz-media,
.dz-post-media {
  position: relative;
  overflow: hidden;
}

.dz-media img,
.dz-post-media img {
  max-width: 100%;
  height: auto;
  width: 100%;
}

.dz-box-bg {
  overflow: hidden;
  background-size: cover;
}

.dz-box-bg .btn,
.dz-box-bg .wp-block-button__link,
.dz-box-bg .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .dz-box-bg a,
.dz-box-bg .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .dz-box-bg a,
.dz-box-bg .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .dz-box-bg input,
.dz-box-bg .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .dz-box-bg .button,
.dz-box-bg .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .dz-box-bg a.checkout-button,
.dz-box-bg .woocommerce #respond input#submit,
.woocommerce #respond .dz-box-bg input#submit,
.dz-box-bg .woocommerce a.button,
.woocommerce .dz-box-bg a.button,
.dz-box-bg .woocommerce button.button,
.woocommerce .dz-box-bg button.button,
.dz-box-bg .woocommerce input.button,
.woocommerce .dz-box-bg input.button,
.dz-box-bg .woocommerce #place_order,
.woocommerce .dz-box-bg #place_order {
  overflow: unset;
}

.dz-box-bg .glyph-icon {
  font-size: 50px;
}

.dz-box-bg .icon-bx-wraper {
  background-color: #f8f8f8;
}

.dz-box-bg .text-primary,
.dz-box-bg .icon-content .dz-tilte,
.dz-box-bg .icon-content p,
.dz-box-bg .icon-box-btn .btn,
.dz-box-bg .icon-box-btn .wp-block-button__link,
.dz-box-bg .icon-box-btn .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .dz-box-bg .icon-box-btn a,
.dz-box-bg .icon-box-btn .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .dz-box-bg .icon-box-btn a,
.dz-box-bg .icon-box-btn .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .dz-box-bg .icon-box-btn input,
.dz-box-bg .icon-box-btn .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .dz-box-bg .icon-box-btn .button,
.dz-box-bg .icon-box-btn .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .dz-box-bg .icon-box-btn a.checkout-button,
.dz-box-bg .icon-box-btn .woocommerce #respond input#submit,
.woocommerce #respond .dz-box-bg .icon-box-btn input#submit,
.dz-box-bg .icon-box-btn .woocommerce a.button,
.woocommerce .dz-box-bg .icon-box-btn a.button,
.dz-box-bg .icon-box-btn .woocommerce button.button,
.woocommerce .dz-box-bg .icon-box-btn button.button,
.dz-box-bg .icon-box-btn .woocommerce input.button,
.woocommerce .dz-box-bg .icon-box-btn input.button,
.dz-box-bg .icon-box-btn .woocommerce #place_order,
.woocommerce .dz-box-bg .icon-box-btn #place_order {
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.dz-box-bg.active .icon-bx-wraper {
  background-color: rgba(0, 0, 0, 0.7);
}

.dz-box-bg.active .text-primary,
.dz-box-bg.active .icon-content .dz-tilte,
.dz-box-bg.active .icon-content p,
.dz-box-bg.active .icon-box-btn .btn,
.dz-box-bg.active .icon-box-btn .wp-block-button__link,
.dz-box-bg.active .icon-box-btn .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .dz-box-bg.active .icon-box-btn a,
.dz-box-bg.active .icon-box-btn .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .dz-box-bg.active .icon-box-btn a,
.dz-box-bg.active .icon-box-btn .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .dz-box-bg.active .icon-box-btn input,
.dz-box-bg.active .icon-box-btn .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .dz-box-bg.active .icon-box-btn .button,
.dz-box-bg.active .icon-box-btn .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .dz-box-bg.active .icon-box-btn a.checkout-button,
.dz-box-bg.active .icon-box-btn .woocommerce #respond input#submit,
.woocommerce #respond .dz-box-bg.active .icon-box-btn input#submit,
.dz-box-bg.active .icon-box-btn .woocommerce a.button,
.woocommerce .dz-box-bg.active .icon-box-btn a.button,
.dz-box-bg.active .icon-box-btn .woocommerce button.button,
.woocommerce .dz-box-bg.active .icon-box-btn button.button,
.dz-box-bg.active .icon-box-btn .woocommerce input.button,
.woocommerce .dz-box-bg.active .icon-box-btn input.button,
.dz-box-bg.active .icon-box-btn .woocommerce #place_order,
.woocommerce .dz-box-bg.active .icon-box-btn #place_order {
  color: #fff;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.dz-box-bg .icon-box-btn .btn,
.dz-box-bg .icon-box-btn .wp-block-button__link,
.dz-box-bg .icon-box-btn .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .dz-box-bg .icon-box-btn a,
.dz-box-bg .icon-box-btn .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .dz-box-bg .icon-box-btn a,
.dz-box-bg .icon-box-btn .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .dz-box-bg .icon-box-btn input,
.dz-box-bg .icon-box-btn .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .dz-box-bg .icon-box-btn .button,
.dz-box-bg .icon-box-btn .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .dz-box-bg .icon-box-btn a.checkout-button,
.dz-box-bg .icon-box-btn .woocommerce #respond input#submit,
.woocommerce #respond .dz-box-bg .icon-box-btn input#submit,
.dz-box-bg .icon-box-btn .woocommerce a.button,
.woocommerce .dz-box-bg .icon-box-btn a.button,
.dz-box-bg .icon-box-btn .woocommerce button.button,
.woocommerce .dz-box-bg .icon-box-btn button.button,
.dz-box-bg .icon-box-btn .woocommerce input.button,
.woocommerce .dz-box-bg .icon-box-btn input.button,
.dz-box-bg .icon-box-btn .woocommerce #place_order,
.woocommerce .dz-box-bg .icon-box-btn #place_order {
  background-color: #eeeeee;
  padding: 15px 20px;
  text-transform: uppercase;
  font-size: 15px;
  border-radius: 0;
  color: #1abc9c;
  font-weight: 600;
  margin-bottom: 0;
}

.flip-bx {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.flip-bx .inner {
  -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
  transform: translateY(-50%) translateZ(60px) scale(0.94);
  top: 50%;
  position: absolute;
  left: 0;
  width: 100%;
  padding: 2rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 1px solid transparent;
  -webkit-perspective: inherit;
  perspective: inherit;
  z-index: 2;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flip-bx .front,
.flip-bx .back {
  background-size: cover;
  background-position: center;
  -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: center;
  min-height: 400px;
  height: auto;
  color: #fff;
}

.flip-bx .back {
  background: var(--secondary);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flip-bx .back .inner p {
  font-size: 16px;
  color: #fff;
}

.flip-bx .back .inner .site-button-link {
  font-size: 16px;
}

.flip-bx .front {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flip-bx .front:after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  content: '';
  display: block;
  opacity: .4;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 4px;
}

.flip-bx:hover .back,
.flip-bx:hover .front {
  -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
}

.flip-bx:hover .back {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flip-bx:hover .front {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.icon-bx-wraper.style-1 [class*="icon-bx-"] {
  background-color: var(--rgba-primary-1);
  border-radius: var(--border-radius-base);
  -webkit-transition: all 2s;
  -ms-transition: all 2s;
  transition: all 2s;
  margin-bottom: 25px;
}

.icon-bx-wraper.style-1 [class*="icon-bx-"] .icon-cell {
  color: var(--primary);
  display: block;
}

.icon-bx-wraper.style-1:hover [class*="icon-bx-"] {
  animation: dash 5s linear infinite;
  background-color: var(--primary);
}

.icon-bx-wraper.style-1:hover [class*="icon-bx-"] .icon-cell {
  animation: shake 1s;
  color: #ffffff;
}

.icon-bx-wraper.style-1 p {
  color: #AAAAAA;
}

.icon-bx-wraper.style-2 [class*="icon-bx-"] {
  border-radius: var(--border-radius-base);
  margin-bottom: 30px;
  position: relative;
  background: linear-gradient(90deg, var(--primary) 50%, transparent 50%), linear-gradient(90deg, var(--primary) 50%, transparent 50%), linear-gradient(0deg, var(--primary) 50%, transparent 50%), linear-gradient(0deg, var(--primary) 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 8px 3px, 8px 3px, 3px 8px, 3px 8px;
  background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
}

.icon-bx-wraper.style-2 [class*="icon-bx-"] .icon-cell {
  color: var(--primary);
}

.icon-bx-wraper.style-2:hover [class*="icon-bx-"] {
  animation: dash 5s linear infinite;
}

.icon-bx-wraper.style-2:hover .icon-cell {
  animation: shake 1s;
}

@media screen and (max-width: 768px) {
  .icon-bx-wraper.style-2 [class*="icon-bx-"] {
    width: 80px;
    height: 80px;
    line-height: 80px;
    margin-bottom: 15px;
    background-size: 8px 2px, 8px 2px, 2px 8px, 2px 8px;
  }

  .icon-bx-wraper.style-2 [class*="icon-bx-"] .icon-cell {
    font-size: 40px;
  }

  .icon-bx-wraper.style-2 .icon-content .dz-title {
    margin-bottom: 0 !important;
  }

  .icon-bx-wraper.style-2 .icon-content p {
    font-size: 16px;
  }
}

.icon-bx-wraper.style-3 {
  box-shadow: 1px 1.732px 60px 0px rgba(0, 0, 0, 0.1);
  padding: 45px;
  border-radius: 14px;
  background-color: #ffffff;
  border: 2px solid transparent;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.icon-bx-wraper.style-3 [class*="icon-lg"] .icon-cell {
  color: #1a1668;
  margin-bottom: 30px;
}

.icon-bx-wraper.style-3 .icon-content .title {
  margin-bottom: 15px;
}

.icon-bx-wraper.style-3 .icon-content p {
  margin-bottom: 20px;
}

.icon-bx-wraper.style-3 .icon-content a {
  font-size: 18px;
  font-weight: 600;
}

.icon-bx-wraper.style-3:hover {
  transform: translateY(-20px);
  border: 2px solid var(--primary);
}

.icon-bx-wraper.style-3:hover [class*="icon-lg"] .icon-cell {
  color: var(--primary);
}

@media screen and (max-width: 1200px) {
  .icon-bx-wraper.style-3 {
    padding: 22px 24px 28px;
  }
}

@media screen and (max-width: 1024px) {
  .icon-bx-wraper.style-3 .icon-content .title {
    font-size: 25px;
  }
}

@media screen and (max-width: 991px) {
  .icon-bx-wraper.style-3 {
    margin-top: 20px;
  }
}

.icon-bx-wraper.style-4 {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.icon-bx-wraper.style-4 [class*="icon-bx-"] {
  background-color: #FFF7EE;
  border-radius: 60px;
  min-width: 90px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  width: 90px;
  height: 90px;
  line-height: 90px;
}

.icon-bx-wraper.style-4 [class*="icon-bx-"] .icon-cell {
  display: block;
  color: #EAA451;
  font-weight: 600;
  font-size: 25px;
}

.icon-bx-wraper.style-4:hover [class*="icon-bx-"] {
  background-color: var(--primary);
}

.icon-bx-wraper.style-4:hover [class*="icon-bx-"] .icon-cell {
  color: #ffffff;
}

.icon-bx-wraper.style-4 .icon-content {
  padding-left: 28px;
}

.icon-bx-wraper.style-4 .icon-content p {
  font-size: 18px;
  font-family: cairo;
}

@media screen and (max-width: 1380px) {
  .icon-bx-wraper.style-4 [class*="icon-bx-"] {
    min-width: 75px;
    width: 75px;
    height: 75px;
    line-height: 75px;
  }

  .icon-bx-wraper.style-4 .icon-content {
    padding-left: 10px;
  }
}

.icon-bx-wraper.style-5 {
  text-align: center;
  border: 1px solid #D7D7D7;
  margin-bottom: 30px;
  padding: 30px 20px;
  border-radius: 14px;
}

.icon-bx-wraper.style-5 [class*="icon-bx-"] {
  width: 100px;
  min-width: 100px;
  height: 100px;
  line-height: 100px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  background-color: var(--primary);
  color: #ffffff;
  border-radius: 50%;
  margin-bottom: 15px;
}

.icon-bx-wraper.style-5 .icon-content p {
  font-size: 18px;
  font-weight: 500;
  font-family: var(--font-family-title);
  color: #131045;
}

@media only screen and (max-width: 1280px) {
  .icon-bx-wraper.style-5 .icon-content p {
    font-size: 16px;
  }
}

.icon-bx-wraper.style-5:hover [class*="icon-bx-"] {
  background-color: var(--rgba-primary-1);
}

.icon-bx-wraper.style-5:hover [class*="icon-bx-"] .icon-cell {
  color: var(--primary);
}

@keyframes dash {
  to {
    background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
  }
}

.dz-blog .post-tags {
  display: block !important;
  margin-bottom: 10px;
}

.dz-blog .post-tags a {
  margin-left: 8px;
  font-weight: 400;
  color: inherit;
  display: inline-block;
}

.dz-blog .post-tags a:hover {
  color: var(--primary);
}

.dz-blog .post-tags strong {
  font-weight: 700;
}

.dz-blog.no-image {
  padding: 30px;
  background: var(--rgba-primary-05);
}

.dz-blog .dz-meta {
  margin-bottom: 10px;
}

.dz-blog .dz-meta>ul>li {
  font-weight: 400;
  font-size: 15px;
  display: inline;
  margin-right: 20px;
}

.dz-blog .dz-meta>ul>li a {
  color: inherit;
}

.dz-blog .dz-meta>ul>li i {
  font-size: 16px;
  position: relative;
  margin-right: 5px;
  color: var(--primary);
}

@media only screen and (max-width: 1280px) {
  .dz-blog .dz-meta>ul>li i {
    font-size: 18px;
    margin-right: 5px;
  }
}

@media only screen and (max-width: 1024px) {
  .dz-blog .dz-meta>ul>li {
    font-size: 14px;
    margin-right: 3px;
  }
}

@media only screen and (max-width: 991px) {
  .dz-blog .dz-meta>ul>li {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-blog .dz-meta>ul>li.post-comment {
    display: none;
  }
}

.dz-blog .dz-meta>ul>li.post-category a {
  margin-right: 0;
  padding-right: 3px;
  padding-left: 3px;
}

.dz-blog .dz-meta>ul>li.post-author a {
  color: inherit;
  margin-left: 3px;
}

.dz-blog .dz-meta>ul>li.post-share {
  position: relative;
}

.dz-blog .dz-meta>ul>li.post-share ul {
  display: inline-block;
  position: absolute;
  right: 40px;
  background-color: var(--primary);
  box-shadow: -2px 9px 20px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  border-radius: 4px;
  transform: scale(0.8);
  -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  z-index: 2;
  opacity: 0;
  padding: 5px;
  visibility: hidden;
}

.dz-blog .dz-meta>ul>li.post-share ul:after {
  content: "";
  position: absolute;
  right: -7px;
  top: 50%;
  background: var(--primary);
  width: 8px;
  height: 8px;
  display: block;
  transform: rotate(45deg) translateX(-50%);
  -moz-transform: rotate(45deg) translateX(-50%);
  -webkit-transform: rotate(45deg) translateX(-50%);
  -ms-transform: rotate(45deg) translateX(-50%);
  -o-transform: rotate(45deg) translateX(-50%);
}

.dz-blog .dz-meta>ul>li.post-share ul li a {
  width: 30px;
  height: 30px;
  display: block;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
}

.dz-blog .dz-meta>ul>li.post-share:hover ul {
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  right: 40px;
  opacity: 1;
  visibility: visible;
}

.dz-blog .dz-title {
  margin-bottom: 10px;
  line-height: 1.4;
  word-break: break-word;
}

.dz-blog .dz-media+.dz-info {
  padding-top: 30px;
}

.dz-blog.blog-half .dz-info {
  padding-top: 0;
  align-self: center;
  flex: 1;
}

.dz-blog.style-1 {
  border-radius: 0.375rem;
  overflow: hidden;
}

.dz-blog.style-1 .dz-info {
  padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
  background: #fff;
}

@media only screen and (max-width: 575px) {
  .dz-blog.style-1 .dz-info {
    padding: 15px;
  }
}

.dz-blog.style-1 .dz-info .dz-meta {
  border-bottom: 1px solid #E1E1F0;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.dz-blog.style-1 .dz-info .meta-bottom {
  border-top: 1px solid #E1E1F0;
  border-bottom: 0;
  padding: 25px 0 0;
  margin: 20px 0 0;
}

@media only screen and (max-width: 575px) {
  .dz-blog.style-1 .dz-info .meta-bottom {
    padding: 15px 0 0;
  }
}

.dz-blog.style-1 .dz-media+.dz-info {
  border-top: 0;
  border-radius: 0 0 6px 6px;
}

.dz-blog.blog-half.style-1 {
  display: flex;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.dz-blog.blog-half.style-1.blog-half-sm .dz-media {
  width: 150px;
  min-width: 150px;
}

.dz-blog.blog-half.style-1.blog-half-sm .dz-media img {
  min-height: 180px;
}

@media only screen and (max-width: 767px) {
  .dz-blog.blog-half.style-1.blog-half-sm .dz-media {
    width: 100%;
    min-width: 100%;
  }
}

.dz-blog.blog-half.style-1 .dz-media {
  max-height: 200px;
}

.dz-blog.blog-half.style-1 .dz-media img {
  height: 100%;
  object-fit: cover;
}

.dz-blog.blog-half.style-1 .dz-media a {
  height: 100%;
}

.dz-blog.blog-half.style-1 .dz-info {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.dz-blog.blog-half.style-1 .dz-media+.dz-info {
  border-left: 0;
  border-radius: 0 6px 6px 0;
}

.dz-blog.blog-half.style-1 .dz-title {
  margin-bottom: 10px;
  line-height: 1.3;
}

.dz-blog.blog-half.style-1 p {
  margin-bottom: 20px;
  font-size: 15px;
  text-align: justify;
}

.dz-blog.blog-half.style-1 .dz-meta {
  margin-bottom: 0;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .dz-blog.blog-half.style-1 {
    display: block;
    padding: 20px;
  }

  .dz-blog.blog-half.style-1 .dz-media {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .dz-blog.blog-half.style-1 .dz-media {
    margin-bottom: 0;
    border-radius: 6px 6px 0 0;
  }

  .dz-blog.blog-half.style-1 .dz-info {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-left: 1px solid rgba(0, 0, 0, 0.125) !important;
    border-radius: 0px 0px 6px 6px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-blog.blog-half.style-1 {
    padding: 0px;
  }
}

.dz-card.style-2 {
  margin-bottom: 10px;
}

.dz-card.style-2 .dz-media {
  border-radius: 12px 12px 0 0;
}

.dz-card.style-2 .dz-media:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  color: rgba(19, 16, 69, 0.87);
}

.dz-card.style-2 .dz-info {
  border: 1px solid #F0F0F0;
  padding: 33px 32px 30px;
  border-radius: 0 0 var(--border-radius-base) var(--border-radius-base);
}

@media only screen and (max-width: 575px) {
  .dz-card.style-2 .dz-info {
    padding: 25px 15px;
  }
}

.dz-card.style-2 .dz-info .dz-title {
  margin-bottom: 5px;
}

.dz-card.style-2 .dz-info .dz-tags {
  margin-bottom: 10px;
}

.dz-card.style-2 .dz-info .dz-tags li {
  display: inline-block;
}

.dz-card.style-2 .dz-info .dz-tags li a {
  font-size: 12px;
  font-weight: 600;
  padding: 4px 8px;
  background-color: #f2f2f2;
  border-radius: var(--border-radius-base);
  color: var(--secondary);
}

.dz-card.style-2 .dz-info .dz-tags li a:hover {
  background-color: var(--primary);
  color: #fff;
}

.dz-card.style-2 .dz-info .bookcard-footer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  position: relative;
}

.dz-card.style-2 .dz-info .bookcard-footer .price-details p {
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  font-family: var(--font-family-title);
}

.dz-card.style-2 .dz-info .bookcard-footer .price-details p span {
  font-size: 20px;
  font-weight: var(--headings-font-weight);
  color: #AAAAAA;
  text-decoration: line-through;
  margin-left: 7px;
}

.post-carousel.owl-theme {
  position: relative;
}

.post-carousel.owl-theme .owl-dots {
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.post-carousel.owl-theme .owl-dots .owl-dot span {
  background: transparent;
  width: 12px;
  height: 12px;
  border: 2px solid #fff;
}

.post-carousel.owl-theme .owl-dots .owl-dot.active span {
  background: var(--primary);
  border: 2px solid var(--primary);
}

.post-carousel.owl-theme .owl-nav {
  margin: 0;
}

.post-carousel.owl-theme .owl-.owl-prev,
.post-carousel.owl-theme .owl-.owl-next {
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  border-radius: 4px;
  font-size: 22px;
}

.post-carousel.owl-theme .owl-.owl-prev:hover,
.post-carousel.owl-theme .owl-.owl-next:hover {
  background: var(--primary);
}

.post-video {
  position: relative;
}

.post-video a {
  display: block;
  position: relative;
}

.post-video .post-video-icon {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  font-size: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  border-radius: 4px;
}

.post-video .post-video-icon:hover {
  background: var(--primary);
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .post-video .post-video-icon {
    width: 50px;
    height: 50px;
    font-size: 24px;
    padding-left: 2px;
  }
}

.post-quote.style-1 {
  background: var(--rgba-primary-05);
  position: relative;
}

.post-quote.style-1 .post-quote-icon {
  position: absolute;
  width: 50px;
  left: 40px;
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.post-quote.style-1 .post-quote-icon svg {
  fill: var(--primary);
}

.post-quote.style-1 .dz-title {
  margin-bottom: 0;
}

.post-quote.style-1 .dz-info {
  padding: 50px;
  padding-left: 120px;
}

@media only screen and (max-width: 575px) {
  .post-quote.style-1 .post-quote-icon {
    left: 14px;
  }

  .post-quote.style-1 .dz-info {
    padding: 30px 30px 30px 70px;
  }
}

@media only screen and (max-width: 991px) {
  .dz-order-1 {
    order: 1;
    margin-top: 20px;
  }
}

.type-post.sticky,
.type-post.tag-sticky-2.post {
  position: relative;
  border: 0.375rem solid var(--primary);
  overflow: hidden;
}

.type-post.sticky .sticky-icon,
.type-post.tag-sticky-2.post .sticky-icon {
  background: var(--primary);
  width: 3.125rem;
  height: 3.125rem;
  position: absolute;
  transform: rotate(45deg);
  top: -1.563rem;
  right: -1.563rem;
  z-index: 1;
  color: #fff;
}

.type-post.sticky .sticky-icon i,
.type-post.tag-sticky-2.post .sticky-icon i {
  position: absolute;
  bottom: 0.1875rem;
  right: 1.125rem;
  transform: rotate(5deg);
  color: #fff;
  font-size: 1rem;
}

.testimonial-pic {
  background: #FFF;
  width: 100px;
  height: 100px;
  position: relative;
  display: inline-block;
  border: 2px solid #FFF;
}

.testimonial-pic.radius {
  border-radius: 100%;
  -webkit-border-radius: 100%;
}

.testimonial-pic.radius img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  -webkit-border-radius: 100%;
}

.testimonial-pic.shadow {
  -webkit-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
  box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
}

.quote-left,
.quote-right {
  position: relative;
}

.quote-left:before,
.quote-right:before {
  font-family: "Flaticon";
  position: absolute;
  z-index: 10;
  height: 36px;
  width: 36px;
  line-height: 36px;
  background-color: var(--primary);
  color: #ffffff;
  border-radius: 30px;
  text-align: center;
  font-size: 18px;
}

.quote-left:before {
  top: 15px;
  content: "\f115";
  left: 0;
}

.quote-right:before {
  top: 15px;
  content: "\f11e";
  right: 0;
}

.testimonial-text {
  position: relative;
  font-weight: 400;
}

.testimonial-text p:last-child {
  margin: 0;
}

.testimonial-detail {
  padding: 5px;
}

.testimonial-position {
  font-size: 15px;
}

.testimonial-name,
.testimonial-position {
  display: block;
}

.testimonial-bg {
  color: #fff;
}

.star-rating li {
  display: inline-block;
}

.testimonial-1 {
  padding: 40px;
  border-radius: 14px;
  background: var(--grey);
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
}

.testimonial-1 .dz-rating {
  margin: 0 -3px 20px;
}

.testimonial-1 .dz-rating li {
  padding: 0px 3px;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
}

.testimonial-1 .testimonial-text p {
  font-size: 18px;
  margin: 0;
  font-weight: 500;
  font-style: italic;
}

.testimonial-1 .testimonial-pic {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
}

.testimonial-1 .testimonial-detail {
  align-items: center;
  display: flex;
  margin-top: 30px;
  padding: 0;
}

.testimonial-1 .testimonial-detail .testimonial-name {
  margin-bottom: 5px;
}

.testimonial-1 .testimonial-detail .testimonial-position {
  font-size: 14px;
  font-weight: var(--headings-font-weight2);
}

.testimonial-1:hover {
  background: var(--primary);
  color: #fff;
}

.testimonial-1:hover .dz-rating li i.text-muted {
  color: #ffffff61;
}

.testimonial-1:hover .dz-rating li i.text-yellow {
  color: #ffffff;
}

.testimonial-1:hover .testimonial-name {
  color: #ffffff;
}

@media only screen and (max-width: 600px) {
  .testimonial-1 {
    margin: 0 50px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-1 {
    margin: 0 14px;
  }
}

.testimonial-swiper {
  padding: 0 15px;
}

.testimonial-swiper-2 .swiper-slide {
  opacity: 0.5;
}

.testimonial-swiper-2 .swiper-slide.swiper-slide-active {
  opacity: 1;
}

.testimonial-2 {
  display: flex;
  position: relative;
  align-items: center;
}

.testimonial-2 .test-quotes {
  position: absolute;
  z-index: 1;
  font-size: 105px;
  bottom: 0;
  color: var(--primary);
  right: 20px;
}

.testimonial-2 .pattern {
  position: absolute;
  z-index: 1;
  left: -68px;
  bottom: 49px;
}

.testimonial-2 .testimonial-pic {
  min-width: 365px;
  height: 369px;
  border: none;
  position: relative;
}

.testimonial-2 .testimonial-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--border-radius-base);
}

@media only screen and (max-width: 991px) {
  .testimonial-2 .testimonial-pic {
    min-width: 120px;
    height: 120px;
    border-radius: 120px;
  }
}

.testimonial-2 .testimonial-info {
  padding: 0 30px;
}

.testimonial-2 .testimonial-info .dz-rating li {
  padding: 0px 2px;
  font-size: 18px;
}

.testimonial-2 .testimonial-info .testimonial-text {
  font-size: 18px;
  font-style: italic;
}

.testimonial-2 .testimonial-info .testimonial-detail {
  display: block;
  margin-top: 30px;
  justify-content: space-between;
}

.testimonial-2 .testimonial-info .testimonial-detail .dz-rating-bar {
  display: flex;
  align-items: center;
  margin-top: 20px;
  line-height: 1;
}

.testimonial-2 .testimonial-info .testimonial-detail .dz-rating-bar .dz-rating {
  display: flex;
}

.testimonial-2 .testimonial-info .testimonial-detail .dz-rating-bar .rate {
  font-size: 20px;
  font-weight: var(--headings-font-weight);
  font-family: var(--font-family-title);
  margin-left: 14px;
  margin-left: -2px;
  margin-left: 15px;
  color: var(--title);
}

@media only screen and (max-width: 1280px) {
  .testimonial-2 .testimonial-info .testimonial-detail {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .testimonial-2 .testimonial-pic {
    align-self: start;
  }

  .testimonial-2 .testimonial-info {
    padding: 0 0px 0 25px;
  }

  .testimonial-2 .testimonial-info .testimonial-text {
    font-size: 15px;
  }

  .testimonial-2 .testimonial-info .testimonial-detail {
    display: block;
    margin-top: 15px;
  }

  .testimonial-2 .testimonial-info .testimonial-detail .testimonial-name {
    font-size: 16px;
  }

  .testimonial-2 .testimonial-info .testimonial-detail .testimonial-position {
    font-size: 14px;
  }

  .testimonial-2 .pattern {
    left: -30px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-2 {
    display: block;
  }

  .testimonial-2 .test-quotes {
    top: 0;
    font-size: 100px;
  }

  .testimonial-2 .pattern {
    display: none;
  }

  .testimonial-2 .testimonial-pic {
    min-width: 100px;
    height: 100px;
  }

  .testimonial-2 .testimonial-info {
    padding: 20px 0 0 0;
  }

  .testimonial-2 .testimonial-info .testimonial-text {
    font-size: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-2 .testimonial-info .testimonial-detail {
    display: block;
  }
}

.testimonial-wrapper {
  overflow: hidden;
}

.testimonial-wrapper .swiper-container {
  margin-left: calc((100% - 1170px) /2);
}

@media only screen and (max-width: 1480px) {
  .testimonial-wrapper .swiper-container {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-wrapper .swiper-container {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-wrapper .swiper-container {
    margin-left: 0;
  }
}

.testimonial-wrapper-2 .swiper-slide .testimonial-2 {
  opacity: 0.5;
}

.testimonial-wrapper-2 .swiper-slide.swiper-slide-active .testimonial-2 {
  opacity: 1;
}

.swiper-slide-active .testimonial-1 {
  background-color: var(--primary);
  color: #fff;
}

.swiper-slide-active .testimonial-1 .dz-rating li i.text-muted {
  color: #ffffff61;
}

.swiper-slide-active .testimonial-1 .dz-rating li i.text-yellow {
  color: #ffffff;
}

.swiper-slide-active .testimonial-1 .testimonial-name {
  color: #ffffff;
}

.pricingtable-wrapper {
  box-shadow: 1px 0 50px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: var(--border-radius-base);
  overflow: hidden;
  margin-top: 25px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.pricingtable-wrapper.active,
.pricingtable-wrapper:hover {
  margin-top: 0;
}

@media only screen and (max-width: 575px) {
  .pricingtable-wrapper {
    margin-top: 0;
  }
}

.pricingtable-wrapper.style-1 {
  padding: 35px 60px;
  position: relative;
  z-index: 1;
}

.pricingtable-wrapper.style-1 .pricingtable-inner {
  text-align: center;
}

.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-title {
  margin-bottom: 15px;
}

.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-bx {
  font-size: 50px;
  margin: 0 0 20px;
  color: var(--primary);
}

.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-bx small,
.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-bx .small {
  font-size: 15px;
  color: #1f2471;
  font-weight: var(--headings-font-weight);
}

.pricingtable-wrapper.style-1 .pricingtable-inner .text {
  margin-bottom: 25px;
}

.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-features {
  padding: 15px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  text-align: left;
  list-style: none;
}

.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-features li {
  padding: 8px 0 8px 40px;
  font-size: 18px;
  position: relative;
}

.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-features li:after {
  content: "\f00c";
  font-family: 'Font Awesome 6 Free';
  font-weight: 700;
  font-size: 12px;
  width: 26px;
  height: 26px;
  background: var(--rgba-primary-1);
  display: block;
  position: absolute;
  left: 0;
  color: var(--primary);
  border-radius: 35px;
  text-align: center;
  top: 10px;
  line-height: 26px;
}

.pricingtable-wrapper.style-1:hover {
  margin-top: 0;
  transform: translateY(-20px);
  background-color: var(--primary);
  color: #fff;
}

.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-title .title {
  color: #fff;
}

.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-price .pricingtable-bx {
  color: #fff;
}

.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-price .pricingtable-bx small,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-price .pricingtable-bx .small {
  color: #fff;
}

.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-features {
  border-top: 1px solid rgba(255, 255, 255, 0.27);
}

.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-features li:after {
  color: #fff;
  background: rgba(255, 245, 232, 0.22);
}

.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-footer .btn,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-footer .wp-block-button__link,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-footer .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-footer a,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-footer .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-footer a,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-footer .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-footer input,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-footer .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-footer .button,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-footer .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-footer a.checkout-button,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-footer .woocommerce #respond input#submit,
.woocommerce #respond .pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-footer input#submit,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-footer .woocommerce a.button,
.woocommerce .pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-footer a.button,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-footer .woocommerce button.button,
.woocommerce .pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-footer button.button,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-footer .woocommerce input.button,
.woocommerce .pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-footer input.button,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-footer .woocommerce #place_order,
.woocommerce .pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-footer #place_order {
  color: var(--primary);
  background-color: #fff;
}

@media only screen and (max-width: 1024px) {
  .pricingtable-wrapper.style-1 {
    padding: 35px 30px;
  }
}

.about-style1 .section-head .title {
  margin-bottom: 20px;
}

.about-style1 .section-head p {
  max-width: 500px;
}

.about-style1 .about-thumb img {
  border-radius: 6px;
}

.about-style1 .list-check li {
  font-size: 15px;
}

.about-style1 .exp-bx {
  display: flex;
  align-items: center;
  margin-left: -125px;
  width: 400px;
  position: relative;
  overflow: hidden;
}

.about-style1 .exp-bx .exp-head {
  background-color: var(--primary);
  padding: 20px;
  border-radius: 6px;
}

.about-style1 .exp-bx .exp-info {
  padding: 20px;
  background-color: #fff;
  width: 100%;
}

.about-style1 .exp-bx .counter-num {
  align-items: center;
  display: flex;
  margin-bottom: 5px;
}

.about-style1 .exp-bx .counter-num h2,
.about-style1 .exp-bx .counter-num .h2 {
  font-size: 62px;
  line-height: 1;
  font-weight: 600;
  color: #fff;
}

.about-style1 .exp-bx .title {
  color: #fff;
  font-size: 20px;
  margin-bottom: 0px;
  font-weight: 400;
  line-height: 1.3;
}

@media only screen and (max-width: 1280px) {
  .about-style1 .exp-bx {
    margin-left: -185px;
  }
}

@media only screen and (max-width: 991px) {
  .about-style1 .exp-bx {
    margin-left: -65px;
  }
}

@media only screen and (max-width: 767px) {
  .about-style1 .exp-bx {
    margin-left: -155px;
  }
}

@media only screen and (max-width: 575px) {
  .about-style1 .exp-bx {
    margin: 0;
    width: 100%;
  }

  .about-style1 .exp-bx .exp-head {
    padding: 12px;
  }

  .about-style1 .exp-bx .exp-info {
    padding: 10px 15px;
  }

  .about-style1 .exp-bx .exp-info ul li {
    font-size: 15px;
    padding: 4px 0px 4px 25px;
  }

  .about-style1 .exp-bx .exp-info ul li:before {
    font-size: 14px;
  }

  .about-style1 .exp-bx .counter-num .counter {
    font-size: 50px;
    line-height: 50px;
  }

  .about-style1 .exp-bx .counter-num small,
  .about-style1 .exp-bx .counter-num .small {
    font-size: 32px;
    line-height: 32px;
  }

  .about-style1 .exp-bx .title {
    font-size: 20px;
    line-height: 25px;
  }
}

.logo-header {
  display: table;
  float: left;
  vertical-align: middle;
  padding: 0;
  color: #EFBB20;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  width: 180px;
  height: 80px;
  position: relative;
  z-index: 9;
}

.logo-header a {
  display: table-cell;
  vertical-align: middle;
}

.logo-header img {
  height: 80px;
  max-width: 280px;
  object-fit: contain;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

@media only screen and (max-width: 991px) {
  .logo-header img {
    max-width: 180px;
    height: 80px;
  }
}

@media only screen and (max-width: 575px) {
  .logo-header img {
    max-width: 150px;
  }
}

.logo-header span {
  font-size:12x;
  letter-spacing: 0px;
}

.top-bar {
  background-color: #fff;
  color: #212529;
  padding: 12px 0;
  position: relative;
  border-bottom: 1px solid #d7d7d7;
}

.top-bar .dz-topbar-left {
  float: left;
}

.top-bar .dz-topbar-left li {
  padding-right: 20px;
}

.top-bar .dz-topbar-right {
  float: right;
}

.top-bar .dz-topbar-right li {
  padding-left: 10px;
}

.top-bar .dz-social li a {
  color: inherit;
  font-size: inherit;
}

.top-bar .dz-social li a:hover {
  color: rgba(255, 255, 255, 0.8);
}

.top-bar .dz-topbar-inner {
  margin-left: -15px;
  margin-right: -15px;
}

.top-bar .dz-topbar-center,
.top-bar .dz-topbar-left,
.top-bar .dz-topbar-right {
  padding-left: 15px;
  padding-right: 15px;
}

.top-bar .dz-topbar-center ul,
.top-bar .dz-topbar-left ul,
.top-bar .dz-topbar-right ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.top-bar .dz-topbar-center ul li,
.top-bar .dz-topbar-left ul li,
.top-bar .dz-topbar-right ul li {
  display: inline-block;
  font-size: 15px;
}

.top-bar .dz-topbar-center ul i,
.top-bar .dz-topbar-left ul i,
.top-bar .dz-topbar-right ul i {
  margin-right: 5px;
}

.top-bar .dz-topbar-center ul a,
.top-bar .dz-topbar-left ul a,
.top-bar .dz-topbar-right ul a {
  color: inherit;
}

.top-bar .dz-topbar-center ul a:hover,
.top-bar .dz-topbar-left ul a:hover,
.top-bar .dz-topbar-right ul a:hover {
  color: rgba(255, 255, 255, 0.8);
}

.top-bar .dz-topbar-center .dz-social-icon li,
.top-bar .dz-topbar-left .dz-social-icon li,
.top-bar .dz-topbar-right .dz-social-icon li {
  padding-left: 0;
}

.top-bar .dz-topbar-right ul {
  margin-left: 15px;
}

@media only screen and (max-width: 991px) {
  .top-bar {
    display: none;
  }
}

.dz-quik-search {
  background: rgba(33, 37, 41, 0.95);
  position: fixed;
  height: 100%;
  width: 100%;
  top: -100%;
  left: 0;
  padding: 0px 30px;
  z-index: 999;
  overflow: hidden;
  display: none;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.dz-quik-search form {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  position: relative;
  top: 50%;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  opacity: 1;
  transform: translateY(-50%);
}

.dz-quik-search .form-control,
.dz-quik-search .woocommerce #review_form #respond input[type="text"],
.woocommerce #review_form #respond .dz-quik-search input[type="text"],
.dz-quik-search .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .dz-quik-search textarea,
.dz-quik-search .woocommerce #review_form #respond input[type="email"],
.woocommerce #review_form #respond .dz-quik-search input[type="email"],
.dz-quik-search .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .dz-quik-search .input-text,
.dz-quik-search .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .dz-quik-search input,
.dz-quik-search .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-quik-search textarea,
.dz-quik-search .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-quik-search select,
.dz-quik-search .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-quik-search input,
.dz-quik-search .woocommerce-EditAccountForm.edit-account input[type="text"],
.woocommerce-EditAccountForm.edit-account .dz-quik-search input[type="text"],
.dz-quik-search .woocommerce-EditAccountForm.edit-account input[type="email"],
.woocommerce-EditAccountForm.edit-account .dz-quik-search input[type="email"],
.dz-quik-search .woocommerce-EditAccountForm.edit-account input[type="password"],
.woocommerce-EditAccountForm.edit-account .dz-quik-search input[type="password"],
.dz-quik-search .login input[type="text"],
.login .dz-quik-search input[type="text"],
.dz-quik-search .login input[type="password"],
.login .dz-quik-search input[type="password"],
.dz-quik-search .woocommerce-ResetPassword.lost_reset_password input[type="text"],
.woocommerce-ResetPassword.lost_reset_password .dz-quik-search input[type="text"],
.dz-quik-search .checkout.woocommerce-checkout input[type="text"],
.checkout.woocommerce-checkout .dz-quik-search input[type="text"],
.dz-quik-search .checkout.woocommerce-checkout input[type="email"],
.checkout.woocommerce-checkout .dz-quik-search input[type="email"],
.dz-quik-search .checkout.woocommerce-checkout input[type="tel"],
.checkout.woocommerce-checkout .dz-quik-search input[type="tel"],
.dz-quik-search .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .dz-quik-search .select2-choice,
.dz-quik-search .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .dz-quik-search textarea,
.dz-quik-search .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .dz-quik-search input.input-text,
.dz-quik-search .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .dz-quik-search .select2-selection,
.dz-quik-search .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .dz-quik-search .select2-choice {
  padding: 15px 60px 15px 60px;
  width: 100%;
  height: 90px;
  border: none;
  background: 0 0;
  color: #fff;
  font-size: 20px;
  border-bottom: 2px solid #fff;
  border-radius: 0;
}

.dz-quik-search .form-control::-webkit-input-placeholder,
.dz-quik-search .woocommerce #review_form #respond input[type="text"]::-webkit-input-placeholder,
.woocommerce #review_form #respond .dz-quik-search input[type="text"]::-webkit-input-placeholder,
.dz-quik-search .woocommerce #review_form #respond textarea::-webkit-input-placeholder,
.woocommerce #review_form #respond .dz-quik-search textarea::-webkit-input-placeholder,
.dz-quik-search .woocommerce #review_form #respond input[type="email"]::-webkit-input-placeholder,
.woocommerce #review_form #respond .dz-quik-search input[type="email"]::-webkit-input-placeholder,
.dz-quik-search .woocommerce-cart-form .table tr td.actions .coupon .input-text::-webkit-input-placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .dz-quik-search .input-text::-webkit-input-placeholder,
.dz-quik-search .woocommerce-cart-form .table tr .quantity input::-webkit-input-placeholder,
.woocommerce-cart-form .table tr .quantity .dz-quik-search input::-webkit-input-placeholder,
.dz-quik-search .woocommerce .woocommerce-checkout.checkout form .form-row textarea::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-quik-search textarea::-webkit-input-placeholder,
.dz-quik-search .woocommerce .woocommerce-checkout.checkout form .form-row select::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-quik-search select::-webkit-input-placeholder,
.dz-quik-search .woocommerce .woocommerce-checkout.checkout form .form-row input::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-quik-search input::-webkit-input-placeholder,
.dz-quik-search .woocommerce-EditAccountForm.edit-account input[type="text"]::-webkit-input-placeholder,
.woocommerce-EditAccountForm.edit-account .dz-quik-search input[type="text"]::-webkit-input-placeholder,
.dz-quik-search .woocommerce-EditAccountForm.edit-account input[type="email"]::-webkit-input-placeholder,
.woocommerce-EditAccountForm.edit-account .dz-quik-search input[type="email"]::-webkit-input-placeholder,
.dz-quik-search .woocommerce-EditAccountForm.edit-account input[type="password"]::-webkit-input-placeholder,
.woocommerce-EditAccountForm.edit-account .dz-quik-search input[type="password"]::-webkit-input-placeholder,
.dz-quik-search .login input[type="text"]::-webkit-input-placeholder,
.login .dz-quik-search input[type="text"]::-webkit-input-placeholder,
.dz-quik-search .login input[type="password"]::-webkit-input-placeholder,
.login .dz-quik-search input[type="password"]::-webkit-input-placeholder,
.dz-quik-search .woocommerce-ResetPassword.lost_reset_password input[type="text"]::-webkit-input-placeholder,
.woocommerce-ResetPassword.lost_reset_password .dz-quik-search input[type="text"]::-webkit-input-placeholder,
.dz-quik-search .checkout.woocommerce-checkout input[type="text"]::-webkit-input-placeholder,
.checkout.woocommerce-checkout .dz-quik-search input[type="text"]::-webkit-input-placeholder,
.dz-quik-search .checkout.woocommerce-checkout input[type="email"]::-webkit-input-placeholder,
.checkout.woocommerce-checkout .dz-quik-search input[type="email"]::-webkit-input-placeholder,
.dz-quik-search .checkout.woocommerce-checkout input[type="tel"]::-webkit-input-placeholder,
.checkout.woocommerce-checkout .dz-quik-search input[type="tel"]::-webkit-input-placeholder,
.dz-quik-search .checkout.woocommerce-checkout .select2-choice::-webkit-input-placeholder,
.checkout.woocommerce-checkout .dz-quik-search .select2-choice::-webkit-input-placeholder,
.dz-quik-search .checkout.woocommerce-checkout textarea::-webkit-input-placeholder,
.checkout.woocommerce-checkout .dz-quik-search textarea::-webkit-input-placeholder,
.dz-quik-search .woocommerce form .form-row input.input-text::-webkit-input-placeholder,
.woocommerce form .form-row .dz-quik-search input.input-text::-webkit-input-placeholder,
.dz-quik-search .woocommerce form .form-row .select2-selection::-webkit-input-placeholder,
.woocommerce form .form-row .dz-quik-search .select2-selection::-webkit-input-placeholder,
.dz-quik-search .woocommerce form .form-row .select2-choice::-webkit-input-placeholder,
.woocommerce form .form-row .dz-quik-search .select2-choice::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.dz-quik-search .form-control:-ms-input-placeholder,
.dz-quik-search .woocommerce #review_form #respond input:-ms-input-placeholder[type="text"],
.woocommerce #review_form #respond .dz-quik-search input:-ms-input-placeholder[type="text"],
.dz-quik-search .woocommerce #review_form #respond textarea:-ms-input-placeholder,
.woocommerce #review_form #respond .dz-quik-search textarea:-ms-input-placeholder,
.dz-quik-search .woocommerce #review_form #respond input:-ms-input-placeholder[type="email"],
.woocommerce #review_form #respond .dz-quik-search input:-ms-input-placeholder[type="email"],
.dz-quik-search .woocommerce-cart-form .table tr td.actions .coupon .input-text:-ms-input-placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .dz-quik-search .input-text:-ms-input-placeholder,
.dz-quik-search .woocommerce-cart-form .table tr .quantity input:-ms-input-placeholder,
.woocommerce-cart-form .table tr .quantity .dz-quik-search input:-ms-input-placeholder,
.dz-quik-search .woocommerce .woocommerce-checkout.checkout form .form-row textarea:-ms-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-quik-search textarea:-ms-input-placeholder,
.dz-quik-search .woocommerce .woocommerce-checkout.checkout form .form-row select:-ms-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-quik-search select:-ms-input-placeholder,
.dz-quik-search .woocommerce .woocommerce-checkout.checkout form .form-row input:-ms-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-quik-search input:-ms-input-placeholder,
.dz-quik-search .woocommerce-EditAccountForm.edit-account input:-ms-input-placeholder[type="text"],
.woocommerce-EditAccountForm.edit-account .dz-quik-search input:-ms-input-placeholder[type="text"],
.dz-quik-search .woocommerce-EditAccountForm.edit-account input:-ms-input-placeholder[type="email"],
.woocommerce-EditAccountForm.edit-account .dz-quik-search input:-ms-input-placeholder[type="email"],
.dz-quik-search .woocommerce-EditAccountForm.edit-account input:-ms-input-placeholder[type="password"],
.woocommerce-EditAccountForm.edit-account .dz-quik-search input:-ms-input-placeholder[type="password"],
.dz-quik-search .login input:-ms-input-placeholder[type="text"],
.login .dz-quik-search input:-ms-input-placeholder[type="text"],
.dz-quik-search .login input:-ms-input-placeholder[type="password"],
.login .dz-quik-search input:-ms-input-placeholder[type="password"],
.dz-quik-search .woocommerce-ResetPassword.lost_reset_password input:-ms-input-placeholder[type="text"],
.woocommerce-ResetPassword.lost_reset_password .dz-quik-search input:-ms-input-placeholder[type="text"],
.dz-quik-search .checkout.woocommerce-checkout input:-ms-input-placeholder[type="text"],
.checkout.woocommerce-checkout .dz-quik-search input:-ms-input-placeholder[type="text"],
.dz-quik-search .checkout.woocommerce-checkout input:-ms-input-placeholder[type="email"],
.checkout.woocommerce-checkout .dz-quik-search input:-ms-input-placeholder[type="email"],
.dz-quik-search .checkout.woocommerce-checkout input:-ms-input-placeholder[type="tel"],
.checkout.woocommerce-checkout .dz-quik-search input:-ms-input-placeholder[type="tel"],
.dz-quik-search .checkout.woocommerce-checkout .select2-choice:-ms-input-placeholder,
.checkout.woocommerce-checkout .dz-quik-search .select2-choice:-ms-input-placeholder,
.dz-quik-search .checkout.woocommerce-checkout textarea:-ms-input-placeholder,
.checkout.woocommerce-checkout .dz-quik-search textarea:-ms-input-placeholder,
.dz-quik-search .woocommerce form .form-row input.input-text:-ms-input-placeholder,
.woocommerce form .form-row .dz-quik-search input.input-text:-ms-input-placeholder,
.dz-quik-search .woocommerce form .form-row .select2-selection:-ms-input-placeholder,
.woocommerce form .form-row .dz-quik-search .select2-selection:-ms-input-placeholder,
.dz-quik-search .woocommerce form .form-row .select2-choice:-ms-input-placeholder,
.woocommerce form .form-row .dz-quik-search .select2-choice:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.dz-quik-search .form-control::placeholder,
.dz-quik-search .woocommerce #review_form #respond input[type="text"]::placeholder,
.woocommerce #review_form #respond .dz-quik-search input[type="text"]::placeholder,
.dz-quik-search .woocommerce #review_form #respond textarea::placeholder,
.woocommerce #review_form #respond .dz-quik-search textarea::placeholder,
.dz-quik-search .woocommerce #review_form #respond input[type="email"]::placeholder,
.woocommerce #review_form #respond .dz-quik-search input[type="email"]::placeholder,
.dz-quik-search .woocommerce-cart-form .table tr td.actions .coupon .input-text::placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .dz-quik-search .input-text::placeholder,
.dz-quik-search .woocommerce-cart-form .table tr .quantity input::placeholder,
.woocommerce-cart-form .table tr .quantity .dz-quik-search input::placeholder,
.dz-quik-search .woocommerce .woocommerce-checkout.checkout form .form-row textarea::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-quik-search textarea::placeholder,
.dz-quik-search .woocommerce .woocommerce-checkout.checkout form .form-row select::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-quik-search select::placeholder,
.dz-quik-search .woocommerce .woocommerce-checkout.checkout form .form-row input::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-quik-search input::placeholder,
.dz-quik-search .woocommerce-EditAccountForm.edit-account input[type="text"]::placeholder,
.woocommerce-EditAccountForm.edit-account .dz-quik-search input[type="text"]::placeholder,
.dz-quik-search .woocommerce-EditAccountForm.edit-account input[type="email"]::placeholder,
.woocommerce-EditAccountForm.edit-account .dz-quik-search input[type="email"]::placeholder,
.dz-quik-search .woocommerce-EditAccountForm.edit-account input[type="password"]::placeholder,
.woocommerce-EditAccountForm.edit-account .dz-quik-search input[type="password"]::placeholder,
.dz-quik-search .login input[type="text"]::placeholder,
.login .dz-quik-search input[type="text"]::placeholder,
.dz-quik-search .login input[type="password"]::placeholder,
.login .dz-quik-search input[type="password"]::placeholder,
.dz-quik-search .woocommerce-ResetPassword.lost_reset_password input[type="text"]::placeholder,
.woocommerce-ResetPassword.lost_reset_password .dz-quik-search input[type="text"]::placeholder,
.dz-quik-search .checkout.woocommerce-checkout input[type="text"]::placeholder,
.checkout.woocommerce-checkout .dz-quik-search input[type="text"]::placeholder,
.dz-quik-search .checkout.woocommerce-checkout input[type="email"]::placeholder,
.checkout.woocommerce-checkout .dz-quik-search input[type="email"]::placeholder,
.dz-quik-search .checkout.woocommerce-checkout input[type="tel"]::placeholder,
.checkout.woocommerce-checkout .dz-quik-search input[type="tel"]::placeholder,
.dz-quik-search .checkout.woocommerce-checkout .select2-choice::placeholder,
.checkout.woocommerce-checkout .dz-quik-search .select2-choice::placeholder,
.dz-quik-search .checkout.woocommerce-checkout textarea::placeholder,
.checkout.woocommerce-checkout .dz-quik-search textarea::placeholder,
.dz-quik-search .woocommerce form .form-row input.input-text::placeholder,
.woocommerce form .form-row .dz-quik-search input.input-text::placeholder,
.dz-quik-search .woocommerce form .form-row .select2-selection::placeholder,
.woocommerce form .form-row .dz-quik-search .select2-selection::placeholder,
.dz-quik-search .woocommerce form .form-row .select2-choice::placeholder,
.woocommerce form .form-row .dz-quik-search .select2-choice::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.dz-quik-search span {
  position: absolute;
  right: 15px;
  top: 50%;
  margin: -15px 0;
  height: 25px;
  font-size: 20px;
  cursor: pointer;
}

.dz-quik-search button {
  background: transparent;
  border: 0;
  line-height: 1;
  font-size: 30px;
  color: #fff;
  position: absolute;
  top: 30px;
  left: 15px;
}

.dz-quik-search.On {
  top: 0;
}

.site-header {
  position: relative;
  z-index: 9999;
}

.site-header .main-bar {
  background:  #1b1d1f;
  width: 100%;
  position: relative;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
}

.site-header .container {
  position: relative;
}

.site-header .extra-nav {
  float: right;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;
  height: 80px;
  padding-left: 30px;
}

@media only screen and (max-width: 767px) {
  .site-header .extra-nav {
    display: none;
  }
}

.site-header .extra-nav .extra-cell {
  display: flex;
  align-items: center;

}

.site-header .extra-nav .extra-cell>* {
  margin-left: 20px;
}

@media only screen and (max-width: 991px) {
  .site-header .extra-nav .extra-cell>* {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .site-header .extra-nav {
    height: 80px;
  }
}

@media only screen and (max-width: 1680px) {
  .site-header .extra-nav {
    padding-left: 0;
  }
}

.site-header .extra-nav #quik-search-btn {
  color: #fff;
  cursor: pointer;
  margin-right: 0px;
  border: 0;
  width: 45px;
  height: 45px;
  background: var(--secondary);
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  border-radius: var(--border-radius-base);
}

@media only screen and (max-width: 575px) {

  .site-header .extra-.btn,
  .site-header .extra-.wp-block-button__link,
  .site-header .extra-.woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .site-header .extra-nav a,
  .site-header .extra-.woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .site-header .extra-nav a,
  .site-header .extra-.woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .site-header .extra-nav input,
  .site-header .extra-.woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .site-header .extra-.button,
  .site-header .extra-.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .site-header .extra-nav a.checkout-button,
  .site-header .extra-.woocommerce #respond input#submit,
  .woocommerce #respond .site-header .extra-nav input#submit,
  .site-header .extra-.woocommerce a.button,
  .woocommerce .site-header .extra-nav a.button,
  .site-header .extra-.woocommerce button.button,
  .woocommerce .site-header .extra-nav button.button,
  .site-header .extra-.woocommerce input.button,
  .woocommerce .site-header .extra-nav input.button,
  .site-header .extra-.woocommerce #place_order,
  .woocommerce .site-header .extra-nav #place_order {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.site-header .extra-nav ul li {
  display: inline-block;
}

.site-header .extra-nav ul li .icon {
  padding: 0;
  background: transparent;
  min-width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
}

.navbar-toggler {
  border: 0 solid #efbb20;
  font-size: 16px;
  line-height: 24px;
  margin: 40px 0 39px 15px;
  padding: 0;
  float: right;
  outline: none !important;
}

@media only screen and (max-width: 991px) {
  .navbar-toggler {
    margin: 18px 0px 16px 15px;
  }
}

.navicon {
  width: 45px;
  height: 45px;
  background: var(--primary);
  box-shadow: 0 5px 15px -10px var(--primary);
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  border-radius: var(--border-radius-base);
}

.navicon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 1px;
  opacity: 1;
  background: #fff;
  left: 10px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.navicon span:nth-child(1) {
  top: 13px;
  width: 22px;
}

.navicon span:nth-child(2) {
  top: 22px;
  width: 25px;
}

.navicon span:nth-child(3) {
  top: 32px;
  width: 20px;
}

.navicon.open span:nth-child(1) {
  top: 23px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.navicon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.navicon.open span:nth-child(3) {
  top: 23px;
  width: 23px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.header-tb .navicon span {
  background: #fff;
}

.header-nav {
  padding: 0;
}

@media only screen and (max-width: 991px) {
  .header-nav {
    overflow-y: scroll;
    position: fixed;
    width: 60px;
    left: -280px;
    height: 100vh !important;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    top: 0;
    background-color: #ffffff;
    margin: 0;
    z-index: 99;
  }
}

@media only screen and (max-width: 991px) {
  .header-nav.show {
    left: -1px;
    -webkit-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
    margin: 0;
    width: 280px;
  }
}

.header-.logo-header {
  display: none;
}

@media only screen and (max-width: 991px) {
  .header-.logo-header {
    padding: 30px 20px;
    float: none;
    height: auto;
    width: 240px;
  }
}

.header-.nav {
  float: right;
  padding: 0;
  font-size: 0;
}

.header-.nav>li {
  margin: 0px;
  font-weight: 400;
  position: relative;
}

.header-.nav>li.has-mega-menu {
  position: inherit;
}

@media only screen and (max-width: 991px) {
  .header-.nav>li {
    border-bottom: 1px solid #eee;
    width: 100%;
  }
}

.header-.nav>li.menu-item-has-children>a:after,
.header-.nav>li.sub-menu-down>a:after {
  content: "\f078";
  font-size: 9px;
  margin-left: 5px;
  margin-top: 0;
  vertical-align: middle;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
}

@media only screen and (max-width: 991px) {

  .header-.nav>li.menu-item-has-children>a:after,
  .header-.nav>li.sub-menu-down>a:after {
    content: "\f054";
    background-color: var(--primary);
    color: #fff;
    height: 30px;
    line-height: 30px;
    right: 0;
    text-align: center;
    width: 30px;
    z-index: 3;
    float: right;
    font-size: 14px;
    margin: 0;
    border-radius: var(--border-radius-base);
  }
}

@media only screen and (max-width: 991px) {

  .header-.nav>li.menu-item-has-children>a:after:before,
  .header-.nav>li.sub-menu-down>a:after:before {
    content: "\f078";
  }
}

@media only screen and (max-width: 991px) {

  .header-.nav>li.menu-item-has-children.open>a:after,
  .header-.nav>li.sub-menu-down.open>a:after {
    content: "\f078";
    background: var(--title);
  }
}

.header-.nav>li>a {
  color: black;
  font-size: 15px;
  padding: 32px 10px;
  font-family: 'Roboto Condensed', sans-serif;
  cursor: pointer;
  font-weight: 800;
  display: inline-block;
  position: relative;
  border-width: 0 0 0 1px;
  text-transform: capitalize;
  line-height: 1;
}

@media only screen and (max-width: 1280px) {
  .header-.nav>li>a {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .header-.nav>li>a {
    padding: 8px 0;
    display: block;
    line-height: 30px;
    font-size: 16px;
  }
}

.header-.nav>li>a span {
  display: inline-block;
}

.header-.nav>li .mega-menu,
.header-.nav>li .sub-menu {
  background-color: #fff;
  display: block;
  left: 0;
  list-style: none;
  opacity: 0;
  padding: 15px 0;
  position: absolute;
  visibility: hidden;
  width: 220px;
  z-index: 10;
  margin-top: 20px;
  text-align: left;
  border-radius: 0 var(--border-radius-base) var(--border-radius-base) var(--border-radius-base);
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
}

@media only screen and (max-width: 991px) {

  .header-.nav>li .mega-menu,
  .header-.nav>li .sub-menu {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: transparent;
    box-shadow: none;
    margin: 0;
    border-top: 1px solid #eee;
    padding: 10px 0;
    -webkit-transition: none;
    -ms-transition: none;
    transition: none;
  }

  .header-.nav>li .mega-menu>li>a i,
  .header-.nav>li .sub-menu>li>a i {
    color: #fff !important;
    height: 30px;
    line-height: 30px;
    right: 0;
    text-align: center;
    width: 30px;
    z-index: 3;
    font-size: 14px !important;
    margin: 0 !important;
    background: #aaa;
  }
}

.header-.nav>li .mega-menu .menu-item-has-children>a:before,
.header-.nav>li .sub-menu .menu-item-has-children>a:before {
  content: "\f054";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  float: right;
  font-size: 10px;
  margin-top: 2px;
}

@media only screen and (max-width: 991px) {

  .header-.nav>li .mega-menu .menu-item-has-children.open>a:before,
  .header-.nav>li .sub-menu .menu-item-has-children.open>a:before {
    content: "\f054";
  }
}

@media only screen and (max-width: 991px) {

  .header-.nav>li .mega-menu,
  .header-.nav>li .sub-menu {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: transparent;
    box-shadow: none;
    margin: 0;
    border-top: 1px solid #eee;
    padding: 0 0;
    -webkit-transition: none;
    -ms-transition: none;
    transition: none;
    opacity: 1;
  }

  .header-.nav>li .mega-menu>li>a i,
  .header-.nav>li .sub-menu>li>a i {
    display: none;
  }

  .header-.nav>li .mega-menu .menu-item-has-children>a:before,
  .header-.nav>li .mega-menu>li>a i,
  .header-.nav>li .sub-menu .menu-item-has-children>a:before,
  .header-.nav>li .sub-menu>li>a i {
    color: #fff !important;
    font-size: 14px;
    content: "\f054";
    height: 30px;
    line-height: 30px;
    right: 0;
    text-align: center;
    width: 30px;
    z-index: 3;
    margin: 0 !important;
    background: var(--primary);
    border-radius: var(--border-radius-base);
  }

  .header-.nav>li .mega-menu .menu-item-has-children.open>a:before,
  .header-.nav>li .sub-menu .menu-item-has-children.open>a:before {
    content: "\f078";
    background: #333;
  }
}

.header-.nav>li .mega-menu li,
.header-.nav>li .sub-menu li {
  position: relative;
}

.header-.nav>li .mega-menu li a,
.header-.nav>li .sub-menu li a {
  color: #212529;
  display: block;
  font-size: 15px;
  padding: 10px 20px;
  line-height: 1.3;
  text-transform: capitalize;
  position: relative;
  transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  font-weight: 400;
}

@media only screen and (max-width: 991px) {

  .header-.nav>li .mega-menu li a,
  .header-.nav>li .sub-menu li a {
    padding: 4px 0 4px 15px;
    display: block;
    line-height: 30px;
    position: relative;
    border: 1px solid #eee;
    border-width: 0 0 1px 0px;
  }

  .header-.nav>li .mega-menu li a:after,
  .header-.nav>li .sub-menu li a:after {
    content: "";
    font-family: "Font Awesome 5 Free";
    width: auto;
    height: auto;
    background: 0 0 !important;
    top: 3px;
    color: #262F5A;
    font-size: 12px;
    position: absolute;
    left: 0;
    font-weight: 700;
  }
}

.header-.nav>li .mega-menu li:hover>a,
.header-.nav>li .sub-menu li:hover>a {
  color: var(--primary);
}

@media only screen and (max-width: 991px) {

  .header-.nav>li .mega-menu li:hover>a,
  .header-.nav>li .sub-menu li:hover>a {
    padding: 4px 0 4px 15px;
    background: transparent;
  }
}

.header-.nav>li .mega-menu li:hover>a:after,
.header-.nav>li .sub-menu li:hover>a:after {
  width: 10px;
}

.header-.nav>li .mega-menu li>.sub-menu,
.header-.nav>li .sub-menu li>.sub-menu {
  left: 220px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

@media only screen and (max-width: 991px) {

  .header-.nav>li .mega-menu li>.sub-menu,
  .header-.nav>li .sub-menu li>.sub-menu {
    display: none;
  }
}

@media only screen and (max-width: 991px) {

  .header-.nav>li .mega-menu .mega-menu,
  .header-.nav>li .mega-menu .sub-menu,
  .header-.nav>li .sub-menu .mega-menu,
  .header-.nav>li .sub-menu .sub-menu {
    border-top: 0;
    padding-left: 20px;
  }

  .header-.nav>li .mega-menu .mega-menu li a:after,
  .header-.nav>li .mega-menu .sub-menu li a:after,
  .header-.nav>li .sub-menu .mega-menu li a:after,
  .header-.nav>li .sub-menu .sub-menu li a:after {
    width: 5px;
    content: none;
    height: 5px;
    background: #262F5A !important;
    border-radius: 4px;
    top: 13px;
  }
}

.header-.nav>li .sub-menu li>a>i {
  color: inherit;
  display: block;
  float: right;
  font-size: 18px;
  opacity: 1;
  margin-top: 0px;
}

@media only screen and (max-width: 991px) {
  .header-.nav>li .sub-menu li>a>i {
    display: none;
  }
}

.header-.nav>li .mega-menu {
  display: flex;
  right: 0px;
  width: 100%;
  z-index: 9;
  padding: 0;
}

.header-.nav>li .mega-menu>li {
  display: block;
  padding: 15px 0 15px;
  position: relative;
  vertical-align: top;
  width: 25%;
}

@media only screen and (max-width: 991px) {
  .header-.nav>li .mega-menu>li {
    width: 100%;
    padding: 0;
  }
}

.header-.nav>li .mega-menu>li>a {
  color: #212529;
  display: block;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
}

.header-.nav>li .mega-menu>li>a>i {
  font-size: 14px;
  margin-right: 5px;
  text-align: center;
  width: 15px;
}

@media only screen and (max-width: 991px) {
  .header-.nav>li .mega-menu>li>a {
    display: none;
  }
}

.header-.nav>li .mega-menu>li:after {
  content: "";
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  width: 1px;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .header-.nav>li .mega-menu>li:after {
    content: none;
  }
}

.header-.nav>li .mega-menu>li:last-child:after {
  display: none;
}

.header-.nav>li .mega-menu>li:hover>a {
  background-color: transparent;
}

.header-.nav>li .mega-menu>li li i {
  font-size: 14px;
  margin-right: 5px;
  text-align: center;
  width: 15px;
}

@media only screen and (max-width: 991px) {
  .header-.nav>li .mega-menu {
    display: none;
    padding: 10px 0;
  }
}

.header-.nav>li.active>a {
  color: var(--primary);
}

.header-.nav>li:hover>a {
  color: var(--primary);
}

.header-.nav>li:hover>.mega-menu,
.header-.nav>li:hover>.sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
}

@media only screen and (max-width: 991px) {

  .header-.nav>li:hover>.mega-menu,
  .header-.nav>li:hover>.sub-menu {
    -webkit-transition: none;
    -ms-transition: none;
    transition: none;
  }
}

.header-.nav>li:hover>.mega-menu li:hover>.sub-menu,
.header-.nav>li:hover>.sub-menu li:hover>.sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  top: -1px;
}

.header-.nav>li.open .mega-menu,
.header-.nav>li.open .sub-menu {
  display: block;
  opacity: 1;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}

.header-.nav>li.open .open .mega-menu,
.header-.nav>li.open .open .sub-menu {
  display: block;
  opacity: 1;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}

@media only screen and (max-width: 1480px) {

  .header-.nav>li:nth-last-child(4) .sub-menu .sub-menu,
  .header-.nav>li:nth-last-child(3) .sub-menu .sub-menu,
  .header-.nav>li:nth-last-child(2) .sub-menu .sub-menu,
  .header-.nav>li:last-child .sub-menu .sub-menu {
    left: -220px;
  }
}

@media only screen and (max-width: 991px) {
  .header-.nav {
    float: none;
    padding: 0 20px;
  }
}

.header-.sidebar-footer {
  display: none;
}

@media only screen and (max-width: 991px) {
  .header-.sidebar-footer {
    display: block;
    padding-top: 30px;
    padding-bottom: 20px;
    margin-top: auto;
    width: 100%;
    text-align: center;
  }
}

.header-.sidebar-footer .dz-login-register {
  text-align: center;
  padding-bottom: 10px;
}

.header-.dz-social-icon {
  text-align: center;
  display: none;
}

.header-.dz-social-icon li {
  display: inline-block;
  margin: 0 2px;
}

.header-.dz-social-icon a {
  width: 40px;
  height: 40px;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  line-height: 38px;
  text-align: center;
  border-radius: var(--border-radius-base);
}

@media only screen and (max-width: 991px) {
  .header-.dz-social-icon {
    display: block;
    padding-top: 40px;
    padding-bottom: 20px;
  }
}

.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.header-transparent .top-bar,
.header-transparent .main-bar {
  background: transparent;
  box-shadow: none;
}

.header-transparent .container-fluid,
.header-transparent .container-sm,
.header-transparent .container-md,
.header-transparent .container-lg,
.header-transparent .container-xl {
  padding-left: 80px;
  padding-right: 80px;
}

@media only screen and (max-width: 1280px) {

  .header-transparent .container-fluid,
  .header-transparent .container-sm,
  .header-transparent .container-md,
  .header-transparent .container-lg,
  .header-transparent .container-xl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.header-transparent .side-menu-btn .menu-icon-in span {
  background-color: #ffffff;
}

.header-transparent .is-fixed .side-menu-btn .menu-icon-in span {
  background-color: #212529;
}

@media only screen and (min-width: 991px) {
  .header-transparent .nav>li>a {
    color: #ffffff;
  }
}

.header-transparent .navbar-toggler span {
  background: #ffffff;
}

.header-transparent .is-fixed .nav>li>a {
  color: #212529;
}

.header-transparent .is-fixed .navbar-toggler span {
  background: #212529;
}

.header-transparent .top-bar {
  border-color: #ffffff;
}

.header-transparent .top-bar .dz-topbar-left li,
.header-transparent .top-bar .dz-topbar-left i,
.header-transparent .top-bar .dz-topbar-right li,
.header-transparent .top-bar .dz-topbar-right i {
  color: #ffffff;
}

.is-fixed .main-bar {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  background: #212529;
  transition: all 0.5s;
}

@media only screen and (max-width: 991px) {
  .mo-left .header-nav {
    overflow-y: scroll;
    position: fixed;
    width: 280px;
    left: -280px;
    height: 100vh !important;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    top: 0;
    background-color: #000;
    margin: 0;
    z-index: 99;
  }

  .mo-left .header-nav.nav-dark {
    background-color: #202020;
  }

  .mo-left .header-nav.show {
    left: -1px;
    transition: all 0.8s;
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -o-transition: all 0.8s;
    margin: 0;
    width: 280px;
  }

  .mo-left .header-.logo-header {
    display: block;
    float: none;
    height: auto !important;
    max-width: 100%;
    padding: 30px 20px;
    width: 100% !important;
  }

  .mo-left .header-.logo-header img {
    max-width: unset;
    width: 180px;
    height: auto;
    vertical-align: middle;
  }

  .mo-left .header-nav li.open a {
    position: relative;
  }

  .mo-left .navbar-toggler.open {
    z-index: 99;
  }

  .mo-left .navbar-toggler.open:after {
    background-color: rgba(0, 0, 0, 0.6);
    content: "";
    left: 0;
    position: fixed;
    right: -20px;
    top: -63px;
    transform: scale(100);
    -o-transform: scale(100);
    -moz-transform: scale(100);
    -webkit-transform: scale(100);
    width: 100%;
    z-index: -1;
    transition: all 0.5s;
    transform-origin: top right;
    margin: 0 0px 0px 20px;
    -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
  }

  .mo-left .navbar-toggler.open span {
    background: #fff;
  }
}

.text-black .nav>li>a {
  color: #212529;
}

.text-black .navbar-toggler span {
  background: #212529;
}

.main-bar {
  -webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -khtml-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
}

.is-fixed .main-bar {
  -webkit-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -khtml-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -moz-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -ms-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -o-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
}

@-moz-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }

  100% {
    margin-top: 0;
  }
}

@-ms-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }

  100% {
    margin-top: 0;
  }
}

@-webkit-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }

  100% {
    margin-top: 0;
  }
}

@keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }

  100% {
    margin-top: 0;
  }
}

.shop-cart {
  display: inline-block;
}

.shop-cart .cart-btn {
  width: 35px;
  height: 40px;
  display: inline-block;
  position: relative;
  padding: 10px 0 2px 0;
  text-align: center;
}

@media only screen and (max-width: 575px) {
  .shop-cart .cart-btn {
    width: 25px;
    height: 34px;
  }
}

.shop-cart .cart-btn:before,
.shop-cart .cart-btn:after {
  content: "";
  position: absolute;
  border: 2px solid var(--title);
  display: block;
}

.shop-cart .cart-btn:before {
  width: 20px;
  height: 8px;
  left: 7px;
  top: 0;
  border-width: 2px 2px 0px 2px !important;
  border-radius: 5px 5px 0 0;
}

@media only screen and (max-width: 575px) {
  .shop-cart .cart-btn:before {
    width: 15px;
    height: 8px;
    left: 5px;
  }
}

.shop-cart .cart-btn:after {
  width: 100%;
  height: 30px;
  border-radius: 0 0 6px 6px;
  bottom: 0;
  left: 0;
}

@media only screen and (max-width: 575px) {
  .shop-cart .cart-btn:after {
    height: 24px;
  }
}

.shop-cart .cart-btn .badge {
  background: var(--primary);
  padding: 0;
  position: unset;
  min-width: 18px;
  height: 18px;
  line-height: 18px;
  font-weight: 400;
}

@media only screen and (max-width: 575px) {
  .shop-cart .cart-btn .badge {
    padding: 2px 3px;
    position: unset;
    min-width: auto;
    height: auto;
    font-size: 12px;
    line-height: 12px;
  }
}

.drop-head .dropdown-toggle {
  font-size: 14px;
  color: black;
  font-weight: 500;
  text-align: center;
  font-family: 'Poppins';
  cursor: pointer;
}

.profile-dropdown .dropdown-toggle {
  cursor: pointer;
}

.profile-dropdown .nav-link {
  display: flex;
  align-items: center;
  background: none !important;
}

.profile-dropdown .nav-link img {
  width: 48px;
  height: 48px;
  border-radius: var(--border-radius-base);
}

.profile-dropdown .nav-link .profile-info {
  margin-left: 15px;
}

.profile-dropdown .nav-link .profile-info span {
  font-size: 12px;
  color: #8F8F8F;
  font-family: var(--font-family-title);
}

.profile-dropdown .nav-link .profile-info .title {
  font-weight: 600;
  margin-bottom: 0;
}

.extra-.extra-cell .header-right {
  align-items: center;
  margin-left: 15px;
  flex-direction: unset;
}

.extra-.extra-cell .header-right .nav-item {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 10px;
  position: relative;
}

.extra-.extra-cell .header-right .nav-item .box {
  background-color: transparent;
  border-color: transparent;
}

.extra-.extra-cell .header-right .nav-item .nav-link {
  padding-right: 0;
  padding-left: 0;
  min-width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  border-radius: var(--border-radius-base);
}

.extra-.extra-cell .header-right .nav-item .nav-link svg {
  min-width: 24px;
  height: 24px;
}

.extra-.extra-cell .header-right .nav-item .nav-link .badge {
  position: absolute;
  top: 0;
  right: 0px;
  font-size: 10px;
  padding: 0;
  line-height: 22px;
  height: 20px;
  width: 20px;
}

.extra-.extra-cell .header-right .nav-item:hover .nav-link {
  background: whitesmoke;
}

.extra-.extra-cell .header-right .dropdown-menu {
  width: 220px;
  border: 0;
  inset: 14px 0px auto auto !important;
  position: absolute;
  border-radius: var(--border-radius-base) !important;
  background-color: #fff;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
}

.extra-.extra-cell .header-right .dropdown-menu svg {
  fill: var(--primary);
}

.extra-.extra-cell .header-right .dropdown-menu li:last-child {
  padding: 15px 15px 10px;
  border-bottom: 0;
}

.profile-dropdown .dropdown-menu {
  background-color: #fff;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
}

.profile-dropdown .dropdown-menu .dropdown-item {
  padding: 10px 20px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
}

.profile-dropdown .dropdown-menu .dropdown-header {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
}

.profile-dropdown .dropdown-menu .dropdown-footer {
  padding: 15px 20px;
}

.site-header.style-1 .header-.search-input {
  display: none;
}

.site-header.style-1 .header-info-bar+.main-bar-wraper .main-bar .logo-header {
  display: none;
}

@media only screen and (max-width: 991px) {
  .site-header.style-1 .header-.search-input {
    display: flex;
    padding: 0 20px;
    margin-bottom: 0;
  }

  .site-header.style-1 .header-info-bar+.main-bar-wraper .main-bar .logo-header {
    display: block;
  }
}

.header-info-bar {
  position: relative;
  flex-wrap: nowrap;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  z-index: 99;
}

@media only screen and (max-width: 991px) {
  .header-info-bar {
    display: none;
  }
}

.header-info-bar .header-search-nav {
  flex-basis: auto;
  display: flex !important;
}

.header-item-search {
  width: 100%;
  margin-left: 150px;
  height: 80px;
  align-items: center;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 1280px) {
  .header-item-search {
    margin-left: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .header-item-search {
    display: block;
    margin-left: 0;
    padding: 0px 20px;
    height: auto;
  }

  .header-item-search .navbar-nav {
    padding: 0px;
  }
}

.header-item-search .main-menu {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
}

@media only screen and (max-width: 991px) {
  .header-item-search .main-menu {
    width: 100%;
  }
}

.header-item-search .main-menu .menu-item {
  border: 0;
  padding: 15px 20px;
  background-color: #f5f5f5;
  color: var(--title);
  min-width: 135px;
  font-size: 14px;
  height: 48px;
  font-weight: 600;
  position: relative;
  font-family: var(--font-family-title);
  display: flex;
  align-items: center;
}

.header-item-search .main-menu .menu-item i {
  font-size: 12px;
  position: absolute;
  right: 20px;
  color: var(--title);
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 1280px) {
  .header-item-search .main-menu .menu-item {
    padding: 15px 20px;
    min-width: 135px;
  }

  .header-item-search .main-menu .menu-item i {
    right: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .header-item-search .main-menu .menu-item {
    display: none;
  }
}

.search-input {
  margin-bottom: 0;
  align-items: center;
}

.search-input .form-control,
.search-input .woocommerce #review_form #respond input[type="text"],
.woocommerce #review_form #respond .search-input input[type="text"],
.search-input .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .search-input textarea,
.search-input .woocommerce #review_form #respond input[type="email"],
.woocommerce #review_form #respond .search-input input[type="email"],
.search-input .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .search-input .input-text,
.search-input .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .search-input input,
.search-input .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .search-input textarea,
.search-input .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .search-input select,
.search-input .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .search-input input,
.search-input .woocommerce-EditAccountForm.edit-account input[type="text"],
.woocommerce-EditAccountForm.edit-account .search-input input[type="text"],
.search-input .woocommerce-EditAccountForm.edit-account input[type="email"],
.woocommerce-EditAccountForm.edit-account .search-input input[type="email"],
.search-input .woocommerce-EditAccountForm.edit-account input[type="password"],
.woocommerce-EditAccountForm.edit-account .search-input input[type="password"],
.search-input .login input[type="text"],
.login .search-input input[type="text"],
.search-input .login input[type="password"],
.login .search-input input[type="password"],
.search-input .woocommerce-ResetPassword.lost_reset_password input[type="text"],
.woocommerce-ResetPassword.lost_reset_password .search-input input[type="text"],
.search-input .checkout.woocommerce-checkout input[type="text"],
.checkout.woocommerce-checkout .search-input input[type="text"],
.search-input .checkout.woocommerce-checkout input[type="email"],
.checkout.woocommerce-checkout .search-input input[type="email"],
.search-input .checkout.woocommerce-checkout input[type="tel"],
.checkout.woocommerce-checkout .search-input input[type="tel"],
.search-input .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .search-input .select2-choice,
.search-input .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .search-input textarea,
.search-input .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .search-input input.input-text,
.search-input .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .search-input .select2-selection,
.search-input .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .search-input .select2-choice {
  border: 0;
  margin-left: 0px !important;
  border-radius: var(--border-radius-base) !important;
}

.search-input .bootstrap-select.default-select+.form-control,
.search-input .woocommerce #review_form #respond .bootstrap-select.default-select+input[type="text"],
.woocommerce #review_form #respond .search-input .bootstrap-select.default-select+input[type="text"],
.search-input .woocommerce #review_form #respond .bootstrap-select.default-select+textarea,
.woocommerce #review_form #respond .search-input .bootstrap-select.default-select+textarea,
.search-input .woocommerce #review_form #respond .bootstrap-select.default-select+input[type="email"],
.woocommerce #review_form #respond .search-input .bootstrap-select.default-select+input[type="email"],
.search-input .woocommerce-cart-form .table tr td.actions .coupon .bootstrap-select.default-select+.input-text,
.woocommerce-cart-form .table tr td.actions .coupon .search-input .bootstrap-select.default-select+.input-text,
.search-input .woocommerce-cart-form .table tr .quantity .bootstrap-select.default-select+input,
.woocommerce-cart-form .table tr .quantity .search-input .bootstrap-select.default-select+input,
.search-input .woocommerce .woocommerce-checkout.checkout form .form-row .bootstrap-select.default-select+textarea,
.search-input .woocommerce .woocommerce-checkout.checkout form .form-row .bootstrap-select.default-select+select,
.woocommerce .woocommerce-checkout.checkout form .form-row .search-input .bootstrap-select.default-select+select,
.search-input .woocommerce .woocommerce-checkout.checkout form .form-row .bootstrap-select.default-select+input,
.woocommerce .woocommerce-checkout.checkout form .form-row .search-input .bootstrap-select.default-select+input,
.search-input .woocommerce-EditAccountForm.edit-account .bootstrap-select.default-select+input[type="text"],
.woocommerce-EditAccountForm.edit-account .search-input .bootstrap-select.default-select+input[type="text"],
.search-input .woocommerce-EditAccountForm.edit-account .bootstrap-select.default-select+input[type="email"],
.woocommerce-EditAccountForm.edit-account .search-input .bootstrap-select.default-select+input[type="email"],
.search-input .woocommerce-EditAccountForm.edit-account .bootstrap-select.default-select+input[type="password"],
.woocommerce-EditAccountForm.edit-account .search-input .bootstrap-select.default-select+input[type="password"],
.search-input .login .bootstrap-select.default-select+input[type="text"],
.login .search-input .bootstrap-select.default-select+input[type="text"],
.search-input .login .bootstrap-select.default-select+input[type="password"],
.login .search-input .bootstrap-select.default-select+input[type="password"],
.search-input .woocommerce-ResetPassword.lost_reset_password .bootstrap-select.default-select+input[type="text"],
.woocommerce-ResetPassword.lost_reset_password .search-input .bootstrap-select.default-select+input[type="text"],
.search-input .checkout.woocommerce-checkout .bootstrap-select.default-select+input[type="text"],
.checkout.woocommerce-checkout .search-input .bootstrap-select.default-select+input[type="text"],
.search-input .checkout.woocommerce-checkout .bootstrap-select.default-select+input[type="email"],
.checkout.woocommerce-checkout .search-input .bootstrap-select.default-select+input[type="email"],
.search-input .checkout.woocommerce-checkout .bootstrap-select.default-select+input[type="tel"],
.checkout.woocommerce-checkout .search-input .bootstrap-select.default-select+input[type="tel"],
.search-input .checkout.woocommerce-checkout .bootstrap-select.default-select+.select2-choice,
.checkout.woocommerce-checkout .search-input .bootstrap-select.default-select+.select2-choice,
.search-input .checkout.woocommerce-checkout .bootstrap-select.default-select+textarea,
.checkout.woocommerce-checkout .search-input .bootstrap-select.default-select+textarea,
.search-input .woocommerce form .form-row .bootstrap-select.default-select+input.input-text,
.woocommerce form .form-row .search-input .bootstrap-select.default-select+input.input-text,
.search-input .woocommerce form .form-row .bootstrap-select.default-select+.select2-selection,
.woocommerce form .form-row .search-input .bootstrap-select.default-select+.select2-selection,
.search-input .woocommerce form .form-row .bootstrap-select.default-select+.select2-choice,
.woocommerce form .form-row .search-input .bootstrap-select.default-select+.select2-choice {
  border-radius: 0 var(--border-radius-base) var(--border-radius-base) 0 !important;
}

.search-input .bootstrap-select.default-select {
  width: auto !important;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.search-input .bootstrap-select.default-select .default-select {
  display: none !important;
}

.search-input .bootstrap-select.default-select .dropdown-toggle {
  height: 48px;
  min-width: 145px;
  position: static;
  background-color: #f5f5f5;
  padding: 0px 15px;
  border-radius: 0;
  line-height: 48px;
  border: 0;
  outline: 0 !important;
  border-radius: var(--border-radius-base) 0 0 var(--border-radius-base);
}

.search-input .bootstrap-select.default-select .dropdown-toggle:after {
  border: 0;
  content: "\f078";
  font-size: 9px;
  margin-left: 5px;
  margin-top: 0;
  vertical-align: middle;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
}

.search-input .bootstrap-select.default-select .dropdown-menu {
  background-color: #fff;
  border: 0;
  border-radius: var(--border-radius-base);
  min-width: 200px !important;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
}

.search-input .btn,
.search-input .wp-block-button__link,
.search-input .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .search-input a,
.search-input .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .search-input a,
.search-input .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .search-input input,
.search-input .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .search-input .button,
.search-input .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .search-input a.checkout-button,
.search-input .woocommerce #respond input#submit,
.woocommerce #respond .search-input input#submit,
.search-input .woocommerce a.button,
.woocommerce .search-input a.button,
.search-input .woocommerce button.button,
.woocommerce .search-input button.button,
.search-input .woocommerce input.button,
.woocommerce .search-input input.button,
.search-input .woocommerce #place_order,
.woocommerce .search-input #place_order {
  background-color: transparent;
  position: absolute;
  right: 0;
  z-index: 9;
  height: 48px;
  width: 48px;
  padding: 0;
  text-align: center;
  line-height: 48px;
  justify-content: center;
}

@media only screen and (max-width: 1280px) {

  .search-input .form-control,
  .search-input .woocommerce #review_form #respond input[type="text"],
  .woocommerce #review_form #respond .search-input input[type="text"],
  .search-input .woocommerce #review_form #respond textarea,
  .woocommerce #review_form #respond .search-input textarea,
  .search-input .woocommerce #review_form #respond input[type="email"],
  .woocommerce #review_form #respond .search-input input[type="email"],
  .search-input .woocommerce-cart-form .table tr td.actions .coupon .input-text,
  .woocommerce-cart-form .table tr td.actions .coupon .search-input .input-text,
  .search-input .woocommerce-cart-form .table tr .quantity input,
  .woocommerce-cart-form .table tr .quantity .search-input input,
  .search-input .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
  .woocommerce .woocommerce-checkout.checkout form .form-row .search-input textarea,
  .search-input .woocommerce .woocommerce-checkout.checkout form .form-row select,
  .woocommerce .woocommerce-checkout.checkout form .form-row .search-input select,
  .search-input .woocommerce .woocommerce-checkout.checkout form .form-row input,
  .woocommerce .woocommerce-checkout.checkout form .form-row .search-input input,
  .search-input .woocommerce-EditAccountForm.edit-account input[type="text"],
  .woocommerce-EditAccountForm.edit-account .search-input input[type="text"],
  .search-input .woocommerce-EditAccountForm.edit-account input[type="email"],
  .woocommerce-EditAccountForm.edit-account .search-input input[type="email"],
  .search-input .woocommerce-EditAccountForm.edit-account input[type="password"],
  .woocommerce-EditAccountForm.edit-account .search-input input[type="password"],
  .search-input .login input[type="text"],
  .login .search-input input[type="text"],
  .search-input .login input[type="password"],
  .login .search-input input[type="password"],
  .search-input .woocommerce-ResetPassword.lost_reset_password input[type="text"],
  .woocommerce-ResetPassword.lost_reset_password .search-input input[type="text"],
  .search-input .checkout.woocommerce-checkout input[type="text"],
  .checkout.woocommerce-checkout .search-input input[type="text"],
  .search-input .checkout.woocommerce-checkout input[type="email"],
  .checkout.woocommerce-checkout .search-input input[type="email"],
  .search-input .checkout.woocommerce-checkout input[type="tel"],
  .checkout.woocommerce-checkout .search-input input[type="tel"],
  .search-input .checkout.woocommerce-checkout .select2-choice,
  .checkout.woocommerce-checkout .search-input .select2-choice,
  .search-input .checkout.woocommerce-checkout textarea,
  .checkout.woocommerce-checkout .search-input textarea,
  .search-input .woocommerce form .form-row input.input-text,
  .woocommerce form .form-row .search-input input.input-text,
  .search-input .woocommerce form .form-row .select2-selection,
  .woocommerce form .form-row .search-input .select2-selection,
  .search-input .woocommerce form .form-row .select2-choice,
  .woocommerce form .form-row .search-input .select2-choice {
    padding: 15px 20px;
  }

  .search-input .btn i,
  .search-input .wp-block-button__link i,
  .search-input .woocommerce .widget_shopping_cart .buttons a i,
  .woocommerce .widget_shopping_cart .buttons .search-input a i,
  .search-input .woocommerce.widget_shopping_cart .buttons a i,
  .woocommerce.widget_shopping_cart .buttons .search-input a i,
  .search-input .woocommerce #review_form #respond .form-submit input i,
  .woocommerce #review_form #respond .form-submit .search-input input i,
  .search-input .woocommerce-cart-form .table tr .button i,
  .woocommerce-cart-form .table tr .search-input .button i,
  .search-input .woocommerce-cart .wc-proceed-to-checkout a.checkout-button i,
  .woocommerce-cart .wc-proceed-to-checkout .search-input a.checkout-button i,
  .search-input .woocommerce #respond input#submit i,
  .woocommerce #respond .search-input input#submit i,
  .search-input .woocommerce a.button i,
  .woocommerce .search-input a.button i,
  .search-input .woocommerce button.button i,
  .woocommerce .search-input button.button i,
  .search-input .woocommerce input.button i,
  .woocommerce .search-input input.button i,
  .search-input .woocommerce #place_order i,
  .woocommerce .search-input #place_order i {
    font-size: 16px;
  }
}

@media only screen and (max-width: 991px) {

  .search-input .form-control,
  .search-input .woocommerce #review_form #respond input[type="text"],
  .woocommerce #review_form #respond .search-input input[type="text"],
  .search-input .woocommerce #review_form #respond textarea,
  .woocommerce #review_form #respond .search-input textarea,
  .search-input .woocommerce #review_form #respond input[type="email"],
  .woocommerce #review_form #respond .search-input input[type="email"],
  .search-input .woocommerce-cart-form .table tr td.actions .coupon .input-text,
  .woocommerce-cart-form .table tr td.actions .coupon .search-input .input-text,
  .search-input .woocommerce-cart-form .table tr .quantity input,
  .woocommerce-cart-form .table tr .quantity .search-input input,
  .search-input .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
  .woocommerce .woocommerce-checkout.checkout form .form-row .search-input textarea,
  .search-input .woocommerce .woocommerce-checkout.checkout form .form-row select,
  .woocommerce .woocommerce-checkout.checkout form .form-row .search-input select,
  .search-input .woocommerce .woocommerce-checkout.checkout form .form-row input,
  .woocommerce .woocommerce-checkout.checkout form .form-row .search-input input,
  .search-input .woocommerce-EditAccountForm.edit-account input[type="text"],
  .woocommerce-EditAccountForm.edit-account .search-input input[type="text"],
  .search-input .woocommerce-EditAccountForm.edit-account input[type="email"],
  .woocommerce-EditAccountForm.edit-account .search-input input[type="email"],
  .search-input .woocommerce-EditAccountForm.edit-account input[type="password"],
  .woocommerce-EditAccountForm.edit-account .search-input input[type="password"],
  .search-input .login input[type="text"],
  .login .search-input input[type="text"],
  .search-input .login input[type="password"],
  .login .search-input input[type="password"],
  .search-input .woocommerce-ResetPassword.lost_reset_password input[type="text"],
  .woocommerce-ResetPassword.lost_reset_password .search-input input[type="text"],
  .search-input .checkout.woocommerce-checkout input[type="text"],
  .checkout.woocommerce-checkout .search-input input[type="text"],
  .search-input .checkout.woocommerce-checkout input[type="email"],
  .checkout.woocommerce-checkout .search-input input[type="email"],
  .search-input .checkout.woocommerce-checkout input[type="tel"],
  .checkout.woocommerce-checkout .search-input input[type="tel"],
  .search-input .checkout.woocommerce-checkout .select2-choice,
  .checkout.woocommerce-checkout .search-input .select2-choice,
  .search-input .checkout.woocommerce-checkout textarea,
  .checkout.woocommerce-checkout .search-input textarea,
  .search-input .woocommerce form .form-row input.input-text,
  .woocommerce form .form-row .search-input input.input-text,
  .search-input .woocommerce form .form-row .select2-selection,
  .woocommerce form .form-row .search-input .select2-selection,
  .search-input .woocommerce form .form-row .select2-choice,
  .woocommerce form .form-row .search-input .select2-choice {
    height: 50px;
  }

  .search-input .btn,
  .search-input .wp-block-button__link,
  .search-input .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .search-input a,
  .search-input .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .search-input a,
  .search-input .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .search-input input,
  .search-input .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .search-input .button,
  .search-input .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .search-input a.checkout-button,
  .search-input .woocommerce #respond input#submit,
  .woocommerce #respond .search-input input#submit,
  .search-input .woocommerce a.button,
  .woocommerce .search-input a.button,
  .search-input .woocommerce button.button,
  .woocommerce .search-input button.button,
  .search-input .woocommerce input.button,
  .woocommerce .search-input input.button,
  .search-input .woocommerce #place_order,
  .woocommerce .search-input #place_order {
    height: 50px;
    width: 50px;
    line-height: 50px;
  }
}

.dzdrop-menu .nav>li.has-mega-menu {
  position: relative;
}

@media only screen and (min-width: 991px) {
  .dzdrop-menu {
    position: absolute;
    left: 0px;
    top: 100%;
    margin-top: 20px;
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.05);
    display: flex;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
  }

  .dzdrop-menu .navbar-nav {
    display: block;
    width: 230px;
    background-color: #fff;
    padding: 10px 0;
    border-radius: 0 0 0 var(--border-radius-base);
  }

  .dzdrop-menu .navbar-nav>li>a {
    padding: 15px 25px;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
  }

  .dzdrop-menu .navbar-nav>li:hover>a {
    background-color: #F8F8F8;
  }

  .dzdrop-menu .navbar-nav>li.sub-menu-down>a:after {
    content: "\f054";
    float: right;
    font-size: 12px;
    margin-top: 2px;
    color: var(--primary);
  }

  .dzdrop-menu .navbar-nav>li .mega-menu,
  .dzdrop-menu .navbar-nav>li .sub-menu {
    left: auto;
    right: -220px;
    top: 0;
  }

  .dzdrop-menu .navbar-nav>li.has-mega-menu .mega-menu {
    left: 230px;
    right: auto;
    top: 0px;
    min-width: 600px;
    flex-wrap: wrap;
    padding: 15px 0px;
  }

  .dzdrop-menu .navbar-nav>li.has-mega-menu .mega-menu li {
    width: 33.33%;
    float: left;
    padding: 0;
    border: 0;
  }

  .dzdrop-menu .navbar-nav>li.has-mega-menu .mega-menu li:after {
    content: none;
  }

  .dzdrop-menu .navbar-nav>li.has-mega-menu .mega-menu li a {
    display: block;
    font-size: 15px;
    padding: 10px 20px;
    line-height: 1.3;
    text-transform: capitalize;
    position: relative;
    transition: all 0.15s linear;
    -webkit-transition: all 0.15s linear;
    -moz-transition: all 0.15s linear;
    -o-transition: all 0.15s linear;
    font-weight: 400;
    border: 0;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1280px) {
  .dzdrop-menu .navbar-nav>li.has-mega-menu .mega-menu {
    min-width: 500px;
  }

  .dzdrop-menu .navbar-nav>li.has-mega-menu .mega-menu li {
    width: 50%;
  }
}

@media only screen and (min-width: 991px) {
  .dzdrop-menu.show {
    opacity: 1;
    visibility: visible;
    margin-top: -15px;
  }
}

@media only screen and (max-width: 1280px) {
  .dzdrop-menu .navbar-nav>li .mega-menu {
    min-width: 450px;
  }

  .dzdrop-menu .navbar-nav>li .mega-menu ul li {
    width: 50%;
  }
}

@media only screen and (max-width: 991px) {
  .dzdrop-menu .navbar-nav>li .mega-menu {
    min-width: 100%;
  }

  .dzdrop-menu .navbar-nav>li .mega-menu ul li {
    width: 100%;
  }
}

.cart-list {
  border: 0;
  right: 0;
  left: auto;
  width: 300px;
  top: 100%;
  transition: none;
  margin-top: 0;
}

.cart-list li {
  padding: 10px 15px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  width: 100%;
}

.cart-list li .media {
  display: flex;
  align-items: center;
}

.cart-list li .media .media-left {
  width: 60px;
  height: 80px;
  border-radius: 6px;
  overflow: hidden;
}

.cart-list li .media .media-left img {
  width: 100%;
  height: 100%;
}

.cart-list li .media .media-body {
  position: relative;
  padding-left: 10px;
  padding-right: 30px;
  flex: 1;
}

.cart-list li .media .media-body .dz-title {
  color: var(--secondary);
  font-size: 18px;
  font-weight: 600;
}

.cart-list li .media .media-body .dz-title a {
  color: inherit;
}

.cart-list li .media .media-body .dz-price {
  font-size: 16px;
  color: var(--primary);
}

.cart-list li .media .media-body .item-close {
  background-color: var(--bs-danger);
  color: white;
  display: block;
  font-size: 24px;
  height: 24px;
  line-height: 24px;
  position: absolute;
  right: 0px;
  text-align: center;
  top: 50%;
  width: 24px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 400;
  transform: translateY(-50%);
}

.cart-list.dropdown-menu {
  width: 300px !important;
}

.extra-.extra-cell .header-right .cart-list.dropdown-menu {
  inset: 100% 0px auto auto !important;
  margin-top: 14px;
  position: absolute;
  transform: translate(0px, 0px) !important;
}

.dz-bnr-inr {
  height: 450px;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 2;
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .dz-bnr-inr {
    height: 300px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-bnr-inr {
    height: 230px;
  }
}

.dz-bnr-inr .container {
  display: table;
  height: 100%;
}

.dz-bnr-inr .dz-bnr-inr-entry {
  height: 400px;
  vertical-align: middle;
  display: table-cell;
  text-align: center;
}

@media only screen and (max-width: 991px) {
  .dz-bnr-inr .dz-bnr-inr-entry {
    height: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .dz-bnr-inr .dz-bnr-inr-entry {
    text-align: center;
  }

  .dz-bnr-inr .dz-bnr-inr-entry .breadcrumb-row {
    display: inline-block;
  }
}

@media only screen and (max-width: 575px) {
  .dz-bnr-inr .dz-bnr-inr-entry {
    height: 230px;
  }
}

.dz-bnr-inr h1,
.dz-bnr-inr .h1 {
  font-size: 3.5rem;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #fff;
  text-transform: capitalize;
}

@media only screen and (max-width: 991px) {

  .dz-bnr-inr h1,
  .dz-bnr-inr .h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {

  .dz-bnr-inr h1,
  .dz-bnr-inr .h1 {
    font-size: 30px;
  }
}

.dz-bnr-inr p {
  color: #fff;
  font-size: 18px;
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 575px) {
  .dz-bnr-inr p {
    font-size: 14px;
  }
}

.dz-bnr-inr.dz-bnr-inr-lg {
  height: 600px;
}

.dz-bnr-inr.dz-bnr-inr-lg .dz-bnr-inr-entry {
  height: 600px;
}

@media only screen and (max-width: 991px) {
  .dz-bnr-inr.dz-bnr-inr-lg {
    height: 450px;
  }

  .dz-bnr-inr.dz-bnr-inr-lg .dz-bnr-inr-entry {
    height: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .dz-bnr-inr.dz-bnr-inr-lg {
    height: 350px;
  }

  .dz-bnr-inr.dz-bnr-inr-lg .dz-bnr-inr-entry {
    height: 350px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-bnr-inr.dz-bnr-inr-lg {
    height: 300px;
  }

  .dz-bnr-inr.dz-bnr-inr-lg .dz-bnr-inr-entry {
    height: 300px;
  }
}

.dz-bnr-inr.dz-bnr-inr-md {
  height: 450px;
}

.dz-bnr-inr.dz-bnr-inr-md .dz-bnr-inr-entry {
  height: 450px;
}

@media only screen and (max-width: 991px) {
  .dz-bnr-inr.dz-bnr-inr-md {
    height: 400px;
  }

  .dz-bnr-inr.dz-bnr-inr-md .dz-bnr-inr-entry {
    height: 400px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-bnr-inr.dz-bnr-inr-md {
    height: 300px;
  }

  .dz-bnr-inr.dz-bnr-inr-md .dz-bnr-inr-entry {
    height: 300px;
  }
}

.dz-bnr-inr.dz-bnr-inr-sm {
  height: 300px;
}

.dz-bnr-inr.dz-bnr-inr-sm .dz-bnr-inr-entry {
  height: 300px;
}

.dz-bnr-inr.bnr-no-img:before {
  content: none;
}

.dz-bnr-inr:after {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  height: 100%;
  width: 60%;
  background-color: #fff;
  opacity: .15;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}

.dz-bnr-inr .breadcrumb-row {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -20px;
}

.dz-bnr-inr .breadcrumb-row ul {
  margin-bottom: 0;
}

@media only screen and (max-width: 991px) {
  .dz-bnr-inr .breadcrumb-row {
    left: 15px;
    right: 15px;
    transform: translateX(0px);
  }
}

.breadcrumb-row ul {
  background: var(--primary);
  padding: 0;
  display: inline-block;
  padding: 15px 30px;
  border-radius: var(--border-radius-base);
}

.breadcrumb-row ul li {
  padding: 0;
  margin-right: 3px;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  color: #ffffff;
}

.breadcrumb-row ul li.active {
  color: #ffffff;
}

.breadcrumb-row ul li a {
  color: #ffffff;
}

.breadcrumb-row ul .breadcrumb-item+.breadcrumb-item::before {
  content: "\f105";
  color: #ffffff;
  font-weight: 700;
  font-family: "Font Awesome 5 Free";
  font-size: 15px;
  padding-right: 10px;
  top: 0px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-row ul .breadcrumb-item+.breadcrumb-item::before {
    top: 2px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-row ul {
    padding: 5px 20px;
  }

  .breadcrumb-row ul li {
    font-size: 15px;
  }
}

.dz-bnr-inr.style-1 {
  background-color: var(--secondary);
}

.dz-bnr-inr.style-1 .dz-bnr-inr-entry {
  height: 400px;
}

.dz-bnr-inr.style-1 .dz-bnr-inr-entry img {
  width: 100px;
  margin-bottom: 0;
}

.dz-bnr-inr.style-1 .dz-bnr-inr-entry .title {
  font-size: 3.125rem;
  padding: 10px 0;
  margin-bottom: 0;
}

@media screen and (max-width: 991px) {
  .dz-bnr-inr.style-1 .dz-bnr-inr-entry p {
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  .dz-bnr-inr.style-1 .dz-bnr-inr-entry p {
    font-size: 13px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 360px) {
  .dz-bnr-inr.style-1 .dz-bnr-inr-entry p {
    display: none;
  }
}

.page-title {
  padding: 30px 0;
}

.page-title.dashboard {
  background: transparent;
  margin-left: 75px;
  padding: 20px 0 0px;
}

.page-title .page-title-content p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}

.page-title .page-title-content p span {
  font-weight: 500;
  color: var(--primary);
}

.breadcrumbs {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 575px) {
  .breadcrumbs {
    justify-content: flex-start;
  }
}

.breadcrumbs li a {
  color: rgba(151, 151, 159, 0.39);
  display: inline-block;
  margin-left: 15px;
  font-size: 14px;
}

.breadcrumbs li:first-child a {
  margin-left: 0px;
}

.breadcrumbs li.active a {
  color: var(--primary);
}

footer {
  background-image: url();
}

@media only screen and (max-width: 767px) {
  footer .row {
    --bs-gutter-x: 10px;
  }
}

footer .widget_about p {
  margin-bottom: 20px;
}

@media only screen and (max-width: 1280px) {
  footer .widget_about {
    text-align: center;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
  }
}

footer .footer-logo {
  margin-bottom: 25px;
}

footer .footer-logo img {
  max-width: 220px;
}

footer .footer-title {
  margin: 0;
  font-weight: 600;
  margin-bottom: 20px;
  position: relative;
  line-height: 1.2;
}

footer .footer-top {
  padding: 70px 0px 30px;
}

footer .footer-top .widget {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  footer .footer-top {
    padding: 50px 0 0;
  }
}

footer .footer-bottom {
  padding: 28px 0;
  border-top: 1px solid #f3f3f3;
  color: #a9a9a9;
  font-size: 15px;
}

@media only screen and (max-width: 575px) {
  footer .footer-bottom {
    font-size: 14px;
  }
}

footer .footer-bottom p {
  margin-bottom: 0;
}

footer .footer-bottom .copyright-text a {
  color: #ffffff;
}

footer .footer-bottom .heart {
  width: 60px;
  height: 60px;
  display: inline-block;
  background: url(../images/like.png);
  cursor: pointer;
  margin: -25px -15px;
}

footer .footer-bottom .heart.heart-blast {
  background-position: -1680px 0 !important;
  transition: background 1s steps(28);
}

@media only screen and (max-width: 1024px) {
  footer .footer-bottom p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 991px) {
  footer .footer-top {
    padding: 45px 0px 30px;
  }

  footer .footer-bottom .text-start,
  footer .footer-bottom .text-end {
    text-align: center !important;
  }

  footer .footer-bottom .text-end {
    margin-top: 15px;
  }

  footer .footer-bottom p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 575px) {
  footer .footer-top {
    padding: 45px 0px 0px;
  }
}

footer .widget_services ul li a {
  display: block;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  font-family: 'Roboto Condensed', sans-serif;
}

footer.footer-dark {
  background: var(--secondary);
  --title: #fff;
}

footer.footer-dark .footer-bottom {
  border-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.5);
}

footer.footer-dark .footer-top {
  color: rgba(255, 255, 255, 0.6);
}

footer.footer-dark .footer-category {
  background: var(--primary);
  border-color: rgba(255, 255, 255, 0.1);
}

footer.footer-dark .footer-category .toggle-items ul li a {
  color: rgba(255, 255, 255, 0.85);
}

footer.footer-dark .footer-category .toggle-items ul li a:hover {
  color: var(--secondary);
}

.widget_getintuch ul li {
  position: relative;
  margin-bottom: 15px;
  padding-left: 45px;
  min-height: 35px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1280px) {
  .widget_getintuch ul li {
    margin-bottom: 20px;
    padding-left: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .widget_getintuch ul li {
    padding-left: 40px;
  }
}

.widget_getintuch ul li h5,
.widget_getintuch ul li .h5 {
  font-size: 20px;
  line-height: 1.33;
  margin-bottom: 5px;
}

@media only screen and (max-width: 1280px) {

  .widget_getintuch ul li h5,
  .widget_getintuch ul li .h5 {
    font-size: 18px;
    line-height: 1.2;
  }
}

.widget_getintuch ul li i {
  position: absolute;
  font-size: 28px;
  line-height: 1;
  left: 0;
  color: var(--primary);
}

@media only screen and (max-width: 1280px) {
  .widget_getintuch ul li i {
    font-size: 35px;
    top: 6px;
  }
}

@media only screen and (max-width: 575px) {
  .widget_getintuch ul li i {
    font-size: 28px;
  }
}

.contact-ft-1 {
  text-align: center;
  margin-bottom: 40px;
}

.contact-ft-1 i {
  color: var(--primary);
  font-size: 50px;
  line-height: 1;
  margin-bottom: 15px;
  display: inline-block;
}

.contact-ft-1 h4,
.contact-ft-1 .h4 {
  color: #ffffff;
  text-decoration: underline;
}

.contact-ft-1 h5,
.contact-ft-1 .h5 {
  color: #ffffff;
  font-weight: 400;
}

.list-column ul {
  display: flex;
  flex-wrap: wrap;
}

.list-column ul li {
  flex: 0 0 50%;
  max-width: 50%;
}

.fb-link {
  list-style: none;
  margin: 0;
  padding: 0;
}

.fb-link li {
  display: inline-block;
}

.fb-link li a {
  color: #fff;
  position: relative;
}

.fb-link li a:after {
  content: "";
  background: var(--primary);
  width: 5px;
  height: 5px;
}

.footer-link li {
  display: inline-block;
  font-family: "Poppins", sans-serif;
  position: relative;
  padding: 0 25px 0 20px;
}

.footer-link li a {
  color: inherit;
}

.footer-link li:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: var(--primary);
  position: absolute;
  top: 50%;
  left: 0;
  border-radius: 8px;
  transform: translateY(-50%);
}

.widget_time ul li {
  display: block;
  margin-bottom: 5px;
}

.widget_time ul li label {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0;
  margin-right: 3px;
}

.footer-category {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #f9f9f9;
}

.footer-category .toggle-btn {
  position: relative;
  font-family: var(--font-family-title);
  color: var(--title);
  font-size: 18px;
  font-weight: 500;
}

.footer-category .toggle-btn:before {
  content: "\2b";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer-category .toggle-btn.active:before {
  content: "\f068";
}

.footer-category .toggle-items:after {
  display: block;
  clear: both;
  content: "";
}

.footer-category .toggle-items ul li {
  width: 25%;
  float: left;
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .footer-category .toggle-items ul li {
    padding: 5px 0;
  }
}

.footer-category .toggle-items ul li a {
  color: #363848;
  display: block;
  padding: 5px 0;
}

.footer-category .toggle-items ul li a:hover {
  color: var(--primary);
}

@media only screen and (max-width: 1280px) {
  .footer-category .toggle-items ul li {
    width: 33%;
  }
}

@media only screen and (max-width: 575px) {
  .footer-category .toggle-items ul li {
    width: 50%;
  }
}

.footer-category .toggle-items .footer-col-book {
  margin-top: 22px;
}

@media only screen and (max-width: 767px) {
  .footer-category .toggle-items .footer-col-book {
    margin-top: 10px;
  }
}

.footer-col-book ul li a {
  padding: 5px 0;
}

#bg {
  background-attachment: fixed;
  background-size: cover;
}

.main-bar-wraper.sticky-no .main-bar {
  position: unset;
}

.boxed .page-wraper {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.boxed .footer-fixed .site-footer {
  left: 50%;
  width: 1200px;
  margin: 0 -600px;
}

.boxed .is-fixed .main-bar {
  transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -webkit-transition: none;
  -o-transition: none;
  position: unset;
}

.boxed .site-header.style-1 .extra-cell>a,
.boxed .site-header.style-1 .extra-cell>div {
  margin-left: 15px;
}

@media only screen and (min-width: 1680px) {
  .boxed .site-header.style-1 .extra-icon-box {
    display: none;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .site-header.style-2 .extra-cell .login-btn i {
    margin: 0;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .site-header.style-2 .extra-cell .login-btn {
    margin-left: 20px;
    width: 45px;
    height: 45px;
    padding: 12px;
  }

  .boxed .site-header.style-2 .extra-cell .login-btn span {
    display: none;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .site-header.style-2 .social-list.style-2 {
    display: none;
  }
}

.boxed .site-header.style-2.center .header-.nav.navbar-left {
  margin-right: 120px;
}

.boxed .site-header.style-2.center .header-.nav.navbar-right {
  margin-left: 120px;
}

.boxed .site-header.style-2.center .extra-icon-box {
  padding-left: 0;
  width: 50px;
}

.boxed .site-header.style-2.center .extra-icon-box .title,
.boxed .site-header.style-2.center .extra-icon-box span {
  display: none;
}

.boxed .site-header.style-2.center .extra-nav-left,
.boxed .site-header.style-2.center .extra-nav {
  width: 120px;
  padding: 0;
}

.boxed .site-header.style-2.center .extra-cell .login-btn {
  display: none;
}

.boxed .site-header.style-2.center .extra-cell>a,
.boxed .site-header.style-2.center .extra-cell>div {
  margin-left: 15px;
}

@media only screen and (min-width: 1680px) {
  .boxed .header-.nav>li>a {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .silder-one .silder-content {
    padding: 40px;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .silder-one .silder-content .title {
    margin-bottom: 20px;
    font-size: 60px;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .silder-one .silder-content .sub-title {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 1680px) {

  .boxed .container-fluid,
  .boxed .container-sm,
  .boxed .container-md,
  .boxed .container-lg,
  .boxed .container-xl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .under-construct {
    padding: 50px;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .under-construct .dz-content .dz-title {
    font-size: 70px;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .under-construct .dz-content p {
    font-size: 28px;
  }
}

.boxed .download-area .download-media.right {
  right: 78%;
}

.boxed .newsletter-bx {
  padding: 40px;
}

.boxed .portfolio-area2 .setResizeMargin {
  padding-right: 15px;
  margin-left: 0 !important;
  padding-left: 15px;
}

.boxed .sidenav-menu {
  position: absolute;
}

.boxed .dz-coming-soon .dz-title {
  font-size: 42px;
}

@media only screen and (max-width: 575px) {
  .boxed .dz-coming-soon .dz-title {
    font-size: 28px;
  }
}

.boxed .dz-coming-soon .countdown .date span {
  font-size: 14px;
}

.boxed .dz-coming-soon .countdown .date .time {
  font-size: 60px;
  line-height: 60px;
}

@media only screen and (max-width: 575px) {
  .boxed .dz-coming-soon .countdown .date .time {
    font-size: 35px;
  }
}

.boxed .dz-coming-soon .dz-coming-bx {
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (max-width: 575px) {
  .boxed .dz-coming-soon .dz-coming-bx {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.boxed .swiper-btn-center-lr {
  position: relative;
}

.boxed .swiper-btn-center-lr .btn-next,
.boxed .swiper-btn-center-lr .btn-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.boxed .swiper-btn-center-lr .btn-prev {
  left: 0px;
}

.boxed .swiper-btn-center-lr .btn-next {
  right: 0px;
}

.boxed .swiper-btn-center-lr:hover .btn-prev {
  left: 0px;
}

.boxed .swiper-btn-center-lr:hover .btn-next {
  right: 0px;
}

/* Frame */
.frame {
  padding: 30px;
}

.frame .page-wraper {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  height: auto !important;
}

@media only screen and (max-width: 1680px) {
  .frame .site-header.style-1 .extra-icon-box {
    display: none;
  }
}

.frame .is-fixed .main-bar {
  left: 0;
  width: 100%;
  margin: 0;
  position: unset;
}

.frame button.scroltop {
  right: 50px;
  bottom: 50px;
}

.frame .sidenav-menu {
  position: absolute;
}

@media only screen and (max-width: 1680px) {
  .frame .dz-coming-bx {
    padding-left: 30px;
    padding-right: 30px;
  }

  .frame .countdown .date .time {
    font-size: 70px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 1024px) {
  .frame {
    padding: 0;
  }

  .frame button.scroltop {
    right: 15px;
    bottom: 15px;
  }
}

.theme-sharped {
  --border-radius-base: 0;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}

.theme-sharped .banner-one .banner-media .shape-1,
.theme-sharped .banner-one .banner-media .shape-2,
.theme-sharped .dz-card .dz-meta li.post-date,
.theme-sharped .progress-bx.style-2 .progress,
.theme-sharped .progress-bx.style-2 .progress .progress-bar {
  border-radius: 0;
}

.theme-rounded {
  --border-radius-base: 6px;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}

.client-swiper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.client-swiper .swiper-wrapper {
  text-align: center;
}

.client-swiper .swiper-wrapper .swiper-slide {
  text-align: center;
  background: var(--white);
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.client-swiper .swiper-wrapper .swiper-slide .swiper-slide img {
  display: block;
  object-fit: cover;
}

.client-swiper img {
  height: 40px;
  max-width: 130px;
  filter: grayscale(1);
  opacity: 0.6;
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
  cursor: pointer;
}

.client-swiper img:hover {
  filter: grayscale(0);
  opacity: 1;
}

.books-card .dz-media img {
  width: 100%;
  border-radius: var(--border-radius-base);
}

.books-card.style-1 {
  text-align: center;
}

.books-card.style-1 .dz-content {
  margin-top: 10px;
}

.books-card.style-1 .dz-content .price {
  color: var(--primary);
  display: block;
  font-family: poppins;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 12px;
}

.books-card.style-2 {
  display: flex;
}

.books-card.style-2 .dz-media {
  width: 335px;
  min-width: 335px;
}

.books-card.style-2 .dz-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--border-radius-base);
}

.books-card.style-2 .dz-content {
  padding-left: 50px;
}

.books-card.style-2 .dz-content .sub-title {
  letter-spacing: 20px;
  font-weight: var(--headings-font-weight3);
}

.books-card.style-2 .dz-content .title {
  font-size: 60px;
}

.books-card.style-2 .dz-content .dz-tags {
  display: flex;
}

.books-card.style-2 .dz-content .dz-tags li {
  margin-right: 25px;
  font-size: 16px;
  font-weight: var(--headings-font-weight2);
  font-family: var(--font-family-title);
  color: var(--secondary);
}

.books-card.style-2 .dz-content .text {
  padding-left: 30px;
  margin: 40px 0px 40px 0px;
  border-left: 1px solid #3B2F4A;
}

.books-card.style-2 .dz-content .price {
  align-items: center;
  display: inline-flex;
}

.books-card.style-2 .dz-content .price .price-num {
  font-size: 50px;
  color: var(--title);
  font-weight: var(--headings-font-weight);
  font-family: var(--font-family-title);
}

.books-card.style-2 .dz-content .price del {
  color: var(--primary);
  font-size: 22px;
  padding: 0 20px;
}

.books-card.style-2 .dz-content .price .badge {
  font-size: 14px;
  font-weight: var(--headings-font-weight);
  color: #131045;
  background-color: #F0F0F0;
  padding: 8px 12px;
}

@media only screen and (max-width: 1280px) {
  .books-card.style-2 .dz-content .title {
    font-size: 48px;
  }
}

@media only screen and (max-width: 991px) {
  .books-card.style-2 .dz-content {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .books-card.style-2 .dz-media {
    width: 200px;
    min-width: 200px;
  }

  .books-card.style-2 .dz-content {
    padding-left: 20px;
  }

  .books-card.style-2 .dz-content .title {
    font-size: 32px;
  }

  .books-card.style-2 .dz-content .text {
    padding-left: 20px;
    margin-top: 20px;
  }

  .books-card.style-2 .dz-content .price .price-num {
    font-size: 32px;
  }
}

@media only screen and (max-width: 575px) {
  .books-card.style-2 {
    display: block;
  }

  .books-card.style-2 .dz-media {
    width: 100%;
    min-width: 100%;
  }

  .books-card.style-2 .dz-content {
    padding-left: 0;
    margin-top: 25px;
  }

  .books-card.style-2 .dz-content .dz-tags li {
    margin-right: 13px;
  }
}

.books-card.style-3 .dz-content {
  padding-top: 20px;
}

.books-card.style-3 .dz-content .dz-tags {
  display: inline-flex;
  margin-bottom: 5px;
}

.books-card.style-3 .dz-content .dz-tags li {
  color: var(--primary);
  margin-right: 10px;
  font-size: 14px;
}

.books-card.style-3 .dz-content .book-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.books-card.style-3 .dz-content .book-footer .rate {
  color: var(--primary);
  font-size: 18px;
  display: flex;
}

.books-card.style-3 .dz-content .book-footer .rate i {
  margin-right: 5px;
}

.books-card.style-3 .dz-content .book-footer .price .price-num {
  font-weight: var(--headings-font-weight);
  font-size: 18px;
  color: var(--title);
}

.books-card.style-3 .dz-content .book-footer .price del {
  font-weight: var(--headings-font-weight);
  font-size: 15px;
  color: #AAAAAA;
  font-family: var(--font-family-title);
  padding: 0 5px;
  font-weight: 400;
}

.books-card.style-4 {
  border-radius: var(--border-radius-base);
  display: flex;
  align-items: center;
  padding: 40px 40px;
  margin-bottom: 10px;
  overflow: hidden;
  z-index: 1;
  position: relative;
  height: calc(100% - 15px);
}

.books-card.style-4:after {
  content: "";
  position: absolute;
  background-color: rgba(19, 16, 69, 0.9);
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.books-card.style-4 .dz-media {
  width: 120px;
  min-width: 120px;
}

.books-card.style-4 .dz-media img {
  width: 100%;
}

.books-card.style-4 .dz-content {
  padding-left: 30px;
  padding-right: 15px;
  width: 100%;
}

.books-card.style-4 .dz-content .title {
  margin-bottom: 5px;
}

.books-card.style-4 .dz-content .title a {
  color: #ffffff;
}

.books-card.style-4 .dz-content .dz-meta {
  color: #fff;
  font-weight: var(--headings-font-weight);
  margin-bottom: 15px;
}

.books-card.style-4 .dz-content .dz-meta li {
  font-weight: 600;
  font-size: 14px;
}

.books-card.style-4 .dz-content .dz-meta li a {
  color: rgba(255, 255, 255, 0.7);
}

.books-card.style-4 .dz-content p {
  color: #ffffff;
  margin-bottom: 0;
}

.books-card.style-4 .book-footer {
  text-align: right;
  min-width: 150px;
}

.books-card.style-4 .book-footer .price {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.books-card.style-4 .book-footer .price .price-num {
  color: #ffffff;
  font-size: 30px;
  font-weight: var(--headings-font-weight);
  font-family: var(--font-family-title);
}

.books-card.style-4 .book-footer .price del {
  color: #EE7979;
  font-family: var(--font-family-title);
  font-size: 22px;
  font-weight: var(--headings-font-weight2);
  padding-left: 10px;
}

.books-card.style-4 .bg-media {
  position: absolute;
  width: 80%;
  z-index: -1;
  top: -45%;
  right: -53%;
  object-position: center center;
  filter: blur(10px);
  transform: rotate(-17deg);
}

.books-card.style-4.card-lg .dz-media {
  width: 255px;
  min-width: 255px;
}

.books-card.style-4.card-lg .dz-content {
  padding-left: 0;
  padding-right: 50px;
}

.books-card.style-4.card-lg .book-footer {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 50px;
}

.books-card.style-4.card-lg .book-footer .price {
  margin-bottom: 0px;
  margin-right: 30px;
}

.books-card.style-4.card-light {
  border: 1px solid #F1F1F1;
}

.books-card.style-4.card-light p {
  color: #777777;
}

.books-card.style-4.card-light .title a,
.books-card.style-4.card-light .price .price-num,
.books-card.style-4.card-light .dz-meta li,
.books-card.style-4.card-light .dz-meta li a {
  color: var(--title);
}

.books-card.style-4.card-light:after {
  background-color: rgba(255, 255, 255, 0.9);
}

@media only screen and (max-width: 1480px) {
  .books-card.style-4.card-lg .dz-media {
    width: 200px;
    min-width: 200px;
  }

  .books-card.style-4.card-lg .dz-content {
    padding-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .books-card.style-4 {
    padding: 20px;
  }

  .books-card.style-4 .dz-content .title {
    margin-bottom: 0;
  }

  .books-card.style-4 .dz-content .dz-meta {
    margin-bottom: 5px;
  }

  .books-card.style-4 .dz-content p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .books-card.style-4 {
    display: block;
  }

  .books-card.style-4 .dz-content {
    margin-top: 15px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .books-card.style-4 .book-footer {
    display: flex;
  }

  .books-card.style-4 .book-footer .price {
    margin-bottom: 0px;
    padding-right: 10px;
  }

  .books-card.style-4.card-lg .dz-content {
    padding-right: 0px;
  }

  .books-card.style-4.card-lg .dz-media {
    margin-top: 20px;
    min-width: 320px;
    width: 320px;
  }

  .books-card.style-4.card-lg .book-footer .price {
    margin-right: 0px;
  }
}

.swiper-container.books-wrapper-2 {
  overflow: unset;
}

.books-wrapper-2 {
  margin: auto;
  max-width: 960px;
}

.books-wrapper-2 .swiper-slide .books-card {
  opacity: 0.5;
}

.books-wrapper-2 .swiper-slide.swiper-slide-active .books-card {
  opacity: 1;
}

.books-wrapper-4 .swiper-slide .books-card {
  opacity: 1;
}

.swiper-pagination-two .swiper-pagination-bullet,
.swiper-pagination-three .swiper-pagination-bullet,
.swiper-pagination-four .swiper-pagination-bullet,
.swiper-pagination-five .swiper-pagination-bullet {
  background-color: #DBDBDB;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin: 5px;
}

.swiper-pagination-two .swiper-pagination-bullet.swiper-pagination-bullet-active,
.swiper-pagination-three .swiper-pagination-bullet.swiper-pagination-bullet-active,
.swiper-pagination-four .swiper-pagination-bullet.swiper-pagination-bullet-active,
.swiper-pagination-five .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--primary);
}

@media only screen and (max-width: 575px) {
  .swiper-pagination-two {
    display: none;
  }
}

.swiper-pagination-four {
  text-align: center;
  margin-top: 50px;
}

.swiper-pagination-four .swiper-pagination-bullet {
  width: 22px;
  height: 8px;
  border-radius: 7px;
}

.swiper-pagination-four .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 45px;
  height: 8px;
}

.pagination-align {
  display: flex;
  align-items: center;
}

.pagination-align.style-1 .swiper-button-prev {
  margin-right: 10px;
}

.pagination-align.style-1 .swiper-button-next {
  margin-left: 10px;
}

.pagination-align.style-1 .swiper-button-next,
.pagination-align.style-1 .swiper-button-prev {
  position: unset;
  top: 0;
  align-items: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #ffffff;
  background-color: var(--primary);
  border-radius: 50%;
  margin-top: 0;
}

.pagination-align.style-1 .swiper-button-next:after,
.pagination-align.style-1 .swiper-button-prev:after {
  content: 'none';
}

.pagination-align.style-1 .swiper-pagination-two.swiper-pagination-bullets {
  margin-top: 8px;
}

.pagination-align.style-2 {
  justify-content: center;
  margin-top: 70px;
}

.pagination-align.style-2 .swiper-button-prev {
  margin-right: 10px;
}

.pagination-align.style-2 .swiper-button-next {
  margin-left: 10px;
}

.pagination-align.style-2 .swiper-button-next,
.pagination-align.style-2 .swiper-button-prev {
  position: unset;
  top: 0;
  margin-top: -6px;
  align-items: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #ffffff;
  background-color: var(--primary);
  border-radius: 50%;
}

.pagination-align.style-2 .swiper-button-next:after,
.pagination-align.style-2 .swiper-button-prev:after {
  content: 'none';
}

@media only screen and (max-width: 575px) {
  .pagination-align.style-2 {
    margin-top: 40px;
  }
}

.pagination-align.style-3 .swiper-button-prev {
  margin-right: 45px;
}

.pagination-align.style-3 .swiper-button-next {
  margin-left: 10px;
}

.pagination-align.style-3 .swiper-button-next,
.pagination-align.style-3 .swiper-button-prev {
  position: unset;
  top: 0;
  width: 25px;
  height: 25px;
  font-size: 24px;
  color: #090606;
  align-items: center;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.pagination-align.style-3 .swiper-button-next:hover,
.pagination-align.style-3 .swiper-button-prev:hover {
  color: var(--primary);
}

.pagination-align.style-3 .swiper-button-next:after,
.pagination-align.style-3 .swiper-button-prev:after {
  content: 'none';
}

@media screen and (max-width: 360px) {
  .pagination-align.style-3 {
    display: none;
  }
}

.reccomend {
  position: relative;
  overflow: hidden;
}

.reccomend .section-head img {
  position: absolute;
  left: 655px;
  top: 162px;
}

@media only screen and (max-width: 1680px) {
  .reccomend .section-head img {
    left: 502px;
    top: 121px;
  }
}

.testimonial .section-head p {
  max-width: 545px;
}

@media only screen and (max-width: 991px) {
  .testimonial .section-head p {
    display: none;
  }
}

.newsletter-wrapper {
  position: relative;
  z-index: 1;
}

.newsletter-wrapper:after {
  content: "";
  position: absolute;
  background-color: rgba(59, 47, 74, 0.82);
  width: 100%;
  height: 100%;
  z-index: -1;
  left: 0;
  top: 0;
}

.newsletter-wrapper.style-2 {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  border-radius: 25px;
  overflow: hidden;
}

.newsletter-wrapper.style-2:after {
  background-color: var(--secondary);
  opacity: 0.8;
}

@media screen and (max-width: 1360px) {
  .newsletter-wrapper.style-2 {
    max-width: 100%;
    margin-bottom: 0;
    border-radius: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .newsletter-wrapper .subscride-inner .section-head {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 575px) {
  .newsletter-wrapper .subscride-inner .section-head .title {
    font-size: 24px;
    margin-bottom: 25px;
  }
}

.newsletter-wrapper .subscride-inner .form-group .input-group .form-control,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce #review_form #respond input[type="text"],
.woocommerce #review_form #respond .newsletter-wrapper .subscride-inner .form-group .input-group input[type="text"],
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .newsletter-wrapper .subscride-inner .form-group .input-group textarea,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce #review_form #respond input[type="email"],
.woocommerce #review_form #respond .newsletter-wrapper .subscride-inner .form-group .input-group input[type="email"],
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .newsletter-wrapper .subscride-inner .form-group .input-group .input-text,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .newsletter-wrapper .subscride-inner .form-group .input-group input,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group select,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group input,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type="text"],
.woocommerce-EditAccountForm.edit-account .newsletter-wrapper .subscride-inner .form-group .input-group input[type="text"],
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type="email"],
.woocommerce-EditAccountForm.edit-account .newsletter-wrapper .subscride-inner .form-group .input-group input[type="email"],
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type="password"],
.woocommerce-EditAccountForm.edit-account .newsletter-wrapper .subscride-inner .form-group .input-group input[type="password"],
.newsletter-wrapper .subscride-inner .form-group .input-group .login input[type="text"],
.login .newsletter-wrapper .subscride-inner .form-group .input-group input[type="text"],
.newsletter-wrapper .subscride-inner .form-group .input-group .login input[type="password"],
.login .newsletter-wrapper .subscride-inner .form-group .input-group input[type="password"],
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-ResetPassword.lost_reset_password input[type="text"],
.woocommerce-ResetPassword.lost_reset_password .newsletter-wrapper .subscride-inner .form-group .input-group input[type="text"],
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout input[type="text"],
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group input[type="text"],
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout input[type="email"],
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group input[type="email"],
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout input[type="tel"],
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group input[type="tel"],
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group .select2-choice,
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group textarea,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group input.input-text,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group .select2-selection,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group .select2-choice {
  height: 72px;
  border: 1px solid #ffffff;
  border-radius: var(--border-radius-base) !important;
}

.newsletter-wrapper .subscride-inner .form-group .input-group .form-control::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce #review_form #respond input[type="text"]::placeholder,
.woocommerce #review_form #respond .newsletter-wrapper .subscride-inner .form-group .input-group input[type="text"]::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce #review_form #respond textarea::placeholder,
.woocommerce #review_form #respond .newsletter-wrapper .subscride-inner .form-group .input-group textarea::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce #review_form #respond input[type="email"]::placeholder,
.woocommerce #review_form #respond .newsletter-wrapper .subscride-inner .form-group .input-group input[type="email"]::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-cart-form .table tr td.actions .coupon .input-text::placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .newsletter-wrapper .subscride-inner .form-group .input-group .input-text::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-cart-form .table tr .quantity input::placeholder,
.woocommerce-cart-form .table tr .quantity .newsletter-wrapper .subscride-inner .form-group .input-group input::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row select::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group select::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row input::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group input::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type="text"]::placeholder,
.woocommerce-EditAccountForm.edit-account .newsletter-wrapper .subscride-inner .form-group .input-group input[type="text"]::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type="email"]::placeholder,
.woocommerce-EditAccountForm.edit-account .newsletter-wrapper .subscride-inner .form-group .input-group input[type="email"]::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type="password"]::placeholder,
.woocommerce-EditAccountForm.edit-account .newsletter-wrapper .subscride-inner .form-group .input-group input[type="password"]::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .login input[type="text"]::placeholder,
.login .newsletter-wrapper .subscride-inner .form-group .input-group input[type="text"]::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .login input[type="password"]::placeholder,
.login .newsletter-wrapper .subscride-inner .form-group .input-group input[type="password"]::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-ResetPassword.lost_reset_password input[type="text"]::placeholder,
.woocommerce-ResetPassword.lost_reset_password .newsletter-wrapper .subscride-inner .form-group .input-group input[type="text"]::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout input[type="text"]::placeholder,
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group input[type="text"]::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout input[type="email"]::placeholder,
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group input[type="email"]::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout input[type="tel"]::placeholder,
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group input[type="tel"]::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout .select2-choice::placeholder,
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group .select2-choice::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout textarea::placeholder,
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group textarea::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce form .form-row input.input-text::placeholder,
.woocommerce form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group input.input-text::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce form .form-row .select2-selection::placeholder,
.woocommerce form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group .select2-selection::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce form .form-row .select2-choice::placeholder,
.woocommerce form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group .select2-choice::placeholder {
  color: #ffffff;
}

.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon {
  position: absolute;
  right: 5px;
  top: 50%;
  z-index: 9;
  transform: translateY(-50%);
}

.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .btn,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .wp-block-button__link,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .button,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.checkout-button,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #respond input#submit,
.woocommerce #respond .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input#submit,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce a.button,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.button,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce button.button,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon button.button,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce input.button,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input.button,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #place_order,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon #place_order {
  height: 60px;
  width: 120px;
  text-align: center;
}

.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .btn span,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .wp-block-button__link span,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a span,
.woocommerce .widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a span,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a span,
.woocommerce.widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a span,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #review_form #respond .form-submit input span,
.woocommerce #review_form #respond .form-submit .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input span,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart-form .table tr .button span,
.woocommerce-cart-form .table tr .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .button span,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button span,
.woocommerce-cart .wc-proceed-to-checkout .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.checkout-button span,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #respond input#submit span,
.woocommerce #respond .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input#submit span,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce a.button span,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.button span,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce button.button span,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon button.button span,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce input.button span,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input.button span,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #place_order span,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon #place_order span {
  display: block;
}

.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .btn i,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .wp-block-button__link i,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a i,
.woocommerce .widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a i,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a i,
.woocommerce.widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a i,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #review_form #respond .form-submit input i,
.woocommerce #review_form #respond .form-submit .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input i,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart-form .table tr .button i,
.woocommerce-cart-form .table tr .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .button i,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button i,
.woocommerce-cart .wc-proceed-to-checkout .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.checkout-button i,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #respond input#submit i,
.woocommerce #respond .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input#submit i,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce a.button i,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.button i,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce button.button i,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon button.button i,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce input.button i,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input.button i,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #place_order i,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon #place_order i {
  display: none;
}

@media only screen and (max-width: 575px) {
  .newsletter-wrapper .subscride-inner .form-group .input-group {
    margin-bottom: 0;
  }

  .newsletter-wrapper .subscride-inner .form-group .input-group .form-control,
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce #review_form #respond input[type="text"],
  .woocommerce #review_form #respond .newsletter-wrapper .subscride-inner .form-group .input-group input[type="text"],
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce #review_form #respond textarea,
  .woocommerce #review_form #respond .newsletter-wrapper .subscride-inner .form-group .input-group textarea,
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce #review_form #respond input[type="email"],
  .woocommerce #review_form #respond .newsletter-wrapper .subscride-inner .form-group .input-group input[type="email"],
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-cart-form .table tr td.actions .coupon .input-text,
  .woocommerce-cart-form .table tr td.actions .coupon .newsletter-wrapper .subscride-inner .form-group .input-group .input-text,
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-cart-form .table tr .quantity input,
  .woocommerce-cart-form .table tr .quantity .newsletter-wrapper .subscride-inner .form-group .input-group input,
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row select,
  .woocommerce .woocommerce-checkout.checkout form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group select,
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row input,
  .woocommerce .woocommerce-checkout.checkout form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group input,
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type="text"],
  .woocommerce-EditAccountForm.edit-account .newsletter-wrapper .subscride-inner .form-group .input-group input[type="text"],
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type="email"],
  .woocommerce-EditAccountForm.edit-account .newsletter-wrapper .subscride-inner .form-group .input-group input[type="email"],
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type="password"],
  .woocommerce-EditAccountForm.edit-account .newsletter-wrapper .subscride-inner .form-group .input-group input[type="password"],
  .newsletter-wrapper .subscride-inner .form-group .input-group .login input[type="text"],
  .login .newsletter-wrapper .subscride-inner .form-group .input-group input[type="text"],
  .newsletter-wrapper .subscride-inner .form-group .input-group .login input[type="password"],
  .login .newsletter-wrapper .subscride-inner .form-group .input-group input[type="password"],
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-ResetPassword.lost_reset_password input[type="text"],
  .woocommerce-ResetPassword.lost_reset_password .newsletter-wrapper .subscride-inner .form-group .input-group input[type="text"],
  .newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout input[type="text"],
  .checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group input[type="text"],
  .newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout input[type="email"],
  .checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group input[type="email"],
  .newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout input[type="tel"],
  .checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group input[type="tel"],
  .newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout .select2-choice,
  .checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group .select2-choice,
  .newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout textarea,
  .checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group textarea,
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce form .form-row input.input-text,
  .woocommerce form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group input.input-text,
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce form .form-row .select2-selection,
  .woocommerce form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group .select2-selection,
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce form .form-row .select2-choice,
  .woocommerce form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group .select2-choice {
    height: 64px;
  }

  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .btn,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .wp-block-button__link,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .button,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.checkout-button,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #respond input#submit,
  .woocommerce #respond .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input#submit,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce a.button,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.button,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce button.button,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon button.button,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce input.button,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input.button,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #place_order,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon #place_order {
    height: 52px;
    width: 52px;
    text-align: center;
    display: block;
    padding: 0;
  }

  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .btn span,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .wp-block-button__link span,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a span,
  .woocommerce .widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a span,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a span,
  .woocommerce.widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a span,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #review_form #respond .form-submit input span,
  .woocommerce #review_form #respond .form-submit .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input span,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart-form .table tr .button span,
  .woocommerce-cart-form .table tr .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .button span,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button span,
  .woocommerce-cart .wc-proceed-to-checkout .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.checkout-button span,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #respond input#submit span,
  .woocommerce #respond .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input#submit span,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce a.button span,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.button span,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce button.button span,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon button.button span,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce input.button span,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input.button span,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #place_order span,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon #place_order span {
    display: none;
  }

  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .btn i,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .wp-block-button__link i,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a i,
  .woocommerce .widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a i,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a i,
  .woocommerce.widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a i,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #review_form #respond .form-submit input i,
  .woocommerce #review_form #respond .form-submit .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input i,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart-form .table tr .button i,
  .woocommerce-cart-form .table tr .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .button i,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button i,
  .woocommerce-cart .wc-proceed-to-checkout .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.checkout-button i,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #respond input#submit i,
  .woocommerce #respond .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input#submit i,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce a.button i,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.button i,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce button.button i,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon button.button i,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce input.button i,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input.button i,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #place_order i,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon #place_order i {
    display: block;
  }
}

.history-wrapper .info-right-history {
  padding-right: 90px;
}

.history-wrapper .info-right-history .section-head p {
  margin-bottom: 12px;
}

@media screen and (max-width: 1400px) {
  .history-wrapper .info-right-history {
    padding-right: 38px;
  }
}

@media screen and (max-width: 1200px) {
  .history-wrapper .info-right-history {
    padding-right: 0px;
  }
}

@media screen and (max-width: 1200px) {
  .history-wrapper .info-right-history p {
    font-size: 15px;
  }
}

.top-space {
  margin-top: 138px;
}

@media screen and (max-width: 1200px) {
  .top-space {
    margin-top: 70px;
  }
}

.facility .media {
  width: 750px;
  height: 580px;
  position: relative;
  left: -372px;
  margin: 0 0 70px 0;
  transform: skewX(1deg);
}

.facility .media:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  transform: rotate(8deg);
  background-color: var(--primary);
}

.facility .media img {
  width: 100%;
  border-radius: 0 25px 25px 0;
  position: absolute;
}

.facility .text {
  margin-bottom: 7px;
  color: #A5A5A5;
}

.facility a {
  margin-top: 40px;
}

@media screen and (max-width: 1600px) {
  .facility .section-head .title {
    font-size: 3rem !important;
  }
}

@media screen and (max-width: 1200px) {
  .facility .section-head .title {
    font-size: 2.5rem !important;
  }
}

@media screen and (max-width: 1024px) {
  .facility .section-head {
    margin-bottom: 20px;
  }

  .facility .section-head .title {
    font-size: 2rem !important;
  }

  .facility .section-head p {
    font-size: 14px;
  }

  .facility a {
    margin-top: 20px;
  }
}

@media screen and (max-width: 991px) {
  .facility .section-head .title {
    font-size: 1.75rem !important;
  }
}

@media screen and (max-width: 600px) {
  .facility a {
    display: none;
  }
}

@media screen and (max-width: 360px) {
  .facility .section-head .title {
    font-size: 1.6875rem !important;
  }
}

.book-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 991px) {
  .book-align {
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .testimonial .section-head p {
    display: none;
  }
}

.circle {
  position: absolute;
  width: 330px;
  height: 330px;
  background-color: #F5F5F5;
  left: calc(50% - 146px + 315px);
  border-radius: 100%;
  top: -174px;
  z-index: -1;
}

.circle.style-1 {
  background-color: #F5F5F5;
}

.circle.style-2 {
  background-color: #191552;
}

.coundown-wrapper .countdown {
  border: 1px solid #F0EEFF;
  border-radius: 12px;
  max-width: 505px;
  margin: auto;
  padding: 10px 0 20px;
  margin-bottom: 80px;
}

@media screen and (max-width: 1400px) {
  .map-iframe {
    margin: 0;
  }
}

.location {
  margin-top: 80px;
}

.location iframe {
  height: 350px;
}

@media only screen and (max-width: 991px) {
  .location {
    margin-top: 40px;
  }
}

.inner-text .title {
  text-decoration: underline;
  font-weight: 500;
  margin-bottom: 10px;
}

.i-false:after {
  content: none !important;
}

/*Blog single */
.blog-single {
  margin-bottom: 3.125rem;
}

.blog-single .dz-title {
  margin-bottom: 1.25rem;
}

.blog-single .dz-post-meta {
  margin-bottom: 1.25rem;
}

.blog-single .dz-post-text {
  margin-top: 1.25rem;
  padding-bottom: 15px;
}

.blog-single .dz-post-text:after {
  content: "";
  display: block;
  width: 100%;
  clear: both;
}

.blog-single .dz-post-tags {
  margin-top: 1.25rem;
}

.blog-single .dz-post-media {
  border-radius: 0.25rem;
}

.blog-single.dz-blog .post-tags {
  margin-bottom: 0;
}

.blog-single.style-1 {
  box-shadow: unset;
  border-radius: 0;
  overflow: unset;
  margin-bottom: 60px;
}

.blog-single.style-1 .dz-info .dz-meta {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 10px;
  border: 0;
}

.blog-single.style-1 .dz-info .dz-meta.meta-bottom {
  border-top: 1px solid #E1E1F0 !important;
  border-bottom: 1px solid #E1E1F0 !important;
  padding: 15px 0;
  margin-top: 0;
}

.blog-single.style-1 {
  margin-bottom: 60px;
  background: transparent;
  border-radius: 0;
}

@media only screen and (max-width: 991px) {
  .blog-single.style-1 {
    margin-bottom: 30px;
  }
}

.blog-single.style-1 .meta-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

@media only screen and (max-width: 1280px) {
  .blog-single.style-1 .dz-title {
    font-size: 1.75rem;
  }
}

@media only screen and (max-width: 767px) {
  .blog-single.style-1 .dz-title {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 575px) {
  .blog-single.style-1 .meta-bottom {
    display: block;
  }

  .blog-single.style-1 .meta-bottom .dz-social-icon {
    margin-top: 10px;
  }
}

.blog-single.style-1 .dz-media+.dz-info {
  padding: 30px 0 0 0 !important;
  border: 0;
  background: transparent;
}

.dz-post-text a {
  text-decoration: underline;
  box-shadow: inset 0 -10px 0 var(--rgba-primary-1);
}

.dz-post-text .widget_archive li a,
.dz-post-text .wp-block-latest-posts li a,
.dz-post-text .wp-block-categories-list li a,
.dz-post-text .wp-block-archives-list li a,
.dz-post-text .widget_categories li a,
.dz-post-text blockquote a,
.dz-post-text .wp-block-button__link {
  box-shadow: none;
  text-decoration: none;
}

.post-header {
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 575px) {
  .post-header {
    margin-bottom: 1.25rem;
  }
}

.post-header .dz-media img {
  min-height: 250px;
  object-fit: cover;
}

.post-header .dz-info {
  position: absolute;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+1,000000+100&0+1,0.6+100 */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.6) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.6) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.6) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#99000000', GradientType=0);
  /* IE6-9 */
  position: absolute;
  bottom: 0;
  text-align: center;
  padding: 100px 30px 30px 30px !important;
  width: 100%;
}

@media only screen and (max-width: 575px) {
  .post-header .dz-info {
    padding: 40px 15px 15px 15px !important;
  }
}

.post-header .dz-info .dz-title {
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .post-header .dz-info .dz-title {
    font-size: 1.125rem;
  }
}

.post-header .dz-info .dz-meta>ul {
  justify-content: center;
}

.post-header .dz-info .dz-meta>ul>li {
  color: #fff;
}

.post-link-in {
  padding: 15px 50px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.post-link-in:hover {
  background: var(--primary);
  color: #fff;
}

.side-bar {
  padding-left: 20px;
}

@media only screen and (max-width: 991px) {
  .side-bar {
    padding-left: 0;
    padding-right: 0;
  }
}

.side-bar.left {
  padding-left: 0;
  padding-right: 20px;
}

@media only screen and (max-width: 991px) {
  .side-bar.left {
    padding-right: 0;
    padding-left: 0;
  }
}

.alignnone {
  margin: 0.3125rem 0 1.563rem 0;
}

.alignnoneimg,
.alignnone.wp-caption,
.alignnone.is-resized {
  margin: 0.3125rem 0 1.563rem 0;
}

.aligncenter {
  display: block;
  margin: 0.3125rem auto 0.938rem;
  text-align: center;
}

.aligncenterimg,
.aligncenter.wp-caption,
.aligncenter.is-resized {
  display: block;
  margin: 0.3125rem auto 0.938rem;
  text-align: center;
}

.alignright {
  float: right;
  margin: 0.3125rem 0 1.563rem 1.563rem;
}

.alignrightimg,
.alignright.wp-caption,
.alignright.is-resized {
  margin: 0.3125rem 0 1.563rem 1.563rem;
  float: right;
}

.alignleft {
  float: left;
  margin: 0.3125rem 1.563rem 1.563rem 0;
}

.alignleftimg,
.alignleft.wp-caption,
.alignleft.is-resized {
  margin: 0.3125rem 1.563rem 1.563rem 0;
  float: left;
}

.wp-caption {
  max-width: 100%;
  text-align: center;
}

.wp-caption img[class*="wp-image-"] {
  display: block;
  margin: 0;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 100%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 0.813rem;
  line-height: 1.125rem;
  margin: 0;
  padding: 0.625rem 0;
  text-align: left;
}

.wp-block-quote.is-large,
.wp-block-quote.is-style-large,
blockquote.wp-block-quote,
blockquote.wp-block-pullquote,
blockquote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignleft,
blockquote {
  padding: 1.25rem 2rem 1.25rem 3rem;
  font-size: 0.938rem;
  color: var(--title);
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  line-height: 1.875rem;
  position: relative;
  clear: both;
  font-weight: 700;
  z-index: 1;
  border: 0;
}

@media only screen and (max-width: 991px) {

  .wp-block-quote.is-large,
  .wp-block-quote.is-style-large,
  blockquote.wp-block-quote,
  blockquote.wp-block-pullquote,
  blockquote.wp-block-pullquote.alignright,
  blockquote.wp-block-pullquote.alignleft,
  blockquote {
    padding: 1.25rem 1.25rem 0.938rem 1.25rem;
    font-size: 0.813rem;
  }
}

.wp-block-quote.is-large cite,
.wp-block-quote.is-style-large cite,
blockquote.wp-block-quote cite,
blockquote.wp-block-pullquote cite,
blockquote.wp-block-pullquote.alignright cite,
blockquote.wp-block-pullquote.alignleft cite,
blockquote cite {
  font-style: normal;
  position: relative;
  display: block;
  margin-top: -0.3125rem;
  font-weight: 600;
  color: var(--primary);
}

.wp-block-quote.is-large cite:before,
.wp-block-quote.is-style-large cite:before,
blockquote.wp-block-quote cite:before,
blockquote.wp-block-pullquote cite:before,
blockquote.wp-block-pullquote.alignright cite:before,
blockquote.wp-block-pullquote.alignleft cite:before,
blockquote cite:before {
  content: "";
  margin-right: 0.625rem;
  width: 15px;
  height: 2px;
  background: var(--primary);
  display: inline-block;
  vertical-align: middle;
}

.wp-block-quote.is-large b,
.wp-block-quote.is-large strong,
.wp-block-quote.is-large .strong,
.wp-block-quote.is-style-large b,
.wp-block-quote.is-style-large strong,
.wp-block-quote.is-style-large .strong,
blockquote.wp-block-quote b,
blockquote.wp-block-quote strong,
blockquote.wp-block-quote .strong,
blockquote.wp-block-pullquote b,
blockquote.wp-block-pullquote strong,
blockquote.wp-block-pullquote .strong,
blockquote.wp-block-pullquote.alignright b,
blockquote.wp-block-pullquote.alignright strong,
blockquote.wp-block-pullquote.alignright .strong,
blockquote.wp-block-pullquote.alignleft b,
blockquote.wp-block-pullquote.alignleft strong,
blockquote.wp-block-pullquote.alignleft .strong,
blockquote b,
blockquote strong,
blockquote .strong {
  color: inherit;
}

.wp-block-quote.is-large:after,
.wp-block-quote.is-style-large:after,
blockquote.wp-block-quote:after,
blockquote.wp-block-pullquote:after,
blockquote.wp-block-pullquote.alignright:after,
blockquote.wp-block-pullquote.alignleft:after,
blockquote:after {
  content: "";
  width: 6px;
  border-radius: 4px;
  height: 100%;
  left: 0;
  top: 0;
  background: var(--primary);
  position: absolute;
  box-shadow: 0 0 30px 0 var(--rgba-primary-2);
}

.wp-block-quote.is-large:before,
.wp-block-quote.is-style-large:before,
blockquote.wp-block-quote:before,
blockquote.wp-block-pullquote:before,
blockquote.wp-block-pullquote.alignright:before,
blockquote.wp-block-pullquote.alignleft:before,
blockquote:before {
  content: "";
  z-index: -1;
  background-size: cover;
  left: 20px;
  background-image: var(--quote);
  background-position: center right;
  width: 90px;
  padding: 0;
  opacity: 0.1;
  background-repeat: no-repeat;
  height: 90px;
  top: 0px;
  position: absolute;
}

.wp-block-quote.is-large.wp-block-pullquote.alignleft,
.wp-block-quote.is-style-large.wp-block-pullquote.alignleft,
blockquote.wp-block-quote.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignleft {
  margin: 0rem 1.563rem 1.563rem 0rem;
}

.wp-block-quote.is-large.wp-block-pullquote.alignright,
.wp-block-quote.is-style-large.wp-block-pullquote.alignright,
blockquote.wp-block-quote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignright {
  margin: 0rem 0 1.563rem 1.563rem;
}

blockquote.wp-block-pullquote:not(.is-style-solid-color) {
  background: #5608e0;
}

.wp-caption-text {
  font-size: 0.875rem;
  line-height: 1.3;
}

.dz-blog.blog-single .dz-post-text blockquote p,
.dz-page-text blockquote p,
blockquote p {
  font-size: 1.5rem;
  line-height: 1.5;
  color: var(--title);
  margin-bottom: 1rem;
  font-weight: 700;
  font-style: italic;
}

@media only screen and (max-width: 991px) {

  .dz-blog.blog-single .dz-post-text blockquote p,
  .dz-page-text blockquote p,
  blockquote p {
    font-size: 1.125rem;
  }
}

.dz-page-text {
  margin-bottom: 60px;
}

.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
  max-width: 100%;
  height: auto;
}

/* Gutenberg Gallery */
/* Gutenberg Css */
/* .dz-blog.blog-single,
.dz-blog.blog-single + #comment-list */
.min-container,
.dz-page-text,
.dz-page-text+#comment-list,
.dz-page-text+#comment-list+.paginate-links {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.938rem;
  padding-right: 0.938rem;
}

@media only screen and (max-width: 991px) {
  #comment-list {
    margin-bottom: 50px;
  }
}

#comment-list .dz-page-text {
  margin-bottom: 0;
}

.dz-blog.blog-single.sidebar+#comment-list {
  padding: 0;
  max-width: 100%;
}

.max-container {
  max-width: 93.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3.4375rem;
  padding-right: 3.4375rem;
}

.dz-page-text+#comment-list+.paginate-links,
.dz-page-text+#comment-list,
.dz-page-text {
  padding: 0;
}

.dz-page-text.sidebar {
  width: 100%;
  padding: 0;
  max-width: 100%;
}

.dz-blog.blog-single .dz-info {
  padding: 0;
}

.dz-page-text>ol,
.dz-page-text>ul,
.dz-page-text>.wp-block-gallery,
.dz-blog.blog-single .dz-post-text>.wp-block-gallery,
.dz-blog.blog-single .dz-post-text>ol,
.dz-blog.blog-single .dz-post-text>ul {
  list-style: none;
  margin-bottom: 1.875rem;
}

.dz-page-text>ol,
.dz-page-text>ul,
.dz-blog.blog-single .dz-post-text>ol,
.dz-blog.blog-single .dz-post-text>ul,
.dz-blog.blog-single .dz-post-text.text>ol,
.dz-blog.blog-single .dz-post-text.text>ul {
  padding-left: 1.25rem;
}

.dz-page-text>ol ol,
.dz-page-text>ol ul,
.dz-page-text>ul ol,
.dz-page-text>ul ul,
.dz-blog.blog-single .dz-post-text>ol ol,
.dz-blog.blog-single .dz-post-text>ol ul,
.dz-blog.blog-single .dz-post-text>ul ol,
.dz-blog.blog-single .dz-post-text>ul ul,
.dz-blog.blog-single .dz-post-text.text>ol ol,
.dz-blog.blog-single .dz-post-text.text>ol ul,
.dz-blog.blog-single .dz-post-text.text>ul ol,
.dz-blog.blog-single .dz-post-text.text>ul ul {
  padding-left: 1.563rem;
}

.dz-page-text>ol li,
.dz-page-text>ul li,
.dz-blog.blog-single .dz-post-text>ol li,
.dz-blog.blog-single .dz-post-text>ul li,
.dz-blog.blog-single .dz-post-text>ol li,
.dz-blog.blog-single .dz-post-text>ul li,
.dz-blog.blog-single .dz-post-text.text>ol li,
.dz-blog.blog-single .dz-post-text.text>ul li {
  padding: 0.5rem 0.5rem;
  position: relative;
  list-style: inherit;
}

.dz-page-text>.wp-block-gallery,
.dz-blog.blog-single .dz-post-text>.wp-block-gallery,
.dz-blog.blog-single .dz-post-text.text>.wp-block-gallery {
  padding: 0;
  list-style: none;
}

.dz-page-text>.wp-block-gallery li,
.dz-blog.blog-single .dz-post-text>.wp-block-gallery li,
.dz-blog.blog-single .dz-post-text.text>.wp-block-gallery li {
  padding: 0;
  list-style: none;
  margin: 0.25rem;
}

.dz-page-text>.wp-block-gallery li:before,
.dz-blog.blog-single .dz-post-text>.wp-block-gallery li:before,
.dz-blog.blog-single .dz-post-text.text>.wp-block-gallery li:before {
  content: none;
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  margin: 0 0.5rem 0.5rem 0;
}

.wp-block-gallery.columns-4 .blocks-gallery-image,
.wp-block-gallery.columns-4 .blocks-gallery-item,
.wp-block-gallery.columns-3 .blocks-gallery-image,
.wp-block-gallery.columns-3 .blocks-gallery-item {
  margin-right: 0.5rem;
}

.content-spacious-full .wp-block-gallery {
  margin-left: calc(-12.5rem - (0.313rem/2));
  margin-right: calc(-12.5rem - (0.313rem/2));
  width: calc(100% + 25rem + 0.313rem);
  max-width: initial;
}

.alignfull,
.alignwide {
  margin-left: calc(-6.25rem - (0.313rem/2));
  margin-right: calc(-6.25rem - (0.313rem/2));
  width: calc(100% + 12.5rem + 0.313rem);
  max-width: initial;
}

.alignfull,
.alignfullwide {
  margin-left: calc(50% - 50vw - (var(--content-share-ml, 0px)/2));
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box;
}

.dz-page-text h1,
.dz-page-text .h1,
.dz-post-text h1,
.dz-post-text .h1,
.thm-unit-test .dz-post-text h1,
.thm-unit-test .dz-post-text .h1 {
  margin-bottom: 1.563rem;
  font-weight: 700;
}

.dz-page-text h2,
.dz-page-text .h2,
.dz-post-text h2,
.dz-post-text .h2,
.thm-unit-test .dz-post-text h2,
.thm-unit-test .dz-post-text .h2 {
  margin-bottom: 1.563rem;
  font-weight: 700;
}

.dz-page-text h3,
.dz-page-text .h3,
.dz-post-text h3,
.dz-post-text .h3,
.thm-unit-test .dz-post-text h3,
.thm-unit-test .dz-post-text .h3 {
  margin-bottom: 1.25rem;
  font-weight: 700;
}

.dz-page-text h4,
.dz-page-text .h4,
.dz-post-text h4,
.dz-post-text .h4,
.thm-unit-test .dz-post-text h4,
.thm-unit-test .dz-post-text .h4 {
  margin-bottom: 0.938rem;
  font-weight: 600;
}

.dz-page-text h5,
.dz-page-text .h5,
.dz-post-text h5,
.dz-post-text .h5,
.thm-unit-test .dz-post-text h5,
.thm-unit-test .dz-post-text .h5 {
  margin-bottom: 0.938rem;
}

.dz-page-text h6,
.dz-page-text .h6,
.dz-post-text h6,
.dz-post-text .h6,
.thm-unit-test .dz-post-text h6,
.thm-unit-test .dz-post-text .h6 {
  margin-bottom: 0.625rem;
}

.wp-block-code,
pre.wp-block-code,
pre {
  margin: 0 0 1.563rem;
  padding: 1.25rem;
  color: #fff;
  background-color: #212326;
  white-space: pre;
  font-size: 0.938rem;
  border-radius: 0.1875rem;
}

.dz-page-text,
.dz-page-text ul li,
.dz-page-text ol li,
.dz-blog.blog-single .dz-post-text,
.dz-blog.blog-single .dz-post-text ul li,
.dz-blog.blog-single .dz-post-text ol li,
.dz-blog.blog-single .dz-blog.blog-single .dz-post-text p:not(.has-text-color):not(.has-text-align-center):not(.has-text-align-left):not(.has-text-align-right) {
  font-size: 1.125rem;
  line-height: 1.7;
}

.wp-block-cover,
.wp-block-cover-image {
  color: #fff;
}

.wp-block-cover p:last-child,
.wp-block-cover-image p:last-child {
  margin-bottom: 0;
}

.wp-block-quote.has-text-align-right {
  border-right: 0;
}

.dz-page-text ul li,
.dz-page-text ol li,
.dz-blog.blog-single .dz-post-text ul li,
.dz-blog.blog-single .dz-post-text ol li {
  position: relative;
}

.dz-page-text ul>li:before,
.dz-page-text ol>li:before,
.dz-blog.blog-single .dz-post-text ul>li:before,
.dz-blog.blog-single .dz-post-text ol>li:before {
  content: "";
  display: inline-block;
  width: 0.375rem;
  height: 0.375rem;
  background: #212529;
  left: -0.938rem;
  position: absolute;
  top: 1.25rem;
}

.dz-page-text ul>li li:before,
.dz-page-text ol>li li:before,
.dz-blog.blog-single .dz-post-text ul>li li:before,
.dz-blog.blog-single .dz-post-text ol>li li:before {
  content: none;
}

.dz-page-text p,
.dz-blog.blog-single .dz-post-text p {
  margin-bottom: 1.65em;
}

.paginate-links {
  display: flex;
  align-items: center;
  margin: 1.5rem 0 4.5rem 0;
}

.paginate-links a,
.paginate-links>span {
  margin: 0 0 0 0.625rem;
  position: relative;
  border: 0rem solid #6cc000;
  color: #1f2471;
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  line-height: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  text-transform: capitalize;
  transition: all 500ms ease 0s;
  background: #E0E0E0;
  border-radius: 0.1875rem;
}

.wp-block-columns {
  margin-bottom: 0;
}

/* Gutenberg Gallery */
.wp-block-gallery {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin: 0 0.375rem 0.375rem 0;
  position: relative;
}

.wp-block-gallery .blocks-gallery-image figure,
.wp-block-gallery .blocks-gallery-item figure {
  height: 100%;
  margin: 0;
}

@supports (position: -webkit-sticky) or (position: sticky) {

  .wp-block-gallery .blocks-gallery-image figure,
  .wp-block-gallery .blocks-gallery-item figure {
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;
  }
}

.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .blocks-gallery-item img {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
  border-radius: 4px;
}

@supports (position: -webkit-sticky) or (position: sticky) {

  .wp-block-gallery .blocks-gallery-image img,
  .wp-block-gallery .blocks-gallery-item img {
    width: auto;
  }
}

.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3) 60%, transparent);
  bottom: 0;
  color: #fff;
  font-size: 0.813rem;
  max-height: 100%;
  overflow: auto;
  padding: 2.5rem 0.625rem 0.3125rem;
  position: absolute;
  text-align: center;
  width: 100%;
}

.wp-block-gallery .blocks-gallery-image figcaption img,
.wp-block-gallery .blocks-gallery-item figcaption img {
  display: inline;
}

.wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img,
.wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .blocks-gallery-item img {
  width: 100%;
}

@supports (position: -webkit-sticky) or (position: sticky) {

  .wp-block-gallery.is-cropped .blocks-gallery-image a,
  .wp-block-gallery.is-cropped .blocks-gallery-image img,
  .wp-block-gallery.is-cropped .blocks-gallery-item a,
  .wp-block-gallery.is-cropped .blocks-gallery-item img {
    -o-object-fit: cover;
    flex: 1;
    height: 100%;
    object-fit: cover;
  }
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  width: calc(50% - 0.5rem);
}

.wp-block-gallery .blocks-gallery-image:nth-of-type(2n),
.wp-block-gallery .blocks-gallery-item:nth-of-type(2n) {
  margin-right: 0;
}

.wp-block-gallery.columns-1 .blocks-gallery-image,
.wp-block-gallery.columns-1 .blocks-gallery-item {
  margin-right: 0;
  width: 100%;
}

@media only screen and (max-width: 64rem) {
  .alignwide {
    margin-left: 0;
    margin-right: 0;
    width: 100% !important;
    max-width: 100% !important;
  }

  .alignwide .alignleft img {
    width: 100%;
  }

  .alignwide .alignleft {
    margin: 0.3125rem 0rem 1.563rem 0;
    float: none;
  }
}

@media (min-width: 37.5rem) {

  .wp-block-gallery.columns-3 .blocks-gallery-image,
  .wp-block-gallery.columns-3 .blocks-gallery-item {
    margin-right: 0.375rem;
    width: calc(33.33333% - 0.666rem);
  }

  .wp-block-gallery.columns-4 .blocks-gallery-image,
  .wp-block-gallery.columns-4 .blocks-gallery-item {
    margin-right: 0.375rem;
    width: calc(25% - 0.75rem);
  }

  .wp-block-gallery.columns-5 .blocks-gallery-image,
  .wp-block-gallery.columns-5 .blocks-gallery-item {
    margin-right: 0.375rem;
    width: calc(20% - 0.8rem);
  }

  .wp-block-gallery.columns-6 .blocks-gallery-image,
  .wp-block-gallery.columns-6 .blocks-gallery-item {
    margin-right: 0.375rem;
    width: calc(16.66667% - 0.833rem);
  }

  .wp-block-gallery.columns-7 .blocks-gallery-image,
  .wp-block-gallery.columns-7 .blocks-gallery-item {
    margin-right: 0.375rem;
    width: calc(14.28571% - 0.857rem);
  }

  .wp-block-gallery.columns-8 .blocks-gallery-image,
  .wp-block-gallery.columns-8 .blocks-gallery-item {
    margin-right: 0.375rem;
    width: calc(12.5% - 0.875rem);
  }

  .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
  .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n),
  .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
  .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n),
  .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
  .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n),
  .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
  .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n),
  .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
  .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n),
  .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
  .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n),
  .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
  .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n),
  .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
  .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
    margin-right: 0;
  }
}

.wp-block-gallery .blocks-gallery-image:last-child,
.wp-block-gallery .blocks-gallery-item:last-child {
  margin-right: 0;
}

.wp-block-gallery .blocks-gallery-item.has-add-item-button {
  width: 100%;
}

.wp-block-image.alignfullwide img {
  border-radius: 0;
}

.wp-block-image img {
  border-radius: 0.625rem;
}

.wp-block-cover,
.wp-block-cover-image,
.wp-block-embed,
.wp-block-image {
  margin-bottom: 1.875rem;
  margin-top: 1.875rem;
}

.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
  max-width: 19.0625rem;
  width: 100%;
}

.wp-block-gallery.aligncenter,
.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
  display: flex;
}

.wp-block-gallery.aligncenter .blocks-gallery-item figure {
  justify-content: center;
}

.dz-page-text.sidebar .alignfull,
.dz-page-text.sidebar .alignfullwide,
.dz-page-text.sidebar .alignwide,
.dz-page-text.sidebar .wp-block-gallery,
.blog-single.dz-blog.sidebar .alignfullwide,
.blog-single.dz-blog.sidebar .alignwide,
.blog-single.dz-blog.sidebar .dz-post-text .wp-block-gallery {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  max-width: initial;
}

.blog-overlap {
  background: #fff;
  margin-top: -8.75rem;
  padding: 1.25rem 1.25rem 0;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
}

.blog-single.dz-blog.sidebar {
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.dz-post-text table,
.dz-page-text table,
.wp-block-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  font-size: 1rem;
  margin-bottom: 1.875rem;
}

.wp-block-image figcaption,
.dz-post-text figcaption,
.dz-page-text figcaption {
  font-size: 0.875rem;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  margin-top: 0.625rem;
}

.dz-post-text table tbody tr:nth-of-type(odd),
.dz-page-text table tbody tr:nth-of-type(odd),
.wp-block-table tbody tr:nth-of-type(odd) {
  background-color: #fafafa;
}

.dz-post-text td,
.dz-post-text th,
.dz-page-text td,
.dz-page-text th,
.wp-block-table td,
.wp-block-table th {
  padding: 0.625rem 0.938rem;
  border: 0.0625rem solid #e4e4e4;
  border-right: 0;
  border-left: 0;
}

.wp-block-media-text {
  margin-bottom: 30px;
}

.wp-block-media-text .wp-block-media-text__content {
  padding: 0 1.875rem;
}

.wp-block-column ol li:before,
.wp-block-column ul li:before {
  content: none !important;
}

.wp-block-pullquote {
  padding: 1em 0;
  border-top: 0;
  border-bottom: 0;
}

.wp-block-pullquote cite {
  color: #fff;
}

.wp-block-pullquote.alignright,
.wp-block-pullquote.alignleft {
  padding: 0;
  border-top: 0;
  border-bottom: 0;
}

.wp-block-pullquote.alignright blockquote,
.wp-block-pullquote.alignleft blockquote {
  margin: 0;
}

.dz-post-text .wp-block-cover-image-text,
.dz-post-text .wp-block-cover-text,
.dz-post-text section.wp-block-cover-image h2,
.dz-post-text section.wp-block-cover-image .h2,
.dz-page-text .wp-block-cover-image-text,
.dz-page-text .wp-block-cover-text,
.dz-page-text section.wp-block-cover-image h2,
.dz-page-text section.wp-block-cover-image .h2 {
  color: #fff;
}

.dz-post-text .bootstrap-select .dropdown-menu li a,
.dz-page-text .bootstrap-select .dropdown-menu li a {
  box-shadow: none;
  font-size: 0.875rem;
  font-family: 'Poppins', sans-serif;
}

.dz-post-text .wp-block-calendar a {
  box-shadow: none !important;
  text-decoration: unset;
}

.wp-block-search,
.wp-block-archives,
.wp-block-latest-posts,
.wp-block-latest-comments,
.wp-block-categories,
.wp-block-calendar {
  margin-bottom: 2.5rem;
}

/* Admin Bar */
.admin-bar .is-fixed .main-bar {
  top: 1.875rem;
}

.wp-block-quote.is-style-large cite:before {
  display: none;
}

/* Theme Unit Test */
.post-password-form label {
  font-size: 1rem;
}

.post-password-form input[type="submit"] {
  padding: 0.625rem 1.563rem;
  background-color: var(--primary);
  border: none;
  height: 2.8125rem;
  font-weight: 600;
  font-size: 0.875rem;
  outline: none;
}

.post-password-form input[type="submit"]:hover {
  background-color: var(--primary);
}

.post-password-form input[type="password"] {
  height: 2.8125rem;
  border: 0.0625rem solid #ced4da;
}

.wp-block-search .wp-block-search__button {
  background: var(--primary);
  border: 0;
  color: #fff;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.625rem 1.563rem;
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
}

.wp-block-search .wp-block-search__input {
  border: 0.0625rem solid #e1e6eb;
  height: 2.8125rem;
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  outline: none;
}

.wp-block-tag-cloud a {
  padding: 0.5rem 0.625rem;
  background: var(--primary);
  font-size: 0.75rem;
  display: inline-block;
  margin: 0 0 0.3125rem;
  color: #fff;
  font-weight: 600;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  text-decoration: none;
  box-shadow: none;
}

.wp-block-tag-cloud a:hover {
  background: var(--primary-hover);
}

.wp-block-latest-comments {
  padding: 0 !important;
}

.wp-block-latest-comments .avatar {
  width: 3.4375rem;
  height: 3.4375rem;
  border-radius: 3.4375rem;
}

.wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-excerpt {
  margin-left: 4.375rem;
}

.wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta {
  color: #1f2471;
  font-family: 'Poppins', sans-serif;
  font-size: 1.063rem;
  font-weight: 500;
  line-height: 1.7;
  margin-bottom: 0.625rem;
}

.wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta a {
  color: #1f2471;
  box-shadow: none;
  text-decoration: none;
}

.wp-block-latest-comments img+article .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta {
  margin-left: 4.375rem;
}

.wp-block-latest-comments .wp-block-latest-comments__comment-meta time {
  display: block;
  width: 100%;
  color: #9fa1a4;
  font-size: 0.813rem;
  font-weight: 400;
}

.wp-block-latest-comments .wp-block-latest-comments__comment-excerpt p {
  font-size: 1rem !important;
  line-height: 1.5 !important;
  margin-bottom: 0.625rem !important;
}

.wp-block-latest-comments li {
  padding: 0 !important;
  border-bottom: 0.0625rem solid #eee;
  padding-bottom: 0.625rem !important;
}

.wp-block-latest-comments li:before {
  content: none !important;
}

.wp-block-latest-comments__comment-avatar {
  width: 3.4375rem;
  height: 3.4375rem;
  border-radius: 3.4375rem;
}

.comment-content.dz-page-text {
  max-width: 100%;
  margin-bottom: 0;
}

.wp-block-rss {
  padding: 0 !important;
}

.wp-block-rss .wp-block-rss__item {
  padding: 0.3125rem 0 !important;
  border-bottom: 0.0625rem solid #eee;
}

.wp-block-rss .wp-block-rss__item:before {
  content: none !important;
}

.wp-block-rss .wp-block-rss__item a {
  font-family: 'Poppins', sans-serif;
  font-size: 1.125rem;
  box-shadow: unset !important;
  font-weight: 600;
  color: #1f2471;
  text-decoration: none;
}

@media only screen and (max-width: 75rem) {

  .dz-page-text .wp-block-gallery,
  .dz-blog.blog-single .dz-post-text .wp-block-gallery {
    margin-left: calc(-6.25rem - (0.625rem/2));
    margin-right: calc(-6.25rem - (0.625rem/2));
    width: calc(100% + 12.5rem + 0.625rem);
  }
}

@media only screen and (max-width: 61.9375rem) {
  .admin-bar .mo-left .header-nav {
    top: 1.875rem;
    height: calc(100vh - 1.875rem) !important;
  }

  .dz-page-text .wp-block-gallery,
  .dz-blog.blog-single .dz-post-text .wp-block-gallery {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .dz-page-text,
  .dz-page-text ul li,
  .dz-page-text ol li,
  .dz-page-text p,
  .dz-blog.blog-single .dz-post-text,
  .dz-blog.blog-single .dz-post-text ul li,
  .dz-blog.blog-single .dz-post-text ol li,
  .dz-blog.blog-single .dz-post-text p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 48.875rem) {
  .admin-bar .is-fixed .main-bar {
    top: 2.8125rem;
  }

  .admin-bar .mo-left .header-nav {
    top: 2.8125rem;
    height: calc(100vh - 2.812rem) !important;
  }

  .wp-block-media-text {
    display: block;
  }

  .wp-block-media-text .wp-block-media-text__media {
    margin-bottom: 0.938rem;
  }

  .wp-block-media-text .wp-block-media-text__content {
    padding: 0;
  }
}

@media only screen and (max-width: 37.5rem) {
  .admin-bar .is-fixed .main-bar {
    top: 0;
  }

  .admin-bar .mo-left .header-nav {
    top: 2.8125rem;
    height: calc(100vh - 2.812rem) !important;
  }

  .admin-bar .mo-left .is-fixed .header-nav {
    top: 0;
    height: 100vh !important;
  }
}

.post-footer {
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.938rem 0 0;
}

.post-footer .dz-meta .tag-list {
  padding-bottom: 0;
  text-align: left;
  margin: 0;
  padding: 0;
  list-style: none;
  text-transform: capitalize;
  display: inline;
}

.post-footer .dz-meta ul li {
  margin-right: 0.3125rem;
  padding: 0;
  display: inline-block;
  color: #333333;
  font-weight: 500;
  font-size: 0.938rem;
  font-style: italic;
}

.post-footer .post-tag a {
  text-transform: capitalize;
  font-size: 0.938rem;
  color: #999;
  background: transparent;
  padding: 0;
  border: 0;
  line-height: 0.875rem;
  font-weight: 500;
}

.share-post li {
  display: inline-block;
}

.share-post a {
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 2.1875rem;
  border: 0.0625rem solid #eee;
  display: block;
  text-align: center;
  line-height: 2.0625rem;
  color: #212529;
}

.comments-area.style-1 .comments-title,
.comments-area.style-1 .comment-reply-title,
.comments-area.style-1 .blog-title,
.comment-respond.style-1 .comments-title,
.comment-respond.style-1 .comment-reply-title,
.comment-respond.style-1 .blog-title,
.extra-blog.style-1 .comments-title,
.extra-blog.style-1 .comment-reply-title,
.extra-blog.style-1 .blog-title {
  position: relative;
  padding-left: 20px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {

  .comments-area.style-1 .comments-title,
  .comments-area.style-1 .comment-reply-title,
  .comments-area.style-1 .blog-title,
  .comment-respond.style-1 .comments-title,
  .comment-respond.style-1 .comment-reply-title,
  .comment-respond.style-1 .blog-title,
  .extra-blog.style-1 .comments-title,
  .extra-blog.style-1 .comment-reply-title,
  .extra-blog.style-1 .blog-title {
    margin-bottom: 30px;
    padding-left: 15px;
    font-size: 22px;
  }
}

.comments-area.style-1 .comments-title:before,
.comments-area.style-1 .comment-reply-title:before,
.comments-area.style-1 .blog-title:before,
.comment-respond.style-1 .comments-title:before,
.comment-respond.style-1 .comment-reply-title:before,
.comment-respond.style-1 .blog-title:before,
.extra-blog.style-1 .comments-title:before,
.extra-blog.style-1 .comment-reply-title:before,
.extra-blog.style-1 .blog-title:before {
  content: "";
  height: 100%;
  width: 5px;
  border-radius: 10px;
  background-color: var(--primary);
  position: absolute;
  left: 0;
  top: 0;
}

.extra-blog {
  margin-bottom: 60px;
}

@media only screen and (max-width: 991px) {
  .extra-blog {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .comment-respond.style-1 .comment-reply-title {
    margin-bottom: 20px;
  }
}

.default-form.comment-respond .comment-reply-title,
.comments-area .comments-title,
.extra-blog .blog-title {
  font-weight: 600;
}

.default-form {
  padding: 30px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: var(--border-radius-base);
}

.says,
.comment-meta {
  display: none;
}

.comments-area .comment-list {
  margin-bottom: 60px;
  padding: 0;
}

.comments-area .comment-list>.comment .comment-body {
  position: relative;
  padding: 2px 0 30px 100px;
  margin-bottom: 30px;
  min-height: 115px;
  border-bottom: 1px solid #E1E1F0;
}

.comments-area .comment-list>.comment .comment-body .comment-author .avatar {
  position: absolute;
  left: 0;
  height: 85px;
  width: 85px;
  top: 0;
}

.comments-area .comment-list>.comment .comment-body .comment-author .fn {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 18px;
  color: var(--title);
  font-weight: 600;
  font-style: normal;
  margin-bottom: 10px;
  display: block;
}

.comments-area .comment-list>.comment .comment-body p {
  font-size: 15px;
  margin-bottom: 10px;
}

.comments-area .comment-list>.comment .comment-body .reply {
  line-height: 1;
}

.comments-area .comment-list>.comment .comment-body .reply .comment-reply-link {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
}

.comments-area .comment-list>.comment .comment-body .reply .comment-reply-link i {
  margin-right: 8px;
}

.comments-area .comment-list>.comment>.children {
  padding-left: 50px;
}

.comments-area .comment-list .default-form {
  margin-bottom: 30px;
}

.comments-area.style-1 .comment-list>.comment .comment-body .comment-author .avatar {
  border-radius: 10px;
}

.comments-area.style-1 .comment-list>.comment:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}

.comments-area.style-2 .comment-list>.comment {
  border-bottom: 1px solid #E1E1F0;
  margin-bottom: 30px;
}

.comments-area.style-2 .comment-list>.comment .comment-body .comment-author .avatar {
  border-radius: 50%;
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  border: 5px solid #ffffff;
}

.comments-area.style-2 .comment-list>.comment:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}

.comments-area.style-3 .comment-list>.comment {
  margin-bottom: 30px;
}

.comments-area.style-3 .comment-list>.comment .comment-body .comment-author .avatar {
  border-radius: 5px;
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  border: 5px solid #ffffff;
}

.comments-area.style-3 .comment-list>.comment:last-child {
  margin-bottom: 0;
}

.comment-reply-title a {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary);
}

.comment-respond .comment-form {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.comment-respond .comment-form p {
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.comment-respond .comment-form p label {
  display: none;
}

.comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p textarea {
  width: 100%;
  height: 45px;
  border-radius: 4px !important;
  padding: 10px 20px;
  position: relative;
  font-size: 15px;
  border: 1px solid #b7b7b7;
}

.comment-respond .comment-form p.comment-form-comment textarea {
  height: 120px !important;
}

.comment-respond .comment-form p.form-submit {
  margin-bottom: 0;
}

.comment-respond .comment-form .comment-form-comment {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.comment-respond.style-1 .comment-form .comment-form-author,
.comment-respond.style-1 .comment-form .comment-form-email,
.comment-respond.style-1 .comment-form .comment-form-url,
.comment-respond.style-3 .comment-form .comment-form-author,
.comment-respond.style-3 .comment-form .comment-form-email,
.comment-respond.style-3 .comment-form .comment-form-url {
  width: 50%;
}

.comment-respond.style-2 .comment-form .comment-form-author,
.comment-respond.style-2 .comment-form .comment-form-email {
  width: 50%;
}

.comment-respond.style-2 .comment-form .comment-form-url {
  width: 100%;
}

@media only screen and (max-width: 575px) {

  .comment-respond .comment-form .comment-form-author,
  .comment-respond .comment-form .comment-form-email,
  .comment-respond .comment-form .comment-form-url {
    width: 100% !important;
  }
}

.dz-page-text [class*="galleryid-"],
.dz-post-text [class*="galleryid-"] {
  clear: both;
  margin: 0 auto;
  overflow: hidden;
}

.gallery .gallery-item {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  padding: 0.3125rem;
  position: relative;
  box-sizing: border-box;
}

.gallery .gallery-item img {
  float: left;
  padding: 0 0rem;
  width: 100%;
  border: none !important;
}

.gallery .gallery-caption {
  font-size: 0.813rem;
  color: #707070;
  display: block;
  font-family: "Noto Sans", sans-serif;
  line-height: 1.5;
  padding: 0.5em 0;
  clear: both;
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
  display: none;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-icon img {
  margin: 0 auto;
}

.post-password-form {
  position: relative;
  clear: both;
}

.post-password-form label {
  display: block;
  font-size: 1rem;
}

.post-password-form input[type="password"] {
  width: 100%;
  border: 0.0625rem solid #ebedf2;
  padding: 0.625rem 6.25rem 0.625rem 0.938rem;
  height: 2.8125rem;
  border: 0.0625rem solid #ced4da;
}

.post-password-form input[type="submit"] {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.625rem 1.25rem;
  background: var(--primary);
  color: #FFF;
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.875rem;
  outline: none;
  height: 2.8125rem;
}

.post-password-form input[type="submit"]:hover {
  background: var(--primary-hover);
}

@media only screen and (max-width: 991px) {
  .side-bar.sticky-top {
    position: unset;
  }
}

.blog-post-banner {
  min-height: 300px;
  padding-top: 70px;
  padding-bottom: 70px;
}

.blog-post-banner .dz-title {
  color: #fff;
  font-size: 32px;
  margin-bottom: 20px;
}

.blog-post-banner .dz-meta {
  color: #fff;
}

.blog-post-banner .dz-meta ul {
  display: flex;
  opacity: 0.7;
}

.blog-post-banner .dz-meta ul li {
  margin-right: 20px;
}

.blog-post-banner .dz-meta ul li a {
  color: #fff;
}

.post-outside {
  margin-top: -120px;
}

.single-post .main-bar {
  border-bottom: 1px solid #eee;
}

.single-post .site-header.header-transparent .main-bar {
  border-bottom: 0;
}

.dz-load-more i:before,
.loadmore-btn i:before {
  -webkit-animation: unset !important;
  -moz-animation: unset !important;
  animation: unset !important;
}

#comment-list:empty+.paginate-links {
  margin-top: -30px;
}

/* .post-btn{
	display:flex;
	align-items:center;
	.next-post,
	.prev-post{
		max-width:50%;
		flex:0 0 50%;
		display:flex;
		align-items:center;
		
		img{
			width:80px;
			height:80px;
		}
		.title{
			flex:1;
		}
	}
	.next-post{
		
	}
	.prev-post{
		
	}
} */
/* Post Next Preview */
.post-btn {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #ededee;
  border-bottom: 1px solid #ededee;
  padding: 15px 0;
  margin: 50px 0px;
  position: relative;
}

.post-btn:after {
  content: "";
  height: calc(100% - 30px);
  width: 1px;
  background: #ededee;
  position: absolute;
  left: 50%;
  top: 15px;
}

.post-btn .prev-post,
.post-btn .next-post {
  max-width: 50%;
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  position: relative;
}

.post-btn .prev-post img,
.post-btn .next-post img {
  height: 70px;
  width: 85px;
  border-radius: 6px;
  object-fit: cover;
}

@media only screen and (max-width: 767px) {

  .post-btn .prev-post img,
  .post-btn .next-post img {
    display: none;
  }
}

.post-btn .prev-post .start,
.post-btn .prev-post .end,
.post-btn .next-post .start,
.post-btn .next-post .end {
  color: #b5b5b5;
  cursor: not-allowed;
  width: 100%;
  padding: 0;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {

  .post-btn .prev-post .title,
  .post-btn .next-post .title {
    font-size: 14px;
  }
}

.post-btn .prev-post .title a,
.post-btn .next-post .title a {
  display: block;
  margin-bottom: 5px;
}

.post-btn .prev-post .title .post-date,
.post-btn .next-post .title .post-date {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #666;
}

@media only screen and (max-width: 767px) {

  .post-btn .prev-post .title .post-date,
  .post-btn .next-post .title .post-date {
    font-size: 12px;
  }
}

.post-btn .next-post {
  padding-right: 30px;
  padding-left: 10px;
}

@media only screen and (max-width: 767px) {
  .post-btn .next-post {
    padding-right: 20px;
  }
}

.post-btn .next-post .title {
  text-align: right;
  width: 100%;
  margin-bottom: 0;
}

.post-btn .next-post .title+img {
  margin-left: 20px;
}

.post-btn .next-post .end {
  text-align: right;
}

.post-btn .next-post:after {
  content: "\f105";
  font-family: "FontAwesome";
  color: var(--primary);
  position: absolute;
  right: 0;
  top: 50%;
  font-size: 30px;
  transform: translateY(-50%);
}

.post-btn .prev-post {
  padding-left: 30px;
  padding-right: 10px;
}

@media only screen and (max-width: 767px) {
  .post-btn .prev-post {
    padding-left: 20px;
  }
}

.post-btn .prev-post .title {
  margin-bottom: 0;
  width: 100%;
}

.post-btn .prev-post img+.title {
  padding: 0 20px;
}

.post-btn .prev-post:after {
  content: "\f104";
  font-family: "FontAwesome";
  color: var(--primary);
  position: absolute;
  left: 0;
  top: 50%;
  font-size: 30px;
  transform: translateY(-50%);
}

.dez-coming-soon {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
  z-index: 1;
  background-color: var(--primary);
}

.dez-coming-soon .bg-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  mix-blend-mode: multiply;
}

.dez-coming-soon .dzSubscribe {
  margin-top: 40px;
  margin-bottom: 70px;
  padding: 0 15px;
}

.dez-coming-soon .dzSubscribe p {
  font-size: 14px;
}

.dez-coming-soon .dzSubscribe .ft-row {
  position: relative;
}

.dez-coming-soon .dzSubscribe .ft-row .btn,
.dez-coming-soon .dzSubscribe .ft-row .wp-block-button__link,
.dez-coming-soon .dzSubscribe .ft-row .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .dez-coming-soon .dzSubscribe .ft-row a,
.dez-coming-soon .dzSubscribe .ft-row .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .dez-coming-soon .dzSubscribe .ft-row a,
.dez-coming-soon .dzSubscribe .ft-row .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .dez-coming-soon .dzSubscribe .ft-row input,
.dez-coming-soon .dzSubscribe .ft-row .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .dez-coming-soon .dzSubscribe .ft-row .button,
.dez-coming-soon .dzSubscribe .ft-row .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .dez-coming-soon .dzSubscribe .ft-row a.checkout-button,
.dez-coming-soon .dzSubscribe .ft-row .woocommerce #respond input#submit,
.woocommerce #respond .dez-coming-soon .dzSubscribe .ft-row input#submit,
.dez-coming-soon .dzSubscribe .ft-row .woocommerce a.button,
.woocommerce .dez-coming-soon .dzSubscribe .ft-row a.button,
.dez-coming-soon .dzSubscribe .ft-row .woocommerce button.button,
.woocommerce .dez-coming-soon .dzSubscribe .ft-row button.button,
.dez-coming-soon .dzSubscribe .ft-row .woocommerce input.button,
.woocommerce .dez-coming-soon .dzSubscribe .ft-row input.button,
.dez-coming-soon .dzSubscribe .ft-row .woocommerce #place_order,
.woocommerce .dez-coming-soon .dzSubscribe .ft-row #place_order {
  position: absolute;
  padding: 0;
  height: 56px;
  width: 56px;
  line-height: 56px;
  border-radius: 56px;
  text-align: center;
  background: var(--primary);
  color: #fff;
  top: 5px;
  right: 5px;
}

.dez-coming-soon .dzSubscribe .ft-row .form-control,
.dez-coming-soon .dzSubscribe .ft-row .woocommerce #review_form #respond input[type="text"],
.woocommerce #review_form #respond .dez-coming-soon .dzSubscribe .ft-row input[type="text"],
.dez-coming-soon .dzSubscribe .ft-row .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .dez-coming-soon .dzSubscribe .ft-row textarea,
.dez-coming-soon .dzSubscribe .ft-row .woocommerce #review_form #respond input[type="email"],
.woocommerce #review_form #respond .dez-coming-soon .dzSubscribe .ft-row input[type="email"],
.dez-coming-soon .dzSubscribe .ft-row .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .dez-coming-soon .dzSubscribe .ft-row .input-text,
.dez-coming-soon .dzSubscribe .ft-row .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .dez-coming-soon .dzSubscribe .ft-row input,
.dez-coming-soon .dzSubscribe .ft-row .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .dez-coming-soon .dzSubscribe .ft-row select,
.dez-coming-soon .dzSubscribe .ft-row .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .dez-coming-soon .dzSubscribe .ft-row input,
.dez-coming-soon .dzSubscribe .ft-row .woocommerce-EditAccountForm.edit-account input[type="text"],
.woocommerce-EditAccountForm.edit-account .dez-coming-soon .dzSubscribe .ft-row input[type="text"],
.dez-coming-soon .dzSubscribe .ft-row .woocommerce-EditAccountForm.edit-account input[type="email"],
.woocommerce-EditAccountForm.edit-account .dez-coming-soon .dzSubscribe .ft-row input[type="email"],
.dez-coming-soon .dzSubscribe .ft-row .woocommerce-EditAccountForm.edit-account input[type="password"],
.woocommerce-EditAccountForm.edit-account .dez-coming-soon .dzSubscribe .ft-row input[type="password"],
.dez-coming-soon .dzSubscribe .ft-row .login input[type="text"],
.login .dez-coming-soon .dzSubscribe .ft-row input[type="text"],
.dez-coming-soon .dzSubscribe .ft-row .login input[type="password"],
.login .dez-coming-soon .dzSubscribe .ft-row input[type="password"],
.dez-coming-soon .dzSubscribe .ft-row .woocommerce-ResetPassword.lost_reset_password input[type="text"],
.woocommerce-ResetPassword.lost_reset_password .dez-coming-soon .dzSubscribe .ft-row input[type="text"],
.dez-coming-soon .dzSubscribe .ft-row .checkout.woocommerce-checkout input[type="text"],
.checkout.woocommerce-checkout .dez-coming-soon .dzSubscribe .ft-row input[type="text"],
.dez-coming-soon .dzSubscribe .ft-row .checkout.woocommerce-checkout input[type="email"],
.checkout.woocommerce-checkout .dez-coming-soon .dzSubscribe .ft-row input[type="email"],
.dez-coming-soon .dzSubscribe .ft-row .checkout.woocommerce-checkout input[type="tel"],
.checkout.woocommerce-checkout .dez-coming-soon .dzSubscribe .ft-row input[type="tel"],
.dez-coming-soon .dzSubscribe .ft-row .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .dez-coming-soon .dzSubscribe .ft-row .select2-choice,
.dez-coming-soon .dzSubscribe .ft-row .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .dez-coming-soon .dzSubscribe .ft-row textarea,
.dez-coming-soon .dzSubscribe .ft-row .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .dez-coming-soon .dzSubscribe .ft-row input.input-text,
.dez-coming-soon .dzSubscribe .ft-row .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .dez-coming-soon .dzSubscribe .ft-row .select2-selection,
.dez-coming-soon .dzSubscribe .ft-row .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .dez-coming-soon .dzSubscribe .ft-row .select2-choice {
  height: 66px;
  border-radius: 66px !important;
  padding: 10px 70px 10px 30px;
}

@media only screen and (max-width: 575px) {
  .dez-coming-soon .dzSubscribe {
    margin-top: 25px;
    margin-bottom: 30px;
  }
}

.dez-coming-soon .container {
  position: relative;
}

.dez-coming-bx {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media only screen and (max-width: 991px) {
  .dez-coming-bx {
    display: block;
    height: auto;
    padding-bottom: 30px;
  }
}

.countdown-timer {
  padding-bottom: 30px;
}

.clock-item {
  width: 155px;
  position: relative;
  margin: 0 10px;
}

.countdown-timer .clock .text {
  position: absolute;
  top: 0;
  width: 100%;
}

.countdown-timer .clock .type-time {
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  letter-spacing: 2px;
  text-align: center;
  margin-bottom: 0;
}

.countdown-timer .clock .kineticjs-content {
  position: relative;
}

.countdown-timer .clock .kineticjs-content:after {
  content: "";
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  z-index: -1;
}

.countdown-timer .clock .val {
  font-size: 70px;
  color: #fff;
  width: 100%;
  line-height: 155px;
  text-align: center;
  margin-bottom: 15px;
}

.countdown-timer .clock {
  display: flex;
}

.bottom-left,
.bottom-right,
.posi-center,
.top-left,
.top-right {
  position: fixed;
  z-index: 2;
  display: block;
}

.bottom-right {
  bottom: 60px;
  right: 60px;
}

.bottom-left {
  bottom: 60px;
  left: 60px;
}

.top-right {
  top: 60px;
  right: 60px;
}

.top-left {
  top: 60px;
  left: 60px;
}

.style-3 .dez-title {
  font-size: 60px;
  line-height: 70px;
  color: #011463;
  margin-top: 0;
  margin-bottom: 30px;
  font-weight: 800;
}

.dez-social-icon {
  display: inline-block;
  margin: 0 0 10px 0;
  padding: 0;
  text-align: center;
}

.dez-social-icon li {
  display: inline-block;
}

.dez-social-icon li a {
  display: inline-block;
  width: 48px;
  height: 48px;
  line-height: 48px;
  border-radius: 48px;
  font-size: 18px;
  background: #fff;
  transition: all .5s;
  -moz-transition: all .5s;
  -webkit-transition: all .5s;
  -ms-transition: all .5s;
  -o-transition: all .5s;
}

/* ===============================
	Coming Soon 3
================================ */
.style-3 .sy-box {
  background: #031322;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.style-3 .sy-pager {
  display: none;
}

.style-3 .dez-title {
  color: #fff;
}

.style-3 .dez-social-icon li a {
  color: var(--primary);
  margin-right: 5px;
}

.style-3 .copyright-text {
  color: #fff;
}

.style-3 .sy-slide>img {
  height: 120%;
  object-fit: cover;
  min-height: 120vh;
}

@media only screen and (max-width: 1400px) {
  .bottom-left {
    bottom: 40px;
    left: 40px;
  }

  .bottom-right {
    bottom: 40px;
    right: 40px;
  }

  .top-left {
    top: 40px;
    left: 40px;
  }

  .top-right {
    top: 40px;
    right: 40px;
  }

  .clock-item {
    width: 130px;
  }

  .countdown-timer .clock .val {
    font-size: 50px;
    line-height: 130px;
  }
}

@media only screen and (max-width: 991px) {

  .posi-center,
  .center-md,
  .posi-center,
  .bottom-right,
  .bottom-left,
  .top-right,
  .top-left {
    position: unset;
  }

  .style-3 .top-left,
  .style-3 .bottom-left {
    width: 100%;
    text-align: center;
  }

  .style-3 .bottom-left {
    padding-bottom: 30px;
  }

  .style-3 .countdown-timer {
    margin-bottom: 35px;
  }

  .style-3 .dez-social-icon li a {
    margin-left: 3px;
    margin-right: 3px;
  }

  .style-3 .center-md .bottom-right {
    position: unset;
  }

  .countdown-timer .clock {
    justify-content: center;
  }

  .style-3.raincanvas .dez-coming-bx {
    position: relative;
    z-index: 2;
  }

  .style-3 .dez-title {
    font-size: 40px;
    line-height: 55px;
  }

  .clock-item {
    width: 120px;
  }

  .style-3 .center-md {
    min-height: calc(100vh - 115px);
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 0;
  }
}

@media only screen and (max-width: 576px) {
  .clock-item {
    width: 70px;
  }

  .countdown-timer .clock .val {
    line-height: 70px;
    font-size: 30px;
    margin-bottom: 5px;
    font-weight: 600;
  }

  .style-3 .dez-title {
    font-size: 28px;
    line-height: 36px;
  }

  .countdown-timer .clock .type-time {
    letter-spacing: 1px;
    font-size: 11px;
    line-height: 18px;
  }
}

.under-construct {
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;
  padding: 80px;
  background-color: #f2f2f4;
  background-position: center;
  z-index: 1;
  overflow: hidden;
}

.under-construct:after {
  content: "";
  background: #fff;
  width: 100%;
  height: 100%;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-position: center;
  background-size: cover;
}

@media only screen and (max-width: 575px) {
  .under-construct:after {
    opacity: 0.85;
  }
}

@media only screen and (max-width: 1680px) {
  .under-construct {
    padding: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .under-construct {
    padding: 20px;
  }
}

.under-construct .logo-header {
  height: auto;
  width: 100%;
}

.under-construct .logo-header a {
  display: inline-block;
  width: 180px;
}

.under-construct .dz-content .dz-title {
  font-size: 90px;
  line-height: 1.2;
  margin-bottom: 10px;
  color: #212529;
  font-family: var(--font-family-title);
}

@media only screen and (max-width: 1680px) {
  .under-construct .dz-content .dz-title {
    font-size: 70px;
  }
}

@media only screen and (max-width: 1280px) {
  .under-construct .dz-content .dz-title {
    font-size: 60px;
  }
}

@media only screen and (max-width: 600px) {
  .under-construct .dz-content .dz-title {
    font-size: 58px;
  }
}

@media only screen and (max-width: 575px) {
  .under-construct .dz-content .dz-title {
    font-size: 37px;
  }
}

.under-construct .dz-content p {
  font-size: 35px;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 1.2;
  color: #333;
}

@media only screen and (max-width: 1680px) {
  .under-construct .dz-content p {
    font-size: 28px;
  }
}

@media only screen and (max-width: 1280px) {
  .under-construct .dz-content p {
    font-size: 24px;
  }
}

@media only screen and (max-width: 575px) {
  .under-construct .dz-content p {
    font-size: 16px;
    font-weight: 500;
  }
}

.under-construct .inner-box {
  height: 100%;
  width: 50%;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 1280px) {
  .under-construct .inner-box {
    width: 100%;
  }
}

.under-construct .uc-bg {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 100%;
}

.error-page {
  background-color: var(--secondary);
  height: 100vh;
  padding: 50px;
  position: relative;
}

.error-page .error-inner {
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 600px;
  transform: translate(-50%, -50%);
  padding: 20px;
}

@media only screen and (max-width: 575px) {
  .error-page .error-inner {
    width: 100%;
  }
}

.error-page .error-head {
  color: #fff;
  margin-bottom: 40px;
}

@media only screen and (max-width: 575px) {
  .error-page .error-head {
    font-size: 20px;
    margin-bottom: 30px;
  }
}

.error-page .dz_error {
  position: relative;
  color: #fff;
  font-size: 200px;
  letter-spacing: 28px;
  animation: dzError 1s infinite linear alternate-reverse;
  font-weight: 900;
  line-height: 200px;
  margin: auto;
}

@media only screen and (max-width: 991px) {
  .error-page .dz_error {
    font-size: 150px;
    line-height: 150px;
  }
}

@media only screen and (max-width: 575px) {
  .error-page .dz_error {
    font-size: 80px;
    line-height: 80px;
    letter-spacing: 5px;
  }
}

.error-page .dz_error::before,
.error-page .dz_error::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.error-page .dz_error::before {
  left: 2px;
  text-shadow: -2px 0 #d700a3;
  clip: rect(44px, 450px, 56px, 0);
  animation: dzError1 5s infinite linear alternate-reverse;
}

.error-page .dz_error::after {
  left: -2px;
  text-shadow: -2px 0 #d700a3, 2px 2px #d700a3;
  clip: rect(44px, 450px, 56px, 0);
  animation: dzError2 5s infinite linear alternate-reverse;
}

@keyframes dzError1 {
  0% {
    clip: rect(445px, 9999px, 279px, 0);
    transform: skew(0.57deg);
  }

  2.5% {
    clip: rect(179px, 9999px, 185px, 0);
    transform: skew(0.938deg);
  }

  5% {
    clip: rect(335px, 9999px, 134px, 0);
    transform: skew(0.878deg);
  }

  7.5% {
    clip: rect(452px, 9999px, 300px, 0);
    transform: skew(0.534deg);
  }

  10% {
    clip: rect(261px, 9999px, 454px, 0);
    transform: skew(0.794deg);
  }

  12.5% {
    clip: rect(414px, 9999px, 64px, 0);
    transform: skew(0.512deg);
  }

  15% {
    clip: rect(383px, 9999px, 279px, 0);
    transform: skew(0.042deg);
  }

  17.5% {
    clip: rect(413px, 9999px, 155px, 0);
    transform: skew(0.532deg);
  }

  20% {
    clip: rect(465px, 9999px, 1px, 0);
    transform: skew(0.074deg);
  }

  22.5% {
    clip: rect(215px, 9999px, 153px, 0);
    transform: skew(0.534deg);
  }

  25% {
    clip: rect(32px, 9999px, 347px, 0);
    transform: skew(0.556deg);
  }

  27.5% {
    clip: rect(8px, 9999px, 174px, 0);
    transform: skew(0.548deg);
  }

  30% {
    clip: rect(105px, 9999px, 283px, 0);
    transform: skew(0.312deg);
  }

  32.5% {
    clip: rect(288px, 9999px, 478px, 0);
    transform: skew(0.914deg);
  }

  35% {
    clip: rect(79px, 9999px, 313px, 0);
    transform: skew(0.33deg);
  }

  37.5% {
    clip: rect(436px, 9999px, 186px, 0);
    transform: skew(0.096deg);
  }

  40% {
    clip: rect(385px, 9999px, 341px, 0);
    transform: skew(0.906deg);
  }

  42.5% {
    clip: rect(40px, 9999px, 166px, 0);
    transform: skew(0.928deg);
  }

  45% {
    clip: rect(174px, 9999px, 497px, 0);
    transform: skew(0.732deg);
  }

  47.5% {
    clip: rect(330px, 9999px, 427px, 0);
    transform: skew(0.488deg);
  }

  50% {
    clip: rect(202px, 9999px, 468px, 0);
    transform: skew(0.87deg);
  }

  52.5% {
    clip: rect(217px, 9999px, 308px, 0);
    transform: skew(0.856deg);
  }

  55% {
    clip: rect(297px, 9999px, 409px, 0);
    transform: skew(0.276deg);
  }

  57.5% {
    clip: rect(143px, 9999px, 264px, 0);
    transform: skew(0.858deg);
  }

  60% {
    clip: rect(375px, 9999px, 360px, 0);
    transform: skew(0.584deg);
  }

  62.5% {
    clip: rect(79px, 9999px, 140px, 0);
    transform: skew(0.368deg);
  }

  65% {
    clip: rect(200px, 9999px, 497px, 0);
    transform: skew(0.818deg);
  }

  67.5% {
    clip: rect(327px, 9999px, 246px, 0);
    transform: skew(0.886deg);
  }

  70% {
    clip: rect(76px, 9999px, 315px, 0);
    transform: skew(0.178deg);
  }

  72.5% {
    clip: rect(444px, 9999px, 222px, 0);
    transform: skew(0.524deg);
  }

  75% {
    clip: rect(126px, 9999px, 391px, 0);
    transform: skew(0.612deg);
  }

  77.5% {
    clip: rect(364px, 9999px, 455px, 0);
    transform: skew(0.78deg);
  }

  80% {
    clip: rect(162px, 9999px, 176px, 0);
    transform: skew(0.906deg);
  }

  82.5% {
    clip: rect(204px, 9999px, 99px, 0);
    transform: skew(0.064deg);
  }

  85% {
    clip: rect(4px, 9999px, 491px, 0);
    transform: skew(0.664deg);
  }

  87.5% {
    clip: rect(358px, 9999px, 282px, 0);
    transform: skew(0.744deg);
  }

  90% {
    clip: rect(102px, 9999px, 167px, 0);
    transform: skew(0.082deg);
  }

  92.5% {
    clip: rect(440px, 9999px, 120px, 0);
    transform: skew(0.534deg);
  }

  95% {
    clip: rect(114px, 9999px, 156px, 0);
    transform: skew(0.788deg);
  }

  97.5% {
    clip: rect(189px, 9999px, 217px, 0);
    transform: skew(0.082deg);
  }
}

@keyframes dzError2 {
  0% {
    clip: rect(297px, 9999px, 130px, 0);
    transform: skew(0.398deg);
  }

  2.5% {
    clip: rect(54px, 9999px, 32px, 0);
    transform: skew(0.976deg);
  }

  5% {
    clip: rect(123px, 9999px, 375px, 0);
    transform: skew(0.344deg);
  }

  7.5% {
    clip: rect(428px, 9999px, 145px, 0);
    transform: skew(0.446deg);
  }

  10% {
    clip: rect(355px, 9999px, 89px, 0);
    transform: skew(0.408deg);
  }

  12.5% {
    clip: rect(226px, 9999px, 54px, 0);
    transform: skew(0.556deg);
  }

  15% {
    clip: rect(59px, 9999px, 215px, 0);
    transform: skew(0.218deg);
  }

  17.5% {
    clip: rect(204px, 9999px, 253px, 0);
    transform: skew(0.636deg);
  }

  20% {
    clip: rect(98px, 9999px, 32px, 0);
    transform: skew(0.108deg);
  }

  22.5% {
    clip: rect(238px, 9999px, 249px, 0);
    transform: skew(0.538deg);
  }

  25% {
    clip: rect(31px, 9999px, 50px, 0);
    transform: skew(0.59deg);
  }

  27.5% {
    clip: rect(473px, 9999px, 461px, 0);
    transform: skew(0.546deg);
  }

  30% {
    clip: rect(85px, 9999px, 176px, 0);
    transform: skew(0.654deg);
  }

  32.5% {
    clip: rect(91px, 9999px, 341px, 0);
    transform: skew(0.692deg);
  }

  35% {
    clip: rect(97px, 9999px, 218px, 0);
    transform: skew(0.866deg);
  }

  37.5% {
    clip: rect(211px, 9999px, 100px, 0);
    transform: skew(0.608deg);
  }

  40% {
    clip: rect(163px, 9999px, 150px, 0);
    transform: skew(0.166deg);
  }

  42.5% {
    clip: rect(394px, 9999px, 406px, 0);
    transform: skew(0.994deg);
  }

  45% {
    clip: rect(315px, 9999px, 280px, 0);
    transform: skew(0.362deg);
  }

  47.5% {
    clip: rect(460px, 9999px, 12px, 0);
    transform: skew(0.12deg);
  }

  50% {
    clip: rect(256px, 9999px, 217px, 0);
    transform: skew(0.258deg);
  }

  52.5% {
    clip: rect(326px, 9999px, 237px, 0);
    transform: skew(0.48deg);
  }

  55% {
    clip: rect(202px, 9999px, 310px, 0);
    transform: skew(0.712deg);
  }

  57.5% {
    clip: rect(332px, 9999px, 315px, 0);
    transform: skew(0.986deg);
  }

  60% {
    clip: rect(324px, 9999px, 429px, 0);
    transform: skew(0.232deg);
  }

  62.5% {
    clip: rect(153px, 9999px, 120px, 0);
    transform: skew(0.386deg);
  }

  65% {
    clip: rect(324px, 9999px, 13px, 0);
    transform: skew(0.182deg);
  }

  67.5% {
    clip: rect(2px, 9999px, 1px, 0);
    transform: skew(0.692deg);
  }

  70% {
    clip: rect(122px, 9999px, 397px, 0);
    transform: skew(0.6deg);
  }

  72.5% {
    clip: rect(181px, 9999px, 282px, 0);
    transform: skew(0.684deg);
  }

  75% {
    clip: rect(497px, 9999px, 160px, 0);
    transform: skew(0.732deg);
  }

  77.5% {
    clip: rect(487px, 9999px, 2px, 0);
    transform: skew(0.474deg);
  }

  80% {
    clip: rect(481px, 9999px, 427px, 0);
    transform: skew(0.518deg);
  }

  82.5% {
    clip: rect(198px, 9999px, 457px, 0);
    transform: skew(0.33deg);
  }

  85% {
    clip: rect(179px, 9999px, 203px, 0);
    transform: skew(0.182deg);
  }

  87.5% {
    clip: rect(319px, 9999px, 334px, 0);
    transform: skew(0.668deg);
  }

  90% {
    clip: rect(384px, 9999px, 234px, 0);
    transform: skew(0.536deg);
  }

  92.5% {
    clip: rect(193px, 9999px, 128px, 0);
    transform: skew(0.516deg);
  }

  95% {
    clip: rect(499px, 9999px, 281px, 0);
    transform: skew(0.538deg);
  }

  97.5% {
    clip: rect(413px, 9999px, 53px, 0);
    transform: skew(0.798deg);
  }
}

@keyframes dzError {
  0% {
    transform: skew(1deg);
  }

  10% {
    transform: skew(4deg);
  }

  20% {
    transform: skew(1deg);
  }

  30% {
    transform: skew(-2deg);
  }

  40% {
    transform: skew(0deg);
  }

  50% {
    transform: skew(5deg);
  }

  60% {
    transform: skew(3deg);
  }

  70% {
    transform: skew(-4deg);
  }

  80% {
    transform: skew(-1deg);
  }

  90% {
    transform: skew(5deg);
  }
}

.contact-wraper1 {
  margin-bottom: 60px;
  position: relative;
  background-position: -260px center;
}

.contact-wraper1 .contact-info {
  padding: 50px 30px;
}

.contact-wraper1 .contact-area1 {
  margin-top: -180px;
  margin-bottom: -100px;
}

.contact-wraper1:before {
  content: "";
  height: 100%;
  width: 50%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background: linear-gradient(to right, rgba(26, 22, 104, 0.5) 0%, #1a1668 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.contact-wraper1:after {
  content: "";
  height: 100%;
  width: 50%;
  background-color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

@media only screen and (max-width: 1280px) {
  .contact-wraper1 .contact-info {
    padding: 50px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .contact-wraper1 {
    background-position: center;
    background-size: cover;
    margin-bottom: 0;
  }

  .contact-wraper1:after {
    content: none;
  }

  .contact-wraper1:before {
    height: 100%;
    width: 100%;
  }

  .contact-wraper1 .contact-area1 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .contact-wraper1 .contact-info {
    padding: 50px 0px 20px;
  }
}

.contact-area1 {
  padding: 50px;
  background: #fff;
  box-shadow: 0px 3px 29px 0px rgba(0, 0, 0, 0.07);
}

@media only screen and (max-width: 575px) {
  .contact-area1 {
    padding: 30px;
  }
}

.map-iframe {
  height: 450px;
}

@media only screen and (max-width: 575px) {
  .map-iframe {
    height: 250px;
  }
}

@media only screen and (max-width: 575px) {
  form .g-recaptcha {
    transform: scale(0.7);
    -moz-transform: scale(0.7);
    -o-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

.filter-area {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: var(--border-radius-base);
  align-items: center;
  background-color: #ffffff;
  display: flex;
}

.filter-area .grid-area {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.filter-area .grid-area ..nav-item {
  align-item: center;
}

.filter-area .grid-area ..nav-item .nav-link {
  color: var(--secondary);
  font-family: var(--font-family-title);
  font-weight: var(--headings-font-weight);
  padding: 0;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-area .grid-area .nav-item .nav-link svg {
  width: 20px;
  height: 20px;
}

.filter-area .grid-area .nav-item .nav-link svg path {
  fill: var(--secondary);
}

.filter-area .grid-area .nav-item .nav-link.active {
  color: var(--secondary);
}

.filter-area .grid-area .nav-item .nav-link:hover {
  color: var(--secondary);
}

@media only screen and (max-width: 1024px) {
  .filter-area .grid-area .filter-day {
    display: none;
  }
}

.filter-area .category {
  position: relative;
  z-index: 1;
  display: flex;
}

.filter-area .category .filter-category {
  align-items: center;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
}

.filter-area .category .filter-category a {
  border: 0;
  background: transparent;
  font-weight: 500;
  color: var(--secondary);
  font-size: 16px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 60px;
  outline: none !important;
  padding: 0px 20px 0px 5px;
  font-family: var(--font-family-title);
}

.filter-area .category .form-group {
  display: flex;
  align-items: center;
  padding: 0;
  background: none;
}

.filter-area .category .form-group .dropdown {
  width: 110px !important;
}

.filter-area .category .form-group .btn,
.filter-area .category .form-group .wp-block-button__link,
.filter-area .category .form-group .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .filter-area .category .form-group a,
.filter-area .category .form-group .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .filter-area .category .form-group a,
.filter-area .category .form-group .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .filter-area .category .form-group input,
.filter-area .category .form-group .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .filter-area .category .form-group .button,
.filter-area .category .form-group .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .filter-area .category .form-group a.checkout-button,
.filter-area .category .form-group .woocommerce #respond input#submit,
.woocommerce #respond .filter-area .category .form-group input#submit,
.filter-area .category .form-group .woocommerce a.button,
.woocommerce .filter-area .category .form-group a.button,
.filter-area .category .form-group .woocommerce button.button,
.woocommerce .filter-area .category .form-group button.button,
.filter-area .category .form-group .woocommerce input.button,
.woocommerce .filter-area .category .form-group input.button,
.filter-area .category .form-group .woocommerce #place_order,
.woocommerce .filter-area .category .form-group #place_order {
  border-bottom: 0;
  padding: 0px 20px 0 0;
  border: 0;
  background: transparent;
  color: var(--secondary);
  font-size: 16px;
  font-weight: 500;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 60px;
  outline: none !important;
}

.filter-area.filter-1 {
  display: flex;
}

@media only screen and (max-width: 575px) {
  .filter-area.filter-1 .category {
    border-top: 0;
  }
}

@media only screen and (max-width: 575px) {
  .filter-area {
    display: block;
  }

  .filter-area .grid-area {
    justify-content: center;
  }

  .filter-area .category {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }

  .filter-area .category:before {
    content: none;
  }
}

.page {
  margin-top: 40px;
  align-items: center;
}

.page .page-text {
  color: #3E4954;
  font-weight: 600;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .page {
    margin-top: 0;
    text-align: center;
  }

  .page .page-text {
    margin-bottom: 25px;
  }
}

.btn-quantity.style-1 {
  width: 140px;
  position: relative;
}

.btn-quantity.style-1 .btn,
.btn-quantity.style-1 .wp-block-button__link,
.btn-quantity.style-1 .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .btn-quantity.style-1 a,
.btn-quantity.style-1 .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .btn-quantity.style-1 a,
.btn-quantity.style-1 .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .btn-quantity.style-1 input,
.btn-quantity.style-1 .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .btn-quantity.style-1 .button,
.btn-quantity.style-1 .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .btn-quantity.style-1 a.checkout-button,
.btn-quantity.style-1 .woocommerce #respond input#submit,
.woocommerce #respond .btn-quantity.style-1 input#submit,
.btn-quantity.style-1 .woocommerce a.button,
.woocommerce .btn-quantity.style-1 a.button,
.btn-quantity.style-1 .woocommerce button.button,
.woocommerce .btn-quantity.style-1 button.button,
.btn-quantity.style-1 .woocommerce input.button,
.woocommerce .btn-quantity.style-1 input.button,
.btn-quantity.style-1 .woocommerce #place_order,
.woocommerce .btn-quantity.style-1 #place_order {
  padding: 0;
  top: 0;
  height: 46px;
  width: 46px;
  position: absolute;
  top: 0;
  background: #eee;
}

.btn-quantity.style-1 .btn-plus {
  right: 0;
}

.btn-quantity.style-1 .btn-minus {
  left: 0;
}

.btn-quantity.style-1 .input-group {
  position: relative;
  margin-bottom: 0;
}

.btn-quantity.style-1 input {
  background: #eeeeee4d;
  border: 0 none;
  font-size: 16px;
  height: 46px;
  text-align: center;
  z-index: 0 !important;
  width: 100%;
}

.btn-quantity.style-1 .bootstrap-touchspin .input-group-btn-vertical {
  position: unset;
}

.btn-quantity.style-1 .input-group-btn-vertical button:first-child,
.btn-quantity.style-1 .input-group-btn-vertical button:last-child {
  background: #ebebeb none repeat scroll 0 0;
  border: 0 none;
  position: absolute;
  height: 40px;
  justify-content: center;
  top: 0;
  z-index: 0;
  width: 40px;
  padding: 0;
  z-index: 2;
  margin: 0;
}

.btn-quantity.style-1 .input-group-btn-vertical button:first-child {
  right: 0;
}

.btn-quantity.style-1 .input-group-btn-vertical button:last-child {
  left: 0;
}

.dz-shop-card {
  position: relative;
}

.dz-shop-card .dz-tags {
  display: flex;
  margin-bottom: 8px;
}

.dz-shop-card .dz-tags li a {
  color: var(--primary);
  margin-right: 5px;
  font-size: 14px;
}

.dz-shop-card.style-1 {
  background-color: #ffffff;
  padding: 22px;
  margin-bottom: 30px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  border-radius: 6px;
}

.dz-shop-card.style-1 .dz-content {
  padding-top: 20px;
  text-align: center;
}

.dz-shop-card.style-1 .dz-content .dz-rating {
  display: flex;
  justify-content: center;
}

.dz-shop-card.style-1 .dz-content .dz-rating li i {
  margin: 0px 3px;
  font-size: 18px;
}

.dz-shop-card.style-1 .dz-content .dz-tags {
  justify-content: center;
}

.dz-shop-card.style-1 .dz-content .book-footer {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  background: #ffffff;
  bottom: -50px;
  padding-bottom: 30px;
  border-radius: 0 0 var(--border-radius-base) var(--border-radius-base);
  left: 0;
  width: 100%;
  align-items: center;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.dz-shop-card.style-1 .dz-content .book-footer .rate p {
  color: var(--primary);
  font-size: 22px;
}

.dz-shop-card.style-1 .dz-content .book-footer .price {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-bottom: 15px;
}

.dz-shop-card.style-1 .dz-content .book-footer .price .price-num {
  font-size: 24px;
  color: var(--primary);
  font-weight: 700;
}

.dz-shop-card.style-1 .dz-content .book-footer .price del {
  font-size: 16px;
  color: #AAAAAA;
  font-weight: 500;
  font-family: var(--font-family-title);
  margin-top: 4px;
  padding: 0 10px;
}

.dz-shop-card.style-1:hover {
  box-shadow: 0px 70px 60px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.dz-shop-card.style-1:hover .dz-content .title {
  margin-bottom: 0;
}

.dz-shop-card.style-1:hover .dz-content .book-footer {
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 1680px) {
  .dz-shop-card.style-1 {
    padding: 18px;
  }
}

@media only screen and (max-width: 991px) {
  .dz-shop-card.style-1 {
    padding: 12px;
  }

  .dz-shop-card.style-1 .dz-content .title {
    font-size: 1.15rem;
  }
}

@media only screen and (max-width: 575px) {
  .dz-shop-card.style-1 {
    padding: 22px;
  }
}

.dz-shop-card.style-2 {
  display: flex;
  border: 1px solid #F0F0F0;
  background-color: #ffffff;
  padding: 20px 20px;
  margin-bottom: 30px;
  border-radius: 6px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

@media only screen and (max-width: 575px) {
  .dz-shop-card.style-2 {
    display: block;
  }
}

.dz-shop-card.style-2 .dz-media {
  min-width: 200px;
  width: 200px;
  min-height: 280px;
  margin-right: 20px;
}

.dz-shop-card.style-2 .dz-media img {
  object-fit: cover;
  height: 100%;
  border-radius: 6px;
}

@media only screen and (max-width: 767px) {
  .dz-shop-card.style-2 .dz-media {
    min-width: 150px;
    width: 150px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-shop-card.style-2 .dz-media {
    min-width: 100%;
    width: 100%;
    min-height: 100%;
  }
}

.dz-shop-card.style-2 .dz-tags {
  margin-bottom: 0;
}

.dz-shop-card.style-2 .dz-content {
  align-self: center;
}

@media only screen and (max-width: 575px) {
  .dz-shop-card.style-2 .dz-content {
    padding-top: 20px;
  }
}

.dz-shop-card.style-2 .dz-content .dz-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 991px) {
  .dz-shop-card.style-2 .dz-content .dz-header {
    display: block;
  }
}

.dz-shop-card.style-2 .dz-content .dz-rating-box {
  display: flex;
}

.dz-shop-card.style-2 .dz-content .dz-rating-box .dz-rating {
  display: flex;
  text-align: center;
}

.dz-shop-card.style-2 .dz-content .dz-rating-box .dz-rating li {
  margin: 0px 2px;
  line-height: 1;
}

.dz-shop-card.style-2 .dz-content .dz-rating-box .review-num {
  display: flex;
  margin-left: 18px;
  flex-direction: column;
  text-align: center;
  min-width: 100px;
}

@media only screen and (max-width: 767px) {
  .dz-shop-card.style-2 .dz-content .dz-rating-box .review-num {
    min-width: 90px;
  }
}

.dz-shop-card.style-2 .dz-content .dz-rating-box .review-num h4,
.dz-shop-card.style-2 .dz-content .dz-rating-box .review-num .h4 {
  margin-bottom: 0;
}

.dz-shop-card.style-2 .dz-content .dz-rating-box .review-num span a {
  font-size: 14px;
  font-family: var(--font-family-base);
  color: #AAAAAA;
}

.dz-shop-card.style-2 .dz-content .price .price-num {
  font-size: 28px;
  color: var(--title);
  font-weight: var(--headings-font-weight);
}

@media only screen and (max-width: 767px) {
  .dz-shop-card.style-2 .dz-content .price .price-num {
    font-size: 24px;
  }
}

.dz-shop-card.style-2 .dz-content .price del {
  font-weight: 500;
  font-size: 20px;
  margin-left: 10px;
  color: #AAAAAA;
  font-family: var(--font-family-title);
}

@media only screen and (max-width: 767px) {
  .dz-shop-card.style-2 .dz-content .price del {
    font-size: 15px;
  }
}

.dz-shop-card.style-2 .dz-content .dz-body {
  margin-top: 0px;
}

@media only screen and (max-width: 991px) {
  .dz-shop-card.style-2 .dz-content .dz-body {
    display: block;
  }
}

.dz-shop-card.style-2 .dz-content .dz-body .dz-para {
  font-size: 14px;
}

@media only screen and (max-width: 991px) {
  .dz-shop-card.style-2 .dz-content .dz-body .dz-para {
    display: none;
  }
}

.dz-shop-card.style-2 .dz-content .dz-body .rate {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 991px) {
  .dz-shop-card.style-2 .dz-content .dz-body .rate {
    display: block;
    margin-top: 10px;
  }
}

.dz-shop-card.style-2 .dz-content .dz-body .rate .book-info {
  display: flex;
}

@media only screen and (max-width: 991px) {
  .dz-shop-card.style-2 .dz-content .dz-body .rate .book-info {
    margin-bottom: 15px;
  }
}

.dz-shop-card.style-2 .dz-content .dz-body .rate .book-info li {
  color: #11142D;
  font-size: 18px;
  font-weight: var(--headings-font-weight);
  padding: 0 20px 0 0;
}

@media only screen and (max-width: 991px) {
  .dz-shop-card.style-2 .dz-content .dz-body .rate .book-info li {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .dz-shop-card.style-2 .dz-content .dz-body .rate .book-info li {
    padding: 0 10px 0 0;
  }
}

.dz-shop-card.style-2 .dz-content .dz-body .rate .book-info li span {
  display: block;
  color: #AAAAAA;
  font-size: 14px;
}

.dz-shop-card.style-5 {
  align-items: center;
  display: flex;
  margin-top: 25px;
  margin-bottom: -3px;
}

.dz-shop-card.style-5 .dz-media {
  min-width: 110px;
  width: 110px;
  min-height: 140px;
}

.dz-shop-card.style-5 .dz-media img {
  width: 100%;
  border-radius: var(--border-radius-base);
}

.dz-shop-card.style-5 .dz-content {
  margin-left: 15px;
}

.dz-shop-card.style-5 .dz-content .dz-tags {
  display: flex;
}

.dz-shop-card.style-5 .dz-content .dz-tags li {
  color: var(--primary);
  font-weight: var(--headings-font-weight2);
  font-size: 14px;
  margin-right: 5px;
}

.dz-shop-card.style-5 .dz-content .price {
  display: flex;
  margin-bottom: 15px;
  line-height: 1.2;
}

.dz-shop-card.style-5 .dz-content .price .price-num {
  font-size: 18px;
  color: var(--primary);
  font-weight: 700;
}

.dz-shop-card.style-5 .dz-content .price del {
  font-size: 14px;
  color: #aaaaaa;
  font-weight: 400;
  font-family: var(--font-family-title);
  margin-top: 4px;
  padding: 0px 5px;
}

.dz-shop-card.style-5 .dz-content .box-btn {
  padding: 13px 14px;
}

.dz-shop-card.style-5 .dz-content .box-btn i {
  margin-right: 8px;
}

@media only screen and (max-width: 575px) {
  .dz-shop-card.style-5 .dz-content .subtitle {
    font-size: 19px;
  }
}

.related-title {
  margin-bottom: 45px;
  margin-top: 34px;
}

@media only screen and (max-width: 1680px) {
  .related-title {
    margin-bottom: 46px;
  }
}

@media only screen and (max-width: 1480px) {
  .related-title {
    margin-bottom: 55px;
  }
}

.book-grid-row.style-4 .dz-box {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .book-grid-row.style-4 .dz-box {
    display: block;
  }
}

.book-grid-row.style-4 .dz-box .dz-media {
  /* min-width: 300px; */
  width: 25%;
}

@media only screen and (max-width: 920px) {
  .book-grid-row.style-4 .dz-box .dz-media {
    /* min-width: 300px; */
    width: 100%;
  }
}

.book-grid-row.style-4 .dz-box .dz-media img {
  border-radius: 10px;
}

@media only screen and (max-width: 1280px) {
  .book-grid-row.style-4 .dz-box .dz-media {
    min-width: 250px;
  }
}

.book-grid-row.style-4 .dz-box .dz-content {
  margin-left: 50px;
  padding-top: 0px;
  width: 75%;
}

@media only screen and (max-width: 1280px) {
  .book-grid-row.style-4 .dz-box .dz-content {
    margin-left: 30px;
    padding-top: 0;
    width: 75%;
  }
}

@media only screen and (max-width: 767px) {
  .book-grid-row.style-4 .dz-box .dz-content {
    margin-left: 0;
    padding-top: 25px;
    width: 100%;
  }
}

.book-grid-row.style-4 .dz-box .dz-content .dz-header {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1280px) {
  .book-grid-row.style-4 .dz-box .dz-content .dz-header {
    margin-bottom: 20px;
  }
}

.book-grid-row.style-4 .dz-box .dz-content .dz-header .shop-item-rating {
  display: flex;
  justify-content: space-between;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-header .shop-item-rating .dz-rating {
  display: flex;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-header .shop-item-rating .dz-rating li {
  margin-right: 4px;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-header .shop-item-rating .social-area {
  display: flex;
  align-items: center;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-header .shop-item-rating .social-area .review-num {
  display: flex;
  margin-right: 15px;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-header .shop-item-rating .social-area .review-num li a {
  font-size: 15px;
  color: #000;
  font-weight: var(--headings-font-weight);
  margin: 0 12px;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-header .shop-item-rating .social-area .review-num li a svg {
  margin-right: 10px;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-detail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-detail .book-info {
  display: flex;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-detail .book-info li {
  color: var(--secondary);
  font-size: 18px;
  font-weight: var(--headings-font-weight);
  padding: 0 65px 0 0;
}

@media only screen and (max-width: 1280px) {
  .book-grid-row.style-4 .dz-box .dz-content .dz-body .book-detail .book-info li {
    padding: 0 20px 0 0;
  }
}

@media only screen and (max-width: 991px) {
  .book-grid-row.style-4 .dz-box .dz-content .dz-body .book-detail .book-info li {
    font-size: 15px;
  }
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-detail .book-info li .writer-info {
  display: flex;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-detail .book-info li .writer-info img {
  height: 50px;
  border-radius: 11px;
  width: 50px;
  margin-right: 20px;
}

@media only screen and (max-width: 575px) {
  .book-grid-row.style-4 .dz-box .dz-content .dz-body .book-detail .book-info li .writer-info img {
    display: none;
  }
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-detail .book-info li span {
  display: block;
  color: #AAAAAA;
  font-size: 14px;
  font-weight: 500;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-detail .right-info .badge {
  border: 1px solid #EBE8FE;
  color: #131045;
  font-size: 14px;
  padding: 10px 14px;
  margin: 0 7px;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-detail .right-info .badge.badge-1 {
  color: #53C258;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .text-1 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1280px) {
  .book-grid-row.style-4 .dz-box .dz-content .dz-body .text-1 {
    margin-bottom: 20px;
  }
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

@media only screen and (max-width: 991px) {
  .book-grid-row.style-4 .dz-box .dz-content .dz-body .book-footer {
    display: block;
  }
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-footer .price {
  display: flex;
  align-items: baseline;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-footer .price h5,
.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-footer .price .h5 {
  font-size: 30px;
  color: var(--primary);
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-footer .price p {
  font-weight: 500;
  font-size: 18px;
  text-decoration: line-through;
  color: #636363;
  font-family: var(--font-family-title);
  margin-bottom: 0;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-footer .price .badge {
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  background-color: #FF754C;
  width: 57px;
  height: 30px;
  border-radius: var(--border-radius-base);
  text-align: center;
  line-height: 20px;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-footer .product-num {
  display: flex;
  align-items: center;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-footer .product-num a span {
  margin-left: 15px;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-footer .product-num .like-btn {
  border: 1px solid #F0F0F0;
  width: 60px;
  border-radius: var(--border-radius-base);
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 22px;
  margin-left: 18px;
}

@media only screen and (max-width: 1191px) {
  .shop-filter {
    padding: 30px 20px;
    position: fixed;
    left: -345px;
    top: 0px;
    z-index: 999999999;
    background: #ffffff;
    height: 100%;
    -webkit-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    width: 300px;
    overflow: scroll;
  }

  .shop-filter.show {
    left: 0;
  }
}

.dz-widget_services .form-check {
  padding-top: 5px;
  padding-bottom: 5px;
}

.book-grid-row .col-book.style-1 {
  width: 25%;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1024px) {
  .book-grid-row .col-book.style-1 {
    width: 33%;
  }
}

@media only screen and (max-width: 991px) {
  .book-grid-row .col-book.style-1 {
    width: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .book-grid-row .col-book.style-1 {
    width: 100%;
    margin-bottom: 0;
  }
}

.book-grid-row .col-book.style-2 {
  width: 33.33%;
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .book-grid-row .col-book.style-2 {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .book-grid-row .col-book.style-2 {
    width: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .book-grid-row .col-book.style-2 {
    width: 100%;
    margin-bottom: 0;
  }
}

.tabs-site-button .nav-tabs {
  margin-bottom: 32px;
}

.tabs-site-button .nav-tabs .nav-link {
  display: inline-block;
  padding: 15px 20px;
  color: var(--secondary);
  font-weight: 700;
  font-size: 18px;
  position: relative;
  line-height: 1.2;
  background-color: transparent;
  border: none;
}

@media only screen and (max-width: 575px) {
  .tabs-site-button .nav-tabs li {
    margin: auto;
  }
}

.tabs-site-button .nav-tabs a:after {
  content: "";
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background: var(--primary);
  height: 3px;
  position: absolute;
  opacity: 0;
}

.tabs-site-button .nav-tabs a.active:after {
  opacity: 1;
}

.order-confirm {
  color: #008000;
  font-size: 40px;
}

.thanks {
  color: #008000;
  text-align: center;
  font-family: var(--font-family-title);
  font-weight: 600;
  font-size: 25px;
}

.shipment {
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-top: 30px;
  padding: 10px 15px;
  margin-bottom: 25px;
}

.form-wizard {
  border: 1px solid transparent !important;
}

.form-wizard .nav-wizard {
  box-shadow: none !important;
  margin-bottom: 2.5rem;
  display: flex;
  align-items: center;
}

.form-wizard .nav-wizard li {
  flex: 0 0 25%;
  max-width: 25%;
}

.form-wizard .nav-wizard li .nav-link {
  display: flex;
  align-items: center;
  padding: 0;
}

.form-wizard .nav-wizard li .nav-link span {
  width: 48px;
  min-width: 48px;
  height: 48px;
  border: 5px solid #E2E2E2;
  line-height: 3rem;
  font-size: 1.125rem;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
}

.form-wizard .nav-wizard li .nav-link span i {
  opacity: 0;
}

.form-wizard .nav-wizard li .nav-link:after {
  content: "";
  border-radius: 0.25rem 0 0 0.25rem;
  flex: 1;
  height: 5px;
  background: #E1E1E1;
}

.form-wizard .nav-wizard li .nav-link.active span,
.form-wizard .nav-wizard li .nav-link.done span {
  background-color: var(--primary);
  color: #ffffff;
  border-color: var(--primary);
}

.form-wizard .nav-wizard li .nav-link.active span i,
.form-wizard .nav-wizard li .nav-link.done span i {
  opacity: 1;
}

.form-wizard .nav-wizard li .nav-link.active:after,
.form-wizard .nav-wizard li .nav-link.done:after {
  background-color: var(--primary);
}

.form-wizard .nav-wizard li .nav-link div {
  padding-right: 35px;
  color: #131045;
  font-size: 18px;
  font-weight: 600;
  padding-left: 35px;
}

.form-wizard .nav-wizard li:last-child .nav-link after {
  content: none;
}

.toolbar .btn,
.toolbar .wp-block-button__link,
.toolbar .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .toolbar a,
.toolbar .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .toolbar a,
.toolbar .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .toolbar input,
.toolbar .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .toolbar .button,
.toolbar .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .toolbar a.checkout-button,
.toolbar .woocommerce #respond input#submit,
.woocommerce #respond .toolbar input#submit,
.toolbar .woocommerce a.button,
.woocommerce .toolbar a.button,
.toolbar .woocommerce button.button,
.woocommerce .toolbar button.button,
.toolbar .woocommerce input.button,
.woocommerce .toolbar input.button,
.toolbar .woocommerce #place_order,
.woocommerce .toolbar #place_order {
  color: #ffffff;
  background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
}

.shop-account .account-detail {
  padding: 30px 0px;
  background-color: var(--rgba-primary-1);
  border-radius: 6px;
  margin-bottom: 25px;
}

.shop-account .account-detail .my-image {
  position: relative;
  display: inline-block;
  border: 2px solid var(--primary);
  outline-offset: 3px;
  border-radius: 100%;
  width: 150px;
  height: 150px;
}

.shop-account .account-detail .my-image img {
  border-radius: 100%;
  width: 100%;
  background-color: #fff;
  padding: 5px;
}

.shop-account .account-detail .account-title {
  margin-top: 25px;
}

.shop-account .account-list {
  list-style: none;
  margin-bottom: 0px;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
}

.shop-account .account-list li a {
  width: 100%;
  padding: 15px 20px;
  display: inline-block;
  border-top: 1px solid #eee;
  color: #232323;
  border-left: 3px solid rgba(0, 0, 0, 0);
}

.shop-account .account-list li a.active {
  background: #eaa4511a;
  border-left-color: var(--primary);
}

.shop-account .account-list li a:hover {
  background: #eaa4511a;
  border-left-color: var(--primary);
}

.shop-account .account-list li a i {
  color: var(--primary);
  margin-right: 14px;
}

.shop-bx {
  padding: 0 20px 30px;
  border-radius: 4px;
  background-color: var(--white);
}

.shop-bx .shop-bx-title {
  border-bottom: 1px solid var(--primary);
  padding: 10px 0;
  font-size: 22px;
  margin-bottom: 30px;
}

.dz-box.product {
  margin-bottom: 30px !important;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  height: calc(100% - 30px);
  background: #fff;
}

.dz-box.product .item-box .item-img {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.dz-box.product .item-box .item-img>img {
  width: 100%;
}

.dz-box.product .item-box .item-img:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 0;
  background: #212529;
  top: 0;
  opacity: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.dz-box.product .item-box .item-info {
  padding: 20px;
  text-align: center;
}

.dz-box.product .item-box .item-info .item-title {
  font-size: 20px;
  margin-bottom: 0;
  line-height: 1.2;
}

.dz-box.product .item-box .item-info .item-price {
  line-height: 1.2;
  margin-bottom: 0;
}

.dz-box.product .item-box .item-info .item-price .price {
  color: var(--primary);
  font-size: 16px !important;
  font-weight: 600;
  margin-bottom: 0;
}

.dz-box.product .item-box .item-info .item-price .woocommerce-Price-currencySymbol {
  font-size: 16px !important;
}

.dz-box.product .item-box .item-info .woocommerce-product-rating {
  margin: 0;
  float: unset;
  text-align: center;
}

.dz-box.product .item-box .item-info .woocommerce-product-rating .woocommerce-review-link {
  margin: 0;
}

.dz-box.product .item-box .item-info-in {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 30px 20px;
  z-index: 1;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  transform: translateY(100px);
  -moz-transform: translateY(100px);
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  -o-transform: translateY(100px);
  opacity: 0;
  left: 0;
}

.dz-box.product .item-box .item-info-in ul {
  margin: 0;
  padding: 0;
  text-align: center;
}

.dz-box.product .item-box .item-info-in ul li {
  list-style: none;
  display: inline-block;
  margin: 0 5px;
}

.dz-box.product .item-box .item-info-in ul li a {
  background: var(--primary);
  color: #fff;
  width: 45px;
  text-align: center;
  display: block;
  font-size: 16px;
  height: 45px;
  line-height: 45px;
  box-shadow: 0 5px 15px -10px var(--primary);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  border-radius: var(--border-radius-base);
}

.dz-box.product .item-box .item-info-in ul li a .fa-heart:before {
  font-weight: 500;
}

.dz-box.product .item-box .item-info-in ul li a i {
  margin: 0;
  padding: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  display: inline-block;
}

.dz-box.product .item-box .item-info-in ul li a:hover {
  background: var(--primary-hover);
}

.dz-box.product .item-box .item-info-in ul li a:hover i {
  transform: scale(1.25);
  -moz-transform: scale(1.25);
  -webkit-transform: scale(1.25);
  -ms-transform: scale(1.25);
  -o-transform: scale(1.25);
}

.dz-box.product .item-box .item-info-in ul li a.add_to_cart_button.added i:before {
  content: "\f217";
}

.dz-box.product .item-box .item-info-in ul li a.add_to_cart_button.loading i:before {
  content: "\f110";
}

.dz-box.product .item-box .item-info-in ul li .add_to_cart_button:before {
  content: none;
}

.dz-box.product .item-box .item-info-in ul li .yith-wcwl-add-to-wishlist {
  margin-top: 0;
}

.dz-box.product:hover {
  box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.15);
  transform: translateY(-15px);
  -moz-transform: translateY(-15px);
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  -o-transform: translateY(-15px);
}

.dz-box.product:hover .item-box .item-img:after {
  opacity: 0.7;
}

.dz-box.product:hover .item-box .item-img .item-info-in {
  transform: translateY(0);
  -moz-transform: translateY(0);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  opacity: 1;
}

.woocommerce div.product .dz-box .woocommerce-product-rating {
  display: block;
}

.woocommerce div.product .dz-box .woocommerce-product-rating .star-rating {
  float: none;
  position: relative;
  display: inline-block;
  font-size: 13px;
  top: 2px;
}

.woocommerce div.product .dz-box .woocommerce-product-rating .star-rating span {
  font-size: 13px;
}

.woocommerce div.product .dz-box .woocommerce-product-rating .woocommerce-review-link {
  display: inline-block;
}

.woocommerce div.product .dz-box .woocommerce-product-rating .woocommerce-review-link .count {
  margin-right: 4px;
}

.woocommerce .woocommerce-ordering {
  margin: 0 0 30px;
}

.woocommerce nav.woocommerce-pagination ul li {
  overflow: unset;
}

.woocommerce nav.woocommerce-pagination ul li span,
.woocommerce nav.woocommerce-pagination ul li a {
  height: 45px;
  width: 45px;
  line-height: 42px !important;
  border-radius: 0;
  color: #777777;
  text-align: center;
  border: 0;
  padding: 0;
  transition: all 0.5s;
  border: 1px solid;
  border-color: #777777;
  font-size: 18px;
  background-color: #fff;
}

.woocommerce nav.woocommerce-pagination ul li span:focus,
.woocommerce nav.woocommerce-pagination ul li span:hover,
.woocommerce nav.woocommerce-pagination ul li span.current,
.woocommerce nav.woocommerce-pagination ul li a:focus,
.woocommerce nav.woocommerce-pagination ul li a:hover,
.woocommerce nav.woocommerce-pagination ul li a.current {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: 0px 5px 12px var(--rgba-primary-4);
}

.woocommerce a.remove {
  color: #000 !important;
  display: block;
  font-size: 14px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  width: 22px;
  opacity: 1 !important;
  background: #fff;
  padding: 0;
  border: 1px solid #000;
  border-radius: 0;
}

.woocommerce ul.cart_list li a,
.woocommerce ul.product_list_widget li a {
  font-family: var(--font-family-base);
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  color: var(--title);
  margin-bottom: 5px;
}

.woocommerce .product_list_widget .star-rating {
  position: relative;
  font-size: 13px;
}

.woocommerce .product_list_widget .star-rating span {
  font-size: 13px;
}

.woocommerce .widget_shopping_cart .cart_list li a.remove,
.woocommerce.widget_shopping_cart .cart_list li a.remove {
  left: auto;
  right: 0;
}

.woocommerce .widget_shopping_cart .cart_list li,
.woocommerce.widget_shopping_cart .cart_list li {
  padding-left: 0;
}

.woocommerce ul.cart_list li,
.woocommerce ul.product_list_widget li {
  padding: 0 0 15px 0;
  border-bottom: 1px solid #eee;
  margin-bottom: 15px;
}

.woocommerce ul.cart_list li:last-child,
.woocommerce ul.product_list_widget li:last-child {
  border: 0;
  margin-bottom: 0;
}

.woocommerce ul.cart_list li,
.woocommerce ul.product_list_widget li .amount {
  color: var(--primary);
  font-size: 13px;
  font-weight: 600;
}

.woocommerce .widget_shopping_cart .total strong,
.woocommerce.widget_shopping_cart .total strong {
  margin-right: 10px;
}

.woocommerce-mini-cart__buttons.buttons {
  justify-content: center;
}

.woocommerce .widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons a {
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 10px 15px;
  text-transform: uppercase;
}

.woocommerce .widget_shopping_cart .total,
.woocommerce.widget_shopping_cart .total {
  border-top: 3px solid #ebe9eb;
  padding: 10px 0 10px;
}

.widget_shopping_cart_content p strong {
  margin-right: 10px;
  color: var(--title);
}

.widget_shopping_cart_content p .amount {
  color: var(--primary);
}

.woocommerce .widget_price_filter .price_slider_amount .button {
  background: var(--primary);
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 600;
  width: auto;
}

.woocommerce .widget_rating_filter ul li {
  position: relative;
}

.woocommerce .woocommerce.widget_price_filter .ui-slider .ui-slider-range {
  background-color: var(--primary) !important;
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-handle {
  border: 2px solid var(--primary);
}

.woocommerce .widget_price_filter .price_slider_amount .price_label {
  font-weight: 700;
  color: var(--title);
}

.widget_product_categories ul li {
  border: 0;
  color: inherit;
  font-family: inherit;
  text-align: right;
  display: table;
  width: 100%;
  padding: 0.5rem 0rem 0.5rem 1.25rem !important;
  line-height: 1.3 !important;
}

.widget_product_categories ul li a {
  color: inherit;
  float: left;
  text-transform: capitalize;
  text-align: left;
  position: relative;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.widget_product_categories ul li a:after {
  content: "\f101";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  display: block;
  left: -1.25rem;
  top: 0.063rem;
  font-size: 14px;
  font-weight: 900;
}

.widget_product_categories ul li a:hover {
  color: var(--primary);
  transform: translateX(10px);
}

.widget_product_categories ul li ul {
  margin-bottom: -0.625rem;
}

.widget_product_categories ul ul {
  padding-left: 1.125rem;
  margin-top: 0.5rem;
  margin-left: -0.9375rem;
}

.woocommerce div.product p.price,
.woocommerce div.product span.price,
.woocommerce div.product .price ins span {
  font-weight: 700;
  font-size: 24px !important;
  color: var(--primary);
  display: inline-block;
  font-family: inherit;
  line-height: 1.2;
  margin: 0 0 15px;
}

.woocommerce div.product p.price .woocommerce-Price-amount.amount,
.woocommerce div.product span.price .woocommerce-Price-amount.amount,
.woocommerce div.product .price ins span .woocommerce-Price-amount.amount {
  font-size: inherit !important;
  margin-bottom: 0;
}

.woocommerce div.product p.price .woocommerce-Price-currencySymbol,
.woocommerce div.product span.price .woocommerce-Price-currencySymbol,
.woocommerce div.product .price ins span .woocommerce-Price-currencySymbol {
  margin-bottom: 0;
  font-weight: inherit;
}

@media only screen and (max-width: 575px) {

  .woocommerce div.product p.price,
  .woocommerce div.product span.price,
  .woocommerce div.product .price ins span {
    font-size: 18px !important;
  }

  .woocommerce div.product p.price .woocommerce-Price-currencySymbol,
  .woocommerce div.product span.price .woocommerce-Price-currencySymbol,
  .woocommerce div.product .price ins span .woocommerce-Price-currencySymbol {
    font-size: 14px;
  }
}

.woocommerce div.product .price ins {
  margin-bottom: 0;
}

.woocommerce-product-details__short-description {
  margin-bottom: 20px;
  display: block;
  clear: both;
}

.product_meta {
  color: var(--title);
  margin-bottom: 20px;
  font-weight: 500;
}

.product_meta a {
  margin: 0 4px;
  font-weight: 500;
}

.woocommerce-review-link {
  color: inherit;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.5;
  padding: 0;
  margin: 30px 0 0 0;
}

.woocommerce div.product .woocommerce-product-rating {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  text-align: right;
  float: right;
  margin: -25px 0 20px;
}

@media only screen and (max-width: 575px) {
  .woocommerce div.product .woocommerce-product-rating {
    display: block;
    float: left;
    margin: 0 0 10px;
  }
}

.price-rating-area {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border: 1px solid #eee;
  border-width: 1px 0 1px 0;
  align-items: center;
  margin-bottom: 20px;
}

.price-rating-area .main-price {
  margin-bottom: 0;
}

.price-rating-area .star-rating {
  margin: 0;
  float: right;
  position: relative !important;
}

.woocommerce .woocommerce-product-rating .star-rating {
  margin: 0;
  float: right;
}

@media only screen and (max-width: 575px) {
  .woocommerce .woocommerce-product-rating .star-rating {
    float: left;
    position: relative;
  }
}

.woocommerce div.product form.cart .variations label {
  font-weight: 500;
  color: var(--title);
  margin: 0;
}

.cart .quantity input.input-text {
  font-size: 18px;
  height: 60.5px !important;
  border-radius: var(--border-radius-base) !important;
  text-align: left;
  padding: 0 20px;
}

@media only screen and (max-width: 767px) {
  .cart .quantity input.input-text {
    height: 51px !important;
  }
}

.woocommerce.woo-variation-swatches.wvs-style-squared .variable-items-wrapper .variable-item {
  border-radius: 0;
}

.woocommerce.woo-variation-swatches .variable-items-wrapper .variable-item:not(.radio-variable-item) {
  background-color: var(--primary);
  color: #fff;
  font-size: 14px;
  box-shadow: none;
  padding: 0px;
  box-shadow: none;
}

.woocommerce .button-variable-item span {
  font-size: 13px;
  font-weight: 600;
}

.woocommerce-variation-add-to-cart .btn,
.woocommerce-variation-add-to-cart .wp-block-button__link,
.woocommerce-variation-add-to-cart .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-variation-add-to-cart a,
.woocommerce-variation-add-to-cart .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-variation-add-to-cart a,
.woocommerce-variation-add-to-cart .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .woocommerce-variation-add-to-cart input,
.woocommerce-variation-add-to-cart .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce-variation-add-to-cart .button,
.woocommerce-variation-add-to-cart .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-variation-add-to-cart a.checkout-button,
.woocommerce-variation-add-to-cart .woocommerce #respond input#submit,
.woocommerce #respond .woocommerce-variation-add-to-cart input#submit,
.woocommerce-variation-add-to-cart .woocommerce a.button,
.woocommerce .woocommerce-variation-add-to-cart a.button,
.woocommerce-variation-add-to-cart .woocommerce button.button,
.woocommerce .woocommerce-variation-add-to-cart button.button,
.woocommerce-variation-add-to-cart .woocommerce input.button,
.woocommerce .woocommerce-variation-add-to-cart input.button,
.woocommerce-variation-add-to-cart .woocommerce #place_order,
.woocommerce .woocommerce-variation-add-to-cart #place_order {
  text-transform: uppercase;
}

.woocommerce.woo-variation-swatches .variable-items-wrapper {
  margin-bottom: 10px !important;
}

.woocommerce div.product form.cart {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

#tab-reviews,
#tab-description {
  background: transparent !important;
  margin-top: 0px;
  padding: 0;
  border: 0;
}

.woocommerce div.product .woocommerce-tabs .panel {
  padding: 0;
  background: transparent;
}

.woocommerce div.product .woocommerce-tabs ul.tabs {
  padding: 0px !important;
  margin: 0px 0px 30px !important;
  border: none !important;
  border-bottom: 1px solid #eee !important;
  overflow: unset;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li {
  margin-bottom: 0;
  background: transparent !important;
  border: 0;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li a {
  color: var(--title);
  border: 0;
  margin: 0;
  padding: 10px 20px;
  position: relative;
  background: transparent;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li a:before {
  bottom: 0;
  background: var(--primary);
  top: auto;
  width: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  opacity: 1;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li:hover a,
.woocommerce div.product .woocommerce-tabs ul.tabs li.active a {
  background: transparent;
  color: var(--primary);
}

.woocommerce div.product .woocommerce-tabs ul.tabs li:hover a:before,
.woocommerce div.product .woocommerce-tabs ul.tabs li.active a:before {
  width: 100%;
  opacity: 1;
}

.woocommerce #review_form #respond p {
  width: 100%;
}

.woocommerce #review_form #respond p {
  margin: 0px 0px 20px !important;
  display: inline-block;
}

#review_form {
  padding: 30px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
  margin: 30px 0;
}

@media only screen and (max-width: 767px) {
  #review_form {
    padding: 20px;
  }
}

#review_form .comment-form-rating {
  margin-bottom: 0;
  display: block;
  width: 100%;
  padding: 0 10px;
}

#review_form .comment-form-rating p {
  display: inline-block !important;
  width: auto !important;
  top: 5px;
  position: relative;
}

#review_form .comment-respond .comment-form p label {
  display: inline-block;
}

#review_form #respond .comment-form-email,
#review_form #respond .comment-form-author {
  width: 50% !important;
}

.woocommerce #reviews #comments ol.commentlist li {
  border-bottom: 1px solid #eee;
}

.woocommerce #reviews #comments ol.commentlist li img.avatar {
  width: 80px;
  border: 0;
  padding: 0;
}

.woocommerce #reviews #comments ol.commentlist li .comment-text {
  margin: 0 0 0 95px;
  padding: 0;
  border: 0;
  border-radius: 0;
}

.woocommerce #reviews #comments ol.commentlist li .comment-text p {
  font-size: 16px;
  font-weight: 400;
}

.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta {
  color: var(--primary);
  font-weight: 500;
}

.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta strong {
  color: var(--title);
  font-size: 20px;
  display: block;
  font-family: var(--font-family-title);
  text-transform: uppercase;
  line-height: 1.2;
  margin-bottom: 5px;
}

.woocommerce .star-rating {
  font-size: 15px;
  color: var(--primary);
  position: absolute;
  right: 0px;
  top: 5px;
}

.woocommerce .star-rating span {
  font-size: 15px;
  color: var(--primary);
}

.woocommerce #reviews #comments h2,
.woocommerce #reviews #comments .h2,
#tab-description h2,
#tab-description .h2,
.woocommerce div.product .woocommerce-tabs .panel h2,
.woocommerce div.product .woocommerce-tabs .panel .h2 {
  font-family: var(--font-family-title);
  color: var(--title);
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.woocommerce-cart-form {
  overflow: auto;
}

.woocommerce-cart-form .table {
  font-size: 14px;
  font-weight: 500;
}

.woocommerce-cart-form .table tr {
  vertical-align: middle;
}

.woocommerce-cart-form .table tr td.actions .coupon .input-text {
  height: 50px;
  width: 180px;
  display: inline-block;
  margin-right: 10px;
}

.woocommerce-cart-form .table tr th {
  color: var(--title);
  font-weight: 700;
  font-size: 16px;
}

@media only screen and (max-width: 575px) {
  .woocommerce-cart-form .table tr th {
    font-size: 14px;
  }
}

.woocommerce-cart-form .table tr th.product-thumbnail {
  width: 100px;
}

.woocommerce-cart-form .table tr th.product-remove {
  width: 40px;
}

.woocommerce-cart-form .table tr img {
  width: 80px;
}

.woocommerce-cart-form .table tr .product-item-name {
  font-weight: 600;
  font-size: 16px;
}

.woocommerce-cart-form .table tr .quantity input {
  height: 50px;
  width: 70px;
  padding: 0 10px 0 15px;
  text-align: left;
}

.woocommerce-cart-form .table tr .button {
  padding: 14px 20px;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
}

.woocommerce .cart_totals h2,
.woocommerce .cart_totals .h2 {
  font-family: var(--font-family-title);
  background: var(--primary);
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  padding: 15px 20px;
  font-weight: 500;
}

.cart_totals {
  color: var(--title);
}

.woocommerce-cart .wc-proceed-to-checkout a.checkout-button {
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  padding: 13px 30px;
  display: block;
}

.woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="text"],
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="email"],
.woocommerce .woocommerce-checkout.checkout form .form-row input[type="tel"],
.woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text,
.woocommerce .woocommerce-checkout.checkout form .form-row input {
  height: 50px;
}

.woocommerce .woocommerce-checkout.checkout .col-2,
.woocommerce .woocommerce-checkout.checkout .col-1 {
  padding-left: 15px;
  padding-right: 15px;
}

.woocommerce-EditAccountForm.edit-account input[type="text"],
.woocommerce-EditAccountForm.edit-account input[type="email"],
.woocommerce-EditAccountForm.edit-account input[type="password"],
.login input[type="text"],
.login input[type="password"],
.woocommerce-ResetPassword.lost_reset_password input[type="text"],
.checkout.woocommerce-checkout input[type="text"],
.checkout.woocommerce-checkout input[type="email"],
.checkout.woocommerce-checkout input[type="tel"],
.checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout textarea,
.woocommerce form .form-row input.input-text,
.woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .select2-choice {
  height: 50px;
}

.woocommerce form .form-row-first,
.woocommerce form .form-row-last,
.woocommerce-page form .form-row-first,
.woocommerce-page form .form-row-last {
  width: 48%;
}

.lost_reset_password .button {
  display: block;
  width: 100%;
  text-align: center;
}

.woocommerce form .form-row label {
  font-family: var(--font-family-base);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
}

.woocommerce .col2-set,
.woocommerce-page .col2-set {
  width: auto;
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.woocommerce form .form-row {
  margin: 0 0 15px;
  padding: 0;
}

.woocommerce-additional-fields h3,
.woocommerce-additional-fields .h3 {
  color: var(--title);
  font-family: var(--font-family-title);
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4em;
  margin: 0 0 20px;
  text-transform: uppercase;
}

.woocommerce table.shop_table thead th {
  color: #fff;
  background: var(--primary);
  font-family: var(--font-family-title);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
}

.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce #place_order {
  text-transform: uppercase;
  text-align: center;
  padding: 13px 30px;
}

.woocommerce-form-coupon-toggle {
  display: none;
}

.payment_methods input+label {
  font-weight: 600;
  color: var(--title);
  padding-left: 30px;
  line-height: 1.2;
  position: relative;
}

.payment_methods input+label:after {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 18px;
  background: #fff;
  border: 2px solid var(--primary);
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.payment_methods input:checked+label:after {
  border-width: 2px;
  background: var(--primary);
  box-shadow: inset 0px 0 0px 3px #fff;
  border-color: var(--primary);
}

.woocommerce-checkout #payment div.payment_box::after {
  content: none;
}

.yith-wcwl-wishlistexistsbrowse,
.yith-wcwl-wishlistaddedbrowse {
  position: relative;
  top: 17px;
}

.yith-wcwl-wishlistexistsbrowse .feedback,
.yith-wcwl-wishlistaddedbrowse .feedback {
  display: inline-block;
  padding: 0 !important;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
  line-height: 45px;
  text-align: center;
}

.yith-wcwl-wishlistexistsbrowse .yith-wcwl-icon,
.yith-wcwl-wishlistaddedbrowse .yith-wcwl-icon {
  margin-right: 0 !important;
}

.yith-wcwl-wishlistexistsbrowse .yith-wcwl-icon:before,
.yith-wcwl-wishlistaddedbrowse .yith-wcwl-icon:before {
  font-weight: 900;
}

.woocommerce form.woocommerce-form.checkout_coupon,
.woocommerce-ResetPassword,
.woocommerce form.woocommerce-form.login,
.woocommerce form.woocommerce-form.register {
  border: 0;
  box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.15);
  padding: 30px;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}

.woocommerce-ResetPassword .form-row-first {
  width: 100% !important;
}

.woocommerce-Addresses .col-2,
.woocommerce-Addresses .col-1 {
  padding-left: 15px;
  padding-right: 15px;
}

.product-details .product_meta>span {
  display: block;
}

.pswp {
  z-index: 9999;
}

.shop-item-rating .woocommerce-product-rating .count {
  margin-left: 2px;
  margin-right: 5px;
}

.woocommerce .woocommerce-form-login .woocommerce-form-login__submit {
  float: unset;
  margin-right: 0;
  display: block;
  margin-top: 10px;
}

.woocommerce-cart-form .button:disabled {
  color: #fff !important;
}

.minicart .widget_shopping_cart .woocommerce-Price-amount.amount {
  text-align: right;
  float: right;
}

.minicart .widget_shopping_cart .total span {
  margin-right: 5px;
  float: unset;
}

/* Extra css */
.woocommerce .product-details .product form .woo-selected-variation-item-name {
  display: none !important;
}

.woocommerce .product-details .product form .reset_variations {
  display: none !important;
}

.woocommerce .product-details .product form.cart .button {
  background-color: var(--primary);
  border-color: var(--primary);
}

.woocommerce .product-details .product .yith-wcwl-wishlistexistsbrowse {
  position: unset;
}

.woocommerce .product-details .product .yith-wcwl-wishlistexistsbrowse .feedback {
  display: block !important;
  margin-bottom: 15px;
  position: unset;
  background-color: var(--primary);
  color: #fff;
  border-radius: var(--border-radius-base);
}

.woocommerce .product-details .product .yith-wcwl-wishlistexistsbrowse .feedback .yith-wcwl-icon {
  margin-right: 8px !important;
}

.woocommerce .product-details .product .yith-wcwl-wishlistexistsbrowse a {
  display: none;
}

.woocommerce .product-details .product .yith-wcwl-add-button {
  display: none;
}

.woocommerce .product-details .product .related-product .yith-wcwl-wishlistexistsbrowse {
  position: relative;
}

.woocommerce .product-details .product .related-product .yith-wcwl-wishlistexistsbrowse .feedback {
  position: absolute;
}

.woocommerce .product-details .product .related-product .yith-wcwl-wishlistexistsbrowse .feedback .yith-wcwl-icon {
  margin-right: 0 !important;
}

.woocommerce .product-details .product .related-product .yith-wcwl-add-button {
  display: block;
}

.woocommerce .woocommerce-product-rating .woocommerce-review-link .count {
  margin-right: 4px;
}

.woo-variation-swatches.wvs-style-squared .variable-items-wrapper .variable-item.button-variable-item {
  width: auto;
  padding: 0px 5px;
}

.woocommerce.single-product .product .summary-content .single_add_to_cart_button.button {
  background-color: var(--primary);
  border-color: var(--primary);
}

.woocommerce.single-product .product .summary-content .product_meta>span {
  display: block;
}

#yith-quick-view-modal .yith-wcqv-wrapper {
  height: auto !important;
}

.yith-wcqv-wrapper #yith-quick-view-close {
  border: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  opacity: 1;
  background-color: var(--primary);
  color: #fff;
}

/* Mini Cart Start */
.shop-cart .site-button-link {
  color: #fff;
  position: relative;
}

.shop-cart .site-button-link i {
  margin-left: 0;
}

.shop-cart .site-button-link .badge {
  padding: 2px 4px 2px 4px;
  font-size: 10px;
  top: -6px;
  position: absolute;
  right: -12px;
}

.minicart {
  background: #fff;
  padding: 25px !important;
  position: absolute;
  right: 0;
  top: 100%;
  width: 350px;
  z-index: 99;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
  display: none;
  color: #777;
  margin-top: 0;
  max-height: 450px;
  overflow-y: auto;
}

.minicart .cart-content a {
  color: #10111e;
  font-size: 14px;
  background: none;
}

.minicart .cart-content a:hover {
  color: #fe4536;
  background: none;
}

.minicart .acod-title {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.minicart .cart-img img {
  width: 85px;
}

.minicart .cart-price span {
  color: #ddd;
  font-size: 13px;
}

.minicart .cart-price .new {
  font-size: 14px;
  color: #747691;
}

.minicart .cart-price span {
  color: #a5a7bc;
  font-size: 13px;
  font-weight: 500;
}

.minicart .total-price {
  border-top: 1px solid #cacadb;
  overflow: hidden;
  padding-top: 25px;
  margin-top: 10px;
}

.minicart .total-price span {
  color: #747691;
  font-weight: 500;
}

.minicart .checkout-link a {
  background: #84b77c;
  color: #fff;
  display: block;
  font-weight: 500;
  padding: 16px 30px;
  text-align: center;
  font-size: 13px;
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.minicart .checkout-link a:last-child {
  margin: 0;
}

.minicart .checkout-link a:hover,
.minicart .checkout-link a.red-color {
  background: #fe4536;
}

.minicart .checkout-link a.red-color:hover {
  background: #84b77c;
}

.woocommerce-widget-layered-nav ul li,
.wc-block-product-categories ul li,
.widget_product_categories ul li {
  border: 0;
  color: inherit;
  font-family: inherit;
  text-align: right;
  display: table;
  width: 100%;
  padding: 0.5rem 0rem 0.5rem 1.25rem !important;
  line-height: 1.3 !important;
}

.woocommerce-widget-layered-nav ul li a,
.wc-block-product-categories ul li a,
.widget_product_categories ul li a {
  color: inherit;
  float: left;
  text-transform: capitalize;
  text-align: left;
  position: relative;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.woocommerce-widget-layered-nav ul li a:after,
.wc-block-product-categories ul li a:after,
.widget_product_categories ul li a:after {
  content: "\f101";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  display: block;
  left: -1.25rem;
  top: 0.063rem;
  font-size: 14px;
  font-weight: 900;
}

.woocommerce-widget-layered-nav ul li a:hover,
.wc-block-product-categories ul li a:hover,
.widget_product_categories ul li a:hover {
  color: var(--primary);
  transform: translateX(10px);
}

.woocommerce-widget-layered-nav ul li ul,
.wc-block-product-categories ul li ul,
.widget_product_categories ul li ul {
  margin-bottom: -0.625rem;
}

.woocommerce-widget-layered-nav ul ul,
.wc-block-product-categories ul ul,
.widget_product_categories ul ul {
  padding-left: 1.125rem;
  margin-top: 0.5rem;
  margin-left: -0.9375rem;
}

.wp-block-woocommerce-product-search form {
  margin-bottom: 10px;
}

.wp-block-woocommerce-product-search .wc-block-product-search__label {
  display: none;
}

.wp-block-woocommerce-product-search .wc-block-product-search__fields {
  position: relative;
}

.wp-block-woocommerce-product-search input {
  border-radius: var(--border-radius-base) !important;
  background-color: #fff;
  box-shadow: 1px 1.732px 60px 0px rgba(0, 0, 0, 0.2);
  border: 0;
  z-index: 0 !important;
  height: 60px;
  padding: 6px 60px 6px 20px !important;
  display: block;
  width: 100%;
}

.wp-block-woocommerce-product-search .wc-block-product-search__button {
  height: 50px;
  width: 50px;
  font-size: 24px;
  padding: 0;
  justify-content: center;
  border-radius: var(--border-radius-base);
  position: absolute;
  top: 0;
  right: 6px;
  display: flex;
  align-items: center;
  background-color: var(--primary);
  border: 0;
  color: #fff;
  transform: translateY(-50%);
  top: 50%;
}

.wc-block-grid .wc-block-grid__products .add_to_cart_button,
.wp-block-product-tag .wc-block-grid__products .add_to_cart_button,
.wp-block-products-by-attribute .wc-block-grid__products .add_to_cart_button,
.wc-block-product-category .wc-block-grid__products .add_to_cart_button {
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  display: block;
  border-radius: var(--border-radius-sm);
}

.wc-block-grid .wc-block-grid__products .add_to_cart_button:before,
.wp-block-product-tag .wc-block-grid__products .add_to_cart_button:before,
.wp-block-products-by-attribute .wc-block-grid__products .add_to_cart_button:before,
.wc-block-product-category .wc-block-grid__products .add_to_cart_button:before {
  content: none;
}

.shop-widget .wp-block-group__inner-container>h5,
.shop-widget .wp-block-group__inner-container>.h5,
.shop-widget.widget.widget_block .wp-block-group__inner-container h4,
.shop-widget.widget.widget_block .wp-block-group__inner-container .h4 {
  position: relative;
  padding-left: 20px;
  margin-bottom: 25px;
  font-weight: 700;
  text-transform: uppercase;
}

.shop-widget .wp-block-group__inner-container>h5:before,
.shop-widget .wp-block-group__inner-container>.h5:before,
.shop-widget.widget.widget_block .wp-block-group__inner-container h4:before,
.shop-widget.widget.widget_block .wp-block-group__inner-container .h4:before {
  content: "";
  height: 100%;
  width: 5px;
  border-radius: 10px;
  background-color: var(--primary);
  position: absolute;
  left: 0;
  top: 0;
}

.pswp .pswp__bg {
  background: rgba(0, 0, 0, 0.9);
}

.pswp .pswp__caption__center {
  text-align: center;
}

.woocommerce .woocommerce-ordering select {
  border: 1px solid #999 !important;
}

body.woo-variation-swatches.wvs-style-squared .variable-items-wrapper .variable-item.button-variable-item {
  width: 35px;
  height: 35px;
}

.variable-item:not(.radio-variable-item) {
  width: 35px;
  height: 35px;
  border: 2px solid #fff;
}

body.woo-variation-swatches .variable-items-wrapper .variable-item:not(.radio-variable-item).selected,
body.woo-variation-swatches .variable-items-wrapper .variable-item:not(.radio-variable-item).selected:hover,
body.woo-variation-swatches .variable-items-wrapper .variable-item:not(.radio-variable-item).selected,
body.woo-variation-swatches .variable-items-wrapper .variable-item:not(.radio-variable-item).selected:hover {
  border: 2px solid #fff;
  box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.3);
}

.woocommerce div.product form.cart .variations {
  margin-bottom: 0;
}

.woocommerce div.product .woocommerce-variation-price span.price {
  font-size: 16px !important;
}

.woocommerce div.product div.images .woocommerce-product-gallery__image:nth-child(n+2) {
  padding: 4px 2px;
}
.h2WhiteColor{
  color: white;
}

/*Gallery*/
#Gallery{
  margin-left: 40px;
  padding-top: 20px;
}



.header-nav .nav > li > a {
  color: #fff;
  font-size: 15px;
  padding: 15px 15px;
  font-family: 'Roboto', Arial, sans-serif;
  cursor: pointer;
  font-weight: 400;
  display: inline-block;
  position: relative;
  border-width: 0 0 0 1px;
  text-transform: capitalize;
  line-height: 1;
}
.container, .container-sm, .container-md, .container-lg, .container-xl {
  max-width: 1500px;
}
.header-nav .nav > li {
  margin: 0px;
  font-weight: 400;
  position: relative;
}
.header-nav .nav > li.open .mega-menu, .header-nav .nav > li.open .sub-menu {
  display: block;
  opacity: 1;
  transition: none;
}
.header-nav .nav > li:hover > .mega-menu, .header-nav .nav > li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  transition: all 1s;
}

.header-nav .nav > li .mega-menu, .header-nav .nav > li .sub-menu {
  background-color: #fff;
  display: block;
  left: 5px;
  list-style: none;
  opacity: 0;
  padding: 15px 0;
  position: absolute;
  visibility: hidden;
  width: 220px;
  z-index: 10;
  margin-top: 20px;
  text-align: left;
  border-radius: 0 var(--border-radius-base) var(--border-radius-base) var(--border-radius-base);
  transition: all 1s;
  box-shadow: 0 20px 40px rgb(0 0 0 / 5%);
}
.header-nav .nav > li .mega-menu li a, .header-nav .nav > li .sub-menu li a {
  color: #212529;
  display: block;
  font-size: 14px;
  padding: 10px 20px;
  line-height: 1.3;
  text-transform: capitalize;
  position: relative;
  transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  font-weight: 400;
  background: white;
}
.header-nav .nav > li.sub-menu-down > a:after {
  content: "\f078";
  font-size: 9px;
  margin-left: 5px;
  margin-top: 0;
  vertical-align: middle;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
}
.pl{
  padding-left: 5px;

}
.pl25{
  padding-left: 15px;

}
.shoppingWidget {
  margin-bottom: 25px;
}
.boldFont{
   font-weight: 700;
}
#toolbarContainer #toolbarViewer div.toolbar {
  display: none !important; /* hide PDF viewer toolbar */
}
#toolbarContainer #toolbarViewer #viewerContainer {
  top: 0 !important; /* move doc up into empty bar space */
}
@media screen and (max-width: 700px)
{
    .main-slider h2 {      
        font-size: 12px;
        font-weight: bold;
        line-height: 20px;
        margin: 0 0 32px;
        top: 50px;       
    }
}

@media only screen and (max-width: 991px)
{
  .header-nav .nav > li .mega-menu, .header-nav .nav > li .sub-menu  {
      display: none;
      position: static;
      visibility: visible;
      width: auto;
      background: transparent;
      box-shadow: none;
      margin: 0;
      border-top: 1px solid #eee;
      padding: 0 0;
      transition: none;
      opacity: 1;
      color: #fff !important;
      font-size: 12px;
  }
}

@media only screen and (max-width: 991px){
  .reg-header {
      max-width: 180px;
      height: 80px;
  }
}

.logo-headerContents {
   margin: 15px;
   width: 50px !important;
   
}

.logo-headerProfile {
  margin-top: 15px;
  width: 50px !important;
  
}
.logo-headerSearch {
  margin-top: 10px;
  width: 230px !important;
  
}

.divPdf{
  overflow-x:hidden;
  overflow-y:auto;
  height:800px;
  padding-top: 10px;
  ;
  justify-content: center;
  background-color: #b5b6b8;
  outline: #b5b6b8;
}
.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}


.loader{
  background-color: 'white';
  opacity: 0.7
  }

  .backgroundWarning{
      background-color: #ffdd59;
      border-radius: 4px;
      padding-left: 10px;
      
  }
  .margin-center{

 margin-top: 35px;
  }

styleborderBottom {
  border-bottom: 1px solid ;
  border-bottom-color: #898c90;
   
};